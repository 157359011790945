import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext, useProjectProductContext } from "hooks";
import { ProjectProductForm } from "forms";
import { useUpdateProjectProductMutation } from "graphql/mutations";
import { useList } from "react-use";

export const ProjectProductUpdateView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectProductsView, toProjectProductView } = useRouting();
  const { project } = useProjectContext();
  const { projectProduct } = useProjectProductContext();
  const [errors, { set: setErrors }] = useList<string>();
  const { updateProjectProduct, updateProjectProductLoading } = useUpdateProjectProductMutation((projectId, projectProductId) => {
    navigate(toProjectProductView(projectId, projectProductId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Modifier le produit"
      permissions={[[Permission.READ_PROJECT_PRODUCTS, Permission.UPDATE_PROJECT_PRODUCTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Produits",
          href: toProjectProductsView(project.id),
        },
        {
          label: projectProduct.name,
          href: toProjectProductView(project.id, projectProduct.id),
        },
      ]}
    >
      <ProjectProductForm
        initialValues={{
          name: projectProduct.name,
          variant: projectProduct.variant,
          categoryId: projectProduct.categoryId,
          description: projectProduct.description,
          activityIds: [...projectProduct.activityIds],
          provider: {
            id: projectProduct.provider.id,
            productModel: projectProduct.provider.productModel,
            productCode: projectProduct.provider.productCode,
            productUrl: projectProduct.provider.productUrl,
          },
          dimension: {
            unitOfMeasure: projectProduct.dimension.unitOfMeasure,
            length: projectProduct.dimension.length,
            width: projectProduct.dimension.width,
            height: projectProduct.dimension.height,
          },
        }}
        errors={errors}
        loading={updateProjectProductLoading}
        onSubmit={async (values) => {
          await updateProjectProduct(project.id, projectProduct.id, {
            description: values.description,
            activityIds: values.activityIds,
            provider: {
              id: values.provider.id,
              productModel: values.provider.productModel,
              productCode: values.provider.productCode,
              productUrl: values.provider.productUrl,
            },
          });
        }}
        onCancel={() => {
          navigate(toProjectProductView(project.id, projectProduct.id));
        }}
      />
    </View>
  );
};
