import { FunctionComponent, useState } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext } from "hooks";
import { useCreateProjectReportSetupMutation } from "graphql/mutations";
import { useList } from "react-use";
import { ProjectReportSetupForm } from "forms";
import { ProjectReportSetupFormValues } from "types";
import { PersistentStorageOperation } from "graphql/globalTypes";

export const ProjectReportSetupCreateView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectReportsView, toProjectReportSetupView, toProjectReportSetupsView } = useRouting();
  const { project, projectModels } = useProjectContext();
  const [errors, { set: setErrors }] = useList<string>();
  const [values, setValues] = useState<ProjectReportSetupFormValues>({
    name: "",
    rooms: [],
  });
  const { createProjectReportSetup, createProjectReportSetupLoading } = useCreateProjectReportSetupMutation((projectId, projectReportSetupId) => {
    navigate(toProjectReportSetupView(projectId, projectReportSetupId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Créer un paramétrage"
      form={{
        loading: createProjectReportSetupLoading,
        onSubmit: async () => {
          await createProjectReportSetup(project.id, {
            name: values.name,
            rooms: values.rooms.map((room) => ({
              id: room.id,
              name: room.name,
              elements: room.elements.map((element) => ({
                id: element.id,
                name: element.name,
                _operation: element._operation,
              })),
              _operation: room._operation,
            })),
          });
        },
        onCancel: () => {
          navigate(toProjectReportSetupsView(project.id));
        },
      }}
      permissions={[[Permission.READ_PROJECT_REPORT_SETUPS, Permission.UPDATE_PROJECT_REPORT_SETUPS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Rapports",
          href: toProjectReportsView(project.id),
        },
        {
          label: "Paramétrages",
          href: toProjectReportSetupsView(project.id),
        },
      ]}
    >
      <ProjectReportSetupForm _operation={PersistentStorageOperation.CREATE} projectModels={projectModels} values={values} setValues={setValues} errors={errors} />
    </View>
  );
};
