import "./index.less";
import { FunctionComponent, useState } from "react";
import { View, ViewFilters } from "buildingBlocks";
import { Permission } from "enums";
import { useConstuctionReportByUnitFloorDocumentContext, useLayout, useProjectContext, useRouting } from "hooks";
import { Button, ButtonToolbar, SelectPicker } from "rsuite";
import { ProjectActivity } from "graphql/__generated__/ProjectActivity";
import { ProjectReportSetup } from "graphql/__generated__/ProjectReportSetup";
import { ConstructionSheetPerFloorDocument } from "documents/ConstructionSheetPerFloorDocument";

export const ProjectReportsConstructionSheetPerFloorView: FunctionComponent = () => {
  const { toProjectsView, toProjectView, toProjectReportsView } = useRouting();
  const { project, projectActivities, projectReportSetups, projectUnits, projectModels, projectProducts, projectContracts } = useProjectContext();
  const [projectActivity, setProjectActivity] = useState<ProjectActivity | undefined>(projectActivities.find((_) => true));
  const [projectUnitFloor, setProjectUnitFloor] = useState<string>("1");
  const [projectReportSetup, setProjectReportSetup] = useState<ProjectReportSetup | undefined>(projectReportSetups.find((_) => true));
  const { download } = useConstuctionReportByUnitFloorDocumentContext("construction-report-by-unit-floor-document-page", project, Number(projectUnitFloor));
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      id="construction-report-by-unit-floor-view"
      permissions={[[Permission.READ_PROJECT_REPORTS], [Permission.READ_PROJECT_REPORTS_CONSTRUCTION_SHEET_PER_FLOOR]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Rapports",
          href: toProjectReportsView(project.id),
        },
        {
          label: "Fiche de construction par étage d'unité",
        },
      ]}
    >
      <ViewFilters>
        <SelectPicker
          size="md"
          label="Activité"
          value={projectActivity?.id ?? undefined}
          onChange={(value) => {
            setProjectActivity(projectActivities.find((projectActivity) => projectActivity.id === value));
          }}
          data={projectActivities.map((projectActivity) => ({
            label: projectActivity.label,
            value: projectActivity.id,
          }))}
          cleanable
          style={{ width: 250 }}
        />
        <SelectPicker
          size="md"
          label="Étage"
          value={projectUnitFloor ?? undefined}
          onChange={(value) => {
            setProjectUnitFloor(value!);
          }}
          data={projectUnits
            .map((projectUnit) => projectUnit.floor)
            .filter((value, index, array) => array.indexOf(value) === index)
            .map((projectUnitFloor) => ({
              label: projectUnitFloor.toString(),
              value: projectUnitFloor.toString(),
            }))}
          cleanable={false}
          style={{ width: 250 }}
        />
        <SelectPicker
          size="md"
          label="Paramètrage"
          defaultValue={projectReportSetup?.id ?? undefined}
          onChange={(value) => {
            setProjectReportSetup(projectReportSetups.find((projectReportSetup) => projectReportSetup.id === value));
          }}
          data={projectReportSetups.map((projectReportSetup) => ({
            label: projectReportSetup.name,
            value: projectReportSetup.id,
          }))}
          cleanable={false}
          style={{ width: 250 }}
        />
      </ViewFilters>
      <hr />
      <ButtonToolbar>
        <Button appearance="primary" size="lg" disabled={projectReportSetups.length === 0 || projectActivities.length === 0 || projectUnits.length === 0} onClick={download}>
          Télécharger
        </Button>
      </ButtonToolbar>
      <hr />
      <ConstructionSheetPerFloorDocument
        projectContracts={projectContracts}
        projectUnits={projectUnits}
        projectModels={projectModels}
        projectProducts={projectProducts}
        projectActivity={projectActivity}
        projectActivities={projectActivities}
        projectReportSetup={projectReportSetup}
        projectUnitFloor={Number(projectUnitFloor)}
      />
      <ConstructionSheetPerFloorDocument
        pageClassName="construction-report-by-unit-floor-document-page"
        projectContracts={projectContracts}
        projectUnits={projectUnits}
        projectModels={projectModels}
        projectProducts={projectProducts}
        projectActivity={projectActivity}
        projectActivities={projectActivities}
        projectReportSetup={projectReportSetup}
        projectUnitFloor={Number(projectUnitFloor)}
      />
    </View>
  );
};
