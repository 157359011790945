import "./index.less";
import { FunctionComponent, PropsWithChildren } from "react";
import { Panel } from "rsuite";

type Props = PropsWithChildren & {
  readonly label: string;
};

export const StatsPanel: FunctionComponent<Props> = ({ label, children }) => (
  <Panel shaded className="stats-panel">
    <div className="content">
      <label>{label.toUpperCase()}</label>
      <span>{children}</span>
    </div>
  </Panel>
);
