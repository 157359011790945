import "./index.less";
import { FunctionComponent } from "react";
import { ProjectModelElement } from "graphql/__generated__/ProjectModelElement";
import { ProjectProduct } from "graphql/__generated__/ProjectProduct";
import { ProjectProductCategory } from "graphql/__generated__/ProjectProductCategory";
import { ProjectModelElementPanel } from "components";
import { ProjectModelVibeItem } from "types";

type Props = {
  readonly projectModelVibeItems?: ProjectModelVibeItem[] | undefined;
  readonly setProjectModelVibeItems?: ((projectModelVibeItems: ProjectModelVibeItem[]) => void) | undefined;
  readonly projectProducts: ReadonlyArray<ProjectProduct>;
  readonly projectModelElements: ReadonlyArray<ProjectModelElement>;
  readonly projectProductCategories: ReadonlyArray<ProjectProductCategory>;
  readonly projectProductPanelSize?: "sm" | "lg";
};

export const ProjectModelElementPanelGroup: FunctionComponent<Props> = ({
  projectProducts,
  projectModelElements,
  projectProductCategories,
  projectModelVibeItems,
  setProjectModelVibeItems,
  projectProductPanelSize = "lg",
}) => (
  <section className="panel-group project-model-element-panel-group">
    {projectModelElements.map((projectModelElement) => {
      const products = projectModelElement.options.map((option) => projectProducts.find((projectProduct) => projectProduct.id === option.productId)!);
      const productCategories = products
        .map((product) => projectProductCategories.find((projectProductCategory) => projectProductCategory.id === product?.categoryId)!)
        .filter((product) => product !== undefined)
        .filter((value, index, self) => self.indexOf(value) === index);
      return (
        <ProjectModelElementPanel
          key={projectModelElement.id}
          projectModelElement={projectModelElement}
          projectProducts={products}
          projectProductCategories={productCategories}
          projectModelVibeItems={projectModelVibeItems}
          setProjectModelVibeItems={setProjectModelVibeItems}
          projectProductPanelSize={projectProductPanelSize}
        />
      );
    })}
  </section>
);
