import "./index.less";
import { FunctionComponent, PropsWithChildren, ReactNode, useEffect } from "react";
import { Container, Content, Header, Breadcrumb, Footer, ButtonToolbar, Button } from "rsuite";
import AngleRightIcon from "@rsuite/icons/legacy/AngleRight";
import { Permission } from "enums";
import { useAuth, useRouting } from "hooks";
import { ContainerLoader } from "buildingBlocks";

type Props = PropsWithChildren & {
  readonly id?: string;
  readonly title?: string;
  readonly header?: ReactNode;
  readonly loading?: boolean;
  readonly permissions: Permission[][];
  readonly fluid?: boolean;
  readonly form?: {
    readonly disabled?: boolean;
    readonly loading: boolean;
    readonly onSubmit: () => Promise<void>;
    readonly onCancel: () => void;
  };
  readonly breadcrumbItems: {
    readonly label: string;
    readonly href?: string;
  }[];
};

export const View: FunctionComponent<Props> = ({ id, title, header, loading = false, permissions, breadcrumbItems, children, fluid = false, form = undefined }) => {
  const { hasPermissions } = useAuth();
  const { navigate } = useRouting();
  useEffect(() => {
    if (!permissions.some((x) => hasPermissions(x))) {
      navigate("/unauthorized");
    }
  }, [permissions, hasPermissions, navigate]);
  if (loading) {
    return <ContainerLoader />;
  }
  return (
    <Container id={id} className={`view${fluid ? " view-fluid" : ""}${form ? " view-form" : ""}`}>
      <Container>
        {!fluid && (
          <Header className="view-header">
            <Breadcrumb maxItems={10} separator={<AngleRightIcon />}>
              {breadcrumbItems.map((breadcrumbItem, index) => (
                <Breadcrumb.Item
                  key={index}
                  active={!breadcrumbItem.href}
                  onClick={() => {
                    if (breadcrumbItem.href) {
                      navigate(breadcrumbItem.href);
                    }
                  }}
                >
                  {breadcrumbItem.label}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
            {title && <h1>{title.toLocaleUpperCase()}</h1>}
            {header && header}
          </Header>
        )}
        <Content className="view-content">{children}</Content>
      </Container>
      {form && (
        <Footer className="view-footer">
          <div className="footer">
            <ButtonToolbar>
              <Button size="lg" appearance="primary" onClick={form.onSubmit} disabled={form.disabled} loading={form.loading}>
                Enregistrer
              </Button>
              <Button size="lg" appearance="ghost" onClick={form.onCancel}>
                Annuler
              </Button>
            </ButtonToolbar>
          </div>
        </Footer>
      )}
    </Container>
  );
};
