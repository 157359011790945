import "./index.less";
import { FunctionComponent } from "react";
import { Form, Uploader } from "rsuite";
import { FormError } from "buildingBlocks";
import { ProjectModelUploadFormValues } from "types";
import produce from "immer";

type Props = {
  readonly errors: string[];
  readonly values: ProjectModelUploadFormValues;
  readonly setValues: (values: ProjectModelUploadFormValues) => void;
};

export const ProjectModelUploadForm: FunctionComponent<Props> = ({ errors, values, setValues }) => {
  return (
    <>
      <FormError errors={errors} />
      <Form className="form">
        <Form.Group>
          <h2>Fichier de configuration du modèle (.json)</h2>
          <Uploader
            className="uploader"
            action=""
            accept="application/json"
            autoUpload={false}
            disabled={values.dataBlobFile !== undefined}
            onChange={(fileList) => {
              const file = fileList[0];
              if (file) {
                setValues(
                  produce(values, (draft) => {
                    draft.dataBlobFile = file.blobFile;
                  })
                );
              }
            }}
            onRemove={() => {
              setValues(
                produce(values, (draft) => {
                  draft.dataBlobFile = undefined;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <h2>Fichier de données Unity (.data)</h2>
          <Uploader
            className="uploader"
            action=""
            autoUpload={false}
            disabled={values.unityDataBlobFile !== undefined}
            onChange={(fileList) => {
              const file = fileList[0];
              if (file) {
                setValues(
                  produce(values, (draft) => {
                    draft.unityDataBlobFile = file.blobFile;
                  })
                );
              }
            }}
            onRemove={() => {
              setValues(
                produce(values, (draft) => {
                  draft.unityDataBlobFile = undefined;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <h2>Fichier du cadriciel Unity (.framework.js)</h2>
          <Uploader
            className="uploader"
            action=""
            autoUpload={false}
            disabled={values.unityFrameworkBlobFile !== undefined}
            onChange={(fileList) => {
              const file = fileList[0];
              if (file) {
                setValues(
                  produce(values, (draft) => {
                    draft.unityFrameworkBlobFile = file.blobFile;
                  })
                );
              }
            }}
            onRemove={() => {
              setValues(
                produce(values, (draft) => {
                  draft.unityFrameworkBlobFile = undefined;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <h2>Fichier de chargement Unity (.loader.js)</h2>
          <Uploader
            className="uploader"
            action=""
            autoUpload={false}
            disabled={values.unityLoaderBlobFile !== undefined}
            onChange={(fileList) => {
              const file = fileList[0];
              if (file) {
                setValues(
                  produce(values, (draft) => {
                    draft.unityLoaderBlobFile = file.blobFile;
                  })
                );
              }
            }}
            onRemove={() => {
              setValues(
                produce(values, (draft) => {
                  draft.unityLoaderBlobFile = undefined;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <h2>Fichier binaire Unity (.wasm)</h2>
          <Uploader
            className="uploader"
            action=""
            autoUpload={false}
            disabled={values.unityWasmBlobFile !== undefined}
            onChange={(fileList) => {
              const file = fileList[0];
              if (file) {
                setValues(
                  produce(values, (draft) => {
                    draft.unityWasmBlobFile = file.blobFile;
                  })
                );
              }
            }}
            onRemove={() => {
              setValues(
                produce(values, (draft) => {
                  draft.unityWasmBlobFile = undefined;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <h2>Vignettes des produits</h2>
          <Uploader
            className="uploader"
            action=""
            multiple
            autoUpload={false}
            disabled={values.projectProductThumbnailBlobFiles.length !== 0}
            onChange={(fileList) => {
              setValues(
                produce(values, (draft) => {
                  draft.projectProductThumbnailBlobFiles = fileList.map((file) => file.blobFile);
                })
              );
            }}
            onRemove={() => {
              setValues(
                produce(values, (draft) => {
                  draft.projectProductThumbnailBlobFiles = [];
                })
              );
            }}
          />
        </Form.Group>
      </Form>
    </>
  );
};
