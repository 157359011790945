import "./index.less";
import { FunctionComponent } from "react";
import { Message } from "rsuite";

type Props = {
  readonly errors: string[];
};

export const FormError: FunctionComponent<Props> = ({ errors }) => {
  if (errors.length !== 0) {
    return (
      <Message className="form-error" showIcon type="error">
        {errors[0]}
      </Message>
    );
  }
  return null;
};
