import "./index.less";
import React, { CSSProperties } from "react";
import { useCountryContext, useCountrySubdivisionContext } from "hooks";
import { ProjectContractInvoice } from "graphql/__generated__/ProjectContractInvoice";
import { Project } from "graphql/__generated__/Project";
import { ProjectContract } from "graphql/__generated__/ProjectContract";
import { ProjectUnit } from "graphql/__generated__/ProjectUnit";
import { ProjectModel } from "graphql/__generated__/ProjectModel";

type Props = {
  readonly id?: string;
  readonly projectContract: ProjectContract;
  readonly projectContractInvoice: ProjectContractInvoice;
  readonly project: Project;
  readonly projectModels: ReadonlyArray<ProjectModel>;
  readonly projectUnits: ReadonlyArray<ProjectUnit>;
};

export const ProjectContractInvoiceDocument = React.forwardRef<any, Props>(({ id, project, projectContract, projectContractInvoice, projectModels, projectUnits }, ref) => {
  const { country } = useCountryContext(projectContractInvoice.billingAddress.country);
  const { countrySubdivision } = useCountrySubdivisionContext(projectContractInvoice.billingAddress.country, projectContractInvoice.billingAddress.state);
  const projectUnit = projectUnits.find((projectUnit) => projectUnit.id === projectContract.projectUnitId);
  const projectContractChoice = projectContract.choices.find((projectContractChoice) => projectContractChoice.id === projectContractInvoice.projectContractChoiceId);
  const projectModel = projectModels.find((projectModel) => projectModel.id === projectContract.projectModelId);
  const projectModelVibe = projectModel?.vibes.find((projectModelVibe) => projectModelVibe.id === projectContractChoice?.projectModelVibeId);
  const style: CSSProperties = {};
  if (id) {
    style.position = "absolute";
    style.right = "-1000px";
    style.bottom = "-1000px";
    style.width = "816px";
    style.fontSize = "75%";
    style.color = "black";
    style.padding = "50px";
  }
  return (
    <div id={id} className="invoice-page" style={style} ref={ref}>
      <div className="invoice-header">
        <div className="invoice-header-left">
          <div className="invoice-address">
            <strong>Dév Méta inc.</strong>
            <address>
              1751, RUE RICHARDSON, BUREAU 4.300
              <br />
              MONTREAL, QUÉBEC, H3K 1G6
              <br />
              CANADA
            </address>
          </div>
          <div className="invoice-unit-info">
            <div>
              <label>Nom du projet :</label>
              <span>{project.name}</span>
            </div>
            {projectUnit && (
              <div>
                <label>Unité :</label>
                <span>#{projectUnit.number}</span>
              </div>
            )}
            {projectModel && (
              <div>
                <label>Modèle :</label>
                <span>{projectModel.name}</span>
              </div>
            )}
            {projectModelVibe && (
              <div>
                <label>Ambiance :</label>
                <span>{projectModelVibe.name}</span>
              </div>
            )}
          </div>
          {/** TODO: Créer un concept de compagnie. */}
        </div>
        <div className="invoice-header-right">
          <div className="invoice-title">
            <h2>FACTURE</h2>
            <span># {projectContractInvoice.label}</span>
          </div>
          <div className="invoice-balance">
            <label>Solde dû</label>
            <span>
              <strong>{Number(projectContractInvoice.balance).toCurrencyFormat("CAD")}</strong>
            </span>
          </div>
        </div>
      </div>
      <div className="invoice-body">
        <div className="invoice-body-top">
          <div className="invoice-billing-address">
            <label>Facturer à</label>
            <address>
              <strong>{projectContractInvoice.customerName}</strong>
              <br />
              {projectContractInvoice.billingAddress.line}
              <br />
              {projectContractInvoice.billingAddress.city}, {countrySubdivision.name.toUpperCase()}, {projectContractInvoice.billingAddress.postalCode}
              <br />
              {country.name.toUpperCase()}
            </address>
          </div>
          <div className="invoice-conditions">
            <div>
              <label>Date de facture :</label>
              <span>{new Date(projectContractInvoice.createdAt).toLocaleDateString("fr-CA")}</span>
            </div>
          </div>
        </div>
        <div className="invoice-items-table">
          <div className="table-head">
            <div className="table-row">
              <div className="table-column table-column-index">#</div>
              <div className="table-column table-column-description">Description</div>
              <div className="table-column table-column-quantity">Quantité</div>
              <div className="table-column table-column-unit-price">Prix unitaire</div>
              <div className="table-column table-column-amount">Montant</div>
            </div>
          </div>
          <div className="table-body">
            {projectContractInvoice.items.map((projectContractInvoiceItem) => (
              <div key={projectContractInvoiceItem.id} className="table-row">
                <div className="table-column table-column-index">{projectContractInvoiceItem.index + 1}</div>
                <div className="table-column table-column-description">{projectContractInvoiceItem.name}</div>
                <div className="table-column table-column-quantity">{projectContractInvoiceItem.quantity}</div>
                <div className="table-column table-column-unit-price">{Number(projectContractInvoiceItem.unitPrice).toCurrencyFormat("CAD")}</div>
                <div className="table-column table-column-amount">{Number(projectContractInvoiceItem.amount).toCurrencyFormat("CAD")}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="invoice-summary">
          {Number(projectContractInvoice.serviceFeeAmount) !== 0 && (
            <div>
              <label>Frais de service</label>
              <span>{Number(projectContractInvoice.serviceFeeAmount).toCurrencyFormat("CAD")}</span>
            </div>
          )}
          <div>
            <label>Sous-total</label>
            <span>{Number(projectContractInvoice.subtotal).toCurrencyFormat("CAD")}</span>
          </div>
          <div>
            <label>Taxe ({Number(projectContractInvoice.taxRatio * 100).toLocaleString("fr-CA")}%)</label>
            <span>{Number(projectContractInvoice.taxAmount).toCurrencyFormat("CAD")}</span>
          </div>
          <div>
            <label>Total</label>
            <span>{Number(projectContractInvoice.total).toCurrencyFormat("CAD")}</span>
          </div>
          <div>
            <label>Solde dû</label>
            <span>{Number(projectContractInvoice.balance).toCurrencyFormat("CAD")}</span>
          </div>
        </div>
      </div>
    </div>
  );
});
