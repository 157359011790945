import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProviderContext } from "hooks";
import { useDeleteProviderMutation } from "graphql/mutations";
import { useList } from "react-use";
import { DeleteForm } from "forms";

export const ProviderDeleteView: FunctionComponent = () => {
  const { navigate, toProvidersView, toProviderView } = useRouting();
  const { provider } = useProviderContext();
  const [errors, { set: setErrors }] = useList<string>([]);
  const { deleteProvider, deleteProviderLoading } = useDeleteProviderMutation(() => {
    navigate(toProvidersView());
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Supprimer le fournisseur"
      permissions={[[Permission.DELETE_PROVIDERS]]}
      breadcrumbItems={[
        {
          label: "Fournisseurs",
          href: toProvidersView(),
        },
        {
          label: provider.name,
          href: toProviderView(provider.id),
        },
      ]}
    >
      <DeleteForm
        label="Nom du fournisseur"
        value={provider.name}
        confirm="Veuillez saisir le nom du fournisseur pour confirmer."
        errors={errors}
        loading={deleteProviderLoading}
        onSubmit={async () => {
          await deleteProvider(provider.id);
        }}
        onCancel={() => {
          navigate(toProviderView(provider.id));
        }}
      >
        Ce fournisseur sera définitivement supprimé. <strong>Cette opération est IRRÉVERSIBLE.</strong>
      </DeleteForm>
    </View>
  );
};
