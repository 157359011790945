import "./index.less";
import { FunctionComponent, useState } from "react";
import { ListItemActions, SearchInputGroup, View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useProjectContext, useProjectModelContext, useRouting } from "hooks";
import { FlexboxGrid, List, Tag } from "rsuite";

export const ProjectModelVibesView: FunctionComponent = () => {
  const {
    searchParams,
    toProjectsView,
    toProjectView,
    toProjectModelsView,
    toProjectModelView,
    toProjectModelVibeView,
    toProjectModelVibeCreateView,
    toProjectModelVibePublishView,
    toProjectModelVibeUpdateView,
    toProjectModelVibeDeleteView,
  } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  const { project } = useProjectContext();
  const { projectModel } = useProjectModelContext();
  useLayout({
    sidebarActions: [],
    sidebarButtons: [
      {
        label: "Créer une ambiance",
        href: toProjectModelVibeCreateView(project.id, projectModel.id),
        permissions: [[Permission.CREATE_PROJECT_MODEL_VIBES]],
      },
    ],
  });
  return (
    <View
      id="project-model-vibes-view"
      permissions={[[Permission.READ_PROJECT_MODEL_VIBES]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Modèles",
          href: toProjectModelsView(project.id),
        },
        {
          label: projectModel.name,
          href: toProjectModelView(project.id, projectModel.id),
        },
        {
          label: "Ambiances",
        },
      ]}
    >
      <SearchInputGroup placeholder="Recherche par nom" onChange={setSearch} />
      <List className="project-model-vibes-list">
        {projectModel.vibes
          .filter((projectModelVibe) => projectModelVibe.name.toSearchFormat().includes(search.toSearchFormat()))
          .map((projectModelVibe, index) => {
            return (
              <List.Item key={projectModelVibe.id} index={index + 1}>
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={11}>
                    <h3>{projectModelVibe.name}</h3>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={11}>
                    {projectModelVibe.isDraft ? (
                      <Tag size="lg">BROUILLON</Tag>
                    ) : (
                      <Tag size="lg" color="green">
                        PUBLIÉE
                      </Tag>
                    )}
                  </FlexboxGrid.Item>
                  <ListItemActions
                    colspan={2}
                    actions={[
                      {
                        href: toProjectModelVibeView(project.id, projectModel.id, projectModelVibe.id),
                        content: "Voir l'ambiance",
                        permissions: [[Permission.READ_PROJECT_MODEL_VIBES]],
                        show: true,
                      },
                      {
                        href: toProjectModelVibeUpdateView(project.id, projectModel.id, projectModelVibe.id),
                        content: "Modifier l'ambiance",
                        permissions: [[Permission.UPDATE_PROJECT_MODEL_VIBES]],
                        show: projectModelVibe.isDraft,
                      },
                      {
                        href: toProjectModelVibePublishView(project.id, projectModel.id, projectModelVibe.id),
                        content: "Publier l'ambiance",
                        permissions: [[Permission.PUBLISH_PROJECT_MODEL_VIBES]],
                        show: projectModelVibe.isDraft,
                      },
                      {
                        href: toProjectModelVibeDeleteView(project.id, projectModel.id, projectModelVibe.id),
                        content: "Supprimer l'ambiance",
                        permissions: [[Permission.DELETE_PROJECT_MODEL_VIBES]],
                        show: projectModelVibe.isDraft,
                      },
                    ]}
                  />
                </FlexboxGrid>
              </List.Item>
            );
          })}
      </List>
    </View>
  );
};
