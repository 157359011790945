import { gql } from "@apollo/client";

export const PROJECT_MODEL_ROOM_FRAGMENT = gql`
  fragment ProjectModelRoom on ProjectModelRoom {
    id
    createdAt
    updatedAt
    index
    name
  }
`;

export const PROJECT_MODEL_ELEMENT_OPTION_PRICE_FRAGMENT = gql`
  fragment ProjectModelElementOptionPrice on ProjectModelElementOptionPrice {
    id
    timestamp
    amount
  }
`;

export const PROJECT_MODEL_ELEMENT_OPTION_FRAGMENT = gql`
  ${PROJECT_MODEL_ELEMENT_OPTION_PRICE_FRAGMENT}
  fragment ProjectModelElementOption on ProjectModelElementOption {
    id
    createdAt
    updatedAt
    isExtra
    isAvailable
    price
    prices {
      ...ProjectModelElementOptionPrice
    }
    productId
  }
`;

export const PROJECT_MODEL_ELEMENT_FRAGMENT = gql`
  ${PROJECT_MODEL_ELEMENT_OPTION_FRAGMENT}
  fragment ProjectModelElement on ProjectModelElement {
    id
    createdAt
    updatedAt
    index
    isBoolean
    isOptional
    name
    projectModelRoomId
    defaultOptionId
    options {
      ...ProjectModelElementOption
    }
  }
`;

export const PROJECT_MODEL_VIBE_ITEM_FRAGMENT = gql`
  fragment ProjectModelVibeItem on ProjectModelVibeItem {
    id
    createdAt
    updatedAt
    projectModelElementId
    projectModelElementOptionId
  }
`;

export const PROJECT_MODEL_VIBE_FRAGMENT = gql`
  ${PROJECT_MODEL_VIBE_ITEM_FRAGMENT}
  fragment ProjectModelVibe on ProjectModelVibe {
    id
    createdAt
    updatedAt
    name
    isDraft
    items {
      ...ProjectModelVibeItem
    }
    customerIds
  }
`;

export const PROJECT_MODEL_TAG_FRAGMENT = gql`
  fragment ProjectModelTag on ProjectModelTag {
    roomTypeName
    count
  }
`;

export const PROJECT_MODEL_FRAGMENT = gql`
  ${PROJECT_MODEL_TAG_FRAGMENT}
  ${PROJECT_MODEL_ROOM_FRAGMENT}
  ${PROJECT_MODEL_ELEMENT_FRAGMENT}
  ${PROJECT_MODEL_VIBE_FRAGMENT}
  fragment ProjectModel on ProjectModel {
    id
    createdAt
    updatedAt
    name
    isDraft
    tags {
      ...ProjectModelTag
    }
    rooms {
      ...ProjectModelRoom
    }
    elements {
      ...ProjectModelElement
    }
    vibes {
      ...ProjectModelVibe
    }
    productIds
  }
`;

export const PROJECT_ACTIVITY_FRAGMENT = gql`
  fragment ProjectActivity on ProjectActivity {
    id
    createdAt
    updatedAt
    number
    title
    label
    description
    productIds
  }
`;

export const PROJECT_PRODUCT_FRAGMENT = gql`
  fragment ProjectProduct on ProjectProduct {
    id
    createdAt
    updatedAt
    name
    variant
    description
    hasThumbnail
    dimension {
      unitOfMeasure
      length
      width
      height
    }
    provider {
      id
      productModel
      productCode
      productUrl
    }
    categoryId
    activityIds
    modelIds
  }
`;

export const PROJECT_PRODUCT_CATEGORY_FRAGMENT = gql`
  fragment ProjectProductCategory on ProjectProductCategory {
    id
    createdAt
    updatedAt
    name
    description
    productIds
  }
`;

export const PROJECT_UNIT_FRAGMENT = gql`
  fragment ProjectUnit on ProjectUnit {
    id
    createdAt
    updatedAt
    label
    number
    floor
    numberOfBedrooms
    numberOfBathrooms
    projectModelId
    customerId
    currentProjectContractId
    projectContractIds
  }
`;

export const PROJECT_REPORT_SETUP_ROOM_FRAGMENT = gql`
  fragment ProjectReportSetupRoom on ProjectReportSetupRoom {
    id
    createdAt
    updatedAt
    name
    index
  }
`;

export const PROJECT_REPORT_SETUP_ELEMENT_FRAGMENT = gql`
  fragment ProjectReportSetupElement on ProjectReportSetupElement {
    id
    createdAt
    updatedAt
    name
    index
    projectReportSetupRoomId
  }
`;

export const PROJECT_REPORT_SETUP_RELATIONSHIP_FRAGMENT = gql`
  fragment ProjectReportSetupRelationship on ProjectReportSetupRelationship {
    id
    createdAt
    updatedAt
    projectReportSetupElementId
    projectModelElementId
  }
`;

export const PROJECT_REPORT_SETUP_FRAGMENT = gql`
  ${PROJECT_REPORT_SETUP_ELEMENT_FRAGMENT}
  ${PROJECT_REPORT_SETUP_ROOM_FRAGMENT}
  ${PROJECT_REPORT_SETUP_RELATIONSHIP_FRAGMENT}
  fragment ProjectReportSetup on ProjectReportSetup {
    id
    createdAt
    updatedAt
    name
    elements {
      ...ProjectReportSetupElement
    }
    rooms {
      ...ProjectReportSetupRoom
    }
    relationships {
      ...ProjectReportSetupRelationship
    }
  }
`;

export const PROJECT_CONTRACT_CHOICE_ITEM_FRAGMENT = gql`
  fragment ProjectContractChoiceItem on ProjectContractChoiceItem {
    id
    createdAt
    updatedAt
    projectModelElementId
    projectModelElementOptionId
    projectModelElementOptionPriceId
  }
`;

export const PROJECT_CONTRACT_CHOICE_BILLING_SETTINGS_FRAGMENT = gql`
  fragment ProjectContractChoiceBillingSettings on ProjectContractChoiceBillingSettings {
    customOptionUnitPrice
    additionalCosts
  }
`;

export const PROJECT_CONTRACT_CHOICE_FRAGMENT = gql`
  ${PROJECT_CONTRACT_CHOICE_BILLING_SETTINGS_FRAGMENT}
  ${PROJECT_CONTRACT_CHOICE_ITEM_FRAGMENT}
  fragment ProjectContractChoice on ProjectContractChoice {
    id
    createdAt
    updatedAt
    index
    label
    mode
    projectModelVibeId
    isDraft
    billingSettings {
      ...ProjectContractChoiceBillingSettings
    }
    items {
      ...ProjectContractChoiceItem
    }
  }
`;

export const PROJECT_CONTRACT_PAYMENT_FRAGMENT = gql`
  fragment ProjectContractPayment on ProjectContractPayment {
    id
    createdAt
    updatedAt
    label
    number
    referenceNumber
    mode
    amount
    projectContractId
    projectContractInvoiceId
  }
`;

export const PROJECT_CONTRACT_INVOICE_ITEM_FRAGMENT = gql`
  fragment ProjectContractInvoiceItem on ProjectContractInvoiceItem {
    id
    createdAt
    updatedAt
    index
    name
    description
    quantity
    unitPrice
    amount
  }
`;

export const PROJECT_CONTRACT_INVOICE_BILLING_ADDRESS_FRAGMENT = gql`
  fragment ProjectContractInvoiceBillingAddress on ProjectContractInvoiceBillingAddress {
    line
    city
    state
    country
    postalCode
  }
`;

export const PROJECT_CONTRACT_INVOICE_FRAGMENT = gql`
  ${PROJECT_CONTRACT_INVOICE_BILLING_ADDRESS_FRAGMENT}
  ${PROJECT_CONTRACT_INVOICE_ITEM_FRAGMENT}
  fragment ProjectContractInvoice on ProjectContractInvoice {
    id
    createdAt
    updatedAt
    number
    label
    customerName
    status
    billingAddress {
      ...ProjectContractInvoiceBillingAddress
    }
    items {
      ...ProjectContractInvoiceItem
    }
    serviceFeeRatio
    serviceFeeAmount
    subtotal
    taxRatio
    taxAmount
    total
    balance
    projectContractId
    projectContractChoiceId
  }
`;

export const PROJECT_CONTRACT_REQUEST_FRAGMENT = gql`
  fragment ProjectContractRequest on ProjectContractRequest {
    id
    createdAt
    updatedAt
    content
    projectModelElementId
  }
`;

export const PROJECT_CONTRACT_FRAGMENT = gql`
  ${PROJECT_CONTRACT_CHOICE_FRAGMENT}
  ${PROJECT_CONTRACT_REQUEST_FRAGMENT}
  ${PROJECT_CONTRACT_INVOICE_FRAGMENT}
  ${PROJECT_CONTRACT_PAYMENT_FRAGMENT}
  fragment ProjectContract on ProjectContract {
    id
    createdAt
    updatedAt
    isCustomized
    balance
    customerId
    currentProjectContractChoiceId
    previousProjectContractChoiceId
    choices {
      ...ProjectContractChoice
    }
    payments {
      ...ProjectContractPayment
    }
    requests {
      ...ProjectContractRequest
    }
    invoices {
      ...ProjectContractInvoice
    }
    isCanceled
    projectId
    projectUnitId
    projectModelId
  }
`;

export const PROJECT_FRAGMENT = gql`
  fragment Project on Project {
    id
    createdAt
    updatedAt
    code
    name
    label
    description
    customOptionUnitPrice
  }
`;

export const PROVIDER_CONTACT_FRAGMENT = gql`
  fragment ProviderContact on ProviderContact {
    id
    createdAt
    updatedAt
    firstName
    lastName
    email
    phone
    note
  }
`;

export const PROVIDER_NOTE_FRAGMENT = gql`
  fragment ProviderNote on ProviderNote {
    id
    createdAt
    updatedAt
    text
  }
`;

export const PROVIDER_FRAGMENT = gql`
  ${PROVIDER_CONTACT_FRAGMENT}
  ${PROVIDER_NOTE_FRAGMENT}
  fragment Provider on Provider {
    id
    createdAt
    updatedAt
    name
    website
    description
    notes {
      ...ProviderNote
    }
    contacts {
      ...ProviderContact
    }
    projectProductIds
  }
`;

export const CUSTOMER_ADDRESS_FRAGMENT = gql`
  fragment CustomerAddress on CustomerAddress {
    line
    city
    state
    country
    postalCode
  }
`;

export const CUSTOMER_FRAGMENT = gql`
  ${CUSTOMER_ADDRESS_FRAGMENT}
  fragment Customer on Customer {
    id
    createdAt
    updatedAt
    firstName
    lastName
    fullName
    label
    email
    phone
    language
    gender
    hasAccount
    credentialsCanBeResentAt
    address {
      ...CustomerAddress
    }
    contractIds
  }
`;

export const ROLE_FRAGMENT = gql`
  fragment Role on Role {
    id
    name
    description
  }
`;

export const USER_FRAGMENT = gql`
  fragment User on User {
    id
    name
    email
    isSuperAdministrator
    hasAccessToAllProjects
    projectIds
    roleIds
  }
`;

export const COUNTRY_SUBDIVISION_FRAGMENT = gql`
  fragment CountrySubdivision on CountrySubdivision {
    code
    name
  }
`;

export const COUNTRY_FRAGMENT = gql`
  ${COUNTRY_SUBDIVISION_FRAGMENT}
  fragment Country on Country {
    code
    name
    subdivisions {
      ...CountrySubdivision
    }
  }
`;

export const ERROR_FRAGMENT = gql`
  fragment Error on Error {
    message
  }
`;
