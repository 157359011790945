import "./index.less";
import { FunctionComponent } from "react";
import { ProjectModelTag } from "graphql/__generated__/ProjectModelTag";

type Props = {
  readonly projectModelTags: ReadonlyArray<ProjectModelTag>;
};

export const ProjectModelTags: FunctionComponent<Props> = ({ projectModelTags }) => (
  <div className="project-model-tags">
    {projectModelTags.map((projectModelTag, index) => (
      <div className="project-model-tag" key={index}>
        <div className="project-model-tag-count">
          <span>{projectModelTag.count}</span>
        </div>
        <div className="project-model-tag-room-type-name">
          <span>{projectModelTag.roomTypeName}</span>
        </div>
      </div>
    ))}
  </div>
);
