import "./index.less";
import { FunctionComponent } from "react";
import { ListItemActions, View, ViewFilters } from "buildingBlocks";
import { Permission } from "enums";
import { useAppContext, useLayout, useProjectContext, useRouting } from "hooks";
import { List, FlexboxGrid, Tag, CheckPicker } from "rsuite";
import { useList } from "react-use";

export const ProjectContractsView: FunctionComponent = () => {
  const { searchParams, toProjectsView, toProjectView, toProjectContractAssociateView, toProjectContractView, toProjectContractCancelView } = useRouting();
  const [customerIds, { set: setCustomerIds }] = useList<string>(searchParams.get("customerIds")?.split(",") ?? []);
  const [projectUnitIds, { set: setProjectUnitIds }] = useList<string>(searchParams.get("projectUnitIds")?.split(",") ?? []);
  const [projectModelIds, { set: setProjectModelIds }] = useList<string>(searchParams.get("projectModelIds")?.split(",") ?? []);
  const { customers } = useAppContext();
  const { project, projectUnits, projectModels, projectContracts } = useProjectContext();
  useLayout({
    sidebarActions: [],
    sidebarButtons: [
      {
        label: "Associer un contrat",
        href: toProjectContractAssociateView(project.id),
        permissions: [[Permission.CREATE_PROJECT_CONTRACTS]],
        enabled: true, // TODO:
      },
    ],
  });
  return (
    <View
      id="project-contracts-view"
      permissions={[[Permission.READ_PROJECT_CONTRACTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Contrats",
        },
      ]}
    >
      <ViewFilters>
        <CheckPicker
          data={projectUnits
            .filter((projectUnit) => projectContracts.some((projectContract) => projectContract.projectUnitId === projectUnit.id))
            .map((projectUnit) => ({
              label: `#${projectUnit.number}`,
              value: projectUnit.id,
            }))}
          style={{
            width: "250px",
          }}
          value={projectUnitIds}
          onChange={setProjectUnitIds}
          label="Unités"
        />
        <CheckPicker
          style={{
            width: "250px",
          }}
          value={customerIds}
          data={customers.map((customer) => ({
            label: customer.fullName,
            value: customer.id,
          }))}
          onChange={setCustomerIds}
          label="Clients"
        />
        <CheckPicker
          style={{
            width: "250px",
          }}
          value={projectModelIds}
          data={projectModels.map((projectModel) => ({
            label: projectModel.name,
            value: projectModel.id,
          }))}
          onChange={setProjectModelIds}
          label="Modèles"
        />
        {/** TODO: Ajouter un filtre par statut. */}
      </ViewFilters>
      <List className="project-contract-list">
        {projectContracts
          .filter((projectContract) => (projectUnitIds.length !== 0 ? projectUnitIds.includes(projectContract.projectUnitId) : true))
          .filter((projectContract) => (customerIds.length !== 0 ? customerIds.includes(projectContract.customerId) : true))
          .filter((projectContract) => (projectModelIds.length !== 0 ? (projectContract.projectModelId ? projectModelIds.includes(projectContract.projectModelId) : false) : true))
          .map((projectContract, index) => {
            return (
              <List.Item key={projectContract.id} index={index + 1}>
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={3}>
                    <h3>Unité #{projectContract.projectUnit.number}</h3>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4}>
                    <dl>
                      <dt>Statut</dt>
                      <dd>
                        {projectContract.isCanceled && (
                          <Tag color="red" size="sm">
                            Annulé
                          </Tag>
                        )}
                        {!projectContract.isCanceled && (
                          <Tag color="green" size="sm">
                            En cours
                          </Tag>
                        )}
                      </dd>
                    </dl>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={7}>
                    <dl>
                      <dt>Client</dt>
                      <dd>{`${projectContract.customer.firstName} ${projectContract.customer.lastName}`}</dd>
                    </dl>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item style={{ justifyContent: "flex-start" }} colspan={8}>
                    <dl style={{ textAlign: "right", width: "100px" }}>
                      <dt>Solde</dt>
                      <dd>{Number(projectContract.balance).toCurrencyFormat("CAD")}</dd>
                    </dl>
                  </FlexboxGrid.Item>
                  <ListItemActions
                    colspan={2}
                    actions={[
                      {
                        content: "Voir le contrat",
                        href: toProjectContractView(project.id, projectContract.id),
                        permissions: [[Permission.READ_PROJECT_CONTRACTS]],
                        show: true,
                      },
                      {
                        content: "Annuler le contrat",
                        href: toProjectContractCancelView(project.id, projectContract.id),
                        permissions: [[Permission.CANCEL_PROJECT_CONTRACTS]],
                        show: !projectContract.isCanceled,
                      },
                    ]}
                  />
                </FlexboxGrid>
              </List.Item>
            );
          })}
      </List>
    </View>
  );
};
