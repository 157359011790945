import "./index.less";
import { FunctionComponent } from "react";
import {
  Button,
  Dropdown,
  IconButton,
  Input,
  MaskedInput,
  Popover,
  Table,
  Whisper,
} from "rsuite";
import {
  ProviderContactAction,
  ProviderContactInput,
} from "graphql/globalTypes";
import { ProviderFormValues } from "forms/ProviderForm";
import produce from "immer";
import MoreIcon from "@rsuite/icons/legacy/More";
import { Guid } from "utils";

type Props = {
  readonly values: ProviderFormValues;
  readonly setValues: (values: ProviderFormValues) => void;
  readonly contacts: ReadonlyArray<ProviderContactInput>;
  readonly createdAt: string;
};

export const ProviderContactsForm: FunctionComponent<Props> = ({
  contacts,
  values,
  setValues,
  createdAt,
}) => (
  <>
    <div className="form-subtitle">
      <h2>Contacts</h2>
      <Button
        appearance="link"
        size="xs"
        onClick={() => {
          setValues(
            produce(values, (draft) => {
              draft.contacts.push({
                id: Guid.newGuid(),
                createdAt,
                firstName: "",
                lastName: "",
                email: null,
                phone: null,
                note: null,
                action: ProviderContactAction.CREATE,
              });
            })
          );
        }}
      >
        Créer un contact
      </Button>
    </div>
    <Table
      className="form-table"
      autoHeight
      data={contacts.filter(
        (contact) => contact.action !== ProviderContactAction.DELETE
      )}
      rowHeight={56}
    >
      <Table.Column flexGrow={0.75}>
        <Table.HeaderCell>Prénom</Table.HeaderCell>
        <Table.Cell>
          {(rowData: any) => {
            const input = rowData as ProviderContactInput;
            return (
              <Input
                size="sm"
                value={input.firstName}
                plaintext={input.action === ProviderContactAction.UPDATE}
                placeholder="John"
                onChange={(value) =>
                  setValues(
                    produce(values, (draft) => {
                      const contact = draft.contacts.find(
                        (contact) => contact.id === input.id
                      );
                      if (contact) {
                        contact.firstName = value;
                      }
                    })
                  )
                }
              />
            );
          }}
        </Table.Cell>
      </Table.Column>
      <Table.Column flexGrow={0.75}>
        <Table.HeaderCell>Nom</Table.HeaderCell>
        <Table.Cell>
          {(rowData: any) => {
            const input = rowData as ProviderContactInput;
            return (
              <Input
                size="sm"
                value={input.lastName}
                plaintext={input.action === ProviderContactAction.UPDATE}
                placeholder="Doe"
                onChange={(value) =>
                  setValues(
                    produce(values, (draft) => {
                      const contact = draft.contacts.find(
                        (contact) => contact.id === input.id
                      );
                      if (contact) {
                        contact.lastName = value;
                      }
                    })
                  )
                }
              />
            );
          }}
        </Table.Cell>
      </Table.Column>
      <Table.Column flexGrow={1.5}>
        <Table.HeaderCell>Courriel</Table.HeaderCell>
        <Table.Cell>
          {(rowData: any) => {
            const input = rowData as ProviderContactInput;
            return (
              <Input
                size="sm"
                value={input.email ?? undefined}
                type="email"
                placeholder="john.doe@contoso.com"
                onChange={(value) =>
                  setValues(
                    produce(values, (draft) => {
                      const contact = draft.contacts.find(
                        (contact) => contact.id === input.id
                      );
                      if (contact) {
                        contact.email = value === "" ? null : value;
                      }
                    })
                  )
                }
              />
            );
          }}
        </Table.Cell>
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Téléphone</Table.HeaderCell>
        <Table.Cell>
          {(rowData: any) => {
            const input = rowData as ProviderContactInput;
            return (
              <MaskedInput
                size="sm"
                value={input.phone ?? undefined}
                onChange={(value) =>
                  setValues(
                    produce(values, (draft) => {
                      const contact = draft.contacts.find(
                        (contact) => contact.id === input.id
                      );
                      if (contact) {
                        contact.phone = value === "" ? null : value;
                      }
                    })
                  )
                }
                type="phone"
                mask={[
                  "+",
                  "1",
                  " ",
                  "(",
                  /[1-9]/,
                  /\d/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                placeholder="+1 (222) 222-2222"
              />
            );
          }}
        </Table.Cell>
      </Table.Column>
      <Table.Column flexGrow={2}>
        <Table.HeaderCell>Note</Table.HeaderCell>
        <Table.Cell>
          {(rowData: any) => {
            const input = rowData as ProviderContactInput;
            return (
              <Input
                size="sm"
                value={input.note ?? undefined}
                placeholder="(Facultative)"
                onChange={(value) =>
                  setValues(
                    produce(values, (draft) => {
                      const contact = draft.contacts.find(
                        (contact) => contact.id === input.id
                      );
                      if (contact) {
                        contact.note = value === "" ? null : value;
                      }
                    })
                  )
                }
              />
            );
          }}
        </Table.Cell>
      </Table.Column>
      <Table.Column width={50}>
        <Table.HeaderCell>{""}</Table.HeaderCell>
        <Table.Cell>
          {(rowData: any) => {
            const input = rowData as ProviderContactInput;
            return (
              <Whisper
                placement="autoVerticalStart"
                trigger="click"
                speaker={({ onClose, left, top, className }, ref) => (
                  <Popover
                    ref={ref}
                    className={className}
                    style={{ left, top }}
                    full
                  >
                    <Dropdown.Menu
                      onSelect={() => {
                        onClose();
                      }}
                    >
                      <Dropdown.Item
                        eventKey="1"
                        onClick={() => {
                          setValues(
                            produce(values, (draft) => {
                              const contact = draft.contacts.find(
                                (contact) => contact.id === input.id
                              );
                              if (contact) {
                                if (
                                  contact.action ===
                                  ProviderContactAction.UPDATE
                                ) {
                                  contact.action = ProviderContactAction.DELETE;
                                } else {
                                  draft.contacts = draft.contacts.filter(
                                    (contact) => contact.id !== input.id
                                  );
                                }
                              }
                            })
                          );
                        }}
                      >
                        Supprimer
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Popover>
                )}
              >
                <IconButton
                  size="sm"
                  appearance="primary"
                  icon={<MoreIcon />}
                />
              </Whisper>
            );
          }}
        </Table.Cell>
      </Table.Column>
    </Table>
  </>
);
