import "./index.less";
import { FunctionComponent, Fragment, useState } from "react";
import { Animation, Toggle } from "rsuite";
import { ProjectModelElement } from "graphql/__generated__/ProjectModelElement";
import { ProjectProduct } from "graphql/__generated__/ProjectProduct";
import { ProjectProductCategory } from "graphql/__generated__/ProjectProductCategory";
import produce from "immer";
import { ProjectModelElementOptionPanel } from "components";
import ArrowDownLineIcon from "@rsuite/icons/ArrowDownLine";
import ArrowUpLineIcon from "@rsuite/icons/ArrowUpLine";
import { ProjectModelVibeItem } from "types";

type Props = {
  readonly projectProducts: ProjectProduct[];
  readonly projectProductCategories: ProjectProductCategory[];
  readonly projectModelElement: ProjectModelElement;
  readonly projectModelVibeItems?: ProjectModelVibeItem[] | undefined;
  readonly setProjectModelVibeItems?: ((projectModelVibeItems: ProjectModelVibeItem[]) => void) | undefined;
  readonly projectProductPanelSize?: "sm" | "lg";
};

export const ProjectModelElementPanel: FunctionComponent<Props> = ({
  projectProducts,
  projectProductCategories,
  projectModelElement,
  projectModelVibeItems,
  setProjectModelVibeItems,
  projectProductPanelSize = "lg",
}) => {
  const [show, setShow] = useState<boolean>(false);
  const projectModelVibeItem = projectModelVibeItems?.find((x) => x.projectModelElementId === projectModelElement.id) ?? null;
  return (
    <div className="panel project-model-element-panel">
      <div
        className="panel-header"
        onClick={() => {
          if (!projectModelElement.isBoolean) {
            setShow(!show);
          }
        }}
      >
        <label>{projectModelElement.name}</label>
        <div style={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", width: "55%" }}>
          {projectModelElement.isBoolean && (
            <Toggle
              readOnly={projectModelVibeItems === undefined || setProjectModelVibeItems === undefined}
              checked={(projectModelVibeItem?.projectModelElementOptionId ?? null) !== null}
              onChange={(checked) => {
                if (projectModelVibeItems && setProjectModelVibeItems) {
                  setProjectModelVibeItems(
                    produce(projectModelVibeItems, (draft) => {
                      const index = draft.findIndex((item) => item.projectModelElementId === projectModelElement.id);
                      if (index !== -1) {
                        if (checked) {
                          draft[index].projectModelElementOptionId = projectModelElement.options.find((_) => true)!.id;
                        } else {
                          draft[index].projectModelElementOptionId = null;
                        }
                      }
                    })
                  );
                }
              }}
              size="md"
            />
          )}
          {!projectModelElement.isBoolean && <>{show ? <ArrowUpLineIcon /> : <ArrowDownLineIcon />}</>}
        </div>
      </div>
      {!projectModelElement.isBoolean && (
        <Animation.Collapse in={show} className="panel-body">
          {(props, ref) => (
            <div {...props} ref={ref}>
              {projectModelElement.options.length > 1 &&
                projectProductCategories.map((projectProductCategory) => (
                  <div className="panel-body-content" key={projectProductCategory.id}>
                    <label>{projectProductCategory.name}</label>
                    <section>
                      {projectProducts
                        .filter((projectProduct) => projectProduct.categoryId === projectProductCategory.id)
                        .map((projectProduct) => {
                          const projectModelElementOption = projectModelElement.options.find((projectModelElementOption) => projectModelElementOption.productId === projectProduct.id)!;
                          return (
                            <ProjectModelElementOptionPanel
                              key={projectModelElementOption.id}
                              size={projectProductPanelSize}
                              projectModelElementOption={projectModelElementOption}
                              active={
                                projectModelVibeItems !== undefined
                                  ? () => {
                                      if (projectModelElementOption) {
                                        const projectModelVibeItem = projectModelVibeItems.find((projectModelVibeItem) => projectModelVibeItem.projectModelElementId === projectModelElement.id);
                                        if (projectModelVibeItem) {
                                          return projectModelElementOption.id === projectModelVibeItem.projectModelElementOptionId;
                                        }
                                      }
                                      return false;
                                    }
                                  : undefined
                              }
                              onClick={() => {
                                if (projectModelVibeItems && setProjectModelVibeItems) {
                                  setProjectModelVibeItems(
                                    produce(projectModelVibeItems, (draft) => {
                                      const index = draft.findIndex((item) => item.projectModelElementId === projectModelElement.id);
                                      if (index !== -1) {
                                        if (projectModelElementOption) {
                                          if (draft[index].projectModelElementOptionId === projectModelElementOption.id) {
                                            draft[index].projectModelElementOptionId = null;
                                          } else {
                                            draft[index].projectModelElementOptionId = projectModelElementOption.id;
                                          }
                                        }
                                      }
                                    })
                                  );
                                }
                              }}
                            />
                          );
                        })}
                    </section>
                  </div>
                ))}
            </div>
          )}
        </Animation.Collapse>
      )}
    </div>
  );
};
