import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext } from "hooks";
import { useCreateProjectUnitMutation } from "graphql/mutations";
import { useList } from "react-use";
import { ProjectUnitForm } from "forms/ProjectUnitForm";
import { PersistentStorageOperation } from "graphql/globalTypes";

export const ProjectUnitCreateView: FunctionComponent = () => {
  const { navigate, toProjectView, toProjectsView, toProjectUnitsView } = useRouting();
  const { project, projectUnits } = useProjectContext();
  const [errors, { set: setErrors }] = useList<string>();
  const { createProjectUnit, createProjectUnitLoading } = useCreateProjectUnitMutation((projectId) => {
    navigate(toProjectUnitsView(projectId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Créer une unité"
      permissions={[[Permission.READ_PROJECT_UNITS, Permission.CREATE_PROJECT_UNITS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Unités",
          href: toProjectUnitsView(project.id),
        },
      ]}
    >
      <ProjectUnitForm
        _operation={PersistentStorageOperation.CREATE}
        initialValues={{
          number: Math.max(...projectUnits.map((projectUnit) => projectUnit.number)) + 1,
          floor: Math.max(...projectUnits.map((projectUnit) => projectUnit.floor)),
          numberOfBedrooms: 1,
          numberOfBathrooms: 1,
          projectModelId: null,
        }}
        errors={errors}
        loading={createProjectUnitLoading}
        onSubmit={async (values) => {
          await createProjectUnit(project.id, {
            number: values.number,
            floor: values.floor,
            numberOfBedrooms: values.numberOfBedrooms,
            numberOfBathrooms: values.numberOfBathrooms,
            projectModelId: values.projectModelId,
          });
        }}
        onCancel={() => {
          navigate(toProjectUnitsView(project.id));
        }}
      />
    </View>
  );
};
