import "./index.less";
import { FunctionComponent, Fragment } from "react";
import { ProjectModelElementPanelGroup } from "components";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useProjectContext, useProjectModelContext, useRouting } from "hooks";
import { ProjectModelTags } from "components/ProjectModelTags";
import { Tag } from "rsuite";

export const ProjectModelView: FunctionComponent = () => {
  const {
    toProjectView,
    toProjectsView,
    toProjectModelsView,
    toProjectModelPublishView,
    toProjectModelUpdateView,
    toProjectModelDeleteView,
    toProjectModelVibesView,
    toProjectReportRelationshipsUpdateView,
  } = useRouting();
  const { project, projectProducts, projectProductCategories } = useProjectContext();
  const { projectModel } = useProjectModelContext();
  useLayout({
    sidebarActions: [
      {
        label: "Ambiances",
        href: toProjectModelVibesView(project.id, projectModel.id),
        permissions: [[Permission.READ_PROJECT_MODEL_VIBES]],
        show: true,
      },
    ],
    sidebarButtons: [
      {
        label: "Modifier le modèle",
        href: toProjectModelUpdateView(project.id, projectModel.id),
        permissions: [[Permission.UPDATE_PROJECT_MODELS]],
        enabled: true,
      },
      {
        label: "Publier le modèle",
        href: toProjectModelPublishView(project.id, projectModel.id),
        permissions: [[Permission.PUBLISH_PROJECT_MODELS]],
        enabled: projectModel.isDraft,
      },
      {
        label: "Supprimer le modèle",
        href: toProjectModelDeleteView(project.id, projectModel.id),
        permissions: [[Permission.DELETE_PROJECT_MODELS]],
        enabled: projectModel.vibes.every((projectModelVibe) => projectModelVibe.customerIds.length === 0) && projectModel.isDraft,
      },
      {
        label: "Modifier les relations",
        href: toProjectReportRelationshipsUpdateView(project.id, projectModel.id),
        permissions: [[Permission.UPDATE_PROJECT_REPORT_RELATIONSHIPS]],
        enabled: true,
      },
    ],
  });
  return (
    <View
      id="project-model-view"
      header={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <h1>{projectModel.name.toUpperCase()}</h1>
          {projectModel.isDraft ? (
            <Tag size="lg">BROUILLON</Tag>
          ) : (
            <Tag size="lg" color="green">
              PUBLIÉ
            </Tag>
          )}
        </div>
      }
      permissions={[[Permission.READ_PROJECT_MODELS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Modèles",
          href: toProjectModelsView(project.id),
        },
        {
          label: projectModel.name,
        },
      ]}
    >
      <ProjectModelTags projectModelTags={projectModel.tags} />
      {projectModel.rooms.map((projectModelRoom) => (
        <Fragment key={projectModelRoom.id}>
          <h2>{projectModelRoom.name}</h2>
          <ProjectModelElementPanelGroup
            projectModelElements={projectModel.elements.filter((projectModelElement) => projectModelElement.projectModelRoomId === projectModelRoom.id)}
            projectProducts={projectProducts}
            projectProductCategories={projectProductCategories}
          />
        </Fragment>
      ))}
    </View>
  );
};
