/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ContractChoiceMode {
  PREVIOUS_CONTRACT_CHOICE_ITEMS = "PREVIOUS_CONTRACT_CHOICE_ITEMS",
  PROJECT_MODEL_VIBE = "PROJECT_MODEL_VIBE",
  PROJECT_MODEL_VIBE_ITEMS = "PROJECT_MODEL_VIBE_ITEMS",
}

export enum ContractInvoiceStatus {
  NO_CHARGE = "NO_CHARGE",
  PAID = "PAID",
  PARTIAL = "PARTIAL",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
}

export enum ContractPaymentMode {
  MANUAL = "MANUAL",
}

export enum PersistentStorageOperation {
  CREATE = "CREATE",
  DELETE = "DELETE",
  UPDATE = "UPDATE",
}

export enum ProviderContactAction {
  CREATE = "CREATE",
  DELETE = "DELETE",
  UPDATE = "UPDATE",
}

export enum ProviderNoteAction {
  CREATE = "CREATE",
  DELETE = "DELETE",
  UPDATE = "UPDATE",
}

export enum TemplateName {
  BULK_UNITS = "BULK_UNITS",
}

export enum UnitOfMeasure {
  CENTIMETER = "CENTIMETER",
  FOOT = "FOOT",
  INCH = "INCH",
  METER = "METER",
  MILLIMETER = "MILLIMETER",
  NONE = "NONE",
}

export interface CreateCustomerInput {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly phone: string;
  readonly gender: string;
  readonly language: string;
  readonly address: CustomerAddressInput;
}

export interface CreateProjectActivityInput {
  readonly number: string;
  readonly title: string;
  readonly description?: string | null;
}

export interface CreateProjectContractChoiceInput {
  readonly mode: ContractChoiceMode;
  readonly billingSettings: ProjectContractChoiceBillingSettingsInput;
}

export interface CreateProjectContractInput {
  readonly customerId: string;
  readonly projectUnitId: string;
  readonly projectModelId?: string | null;
  readonly projectContractBlobFile: any;
  readonly isCustomized: boolean;
  readonly projectContractChoiceMode: ContractChoiceMode;
  readonly projectContractChoiceBillingSettings: ProjectContractChoiceBillingSettingsInput;
}

export interface CreateProjectContractPaymentInput {
  readonly amount: any;
  readonly referenceNumber?: string | null;
}

export interface CreateProjectInput {
  readonly code: string;
  readonly name: string;
  readonly description?: string | null;
  readonly customOptionUnitPrice: any;
}

export interface CreateProjectModelVibeInput {
  readonly name: string;
  readonly items: ReadonlyArray<ProjectModelVibeItemInput>;
}

export interface CreateProjectReportSetupInput {
  readonly name: string;
  readonly rooms: ReadonlyArray<ProjectReportSetupRoomInput>;
}

export interface CreateProjectUnitInput {
  readonly number: number;
  readonly floor: number;
  readonly numberOfBedrooms: number;
  readonly numberOfBathrooms: number;
  readonly projectModelId?: string | null;
}

export interface CreateProviderInput {
  readonly name: string;
  readonly website?: string | null;
  readonly description?: string | null;
  readonly contacts?: ReadonlyArray<ProviderContactInput> | null;
  readonly notes?: ReadonlyArray<ProviderNoteInput> | null;
}

export interface CustomerAddressInput {
  readonly line: string;
  readonly city: string;
  readonly state: string;
  readonly country: string;
  readonly postalCode: string;
}

export interface ProjectContractChoiceBillingSettingsInput {
  readonly customOptionUnitPrice: any;
  readonly additionalCosts: any;
}

export interface ProjectModelElementOptionPriceInput {
  readonly projectModelElementOptionId: string;
  readonly amount: any;
}

export interface ProjectModelVibeItemInput {
  readonly projectModelElementId: string;
  readonly projectModelElementOptionId?: string | null;
}

export interface ProjectProductInput {
  readonly id: string;
  readonly activityIds: ReadonlyArray<string>;
  readonly provider: ProjectProductProviderInput;
}

export interface ProjectProductProviderInput {
  readonly id?: string | null;
  readonly productModel?: string | null;
  readonly productCode?: string | null;
  readonly productUrl?: string | null;
}

export interface ProjectReportSetupElementInput {
  readonly id: string;
  readonly name: string;
  readonly _operation: PersistentStorageOperation;
}

export interface ProjectReportSetupRelationshipInput {
  readonly projectModelElementId: string;
  readonly projectReportSetupElementId?: string | null;
}

export interface ProjectReportSetupRoomInput {
  readonly id: string;
  readonly name: string;
  readonly elements: ReadonlyArray<ProjectReportSetupElementInput>;
  readonly _operation: PersistentStorageOperation;
}

export interface ProjectUnitInput {
  readonly id: string;
  readonly projectModelId?: string | null;
}

export interface ProviderContactInput {
  readonly id: string;
  readonly createdAt?: any | null;
  readonly firstName: string;
  readonly lastName: string;
  readonly email?: string | null;
  readonly phone?: string | null;
  readonly note?: string | null;
  readonly action: ProviderContactAction;
}

export interface ProviderNoteInput {
  readonly id: string;
  readonly createdAt?: any | null;
  readonly text: string;
  readonly action: ProviderNoteAction;
}

export interface ResendCustomerCredentialsInput {
  readonly password?: string | null;
}

export interface UpdateCustomerInput {
  readonly firstName: string;
  readonly lastName: string;
  readonly phone: string;
  readonly gender: string;
  readonly language: string;
  readonly address: CustomerAddressInput;
}

export interface UpdateProjectActivityInput {
  readonly number: string;
  readonly title: string;
  readonly description?: string | null;
}

export interface UpdateProjectContractPaymentInput {
  readonly amount: any;
  readonly referenceNumber?: string | null;
}

export interface UpdateProjectContractRequestInput {
  readonly projectModelElementId: string;
  readonly content?: string | null;
}

export interface UpdateProjectContractRequestsInput {
  readonly projectModelId: string;
  readonly projectContractRequests: ReadonlyArray<UpdateProjectContractRequestInput>;
}

export interface UpdateProjectInput {
  readonly code: string;
  readonly name: string;
  readonly description?: string | null;
  readonly customOptionUnitPrice: any;
}

export interface UpdateProjectModelInput {
  readonly name: string;
  readonly projectModelElementOptionPrices: ReadonlyArray<ProjectModelElementOptionPriceInput>;
}

export interface UpdateProjectModelReportSetupRelationshipsInput {
  readonly projectReportSetupId: string;
  readonly projectReportSetupRelationships: ReadonlyArray<ProjectReportSetupRelationshipInput>;
}

export interface UpdateProjectModelVibeInput {
  readonly name: string;
  readonly items: ReadonlyArray<ProjectModelVibeItemInput>;
}

export interface UpdateProjectProductInput {
  readonly description?: string | null;
  readonly activityIds: ReadonlyArray<string>;
  readonly provider: ProjectProductProviderInput;
}

export interface UpdateProjectProductsInput {
  readonly projectProducts: ReadonlyArray<ProjectProductInput>;
}

export interface UpdateProjectReportSetupInput {
  readonly name: string;
  readonly rooms: ReadonlyArray<ProjectReportSetupRoomInput>;
}

export interface UpdateProjectUnitInput {
  readonly numberOfBedrooms: number;
  readonly numberOfBathrooms: number;
  readonly projectModelId?: string | null;
}

export interface UpdateProjectUnitsInput {
  readonly projectUnits: ReadonlyArray<ProjectUnitInput>;
}

export interface UpdateProviderInput {
  readonly name: string;
  readonly website?: string | null;
  readonly description?: string | null;
  readonly contacts?: ReadonlyArray<ProviderContactInput> | null;
  readonly notes?: ReadonlyArray<ProviderNoteInput> | null;
}

export interface UpdateUserAccessInput {
  readonly hasAccessToAllProjects: boolean;
  readonly projectIds: ReadonlyArray<string>;
  readonly roleIds: ReadonlyArray<string>;
}

export interface UploadProjectModelInput {
  readonly dataBlobFile: any;
  readonly unityDataBlobFile: any;
  readonly unityFrameworkBlobFile: any;
  readonly unityLoaderBlobFile: any;
  readonly unityWasmBlobFile: any;
  readonly projectProductThumbnailBlobFiles: ReadonlyArray<any>;
}

export interface UploadProjectUnitsInput {
  readonly projectUnitsBlobFile: any;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
