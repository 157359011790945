import "./index.less";
import { FunctionComponent, useState } from "react";
import { Link } from "react-router-dom";
import { ListItemActions, SearchInputGroup, View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useProjectContext, useRouting } from "hooks";
import { List, FlexboxGrid } from "rsuite";

export const ProjectActivitiesView: FunctionComponent = () => {
  const { searchParams, toProjectsView, toProjectView, toProjectActivityCreateView, toProjectActivityUpdateView, toProjectActivityDeleteView, toProjectProductsView } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  const { project, projectActivities } = useProjectContext();
  useLayout({
    sidebarActions: [],
    sidebarButtons: [
      {
        label: "Créer une activité",
        href: toProjectActivityCreateView(project.id),
        permissions: [[Permission.CREATE_PROJECT_ACTIVITIES]],
      },
    ],
  });
  return (
    <View
      id="project-activities-view"
      permissions={[[Permission.READ_PROJECT_ACTIVITIES]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Activités",
        },
      ]}
    >
      <SearchInputGroup placeholder="Recherche par étiquette" onChange={setSearch} />
      <List className="project-activity-list">
        {projectActivities
          .filter((projectActivity) => projectActivity.label.toSearchFormat().includes(search.toSearchFormat()))
          .map((projectActivity, index) => {
            return (
              <List.Item key={projectActivity.id} index={index + 1}>
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={11}>
                    <h3>{projectActivity.label}</h3>
                    <span className="project-activity-description">{projectActivity.description ?? "Pas de description."}</span>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={11}>
                    <span className="subtile">Lié à</span>
                    <div className="project-activity">
                      <Link
                        to={toProjectProductsView(project.id, {
                          activityIds: [projectActivity.id],
                        })}
                      >{`${projectActivity.productIds.length} produit(s)`}</Link>
                    </div>
                  </FlexboxGrid.Item>
                  <ListItemActions
                    colspan={2}
                    actions={[
                      {
                        href: toProjectActivityUpdateView(project.id, projectActivity.id),
                        content: "Modifier l'activité",
                        permissions: [[Permission.UPDATE_PROJECT_ACTIVITIES]],
                        show: true,
                      },
                      {
                        href: toProjectActivityDeleteView(project.id, projectActivity.id),
                        content: "Supprimer l'activité",
                        permissions: [[Permission.DELETE_PROJECT_ACTIVITIES]],
                        show: projectActivity.productIds.length === 0,
                      },
                    ]}
                  />
                </FlexboxGrid>
              </List.Item>
            );
          })}
      </List>
    </View>
  );
};
