import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext, useProjectContractContext } from "hooks";
import { useCreateProjectContractChoiceMutation } from "graphql/mutations";
import { useList } from "react-use";
import { ProjectContractChoiceCreateForm } from "forms";
import { ContractChoiceMode } from "graphql/globalTypes";

export const ProjectContractChoiceCreateView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectContractsView, toProjectContractView, toProjectContractChoiceView, toProjectContractChoicesView } = useRouting();
  const { project } = useProjectContext();
  const { projectContract } = useProjectContractContext();
  const [errors, { set: setErrors }] = useList<string>();
  const { createProjectContractChoice, createProjectContractChoiceLoading } = useCreateProjectContractChoiceMutation((projectId, customerContractId, customerChoiceId) => {
    navigate(toProjectContractChoiceView(projectId, customerContractId, customerChoiceId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Créer une révision"
      permissions={[[Permission.READ_PROJECT_CONTRACTS, Permission.CREATE_PROJECT_CONTRACT_CHOICES]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Contrats",
          href: toProjectContractsView(project.id),
        },
        {
          label: `Unité #${projectContract.projectUnit.number} (${projectContract.customer.firstName} ${projectContract.customer.lastName})`,
          href: toProjectContractView(project.id, projectContract.id),
        },
        {
          label: "Révisions",
          href: toProjectContractChoicesView(project.id, projectContract.id),
        },
      ]}
    >
      <ProjectContractChoiceCreateForm
        project={project}
        initialValues={{
          mode: ContractChoiceMode.PREVIOUS_CONTRACT_CHOICE_ITEMS,
          billingSettings: {
            customOptionUnitPrice: project.customOptionUnitPrice,
            additionalCosts: 0,
          },
        }}
        errors={errors}
        loading={createProjectContractChoiceLoading}
        onSubmit={async (values) => {
          await createProjectContractChoice(project.id, projectContract.id, {
            mode: values.mode,
            billingSettings: {
              customOptionUnitPrice: values.billingSettings.customOptionUnitPrice,
              additionalCosts: values.billingSettings.additionalCosts,
            },
          });
        }}
        onCancel={() => {
          navigate(toProjectContractChoicesView(project.id, projectContract.id));
        }}
      />
    </View>
  );
};
