import "./index.less";
import { FunctionComponent } from "react";
import { Button, Popover, Whisper } from "rsuite";
import { TypeAttributes } from "rsuite/esm/@types/common";

type Props = {
  readonly title: string;
  readonly content: string;
  readonly appearance?: TypeAttributes.Appearance | undefined;
  readonly size?: TypeAttributes.Size | undefined;
  readonly disabled?: boolean;
  readonly onClick?: (() => void) | undefined;
};

export const ButtonWithPopover: FunctionComponent<Props> = ({ title, content, appearance = undefined, size = "md", disabled = false, onClick = undefined }) => {
  return (
    <Whisper
      trigger="hover"
      placement="top"
      controlId={`control-id-top`}
      speaker={
        <Popover title={title} visible>
          <p>{content}</p>
        </Popover>
      }
    >
      <Button className="button-with-popover" onClick={onClick} size={size} appearance={appearance} disabled={disabled}>
        {title}
      </Button>
    </Whisper>
  );
};
