import { Permission } from "enums";
import { useRouting } from "hooks";
import { createContext, FunctionComponent, PropsWithChildren, useCallback } from "react";
import { useList } from "react-use";
import { IHookStateSetAction } from "react-use/lib/misc/hookState";
import { NavItem, SidebarButton, SidebarAction } from "types";

type LayoutContextValue = {
  readonly showSidebar: boolean;
  readonly sidebarButtons: SidebarButton[];
  readonly setSidebarButtons: (sidebarActions: IHookStateSetAction<SidebarButton[]>) => void;
  readonly sidebarActions: SidebarAction[];
  readonly setSidebarActions: (sidebarActions: IHookStateSetAction<SidebarAction[]>) => void;
  readonly getNavItems: (projectId: string | undefined) => NavItem[];
};

const initialValue: LayoutContextValue = {
  showSidebar: true,
  sidebarButtons: [],
  setSidebarButtons: () => {},
  sidebarActions: [],
  setSidebarActions: () => {},
  getNavItems: () => [],
};

export const LayoutContext = createContext<LayoutContextValue>(initialValue);

export const LayoutProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const {
    toProjectContractsView,
    toProjectModelsView,
    toProjectProductsView,
    toProjectActivitiesView,
    toProjectUnitsView,
    toProjectReportsView,
    toProjectsView,
    toCustomersView,
    toProvidersView,
    toUsersView,
  } = useRouting();
  const [sidebarButtons, { set: setSidebarButtons }] = useList<SidebarButton>();
  const [sidebarActions, { set: setSidebarActions }] = useList<SidebarAction>();
  const getNavItems: (projectId: string | undefined) => NavItem[] = useCallback(
    (projectId: string | undefined) => {
      return projectId
        ? [
            {
              label: "Contrats",
              href: toProjectContractsView(projectId),
              permissions: [[Permission.READ_PROJECT_CONTRACTS]],
            },
            {
              label: "Modèles",
              href: toProjectModelsView(projectId),
              permissions: [[Permission.READ_PROJECT_MODELS]],
            },
            {
              label: "Produits",
              href: toProjectProductsView(projectId),
              permissions: [[Permission.READ_PROJECT_PRODUCTS]],
            },
            {
              label: "Activités",
              href: toProjectActivitiesView(projectId),
              permissions: [[Permission.READ_PROJECT_ACTIVITIES]],
            },
            {
              label: "Unités",
              href: toProjectUnitsView(projectId),
              permissions: [[Permission.READ_PROJECT_UNITS]],
            },
            {
              label: "Rapports",
              href: toProjectReportsView(projectId),
              permissions: [
                [Permission.READ_PROJECT_REPORTS],
                [Permission.READ_PROJECT_REPORTS_CONSTRUCTION_SHEET_PER_FLOOR],
                [Permission.READ_PROJECT_REPORTS_CONSTRUCTION_SHEET_PER_UNIT],
                [Permission.READ_PROJECT_REPORTS_CUSTOMER_SHEET_PER_UNIT],
              ],
            },
          ]
        : [
            {
              label: "Projets",
              href: toProjectsView(),
              permissions: [[Permission.READ_PROJECTS]],
            },
            {
              label: "Clients",
              href: toCustomersView(),
              permissions: [[Permission.READ_CUSTOMERS]],
            },
            {
              label: "Fournisseurs",
              href: toProvidersView(),
              permissions: [[Permission.READ_PROVIDERS]],
            },
            {
              label: "Utilisateurs",
              href: toUsersView(),
              permissions: [[Permission.READ_USERS]],
            },
          ];
    },
    [
      toProjectContractsView,
      toProjectModelsView,
      toProjectProductsView,
      toProjectActivitiesView,
      toProjectUnitsView,
      toProjectReportsView,
      toProjectsView,
      toCustomersView,
      toProvidersView,
      toUsersView,
    ]
  );
  return (
    <LayoutContext.Provider
      value={{
        showSidebar: sidebarActions.length !== 0 || sidebarButtons.length !== 0,
        getNavItems,
        sidebarButtons,
        setSidebarButtons,
        sidebarActions,
        setSidebarActions,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
