import "./index.less";
import { FunctionComponent, useState } from "react";
import { Button, ButtonToolbar, Form, Input, InputNumber } from "rsuite";
import { FormError } from "buildingBlocks";
import produce from "immer";

type ProjectFormValues = {
  readonly code: string;
  readonly name: string;
  readonly description: string;
  readonly customOptionUnitPrice: number;
};

type Props = {
  readonly initialValues: ProjectFormValues;
  readonly errors: string[];
  readonly loading: boolean;
  readonly onSubmit: (values: ProjectFormValues) => Promise<void>;
  readonly onCancel: () => void;
};

export const ProjectForm: FunctionComponent<Props> = ({ initialValues, errors, loading, onSubmit, onCancel }) => {
  const [values, setValues] = useState<ProjectFormValues>(initialValues);
  return (
    <>
      <FormError errors={errors} />
      <Form className="form" fluid>
        <Form.Group>
          <Form.ControlLabel>Code</Form.ControlLabel>
          <Input
            value={values.code}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.code = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Nom</Form.ControlLabel>
          <Input
            value={values.name}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.name = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Description</Form.ControlLabel>
          <Input
            value={values.description}
            onChange={(value) =>
              setValues(
                produce(values, (draft) => {
                  draft.description = value;
                })
              )
            }
            as="textarea"
            rows={3}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Prix unitaire d'une option personnalisé</Form.ControlLabel>
          <InputNumber
            value={values.customOptionUnitPrice}
            onChange={(value) =>
              setValues(
                produce(values, (draft) => {
                  draft.customOptionUnitPrice = Number(value);
                })
              )
            }
            min={0}
            max={10000}
            step={10}
          />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              loading={loading}
              appearance="primary"
              onClick={() => {
                onSubmit(values);
              }}
            >
              Enregistrer
            </Button>
            <Button appearance="default" onClick={onCancel}>
              Annuler
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};
