import "./index.less";
import { FunctionComponent, useState } from "react";
import { Button, ButtonToolbar, Form, Input, Toggle } from "rsuite";
import { FormError } from "buildingBlocks";
import produce from "immer";
import { useToggle } from "react-use";
import { useTimer } from "react-timer-hook";
import moment from "moment";

type CustomerCredentialsResendFormValues = {
  readonly fullName: string;
  readonly email: string;
  readonly credentialsCanBeResentAt: any;
  readonly password?: string | null;
};

type Props = {
  readonly initialValues: CustomerCredentialsResendFormValues;
  readonly errors: string[];
  readonly loading: boolean;
  readonly onSubmit: (values: CustomerCredentialsResendFormValues) => Promise<void>;
  readonly onCancel: () => void;
};

export const CustomerCredentialsResendForm: FunctionComponent<Props> = ({ initialValues, errors, loading, onSubmit, onCancel }) => {
  const [values, setValues] = useState<CustomerCredentialsResendFormValues>(initialValues);
  const { seconds, minutes, isRunning } = useTimer({
    autoStart: true,
    expiryTimestamp: moment.utc(values.credentialsCanBeResentAt).local().toDate(),
    onExpire: () => {},
  });
  const [useRandomPassword, toggleUseRandomPassword] = useToggle(true);
  return (
    <>
      <FormError errors={errors} />
      <Form className="form" fluid>
        {/* <Form.Group>
          <Message  showIcon type="info">
            <p>Lorsque vous renvoyez les identifiants d'un client, un nouveau mot de passe sera généré aléatoirement. Vous avez également la possibilité de définir un mot de passe personnalisé.</p>
            <p>Vous pouvez renvoyez les identifiants du client toutes les 15 minutes.</p>
          </Message>
        </Form.Group> */}
        <Form.Group>
          <Form.ControlLabel>Nom</Form.ControlLabel>
          <Input plaintext value={values.fullName} />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Courriel</Form.ControlLabel>
          <Input plaintext value={values.email} />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Utilisé un mot de passe aléatoire</Form.ControlLabel>
          <Toggle
            checked={useRandomPassword}
            onChange={() => {
              toggleUseRandomPassword();
              setValues(
                produce(values, (draft) => {
                  draft.password = null;
                })
              );
            }}
          />
        </Form.Group>
        {!useRandomPassword && (
          <Form.Group>
            <Form.ControlLabel>Password</Form.ControlLabel>
            <Input
              value={values.password ?? undefined}
              onChange={(value) => {
                setValues(
                  produce(values, (draft) => {
                    draft.password = value;
                  })
                );
              }}
            />
          </Form.Group>
        )}
        <Form.Group>
          <ButtonToolbar>
            <Button
              disabled={isRunning}
              loading={loading}
              appearance="primary"
              onClick={() => {
                onSubmit(values);
              }}
            >
              {isRunning ? `${minutes}:${seconds}` : "Renvoyer"}
            </Button>
            <Button appearance="default" onClick={onCancel}>
              Annuler
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};
