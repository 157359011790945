import "./index.less";
import { Fragment, FunctionComponent } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Container, Header, Nav, Navbar, Avatar, SelectPicker, Button, Sidebar, Sidenav } from "rsuite";
import HomeIcon from "@rsuite/icons/legacy/Home";
import { useAuth, useLayoutContext, useAppContext, useRouting } from "hooks";

type Params = {
  readonly projectId?: string;
};

export const Layout: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView } = useRouting();
  const { projectId } = useParams<Params>();
  const { showSidebar, sidebarButtons, sidebarActions, getNavItems } = useLayoutContext();
  const navItems = getNavItems(projectId);
  const { projects } = useAppContext();
  const { userEmail, userInitial, logout, hasPermissions, hasProjects } = useAuth();
  return (
    <Container>
      <Header>
        <Navbar appearance="inverse">
          <Nav>
            <Nav.Item
              icon={<HomeIcon />}
              onClick={() => {
                navigate(toProjectsView());
              }}
            />
            <Nav.Item>
              <SelectPicker
                label="Projet"
                placeholder="Tous"
                size="sm"
                data={projects
                  .filter((project) => hasProjects([project.id]))
                  .map((project) => ({
                    label: project.label,
                    value: project.id,
                  }))}
                value={projectId ?? null}
                style={{ width: 224 }}
                onChange={(value) => {
                  if (value) {
                    navigate(toProjectView(value));
                  }
                }}
                onClean={() => {
                  navigate(toProjectsView());
                }}
              />
            </Nav.Item>
            {navItems
              .filter((navItem) => navItem.permissions.some((permissions) => hasPermissions(permissions)))
              .map((navItem, index) => (
                <Nav.Item
                  key={index}
                  onClick={() => {
                    navigate(navItem.href);
                  }}
                >
                  {navItem.label}
                </Nav.Item>
              ))}
          </Nav>
          <Nav pullRight>
            <Nav.Menu title={<Avatar style={{ background: "#000", fontWeight: "bold" }}>{userInitial}</Avatar>} trigger="hover" placement="bottomEnd">
              <Nav.Item disabled>{userEmail}</Nav.Item>
              <Nav.Item divider />
              <Nav.Item
                onClick={() => {
                  logout();
                }}
              >
                Se déconnecter
              </Nav.Item>
            </Nav.Menu>
          </Nav>
        </Navbar>
      </Header>
      <Container>
        <Outlet />
        {showSidebar && (
          <Sidebar width={260} collapsible>
            <Sidenav expanded appearance="default">
              <Sidenav.Body>
                {sidebarActions.length !== 0 && (
                  <Nav className="sidenav">
                    {sidebarActions
                      .filter((sidebarAction) => sidebarAction.permissions.some((permissions) => hasPermissions(permissions)))
                      .filter((sidebarAction) => sidebarAction.show)
                      .map((sidebarAction, index) => (
                        <Nav.Item
                          key={index}
                          className="sidenav-item"
                          onClick={() => {
                            navigate(sidebarAction.href);
                          }}
                        >
                          {sidebarAction.label}
                        </Nav.Item>
                      ))}
                  </Nav>
                )}
                {sidebarButtons.length !== 0 && (
                  <Nav className="sidenav">
                    <Nav.Item className="sidenav-item-buttons">
                      {sidebarButtons
                        .filter((sidebarButton) => (sidebarButton.enabled !== undefined ? sidebarButton.enabled : true))
                        .map((sidebarButton, index) => {
                          if (sidebarButton.permissions.some((permissions) => hasPermissions(permissions))) {
                            return (
                              <Button
                                key={index}
                                block
                                appearance="primary"
                                onClick={() => {
                                  const { href, onClick } = sidebarButton;
                                  if (href) {
                                    if (typeof href === "number") {
                                      navigate(href as number);
                                    }
                                    if (typeof href === "string") {
                                      navigate(href as string);
                                    }
                                  }
                                  if (onClick) {
                                    onClick();
                                  }
                                }}
                              >
                                {sidebarButton.label}
                              </Button>
                            );
                          } else {
                            return <Fragment />;
                          }
                        })}
                    </Nav.Item>
                  </Nav>
                )}
              </Sidenav.Body>
            </Sidenav>
          </Sidebar>
        )}
      </Container>
    </Container>
  );
};
