import "./index.less";
import { FunctionComponent, useState } from "react";
import { ListItemActions, SearchInputGroup, View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useProjectContext, useRouting } from "hooks";
import { List, FlexboxGrid } from "rsuite";

export const ProjectReportSetupsView: FunctionComponent = () => {
  const {
    searchParams,
    toProjectsView,
    toProjectView,
    toProjectReportsView,
    toProjectReportSetupView,
    toProjectReportSetupCreateView,
    toProjectReportSetupUpdateView,
    toProjectReportSetupDeleteView,
  } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  const { project, projectReportSetups } = useProjectContext();
  useLayout({
    sidebarActions: [],
    sidebarButtons: [
      {
        label: "Créer un paramétrage",
        href: toProjectReportSetupCreateView(project.id),
        permissions: [[Permission.UPDATE_PROJECT_REPORT_SETUPS]],
        enabled: projectReportSetups.length === 0,
      },
    ],
  });
  return (
    <View
      id="project-report-setups-view"
      permissions={[[Permission.READ_PROJECT_REPORT_SETUPS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Rapports",
          href: toProjectReportsView(project.id),
        },
        {
          label: "Paramétrages",
        },
      ]}
    >
      <SearchInputGroup placeholder="Recherche par nom" onChange={setSearch} />
      <List className="project-report-setup-list">
        {projectReportSetups
          .filter((projectReportSetup) => projectReportSetup.name.toSearchFormat().includes(search.toSearchFormat()))
          .map((projectReportSetup, index) => {
            return (
              <List.Item key={projectReportSetup.id} index={index + 1}>
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={22}>
                    <h3>{projectReportSetup.name}</h3>
                  </FlexboxGrid.Item>
                  <ListItemActions
                    colspan={2}
                    actions={[
                      {
                        content: "Voir le paramétrage",
                        href: toProjectReportSetupView(project.id, projectReportSetup.id),
                        permissions: [[Permission.READ_PROJECT_REPORT_SETUPS]],
                        show: true,
                      },
                      {
                        content: "Modifier le paramétrage",
                        href: toProjectReportSetupUpdateView(project.id, projectReportSetup.id),
                        permissions: [[Permission.UPDATE_PROJECT_REPORT_SETUPS]],
                        show: true,
                      },
                      {
                        content: "Supprimer le paramétrage",
                        href: toProjectReportSetupDeleteView(project.id, projectReportSetup.id),
                        permissions: [[Permission.DELETE_PROJECT_REPORT_SETUPS]],
                        show: true,
                      },
                    ]}
                  />
                </FlexboxGrid>
              </List.Item>
            );
          })}
      </List>
    </View>
  );
};
