import "./index.less";
import { FunctionComponent } from "react";
import { Button, ButtonToolbar, Form, Input, Message, Tag } from "rsuite";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import { ProjectModelElementOptionPriceList } from "components";
import { FormError } from "buildingBlocks";

type Props = {
  readonly projectModel: ProjectModel;
  readonly errors: string[];
  readonly loading: boolean;
  readonly onSubmit: () => Promise<void>;
  readonly onCancel: () => void;
};

export const ProjectModelPublishForm: FunctionComponent<Props> = ({ projectModel, errors, loading, onSubmit, onCancel }) => {
  return (
    <>
      <FormError errors={errors} />
      <Form id="project-model-publish-form" className="form" fluid>
        <Message
          style={{
            marginBottom: "24px",
          }}
          showIcon
          type="info"
        >
          Le modèle doit avoir au moins une ambiance pour être publié. Le prix des options supplémentaires pourra être modifié ultérieurement.
        </Message>
        {projectModel.elements
          .map((projectModelElement) => projectModelElement.options)
          .reduce((left, right) => left.concat(right), [])
          .filter((projectModelElementOption) => projectModelElementOption.isExtra)
          .some((projectModelElementOption) => projectModelElementOption.prices.length === 0) && (
          <Message
            header="ATTENTION!"
            style={{
              marginBottom: "24px",
            }}
            showIcon
            type="warning"
          >
            Certaines options identifiées comme extra dans le modèle n'ont pas de prix. Aucuns frais ne seront ajoutés à la facture des clients s'il choisit l'une de ses options après la publication
            d'un modèle. Vous pouvez modifier les prix des options en extra après la publication du modèle à tout moment.
          </Message>
        )}
        <Form.Group>
          <Form.ControlLabel>Nom du modèle</Form.ControlLabel>
          <Input plaintext value={projectModel.name} />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Ambiances</Form.ControlLabel>
          {projectModel.vibes.length === 0 ? (
            <span className="rs-plaintext">N/A</span>
          ) : (
            <ul className="project-model-vibes">
              {projectModel.vibes.map((projectModelVibe) => (
                <li className="project-model-vibe" key={projectModelVibe.id}>
                  <span>{projectModelVibe.name}</span>
                  {projectModelVibe.isDraft ? <Tag>Brouillon</Tag> : <Tag color="green">Publié</Tag>}
                </li>
              ))}
            </ul>
          )}
        </Form.Group>
        <ProjectModelElementOptionPriceList
          projectModel={projectModel}
          values={{
            name: projectModel.name,
            projectModelElementOptionPrices: projectModel.elements
              .map((projectModelElement) => projectModelElement.options)
              .reduce((left, right) => left.concat(right), [])
              .filter((projectModelElementOption) => projectModelElementOption.isExtra)
              .map((projectModelElementOption) => ({
                projectModelElementOptionId: projectModelElementOption.id,
                amount: projectModelElementOption.price ?? 0,
              })),
          }}
        />
        <Form.Group>
          <ButtonToolbar>
            <Button disabled={!projectModel.vibes.some((projectModelVibe) => !projectModelVibe.isDraft)} appearance="primary" loading={loading} onClick={onSubmit}>
              Publier
            </Button>
            <Button
              disabled={loading}
              appearance="default"
              onClick={() => {
                onCancel();
              }}
            >
              Annuler
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};
