import "./index.less";
import { FunctionComponent } from "react";
import { ProjectModelElement } from "graphql/__generated__/ProjectModelElement";
import { ProjectModelElementOptionPriceListItem } from "components";

type Props = {
  readonly projectModelElements: ProjectModelElement[];
  readonly setValues:
    | ((values: {
        readonly name: string;
        readonly projectModelElementOptionPrices: {
          readonly projectModelElementOptionId: string;
          readonly amount: number;
        }[];
      }) => void)
    | undefined;
  readonly values: {
    readonly name: string;
    readonly projectModelElementOptionPrices: {
      readonly projectModelElementOptionId: string;
      readonly amount: number;
    }[];
  };
};

export const ProjectModelElementOptionPriceListGroup: FunctionComponent<Props> = ({ values, setValues, projectModelElements }) => (
  <div className="project-model-element-option-price-list-group">
    {projectModelElements
      .map((projectModelElement) => {
        const projectModelElementOptions = projectModelElement.options.filter((projectModelElementOption) => projectModelElementOption.isExtra);
        return projectModelElementOptions.map((projectModelElementOption) => (
          <ProjectModelElementOptionPriceListItem
            key={projectModelElementOption.id}
            values={values}
            setValues={setValues}
            projectModelElement={projectModelElement}
            projectModelElementOption={projectModelElementOption}
          />
        ));
      })
      .reduce((left, right) => left.concat(right), [])}
  </div>
);
