import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext, useProjectContractContext, useAppContext } from "hooks";
import { useList } from "react-use";
import { useCancelProjectContractMutation } from "graphql/mutations";
import { ProjectContractCancelForm } from "forms";

// TODO: Need refactoring.
export const ProjectContractCancelView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectContractsView, toProjectContractView } = useRouting();
  const { customers } = useAppContext();
  const { project } = useProjectContext();
  const { projectContract } = useProjectContractContext();
  const [errors, { set: setErrors }] = useList<string>();
  const { cancelProjectContract, cancelProjectContractLoading } = useCancelProjectContractMutation((projectId) => {
    navigate(toProjectContractsView(projectId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Annuler le contrat"
      permissions={[[Permission.READ_PROJECT_CONTRACTS, Permission.CANCEL_PROJECT_CONTRACTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Contrats",
          href: toProjectContractsView(project.id),
        },
        {
          label: `Unité #${projectContract.projectUnit.number} (${projectContract.customer.firstName} ${projectContract.customer.lastName})`,
          href: toProjectContractView(project.id, projectContract.id),
        },
      ]}
    >
      <ProjectContractCancelForm
        customers={customers}
        projectContract={projectContract}
        errors={errors}
        loading={cancelProjectContractLoading}
        onSubmit={async () => {
          await cancelProjectContract(project.id, projectContract.id);
        }}
        onCancel={() => {
          navigate(toProjectContractView(project.id, projectContract.id));
        }}
      />
    </View>
  );
};
