import "./index.less";
import { FunctionComponent } from "react";
import { ProjectContractInvoicePanel } from "components";
import { View, ViewFilters } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useProjectContext, useProjectContractContext, useRouting } from "hooks";
import { CheckPicker } from "rsuite";
import { useList } from "react-use";

export const ProjectContractInvoicesView: FunctionComponent = () => {
  const { searchParams, toProjectsView, toProjectView, toProjectContractsView, toProjectContractView, toProjectContractPaymentsView, toProjectContractInvoiceView } = useRouting();
  // TODO:
  const [customerChoiceIds, { set: setCustomerChoiceIds }] = useList<string>(searchParams.get("customerChoiceIds")?.split(",") ?? []);
  const { project } = useProjectContext();
  const { projectContract } = useProjectContractContext();
  useLayout({
    sidebarActions: [
      {
        label: "Paiements",
        href: toProjectContractPaymentsView(project.id, projectContract.id),
        permissions: [[Permission.READ_PROJECT_CONTRACT_PAYMENTS]],
        show: true,
      },
    ],
    sidebarButtons: [],
  });
  return (
    <View
      id="project-contract-invoices-view"
      permissions={[[Permission.READ_PROJECT_CONTRACTS, Permission.READ_PROJECT_CONTRACT_INVOICES]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Contrats",
          href: toProjectContractsView(project.id),
        },
        {
          label: `Unité #${projectContract.projectUnit.number} (${projectContract.customer.firstName} ${projectContract.customer.lastName})`, // TODO:
          href: toProjectContractView(project.id, projectContract.id),
        },
        {
          label: "Factures",
        },
      ]}
    >
      <ViewFilters>
        <CheckPicker
          label="Révisions"
          style={{
            width: "250px",
          }}
          value={customerChoiceIds}
          data={projectContract.choices.map((projectContractChoice) => ({
            label: projectContractChoice.label,
            value: projectContractChoice.id,
          }))}
          onChange={setCustomerChoiceIds}
        />
      </ViewFilters>
      {projectContract.invoices
        .filter((projectContractInvoice) =>
          customerChoiceIds.length !== 0 ? (projectContractInvoice.projectContractChoiceId ? customerChoiceIds.includes(projectContractInvoice.projectContractChoiceId) : false) : true
        )
        .map((projectContractInvoice) => (
          <ProjectContractInvoicePanel
            key={projectContractInvoice.id}
            href={toProjectContractInvoiceView(project.id, projectContract.id, projectContractInvoice.id)}
            projectContractInvoice={projectContractInvoice}
          />
        ))}
    </View>
  );
};
