import "./index.less";
import { FunctionComponent } from "react";
import { Tooltip, Whisper } from "rsuite";
import { ProjectModelElementOption } from "graphql/__generated__/ProjectModelElementOption";
import { useProjectContext } from "hooks";

type Props = {
  projectModelElementOption: ProjectModelElementOption;
  active?: (() => boolean) | boolean | undefined;
  onClick?: (() => void) | undefined;
  size?: "sm" | "lg";
};

export const ProjectModelElementOptionPanel: FunctionComponent<Props> = ({ projectModelElementOption, active, onClick, size = "lg" }) => {
  const { projectProducts } = useProjectContext();
  const projectProduct = projectProducts.find((projectProduct) => projectProduct.id === projectModelElementOption.productId)!;
  let className = "project-model-element-option-panel";
  if (size === "lg") {
    className += " project-model-element-option-panel-lg";
  }
  if (size === "sm") {
    className += " project-model-element-option-panel-sm";
  }
  if (active !== undefined) {
    if (typeof active === "function") {
      if (active()) {
        className += " active";
      }
    }
    if (typeof active === "boolean") {
      if (active) {
        className += " active";
      }
    }
  }
  const element = (
    <div className={className} onClick={onClick}>
      {projectProduct.hasThumbnail ? (
        <img
          className="panel project-model-element-option-panel-image"
          alt="Vignette du produit"
          src={`${process.env.REACT_APP_AZURE_STORAGE_BLOB_SERVICE_URL}/project-products/${projectProduct.id}/thumbnail.png`}
        />
      ) : (
        <div className="project-model-element-option-panel-image" />
      )}
      {size === "lg" && (
        <div>
          <h5>{projectProduct?.variant ?? projectProduct.name}</h5>
          <div>Prix: {projectModelElementOption.isExtra ? projectModelElementOption.price ? <span>{projectModelElementOption.price}$</span> : <small>N/A</small> : <small>INCLUT</small>}</div>
        </div>
      )}
    </div>
  );
  if (size === "lg") {
    return element;
  }
  return (
    <Whisper
      placement="top"
      trigger="hover"
      speaker={({ className }, ref) => {
        return (
          <Tooltip ref={ref} className={className}>
            {projectProduct.variant ?? projectProduct.name}
          </Tooltip>
        );
      }}
    >
      {element}
    </Whisper>
  );
};
