import "./index.less";
import { FunctionComponent, useState } from "react";
import { Button, ButtonToolbar, Form, InputNumber, SelectPicker } from "rsuite";
import { FormError } from "buildingBlocks";
import produce from "immer";
import { ContractChoiceMode } from "graphql/globalTypes";
import { Project } from "graphql/__generated__/Project";

type ProjectContractChoiceCreateFormValues = {
  readonly mode: ContractChoiceMode;
  readonly billingSettings: {
    readonly customOptionUnitPrice: number;
    readonly additionalCosts: number;
  };
};

type Props = {
  readonly project: Project;
  readonly initialValues: ProjectContractChoiceCreateFormValues;
  readonly errors: string[];
  readonly loading: boolean;
  readonly onSubmit: (values: ProjectContractChoiceCreateFormValues) => Promise<void>;
  readonly onCancel: () => void;
};

export const ProjectContractChoiceCreateForm: FunctionComponent<Props> = ({ project, initialValues, errors, loading, onSubmit, onCancel }) => {
  const [values, setValues] = useState<ProjectContractChoiceCreateFormValues>(initialValues);
  return (
    <>
      <FormError errors={errors} />
      <Form className="form" fluid>
        <Form.Group>
          <Form.ControlLabel>Mode pour le choix</Form.ControlLabel>
          <SelectPicker
            cleanable={false}
            value={values.mode}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  const projectContractChoiceMode = value!;
                  draft.mode = projectContractChoiceMode;
                  if (projectContractChoiceMode === ContractChoiceMode.PROJECT_MODEL_VIBE) {
                    draft.billingSettings.customOptionUnitPrice = 0;
                  } else {
                    if (values.billingSettings.customOptionUnitPrice === 0) {
                      draft.billingSettings.customOptionUnitPrice = project.customOptionUnitPrice;
                    }
                  }
                })
              );
            }}
            data={[
              {
                label: "L'ambiance",
                value: ContractChoiceMode.PROJECT_MODEL_VIBE,
              },
              {
                label: "Éléments de l'ambiance",
                value: ContractChoiceMode.PROJECT_MODEL_VIBE_ITEMS,
              },
              {
                label: "Choix précédent au contrat",
                value: ContractChoiceMode.PREVIOUS_CONTRACT_CHOICE_ITEMS,
              },
            ]}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Coût unitaire par option personnalisée</Form.ControlLabel>
          <InputNumber
            disabled={values.mode === ContractChoiceMode.PROJECT_MODEL_VIBE}
            value={values.billingSettings.customOptionUnitPrice}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.billingSettings.customOptionUnitPrice = Number(value);
                })
              );
            }}
            min={0}
            max={10000}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Coût additionnel</Form.ControlLabel>
          <InputNumber
            value={values.billingSettings.additionalCosts}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.billingSettings.additionalCosts = Number(value);
                })
              );
            }}
            min={0}
            max={100000}
          />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              loading={loading}
              appearance="primary"
              onClick={() => {
                onSubmit(values);
              }}
            >
              Enregistrer
            </Button>
            <Button appearance="default" onClick={onCancel}>
              Annuler
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};
