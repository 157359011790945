import "./index.less";
import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useAppContext, useLayout, useProjectContext, useProjectContractChoiceContext, useProjectContractContext, useRouting } from "hooks";
import { CustomerSheetPerUnitDocument } from "documents";

export const ProjectContractChoiceView: FunctionComponent = () => {
  const { toProjectsView, toProjectView, toProjectContractsView, toProjectContractView, toProjectContractChoiceView, toProjectContractInvoicesView, toProjectContractChoicesView } = useRouting();
  const { customers } = useAppContext();
  const { project, projectModels, projectProducts, projectUnits } = useProjectContext();
  const { projectContract } = useProjectContractContext();
  const { projectContractChoice } = useProjectContractChoiceContext();
  const projectModel = projectModels.find((projectModel) => projectModel.id === projectContract.projectModelId);
  useLayout({
    sidebarActions: [
      {
        label: "Factures",
        href: toProjectContractInvoicesView(project.id, projectContract.id, {
          customerChoiceIds: [projectContractChoice.id],
        }),
        permissions: [[Permission.READ_PROJECT_CONTRACT_INVOICES]],
        show: true,
      },
    ],
    sidebarButtons: [],
  });
  return (
    <View
      title={projectContractChoice.label}
      permissions={[[Permission.READ_PROJECT_CONTRACTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Contrats",
          href: toProjectContractsView(project.id),
        },
        {
          label: `Unité #${projectContract.projectUnit.number} (${projectContract.customer.firstName} ${projectContract.customer.lastName})`,
          href: toProjectContractView(project.id, projectContract.id),
        },
        {
          label: "Révisions",
          href: toProjectContractChoicesView(project.id, projectContract.id),
        },
        {
          label: projectContractChoice.label,
          href: toProjectContractChoiceView(project.id, projectContract.id, projectContractChoice.id),
        },
      ]}
    >
      <CustomerSheetPerUnitDocument
        project={project}
        projectUnit={projectContract.projectUnit}
        projectUnits={projectUnits}
        projectContract={projectContract}
        customers={customers}
        projectContractChoice={projectContractChoice}
        projectModel={projectModel}
        projectModels={projectModels}
        projectProducts={projectProducts}
      />
    </View>
  );
};
