import "./index.less";
import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { ProjectContractInvoiceDocument } from "documents";
import { Permission } from "enums";
import { useLayout, useProjectContext, useProjectContractContext, useProjectContractInvoiceContext, useProjectContractInvoiceDocumentContext } from "hooks";
import { Button, ButtonToolbar } from "rsuite";
import { useRouting } from "hooks";

export const ProjectContractInvoiceView: FunctionComponent = () => {
  const { toProjectsView, toProjectView, toProjectContractsView, toProjectContractView, toProjectContractPaymentCreateView, toProjectContractPaymentsView, toProjectContractInvoicesView } =
    useRouting();
  const { project, projectModels, projectUnits } = useProjectContext();
  const { projectContract } = useProjectContractContext();
  const { projectContractInvoice } = useProjectContractInvoiceContext();
  const { download } = useProjectContractInvoiceDocumentContext("invoice-document", projectContractInvoice.label);
  useLayout({
    sidebarActions: [
      {
        label: "Paiements",
        href: toProjectContractPaymentsView(project.id, projectContract.id, {
          customerInvoiceIds: [projectContractInvoice.id],
        }),
        permissions: [[Permission.READ_PROJECT_CONTRACT_PAYMENTS]],
        show: true,
      },
    ],
    sidebarButtons: [
      {
        label: "Créer un paiement",
        href: toProjectContractPaymentCreateView(project.id, projectContract.id, {
          customerInvoiceId: projectContractInvoice.id,
        }),
        permissions: [[Permission.CREATE_PROJECT_CONTRACT_PAYMENTS]],
        enabled: !projectContract.isCanceled,
      },
    ],
  });
  return (
    <View
      id="project-contract-invoice-view"
      permissions={[[Permission.READ_PROJECT_CONTRACTS, Permission.READ_PROJECT_CONTRACT_INVOICES]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Contrats",
          href: toProjectContractsView(project.id),
        },
        {
          label: `Unité #${projectContract.projectUnit.number} (${projectContract.customer.firstName} ${projectContract.customer.lastName})`,
          href: toProjectContractView(project.id, projectContract.id),
        },
        {
          label: "Factures",
          href: toProjectContractInvoicesView(project.id, projectContract.id),
        },
        {
          label: `# ${projectContractInvoice.label}`,
        },
      ]}
    >
      <ButtonToolbar>
        <Button appearance="primary" size="lg" onClick={download}>
          Télécharger
        </Button>
      </ButtonToolbar>
      <hr />
      <ProjectContractInvoiceDocument project={project} projectContract={projectContract} projectContractInvoice={projectContractInvoice} projectUnits={projectUnits} projectModels={projectModels} />
      <ProjectContractInvoiceDocument
        id="invoice-document"
        project={project}
        projectContract={projectContract}
        projectContractInvoice={projectContractInvoice}
        projectUnits={projectUnits}
        projectModels={projectModels}
      />
    </View>
  );
};
