import "./index.less";
import { FunctionComponent, useState } from "react";
import { ListItemActions, SearchInputGroup, View } from "buildingBlocks";
import { Permission } from "enums";
import { useAppContext, useLayout, useRouting } from "hooks";
import { List, FlexboxGrid } from "rsuite";

export const ProvidersView: FunctionComponent = () => {
  const { searchParams, toProviderCreateView, toProviderView, toProviderUpdateView, toProviderDeleteView } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  const { providers } = useAppContext();
  useLayout({
    sidebarActions: [],
    sidebarButtons: [
      {
        label: "Créer un fournisseur",
        href: toProviderCreateView(),
        permissions: [[Permission.CREATE_PROVIDERS]],
      },
    ],
  });
  return (
    <View
      id="providers-view"
      permissions={[[Permission.READ_PROVIDERS]]}
      breadcrumbItems={[
        {
          label: "Fournisseurs",
        },
      ]}
    >
      <SearchInputGroup placeholder="Recherche par nom" onChange={setSearch} />
      <List className="provider-list">
        {providers
          .filter((provider) => provider.name.toSearchFormat().includes(search.toSearchFormat()))
          .map((provider, index) => {
            return (
              <List.Item key={provider.id} index={index + 1}>
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={22}>
                    <h3>{provider.name}</h3>
                  </FlexboxGrid.Item>
                  <ListItemActions
                    colspan={2}
                    actions={[
                      {
                        href: toProviderView(provider.id),
                        content: "Voir le fournisseur",
                        permissions: [[Permission.READ_PROVIDERS]],
                        show: true,
                      },
                      {
                        href: toProviderUpdateView(provider.id),
                        content: "Modifier le fournisseur",
                        permissions: [[Permission.UPDATE_PROVIDERS]],
                        show: true,
                      },
                      {
                        href: toProviderDeleteView(provider.id),
                        content: "Supprimer le fournisseur",
                        permissions: [[Permission.DELETE_PROVIDERS]],
                        show: provider.projectProductIds.length === 0,
                      },
                    ]}
                  />
                </FlexboxGrid>
              </List.Item>
            );
          })}
      </List>
    </View>
  );
};
