import "./index.less";
import { FunctionComponent } from "react";
import { ProjectReportSetupElement } from "graphql/__generated__/ProjectReportSetupElement";

type Props = {
  readonly projectReportSetupElement: ProjectReportSetupElement;
};

export const ProjectReportSetupElementPanel: FunctionComponent<Props> = ({ projectReportSetupElement }) => {
  return (
    <div className="project-report-setup-element-panel">
      <div className="project-report-setup-element-panel-header">
        <span>{projectReportSetupElement.name}</span>
      </div>
    </div>
  );
};
