import "./index.less";
import { FunctionComponent, useState } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useProjectContext, useRouting } from "hooks";
import { ProjectModelUploadForm } from "forms";
import { useList } from "react-use";
import { ProjectModelUploadFormValues } from "types";
import { useUploadProjectModelMutation } from "graphql/mutations";

export const ProjectModelUploadView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectModelsView, toProjectModelView } = useRouting();
  const { project } = useProjectContext();
  const [errors, { set: setErrors }] = useList<string>();
  const { uploadProjectModel, uploadProjectModelLoading } = useUploadProjectModelMutation((projectId, projectModelId) => {
    navigate(toProjectModelView(projectId, projectModelId));
  }, setErrors);
  const [values, setValues] = useState<ProjectModelUploadFormValues>({
    dataBlobFile: undefined,
    unityDataBlobFile: undefined,
    unityFrameworkBlobFile: undefined,
    unityLoaderBlobFile: undefined,
    unityWasmBlobFile: undefined,
    projectProductThumbnailBlobFiles: [],
  });
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      form={{
        loading: uploadProjectModelLoading,
        disabled:
          values.dataBlobFile === undefined ||
          values.unityDataBlobFile === undefined ||
          values.unityFrameworkBlobFile === undefined ||
          values.unityLoaderBlobFile === undefined ||
          values.unityWasmBlobFile === undefined ||
          values.projectProductThumbnailBlobFiles.length === 0,
        onSubmit: async () => {
          await uploadProjectModel(project.id, {
            dataBlobFile: values.dataBlobFile,
            unityDataBlobFile: values.unityDataBlobFile,
            unityFrameworkBlobFile: values.unityFrameworkBlobFile,
            unityLoaderBlobFile: values.unityLoaderBlobFile,
            unityWasmBlobFile: values.unityWasmBlobFile,
            projectProductThumbnailBlobFiles: values.projectProductThumbnailBlobFiles,
          });
        },
        onCancel: () => {
          navigate(toProjectModelsView(project.id));
        },
      }}
      title="Téléverser un modèle"
      permissions={[[Permission.READ_PROJECT_MODELS, Permission.UPLOAD_PROJECT_MODELS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Modèles",
          href: toProjectModelsView(project.id),
        },
      ]}
    >
      <ProjectModelUploadForm errors={errors} values={values} setValues={setValues} />
    </View>
  );
};
