import "./index.less";
import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext, useProjectContractContext, useProjectContractPaymentContext } from "hooks";
import { useUpdateProjectContractPaymentMutation } from "graphql/mutations";
import { useList } from "react-use";
import { ProjectContractPaymentForm } from "forms";
import { PersistentStorageOperation } from "graphql/globalTypes";

export const ProjectContractPaymentUpdateView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectContractsView, toProjectContractView, toProjectContractPaymentsView } = useRouting();
  const { project } = useProjectContext();
  const { projectContract } = useProjectContractContext();
  const { projectContractPayment } = useProjectContractPaymentContext();
  const [errors, { set: setErrors }] = useList<string>();
  const { updateProjectContractPayment, updateProjectContractPaymentLoading } = useUpdateProjectContractPaymentMutation((projectId, customerContractId) => {
    navigate(toProjectContractPaymentsView(projectId, customerContractId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      id="project-contract-payment-update-view"
      title="Modifier le paiement"
      permissions={[[Permission.READ_PROJECT_CONTRACTS, Permission.READ_PROJECT_CONTRACT_PAYMENTS, Permission.UPDATE_PROJECT_CONTRACT_PAYMENTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Contrats",
          href: toProjectContractsView(project.id),
        },
        {
          label: `Unité #${projectContract.projectUnit.number} (${projectContract.customer.firstName} ${projectContract.customer.lastName})`,
          href: toProjectContractView(project.id, projectContract.id),
        },
        {
          label: "Paiements",
          href: toProjectContractPaymentsView(project.id, projectContract.id),
        },
      ]}
    >
      <ProjectContractPaymentForm
        _operation={PersistentStorageOperation.UPDATE}
        readonly={false}
        projectContractInvoices={projectContract.invoices}
        initialValues={{
          customerInvoiceId: projectContractPayment.projectContractInvoiceId,
          amount: projectContractPayment.amount,
          referenceNumber: projectContractPayment.referenceNumber ?? "",
        }}
        errors={errors}
        loading={updateProjectContractPaymentLoading}
        onSubmit={async (values) => {
          await updateProjectContractPayment(project.id, projectContract.id, values.customerInvoiceId, projectContractPayment.id, {
            amount: values.amount,
            referenceNumber: values.referenceNumber === "" ? null : values.referenceNumber,
          });
        }}
        onCancel={() => {
          navigate(toProjectContractPaymentsView(project.id, projectContract.id));
        }}
      />
    </View>
  );
};
