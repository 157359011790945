import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext, useProjectModelContext } from "hooks";
import { usePublishProjectModelMutation } from "graphql/mutations";
import { useList } from "react-use";
import { ProjectModelPublishForm } from "forms";

export const ProjectModelPublishView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectModelsView, toProjectModelView } = useRouting();
  const { project } = useProjectContext();
  const { projectModel } = useProjectModelContext();
  const [errors, { set: setErrors }] = useList<string>();
  const { publishProjectModel, publishProjectModelLoading } = usePublishProjectModelMutation((projectId, projectModelId) => {
    navigate(toProjectModelView(projectId, projectModelId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Publier le modèle"
      permissions={[[Permission.READ_PROJECT_MODELS, Permission.PUBLISH_PROJECT_MODELS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Modèles",
          href: toProjectModelsView(project.id),
        },
        {
          label: projectModel.name,
          href: toProjectModelView(project.id, projectModel.id),
        },
      ]}
    >
      <ProjectModelPublishForm
        projectModel={projectModel}
        errors={errors}
        loading={publishProjectModelLoading}
        onSubmit={async () => {
          await publishProjectModel(project.id, projectModel.id);
        }}
        onCancel={() => {
          navigate(toProjectModelView(project.id, projectModel.id));
        }}
      />
    </View>
  );
};
