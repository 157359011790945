import "./index.less";
import { FunctionComponent } from "react";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import { ProjectModelElementOptionPriceListGroup } from "components";

type Props = {
  readonly projectModel: ProjectModel;
  readonly values: {
    readonly name: string;
    readonly projectModelElementOptionPrices: {
      readonly projectModelElementOptionId: string;
      readonly amount: number;
    }[];
  };
  readonly setValues?:
    | ((values: {
        readonly name: string;
        readonly projectModelElementOptionPrices: {
          readonly projectModelElementOptionId: string;
          readonly amount: number;
        }[];
      }) => void)
    | undefined;
};

export const ProjectModelElementOptionPriceList: FunctionComponent<Props> = ({ values, setValues, projectModel }) => {
  return (
    <div className="project-model-element-option-price-list">
      <h2>Liste des options (EXTRA)</h2>
      {projectModel.rooms
        .filter((projectModelRoom) =>
          projectModel.elements
            .filter((projectModelElement) => projectModelElement.projectModelRoomId === projectModelRoom.id)
            .some((projectModelElement) => projectModelElement.options.some((projectModelElementOption) => projectModelElementOption.isExtra))
        )
        .map((projectModelRoom) => {
          const projectModelElements = projectModel.elements.filter((projectModelElement) => projectModelElement.projectModelRoomId === projectModelRoom.id);
          return (
            <div key={projectModelRoom.id}>
              <h3>{projectModelRoom.name}</h3>
              <ProjectModelElementOptionPriceListGroup values={values} setValues={setValues} projectModelElements={projectModelElements} />
            </div>
          );
        })}
    </div>
  );
};
