import "./index.less";
import { FunctionComponent, PropsWithChildren } from "react";
import { Panel } from "rsuite";

type Props = PropsWithChildren & {
  readonly label: string;
};

export const ChartPanel: FunctionComponent<Props> = ({ label, children }) => (
  <Panel shaded header={label.toUpperCase()} className="chart-panel">
    {children}
  </Panel>
);
