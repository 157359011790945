import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useCustomerContext, useAppContext, useRouting } from "hooks";
import { CustomerForm } from "forms";
import { useUpdateCustomerMutation } from "graphql/mutations";
import { useList } from "react-use";
import { PersistentStorageOperation } from "graphql/globalTypes";

export const CustomerUpdateView: FunctionComponent = () => {
  const { navigate, toCustomersView, toCustomerView } = useRouting();
  const { countries } = useAppContext();
  const { customer } = useCustomerContext();
  const [errors, { set: setErrors }] = useList<string>();
  const { updateCustomer, updateCustomerLoading } = useUpdateCustomerMutation((customerId) => {
    navigate(toCustomerView(customerId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Modifier le client"
      permissions={[[Permission.UPDATE_CUSTOMERS]]}
      breadcrumbItems={[
        {
          label: "Clients",
          href: toCustomersView(),
        },
        {
          label: customer.fullName,
          href: toCustomerView(customer.id),
        },
      ]}
    >
      <CustomerForm
        _operation={PersistentStorageOperation.UPDATE}
        countries={countries}
        initialValues={{
          firstName: customer.firstName,
          lastName: customer.lastName,
          phone: customer.phone,
          email: customer.email,
          language: customer.language,
          gender: customer.gender,
          address: {
            line: customer.address.line,
            city: customer.address.city,
            state: customer.address.state,
            country: customer.address.country,
            postalCode: customer.address.postalCode,
          },
        }}
        errors={errors}
        loading={updateCustomerLoading}
        onCancel={() => {
          navigate(toCustomerView(customer.id));
        }}
        onSubmit={async (values) => {
          await updateCustomer(customer.id, {
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            language: values.language,
            gender: values.gender,
            address: {
              line: values.address.line,
              city: values.address.city,
              state: values.address.state,
              country: values.address.country,
              postalCode: values.address.postalCode,
            },
          });
        }}
      />
    </View>
  );
};
