import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useAppContext, useLayout, useRouting, useUserContext } from "hooks";
import { UserAccessUpdateForm } from "forms";
import { useList } from "react-use";
import { useUpdateUserAccessMutation } from "graphql/mutations";

export const UserAccessUpdateView: FunctionComponent = () => {
  const { navigate, toUsersView } = useRouting();
  const { projects, roles } = useAppContext();
  const { user } = useUserContext();
  const [errors, { set: setErrors }] = useList<string>();
  const { updateUserAccess, updateUserAccessLoading } = useUpdateUserAccessMutation((userId) => {
    navigate(toUsersView());
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Modifier l'accès de l'utilisateur"
      permissions={[[Permission.READ_USERS, Permission.UPDATE_USER_ACCESS]]}
      breadcrumbItems={[
        {
          label: "Utilisateurs",
          href: toUsersView(),
        },
        {
          label: `${user.name} (${user.email})`,
        },
      ]}
    >
      <UserAccessUpdateForm
        projects={projects}
        roles={roles}
        initialValues={{
          hasAccessToAllProjects: user.hasAccessToAllProjects,
          projectIds: [...user.projectIds],
          roleIds: [...user.roleIds],
        }}
        errors={errors}
        loading={updateUserAccessLoading}
        onSubmit={async (values) => {
          await updateUserAccess(user.id, {
            hasAccessToAllProjects: values.hasAccessToAllProjects,
            projectIds: values.hasAccessToAllProjects ? [] : values.projectIds,
            roleIds: values.roleIds,
          });
        }}
        onCancel={() => {
          navigate(toUsersView());
        }}
      />
    </View>
  );
};
