import { useAuth0 } from "@auth0/auth0-react";
import { Role, Permission } from "enums";
import { useCallback } from "react";

export const useAuth = () => {
  const {
    user: userAuth0,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    getIdTokenClaims,
    loginWithPopup,
    loginWithRedirect,
    logout,
    buildAuthorizeUrl,
    buildLogoutUrl,
    handleRedirectCallback,
    isLoading,
    isAuthenticated,
  } = useAuth0();
  const user = { ...userAuth0 };
  const userId: string = user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/claims/userId`];
  const userFirstName: string = user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/claims/firstName`];
  const userLastName: string = user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/claims/lastName`];
  const userFullName: string = `${userFirstName} ${userLastName}`;
  const userInitial: string = userFullName
    .split(" ")
    .map((part) => part[0])
    .join("")
    .toUpperCase();
  const userEmail = `${user.email}`;
  const userRoles: Role[] = user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/claims/roles`];
  const userPermissions: Permission[] = user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/claims/permissions`];
  const userProjectIds: string[] = user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/claims/projectIds`];
  const hasRoles = useCallback(
    (roles: Role[]) => {
      return roles.every((role) => userRoles.includes(role));
    },
    [userRoles]
  );
  const hasPermissions = useCallback(
    (permissions: Permission[]) => {
      return hasRoles([Role.SA]) || permissions.every((permission) => userPermissions.includes(permission));
    },
    [hasRoles, userPermissions]
  );
  const hasProjects = useCallback(
    (projectIds: string[]) => {
      return hasRoles([Role.SA]) || hasRoles([Role.ALL_PROJECTS]) || projectIds.every((projectId) => userProjectIds.includes(projectId));
    }, // eslint-disable-next-line
    [hasRoles, userProjectIds]
  );
  return {
    userId,
    userEmail,
    userFullName,
    userInitial,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    getIdTokenClaims,
    loginWithPopup,
    loginWithRedirect,
    logout,
    buildAuthorizeUrl,
    buildLogoutUrl,
    handleRedirectCallback,
    isLoading,
    isAuthenticated,
    hasRoles,
    hasPermissions,
    hasProjects,
  };
};
