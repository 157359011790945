import "./index.less";
import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useAuth, useLayout, useProjectContext, useRouting } from "hooks";
import { Button, Col, Panel, Row } from "rsuite";

export const ProjectReportsView: FunctionComponent = () => {
  const {
    navigate,
    toProjectsView,
    toProjectView,
    toProjectReportSetupsView,
    toProjectReportsConstructionSheetPerFloorView,
    toProjectReportsConstructionSheetPerUnitView,
    toProjectReportsCustomerSheetPerUnitView,
  } = useRouting();
  const { hasPermissions } = useAuth();
  const { project } = useProjectContext();
  useLayout({
    sidebarActions: [
      {
        label: "Paramétrages",
        href: toProjectReportSetupsView(project.id),
        permissions: [[Permission.READ_PROJECT_REPORT_SETUPS]],
        show: true,
      },
    ],
    sidebarButtons: [],
  });
  return (
    <View
      id="project-reports-view"
      permissions={[
        [Permission.READ_PROJECT_REPORTS],
        [Permission.READ_PROJECT_REPORTS_CONSTRUCTION_SHEET_PER_FLOOR],
        [Permission.READ_PROJECT_REPORTS_CONSTRUCTION_SHEET_PER_UNIT],
        [Permission.READ_PROJECT_REPORTS_CUSTOMER_SHEET_PER_UNIT],
      ]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Rapports",
        },
      ]}
    >
      <Row>
        {(hasPermissions([Permission.READ_PROJECT_REPORTS]) || hasPermissions([Permission.READ_PROJECT_REPORTS_CONSTRUCTION_SHEET_PER_FLOOR])) && (
          <Col xl={8} lg={12} md={24} sm={24}>
            <Panel bordered shaded header={<h2>Fiche de construction par étage</h2>} style={{ marginBottom: "10px" }}>
              <p>Une liste des choix des clients pour chaque unité filtrée par étage et par code d'activité. Ce rapport utilise les références des clients pour l'affichage.</p>
              <Button
                appearance="primary"
                size="lg"
                onClick={() => {
                  navigate(toProjectReportsConstructionSheetPerFloorView(project.id));
                }}
              >
                Voir le rapport
              </Button>
            </Panel>
          </Col>
        )}
        {(hasPermissions([Permission.READ_PROJECT_REPORTS]) || hasPermissions([Permission.READ_PROJECT_REPORTS_CONSTRUCTION_SHEET_PER_UNIT])) && (
          <Col xl={8} lg={12} md={24} sm={24}>
            <Panel bordered shaded header={<h2>Fiche de construction par unité</h2>} style={{ marginBottom: "10px" }}>
              <p>Une liste des choix d'un client pour la personnalisation de son unité. Ce rapport utilise les références des fournisseurs pour l'affichage.</p>
              <Button
                appearance="primary"
                size="lg"
                onClick={() => {
                  navigate(toProjectReportsConstructionSheetPerUnitView(project.id));
                }}
              >
                Voir le rapport
              </Button>
            </Panel>
          </Col>
        )}
        {(hasPermissions([Permission.READ_PROJECT_REPORTS]) || hasPermissions([Permission.READ_PROJECT_REPORTS_CUSTOMER_SHEET_PER_UNIT])) && (
          <Col xl={8} lg={12} md={24} sm={24}>
            <Panel bordered shaded header={<h2>Fiche client par unité</h2>} style={{ marginBottom: "10px" }}>
              <p>Une liste des choix d'un client pour la personnalisation de son unité. Ce rapport utilise les références des clients pour l'affichage.</p>
              <Button
                appearance="primary"
                size="lg"
                onClick={() => {
                  navigate(toProjectReportsCustomerSheetPerUnitView(project.id));
                }}
              >
                Voir le rapport
              </Button>
            </Panel>
          </Col>
        )}
      </Row>
    </View>
  );
};
