import "./index.less";
import { FunctionComponent } from "react";
import { FlexboxGrid, List } from "rsuite";
import { ProjectModelElement } from "graphql/__generated__/ProjectModelElement";
import { ProjectProduct } from "graphql/__generated__/ProjectProduct";
import { ProjectModelVibeItem } from "types";

type Props = {
  readonly projectModelVibeItems: ProjectModelVibeItem[];
  readonly projectProducts: ProjectProduct[];
  readonly projectModelElements: ProjectModelElement[];
};

const ProjectModelVibeItemOptionLabel: FunctionComponent<{ projectModelElement: ProjectModelElement; projectProduct?: ProjectProduct | undefined }> = ({
  projectModelElement,
  projectProduct,
}) => {
  if (projectModelElement.isBoolean) {
    return <>{projectProduct === undefined ? "EXCLU" : "INCLUS"}</>;
  }
  return <>{projectProduct === undefined ? "N/A" : projectProduct.name}</>;
};

export const ProjectModelVibeItemList: FunctionComponent<Props> = ({ projectProducts, projectModelElements, projectModelVibeItems }) => (
  <List className="project-model-vibe-item-list">
    {projectModelElements.map((projectModelElement, projectModelElementIndex) => {
      const projectModelVibeItem = projectModelVibeItems.find((projectModelVibeItem) => projectModelVibeItem.projectModelElementId === projectModelElement.id)!;
      const projectModelElementOptions = projectModelElement.options;
      const projectModelElementOption = projectModelElementOptions.find(
        (projectModelElementOption) => projectModelElementOption.id === projectModelVibeItem.projectModelElementOptionId
      );
      const projectProduct = projectProducts.find((projectProduct) => projectProduct.id === projectModelElementOption?.productId);
      return (
        <List.Item key={projectModelElement.id} index={projectModelElementIndex + 1}>
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={12}>
              <h3>{projectModelElement.name}</h3>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item style={{ display: "flex", justifyContent: "end", alignItems: "center" }} colspan={12}>
              <ProjectModelVibeItemOptionLabel projectModelElement={projectModelElement} projectProduct={projectProduct} />
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </List.Item>
      );
    })}
  </List>
);
