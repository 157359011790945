import { FunctionComponent, useState } from "react";
import { Form } from "rsuite";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useProviderContext, useRouting } from "hooks";

type InitialFormValue = {
  name: string;
  website: string | null | undefined;
};

const initialFormValue: InitialFormValue = {
  name: "",
  website: undefined,
};

export const ProviderView: FunctionComponent = () => {
  const { toProvidersView, toProviderUpdateView, toProviderDeleteView } = useRouting();
  const { provider } = useProviderContext();
  const [formValue, setFormValue] = useState<InitialFormValue>(initialFormValue);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [
      {
        label: "Modifier le fournisseur",
        href: toProviderUpdateView(provider.id),
        permissions: [[Permission.UPDATE_PROVIDERS]],
      },
      {
        label: "Supprimer le fournisseur",
        href: toProviderDeleteView(provider.id),
        permissions: [[Permission.DELETE_PROVIDERS]],
        enabled: provider.projectProductIds.length === 0,
      },
    ],
  });
  return (
    <View
      title={provider.name}
      permissions={[[Permission.READ_PROVIDERS]]}
      breadcrumbItems={[
        {
          label: "Fournisseurs",
          href: toProvidersView(),
        },
        {
          label: provider.name,
        },
      ]}
    >
      <Form plaintext formValue={formValue} onChange={(formValue) => setFormValue(formValue as InitialFormValue)}>
        <Form.Group controlId="name">
          <Form.ControlLabel>Nom</Form.ControlLabel>
          <Form.Control size="lg" name="name" />
        </Form.Group>
        <Form.Group controlId="website">
          <Form.ControlLabel>Site Web</Form.ControlLabel>
          <Form.Control size="lg" name="website" />
        </Form.Group>
      </Form>
    </View>
  );
};
