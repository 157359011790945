import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectActivityContext, useProjectContext } from "hooks";
import { useList } from "react-use";
import { useDeleteProjectActivityMutation } from "graphql/mutations";
import { DeleteForm } from "forms";

export const ProjectActivityDeleteView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectActivitiesView } = useRouting();
  const { project } = useProjectContext();
  const { projectActivity } = useProjectActivityContext();
  const [errors, { set: setErrors }] = useList<string>([]);
  const { deleteProjectActivity, deleteProjectActivityLoading } = useDeleteProjectActivityMutation((projectId) => {
    navigate(toProjectActivitiesView(projectId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Supprimer l'activité"
      permissions={[[Permission.READ_PROJECT_ACTIVITIES, Permission.DELETE_PROJECT_ACTIVITIES]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Activités",
          href: toProjectActivitiesView(project.id),
        },
        {
          label: projectActivity.label,
        },
      ]}
    >
      <DeleteForm
        label="Nom de l'activité"
        value={projectActivity.label}
        confirm="Veuillez saisir le nom de l'activité pour confirmer."
        errors={errors}
        loading={deleteProjectActivityLoading}
        onSubmit={async () => {
          await deleteProjectActivity(project.id, projectActivity.id);
        }}
        onCancel={() => {
          navigate(toProjectActivitiesView(project.id));
        }}
      >
        L'activité sera définitivement supprimé. <strong>Cette opération est IRRÉVERSIBLE.</strong>
      </DeleteForm>
    </View>
  );
};
