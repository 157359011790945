import "./index.less";
import { FunctionComponent } from "react";
import { useAuth, useRouting } from "hooks";
import { Button } from "rsuite";

export const Unauthorized: FunctionComponent = () => {
  const { navigate, toProjectsView } = useRouting();
  const { logout } = useAuth();
  return (
    <div className="unauthorized">
      <h1>Dév Méta</h1>
      <h2>ACCÈS REFUSÉ</h2>
      <p>Veuillez contacter un administrateur pour avoir accès à la plateforme.</p>
      <div>
        <Button
          onClick={() => {
            navigate(toProjectsView());
          }}
        >
          Accueil
        </Button>
        <Button
          onClick={() => {
            logout();
          }}
        >
          Se déconnecter
        </Button>
      </div>
      <small>
        <pre>Error Code: 401 Unauthorized</pre>
      </small>
    </div>
  );
};
