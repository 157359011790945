import "./index.less";
import { FunctionComponent } from "react";
import { Input, SelectPicker } from "rsuite";
import { ProjectModelElement } from "graphql/__generated__/ProjectModelElement";
import { ProjectReportSetup } from "graphql/__generated__/ProjectReportSetup";
import { ProjectReportSetupRelationshipsFormValues } from "types";
import { produce } from "immer";

type Props = {
  readonly projectModelElement: ProjectModelElement;
  readonly projectReportSetup: ProjectReportSetup | null;
  readonly values: ProjectReportSetupRelationshipsFormValues;
  readonly setValues: (values: ProjectReportSetupRelationshipsFormValues) => void;
};

export const ProjectReportSetupRelationshipPanel: FunctionComponent<Props> = ({ projectModelElement, projectReportSetup, values, setValues }) => {
  const projectReportSetupRelationship = values.projectReportSetupRelationships.find(
    (projectReportSetupRelationship) => projectReportSetupRelationship.projectModelElementId === projectModelElement.id
  );
  return (
    <div className="project-report-setup-relationship-panel">
      <div className="project-report-setup-relationship-panel-header">
        <span>{projectModelElement.name}</span>
        {projectReportSetup === null ? (
          <Input
            readOnly
            size="sm"
            style={{
              width: "250px",
            }}
            value="SELECTIONNER UN PARAMÈTRAGE"
          />
        ) : (
          <SelectPicker
            style={{
              width: "250px",
            }}
            size="sm"
            cleanable
            groupBy="role"
            value={projectReportSetupRelationship?.projectReportSetupElementId}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  const projectReportSetupRelationship = draft.projectReportSetupRelationships.find(
                    (projectReportSetupRelationship) => projectReportSetupRelationship.projectModelElementId === projectModelElement.id
                  );
                  if (projectReportSetupRelationship) {
                    projectReportSetupRelationship.projectReportSetupElementId = value;
                  }
                })
              );
            }}
            data={
              projectReportSetup?.elements
                .filter(
                  (projectReportSetupElement) =>
                    !values.projectReportSetupRelationships
                      .filter((projectReportSetupRelationship) => projectReportSetupRelationship.projectModelElementId !== projectModelElement.id)
                      .map((projectReportSetupRelationship) => projectReportSetupRelationship.projectReportSetupElementId)
                      .includes(projectReportSetupElement.id)
                )
                .map((projectReportSetupElement) => {
                  const role = projectReportSetup.rooms.find((projectReportSetupRoom) => projectReportSetupRoom.id === projectReportSetupElement.projectReportSetupRoomId)?.name ?? "Autres";
                  return {
                    label: `${role} - ${projectReportSetupElement.name}`,
                    value: projectReportSetupElement.id,
                    role: role,
                  };
                }) ?? []
            }
          />
        )}
      </div>
    </div>
  );
};
