import "./index.less";
import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useAppContext, useLayout, useProjectContext, useProjectContractContext, useRouting } from "hooks";
import { CustomerSheetPerUnitDocument } from "documents";
import { useDownloadProjectContractMutation } from "graphql/mutations";

export const ProjectContractView: FunctionComponent = () => {
  const {
    toProjectsView,
    toProjectView,
    toProjectContractsView,
    toProjectContractRequestsUpsertView,
    toProjectContractPaymentCreateView,
    toProjectContractPaymentsView,
    toProjectContractChoicesView,
    toProjectContractInvoicesView,
    toProjectContractCancelView,
  } = useRouting();
  const { customers } = useAppContext();
  const { project, projectModels, projectProducts, projectUnits } = useProjectContext();
  const { projectContract } = useProjectContractContext();
  const projectContractChoice = projectContract.choices.find((projectContractChoice) => projectContractChoice.id === projectContract.currentProjectContractChoiceId);
  const projectModel = projectModels.find((projectModel) => projectModel.id === projectContract.projectModelId);
  const { downloadProjectContract } = useDownloadProjectContractMutation(
    (contractUrl) => {
      const link = document.createElement("a");
      link.href = contractUrl;
      link.click();
    },
    (errors) => {
      console.error(errors);
    }
  );
  useLayout({
    sidebarActions: [
      {
        label: "Révisions",
        href: toProjectContractChoicesView(project.id, projectContract.id),
        permissions: [[Permission.READ_PROJECT_CONTRACTS]],
        show: !projectContract.isCustomized,
      },
      {
        label: "Factures",
        href: toProjectContractInvoicesView(project.id, projectContract.id),
        permissions: [[Permission.READ_PROJECT_CONTRACT_INVOICES]],
        show: !projectContract.isCustomized && projectContract.invoices.length !== 0,
      },
      {
        label: "Paiements",
        href: toProjectContractPaymentsView(project.id, projectContract.id),
        permissions: [[Permission.READ_PROJECT_CONTRACT_PAYMENTS]],
        show: !projectContract.isCustomized,
      },
    ],
    sidebarButtons: [
      {
        label: "Modifier une demande client",
        href: toProjectContractRequestsUpsertView(project.id, projectContract.id),
        permissions: [[Permission.UPDATE_PROJECT_CONTRACT_REQUESTS]],
        enabled: !projectContract.isCanceled && !!projectContractChoice && !projectContractChoice.isDraft && projectContractChoice.items.length !== 0,
      },
      {
        label: "Créer un paiement",
        href: toProjectContractPaymentCreateView(project.id, projectContract.id),
        permissions: [[Permission.CREATE_PROJECT_CONTRACT_PAYMENTS]],
        enabled: !projectContract.isCanceled && !projectContract.isCustomized,
      },
      {
        label: "Annuler le contrat",
        href: toProjectContractCancelView(project.id, projectContract.id),
        permissions: [[Permission.CANCEL_PROJECT_CONTRACTS]],
        enabled: !projectContract.isCanceled,
      },
      {
        label: "Télécharger le contrat",
        onClick: () => {
          downloadProjectContract(project.id, projectContract.id);
        },
        permissions: [[Permission.READ_PROJECT_CONTRACTS]],
        enabled: true,
      },
    ],
  });
  return (
    <View
      id="project-contract-view"
      permissions={[[Permission.READ_PROJECT_CONTRACTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Contrats",
          href: toProjectContractsView(project.id),
        },
        {
          label: `Unité #${projectContract.projectUnit.number} (${projectContract.customer.firstName} ${projectContract.customer.lastName})`,
        },
      ]}
    >
      <CustomerSheetPerUnitDocument
        project={project}
        customers={customers}
        projectContract={projectContract}
        projectContractChoice={projectContractChoice}
        projectUnit={projectContract.projectUnit}
        projectModel={projectModel}
        projectModels={projectModels}
        projectUnits={projectUnits}
        projectProducts={projectProducts}
      />
    </View>
  );
};
