import "./index.less";
import { FunctionComponent, useState } from "react";
import { Button, ButtonToolbar, CheckPicker, Form, Toggle } from "rsuite";
import { FormError } from "buildingBlocks";
import produce from "immer";
import { Role } from "graphql/__generated__/Role";
import { Project } from "graphql/__generated__/Project";
import { Role as RoleName } from "enums";

type UserAccessUpdateFormValues = {
  readonly hasAccessToAllProjects: boolean;
  readonly projectIds: string[];
  readonly roleIds: string[];
};

type Props = {
  readonly projects: ReadonlyArray<Project>;
  readonly roles: ReadonlyArray<Role>;
  readonly initialValues: UserAccessUpdateFormValues;
  readonly errors: string[];
  readonly loading: boolean;
  readonly onSubmit: (values: UserAccessUpdateFormValues) => Promise<void>;
  readonly onCancel: () => void;
};

export const UserAccessUpdateForm: FunctionComponent<Props> = ({ projects, roles, initialValues, errors, loading, onSubmit, onCancel }) => {
  const [values, setValues] = useState<UserAccessUpdateFormValues>(initialValues);
  return (
    <>
      <FormError errors={errors} />
      <Form className="form" fluid>
        <Form.Group>
          <Form.ControlLabel>Rôles</Form.ControlLabel>
          <CheckPicker
            value={values.roleIds}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.roleIds = value;
                })
              );
            }}
            cleanable
            data={roles
              .filter((role) => role.name !== RoleName.SA && role.name !== RoleName.ALL_PROJECTS && role.name !== RoleName.CUSTOMER)
              .map((role) => ({
                label: role.description,
                value: role.id,
              }))}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Accès à tous les projets</Form.ControlLabel>
          <Toggle
            checked={values.hasAccessToAllProjects}
            onChange={(checked) => {
              setValues(
                produce(values, (draft) => {
                  draft.hasAccessToAllProjects = checked;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Projets</Form.ControlLabel>
          <CheckPicker
            disabled={values.hasAccessToAllProjects}
            value={values.projectIds}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.projectIds = value;
                })
              );
            }}
            cleanable
            data={projects.map((project) => ({
              label: project.label,
              value: project.id,
            }))}
          />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              loading={loading}
              appearance="primary"
              onClick={() => {
                onSubmit(values);
              }}
            >
              Enregistrer
            </Button>
            <Button appearance="default" onClick={onCancel}>
              Annuler
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};
