import "./index.less";
import { FunctionComponent } from "react";
import { ProjectActivity } from "graphql/__generated__/ProjectActivity";
import { ProjectUnit } from "graphql/__generated__/ProjectUnit";
import { ProjectReportSetup } from "graphql/__generated__/ProjectReportSetup";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import { ProjectProduct } from "graphql/__generated__/ProjectProduct";
import { ProjectContract } from "graphql/__generated__/ProjectContract";
import { Message } from "rsuite";

type Props = {
  readonly pageClassName?: string;
  readonly projectUnits: ReadonlyArray<ProjectUnit>;
  readonly projectModels: ReadonlyArray<ProjectModel>;
  readonly projectReportSetup: ProjectReportSetup | null | undefined;
  readonly projectActivity: ProjectActivity | null | undefined;
  readonly projectUnitFloor: number;
  readonly projectProducts: ReadonlyArray<ProjectProduct>;
  readonly projectActivities: ReadonlyArray<ProjectActivity>;
  readonly projectContracts: ReadonlyArray<ProjectContract>;
};

export const ConstructionSheetPerFloorDocument: FunctionComponent<Props> = ({
  pageClassName = undefined,
  projectUnits,
  projectModels,
  projectReportSetup,
  projectUnitFloor,
  projectProducts,
  projectActivity,
  projectActivities,
  projectContracts,
}) => {
  const style: React.CSSProperties = {};
  if (pageClassName) {
    style.display = "none";
  }
  if (!projectReportSetup) {
    return (
      <Message type="warning" style={style} header={<h2>Projet sans paramétrage de rapport!</h2>}>
        Vous devez configurer un paramétrage de rapport pour avoir accès au rapport de construction par étage.
      </Message>
    );
  }
  if (projectActivities.length === 0) {
    return (
      <Message type="warning" style={style} header={<h2>Aucune activité n'a été configurée!</h2>}>
        Vous devez configurer et associer des activités à des produits pour avoir accès au rapport de construction par étage.
      </Message>
    );
  }
  return (
    <Document
      pageClassName={pageClassName}
      projectUnits={projectUnits}
      projectModels={projectModels}
      projectReportSetup={projectReportSetup}
      projectUnitFloor={projectUnitFloor}
      projectProducts={projectProducts}
      projectActivity={projectActivity}
      projectActivities={projectActivities}
      projectContracts={projectContracts}
    />
  );
};

type DocumentProps = {
  readonly pageClassName?: string;
  readonly projectUnits: ReadonlyArray<ProjectUnit>;
  readonly projectModels: ReadonlyArray<ProjectModel>;
  readonly projectReportSetup: ProjectReportSetup;
  readonly projectActivity: ProjectActivity | null | undefined;
  readonly projectUnitFloor: number;
  readonly projectProducts: ReadonlyArray<ProjectProduct>;
  readonly projectActivities: ReadonlyArray<ProjectActivity>;
  readonly projectContracts: ReadonlyArray<ProjectContract>;
};

const Document: FunctionComponent<DocumentProps> = ({
  pageClassName = undefined,
  projectUnits,
  projectModels,
  projectReportSetup,
  projectUnitFloor,
  projectProducts,
  projectActivity,
  projectActivities,
  projectContracts,
}) => {
  const style: React.CSSProperties = {};
  if (pageClassName) {
    style.position = "absolute";
    style.right = "-2000px";
    style.bottom = "-2000px";
    style.width = "1632px";
  }
  if (projectActivity) {
    return (
      <div className="construction-report-by-unit-floor-document" style={style}>
        <Page
          className={pageClassName}
          projectUnits={projectUnits}
          projectModels={projectModels}
          projectReportSetup={projectReportSetup}
          projectUnitFloor={projectUnitFloor}
          projectProducts={projectProducts}
          projectActivity={projectActivity}
          projectContracts={projectContracts}
        />
      </div>
    );
  } else {
    return (
      <div className="construction-report-by-unit-floor-document" style={style}>
        {projectActivities.map((projectActivity) => (
          <Page
            key={projectActivity.id}
            className={pageClassName}
            projectUnits={projectUnits}
            projectModels={projectModels}
            projectReportSetup={projectReportSetup}
            projectUnitFloor={projectUnitFloor}
            projectProducts={projectProducts}
            projectActivity={projectActivity}
            projectContracts={projectContracts}
          />
        ))}
      </div>
    );
  }
};

type PageProps = {
  readonly className?: string;
  readonly projectUnits: ReadonlyArray<ProjectUnit>;
  readonly projectModels: ReadonlyArray<ProjectModel>;
  readonly projectReportSetup: ProjectReportSetup;
  readonly projectUnitFloor: number;
  readonly projectProducts: ReadonlyArray<ProjectProduct>;
  readonly projectActivity: ProjectActivity;
  readonly projectContracts: ReadonlyArray<ProjectContract>;
  readonly usedByDocument?: boolean;
};

const Page: FunctionComponent<PageProps> = ({ className = undefined, projectUnits, projectModels, projectReportSetup, projectUnitFloor, projectProducts, projectActivity, projectContracts }) => {
  const { table } = useConstructionSheetPerFloorContext(projectUnits, projectModels, projectReportSetup, projectUnitFloor, projectProducts, projectActivity, projectContracts);
  const projectUnitFloorLabel = `${projectUnitFloor === 1 ? projectUnitFloor + "er" : projectUnitFloor + "e"} étage`;
  return (
    <div className={`construction-report-by-unit-floor${className ? ` ${className}` : ""}`}>
      <div className="table-title">
        {projectActivity?.title} | {projectUnitFloorLabel}
      </div>
      <div className="table">
        <div className="table-row">
          <div className="table-row-header">
            <div className="table-column table-column-info">UNITÉ #</div>
            <div className="table-column table-column-info">ÉTAGE</div>
            <div className="table-column table-column-info">SDB</div>
          </div>
          <div className="table-row-body">
            {table.rowHeader.withoutExtraItems.map((withoutExtraItem) => {
              return (
                <div key={Math.random()} className="table-column table-column-without-extra">
                  {withoutExtraItem.toUpperCase()}
                </div>
              );
            })}
            {table.rowHeader.withExtraItems.map((withExtraItem) => {
              return (
                <div key={Math.random()} className="table-column table-column-with-extra">
                  {withExtraItem.toUpperCase()}
                </div>
              );
            })}
            <div key={Math.random()} className="table-column">
              COMMENTAIRES
            </div>
          </div>
        </div>
        {table.rows.map((tableRow) => (
          <div key={Math.random()} className="table-row">
            <div className="table-row-header">
              <div className="table-column table-column-info">{tableRow.unitNumber}</div>
              <div className="table-column table-column-info">{tableRow.unitFloor}</div>
              <div className="table-column table-column-info">{tableRow.unitNumberOfBathrooms}</div>
            </div>
            <div className="table-row-body">
              {tableRow.withoutExtraItems.map((withoutExtraItem) => (
                <div key={Math.random()} className="table-column table-column-without-extra">
                  {withoutExtraItem}
                </div>
              ))}
              {tableRow.withExtraItems.map((withExtraItem) => (
                <div key={Math.random()} className="table-column table-column-with-extra">
                  {withExtraItem}
                </div>
              ))}
              <div key={Math.random()} className="table-column">
                {tableRow.comments}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

type ConstructionSheetPerFloorTable = {
  readonly rowHeader: ConstructionSheetPerFloorTableRowHeader;
  readonly rows: ConstructionSheetPerFloorTableRow[];
};

type ConstructionSheetPerFloorTableRowHeader = {
  readonly withoutExtraItems: string[];
  readonly withExtraItems: string[];
};

type ConstructionSheetPerFloorTableRow = {
  readonly unitNumber: number;
  readonly unitFloor: number;
  readonly unitNumberOfBathrooms: number;
  readonly withoutExtraItems: string[];
  readonly withExtraItems: string[];
  readonly comments: string;
};

const getProjectReportRelationships = (
  projectActivity: ProjectActivity,
  projectModels: ReadonlyArray<ProjectModel>,
  projectReportSetup: ProjectReportSetup,
  projectProducts: ReadonlyArray<ProjectProduct>
) => {
  const projectModelElements = projectModels.map((projectModel) => projectModel.elements).reduce((previousValue, currentValue) => previousValue.concat(currentValue), []);
  return projectReportSetup.relationships
    .filter((projectReportSetupRelationship) =>
      projectModelElements
        .find((projectModelElement) => projectModelElement.id === projectReportSetupRelationship.projectModelElementId)!
        .options.map((projectModelElementOption) => projectProducts.find((projectProduct) => projectProduct.id === projectModelElementOption.productId)!)
        .map((projectProduct) => projectProduct.activityIds)
        .reduce((previousValue, currentValue) => previousValue.concat(currentValue), [])
        .filter((value, index, array) => array.indexOf(value) === index)
        .includes(projectActivity.id)
    )
    .map((projectReportSetupRelationship) => ({
      projectReportSetupElement: projectReportSetup.elements.find((projectReportSetupElement) => projectReportSetupElement.id === projectReportSetupRelationship.projectReportSetupElementId)!,
      projectModelElement: projectModelElements.find((projectModelElement) => projectModelElement.id === projectReportSetupRelationship.projectModelElementId)!,
    }))
    .map((projectReportSetupRelationship) => ({
      ...projectReportSetupRelationship,
      projectReportSetupRoomName:
        projectReportSetup.rooms.find((projectReportSetupRoom) => projectReportSetupRoom.id === projectReportSetupRelationship.projectReportSetupElement.projectReportSetupRoomId)?.name ?? "Autres",
    }))
    .sort((left, right) => left.projectReportSetupRoomName.localeCompare(right.projectReportSetupRoomName));
};
const useConstructionSheetPerFloorContext = (
  projectUnits: ReadonlyArray<ProjectUnit>,
  projectModels: ReadonlyArray<ProjectModel>,
  projectReportSetup: ProjectReportSetup,
  projectUnitFloor: number,
  projectProducts: ReadonlyArray<ProjectProduct>,
  projectActivity: ProjectActivity,
  projectContracts: ReadonlyArray<ProjectContract>
) => {
  projectUnits = projectUnits.filter((projectUnit) => projectUnit.floor === projectUnitFloor);
  const projectReportSetupRelationships = getProjectReportRelationships(projectActivity, projectModels, projectReportSetup, projectProducts);
  const projectReportSetupRelationshipsWithoutExtra = projectReportSetupRelationships.filter(
    (projectReportSetupRelationship) => !projectReportSetupRelationship.projectModelElement.options.every((projectModelElementOption) => projectModelElementOption.isExtra)
  );
  const projectReportSetupRelationshipsWithExtra = projectReportSetupRelationships.filter((projectReportSetupRelationship) =>
    projectReportSetupRelationship.projectModelElement.options.some((projectModelElementOption) => projectModelElementOption.isExtra)
  );
  const tableRowHeaderWithoutExtraItems = projectReportSetupRelationshipsWithoutExtra.map(
    (projectReportSetupRelationship) => `${projectReportSetupRelationship.projectReportSetupRoomName} - ${projectReportSetupRelationship.projectReportSetupElement.name}`
  );
  const tableRowHeaderWithExtraItems = projectReportSetupRelationshipsWithExtra.map(
    (projectReportSetupRelationship) => `${projectReportSetupRelationship.projectReportSetupRoomName} - ${projectReportSetupRelationship.projectReportSetupElement.name}`
  );
  const tableRows: ConstructionSheetPerFloorTableRow[] = [];
  for (const projectUnit of projectUnits) {
    const projectContract = projectContracts.find((projectContract) => projectContract.id === projectUnit.currentProjectContractId);
    const projectContractChoice = projectContract?.choices.find((projectContractChoice) => projectContractChoice.id === projectContract.currentProjectContractChoiceId);
    const projectModel = projectModels.find((projectModel) => projectModel.id === projectContract?.projectModelId);
    const withoutExtraItems: string[] = [];
    for (const projectReportSetupRelationship of projectReportSetupRelationshipsWithoutExtra) {
      if (projectContract && projectContract.isCustomized) {
        withoutExtraItems.push("PERSO");
      } else {
        if (projectContractChoice && projectModel) {
          const projectContractChoiceItem = projectContractChoice.items.find(
            (projectContractChoiceItem) => projectContractChoiceItem.projectModelElementId === projectReportSetupRelationship.projectModelElement.id
          );
          if (projectContractChoiceItem) {
            const projectModelElementOption = projectReportSetupRelationship.projectModelElement.options.find(
              (projectModelElementOption) => projectModelElementOption.id === projectContractChoiceItem.projectModelElementOptionId
            );
            const projectProduct = projectProducts.find((projectProduct) => projectProduct.id === projectModelElementOption?.productId);
            if (projectProduct?.activityIds.includes(projectActivity.id)) {
              withoutExtraItems.push(
                projectModelElementOption?.isExtra ?? false
                  ? "EXTRA"
                  : projectReportSetupRelationship.projectModelElement.isBoolean && projectModelElementOption !== null
                  ? "OUI"
                  : projectProduct?.name ?? "--"
              );
            } else {
              withoutExtraItems.push("--");
            }
          } else {
            withoutExtraItems.push("--");
          }
        } else {
          withoutExtraItems.push("--");
        }
      }
    }
    const withExtraItems: string[] = [];
    for (const projectReportSetupRelationship of projectReportSetupRelationshipsWithExtra) {
      if (projectContract && projectContract.isCustomized) {
        withExtraItems.push("PERSO");
      } else {
        if (projectContractChoice && projectModel) {
          const projectContractChoiceItem = projectContractChoice.items.find(
            (projectContractChoiceItem) => projectContractChoiceItem.projectModelElementId === projectReportSetupRelationship.projectModelElement.id
          );
          if (projectContractChoiceItem) {
            const projectModelElementOption = projectReportSetupRelationship.projectModelElement.options.find(
              (projectModelElementOption) => projectModelElementOption.id === projectContractChoiceItem.projectModelElementOptionId
            );
            const projectProduct = projectProducts.find((projectProduct) => projectProduct.id === projectModelElementOption?.productId);
            if (projectProduct?.activityIds.includes(projectActivity.id) && projectModelElementOption?.isExtra) {
              withExtraItems.push(projectReportSetupRelationship.projectModelElement.isBoolean && projectModelElementOption !== null ? "OUI" : projectProduct?.name ?? "--");
            } else {
              withExtraItems.push("--");
            }
          } else {
            withExtraItems.push("--");
          }
        } else {
          withExtraItems.push("--");
        }
      }
    }
    tableRows.push({
      unitNumber: projectUnit.number,
      unitFloor: projectUnit.floor,
      unitNumberOfBathrooms: projectUnit.numberOfBathrooms,
      withoutExtraItems: withoutExtraItems,
      withExtraItems: withExtraItems,
      comments:
        projectContract?.requests
          .filter((projectContractRequest) =>
            projectReportSetupRelationshipsWithoutExtra
              .map((x) => x.projectModelElement.id)
              .concat(projectReportSetupRelationshipsWithExtra.map((x) => x.projectModelElement.id))
              .includes(projectContractRequest.projectModelElementId)
          )
          .map((customerRequest) => customerRequest.content)
          .join(" | ") ?? "",
    });
  }
  const table: ConstructionSheetPerFloorTable = {
    rowHeader: {
      withoutExtraItems: tableRowHeaderWithoutExtraItems,
      withExtraItems: tableRowHeaderWithExtraItems,
    },
    rows: tableRows,
  };
  return { table };
};
