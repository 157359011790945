import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { useCreateProviderMutation } from "graphql/mutations";
import { Permission } from "enums";
import { useLayout, useRouting } from "hooks";
import { ProviderForm } from "forms";
import { useList } from "react-use";

export const ProviderCreateView: FunctionComponent = () => {
  const { navigate, toProvidersView, toProviderView } = useRouting();
  const [errors, { set: setErrors }] = useList<string>();
  const { createProvider, createProviderLoading } = useCreateProviderMutation((providerId) => {
    navigate(toProviderView(providerId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Créer un fournisseur"
      permissions={[[Permission.READ_PROVIDERS, Permission.CREATE_PROVIDERS]]}
      breadcrumbItems={[
        {
          label: "Fournisseurs",
          href: toProvidersView(),
        },
      ]}
    >
      <ProviderForm
        initialValues={{
          name: "",
          website: null,
          description: null,
          contacts: [],
          notes: [],
        }}
        errors={errors}
        loading={createProviderLoading}
        onCancel={() => {
          navigate(toProvidersView());
        }}
        onSubmit={async (values) => {
          await createProvider({
            name: values.name,
            website: values.website,
            description: values.description,
            contacts: values.contacts,
            notes: values.notes,
          });
        }}
      />
    </View>
  );
};
