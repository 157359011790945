import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext, useProjectReportSetupContext } from "hooks";
import { useDeleteProjectReportSetupMutation } from "graphql/mutations";
import { useList } from "react-use";
import { DeleteForm } from "forms";

export const ProjectReportSetupDeleteView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectReportsView, toProjectReportSetupsView, toProjectReportSetupView } = useRouting();
  const [errors, { set: setErrors }] = useList<string>();
  const { project } = useProjectContext();
  const { projectReportSetup } = useProjectReportSetupContext();
  const { deleteProjectReportSetup, deleteProjectReportSetupLoading } = useDeleteProjectReportSetupMutation((projectId) => {
    navigate(toProjectReportSetupsView(projectId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Supprimer un paramétrage"
      permissions={[[Permission.READ_PROJECT_REPORT_SETUPS, Permission.DELETE_PROJECT_REPORT_SETUPS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Rapports",
          href: toProjectReportsView(project.id),
        },
        {
          label: "Paramétrages",
          href: toProjectReportSetupsView(project.id),
        },
        {
          label: projectReportSetup.name,
          href: toProjectReportSetupView(project.id, projectReportSetup.id),
        },
      ]}
    >
      <DeleteForm
        label="Nom du paramétrage"
        value={projectReportSetup?.name}
        confirm="Veuillez saisir le nom du paramétrage pour confirmer."
        errors={errors}
        loading={deleteProjectReportSetupLoading}
        onSubmit={async () => {
          await deleteProjectReportSetup(project.id, projectReportSetup.id);
        }}
        onCancel={() => {
          navigate(toProjectReportSetupView(project.id, projectReportSetup.id));
        }}
      >
        Ce paramétrage sera définitivement supprimé. <strong>Cette opération est IRRÉVERSIBLE.</strong>
      </DeleteForm>
    </View>
  );
};
