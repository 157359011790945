import "./index.less";
import { FunctionComponent } from "react";
import { Message } from "rsuite";
import { ProjectContract } from "graphql/__generated__/ProjectContract";
import { ProjectContractChoice } from "graphql/__generated__/ProjectContractChoice";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import { ProjectProduct } from "graphql/__generated__/ProjectProduct";
import { ProjectUnit } from "graphql/__generated__/ProjectUnit";
import { Customer } from "graphql/__generated__/Customer";
import { Project } from "graphql/__generated__/Project";
import { ProjectContractInfos } from "components";

type Props = {
  readonly pageClassName?: string;
  readonly project: Project;
  readonly projectUnits: ReadonlyArray<ProjectUnit>;
  readonly projectUnit: ProjectUnit | null | undefined;
  readonly projectProducts: ReadonlyArray<ProjectProduct>;
  readonly projectModels: ReadonlyArray<ProjectModel>;
  readonly projectModel: ProjectModel | null | undefined;
  readonly projectContract: ProjectContract | null | undefined;
  readonly projectContractChoice: ProjectContractChoice | null | undefined;
  readonly customers: ReadonlyArray<Customer>;
};

export const CustomerSheetPerUnitDocument: FunctionComponent<Props> = ({
  pageClassName = undefined,
  project,
  projectUnit,
  projectUnits,
  projectContract,
  projectContractChoice,
  projectModel,
  projectModels,
  projectProducts,
  customers,
}) => {
  const style: React.CSSProperties = {};
  if (pageClassName) {
    style.display = "none";
  }
  if (!projectUnit) {
    return (
      <Message type="warning" style={style} header={<h2>Projet sans unité</h2>}>
        Ajouter une unité au projet pour avoir accès au rapport par unité.
      </Message>
    );
  }
  if (!projectContract) {
    return (
      <Message type="warning" style={style} header={<h2>Unité sans contrat</h2>}>
        Un contrat client doit être associé à cette unité pour qu'un rapport soit généré.
      </Message>
    );
  }
  if (projectContract.isCustomized) {
    return (
      <Message style={style} header={<h2>Unité en personnalisation</h2>}>
        Le rapport n'est pas disponible. Cette unité n'est pas gérée par l'application.
      </Message>
    );
  }
  if (!projectModel) {
    return (
      <Message type="warning" style={style} header={<h2>Unité sans modèle</h2>}>
        Assigner un modèle à l'unité pour que le client puisse faire son choix.
      </Message>
    );
  }
  if (projectContract.choices.length > 1 && (!projectContractChoice || projectContractChoice.items.length === 0)) {
    return (
      <Message type="warning" style={style} header={<h2>Unité en révision</h2>}>
        Le client doit refaire son choix d'options pour que sa fiche client soit générée.
      </Message>
    );
  }
  if (!projectContractChoice || projectContractChoice.items.length === 0) {
    return (
      <Message type="warning" style={style} header={<h2>Unité sans choix du client</h2>}>
        Le client doit faire son choix d'options pour qu'un rapport soit généré.
      </Message>
    );
  }
  return (
    <Document
      pageClassName={pageClassName}
      project={project}
      projectUnits={projectUnits}
      projectUnit={projectUnit}
      projectContract={projectContract}
      projectContractChoice={projectContractChoice}
      projectModels={projectModels}
      projectModel={projectModel}
      projectProducts={projectProducts}
      customers={customers}
    />
  );
};

type DocumentProps = {
  readonly pageClassName?: string;
  readonly project: Project;
  readonly projectUnit: ProjectUnit;
  readonly projectUnits: ReadonlyArray<ProjectUnit>;
  readonly projectContract: ProjectContract;
  readonly projectContractChoice: ProjectContractChoice;
  readonly projectModel: ProjectModel;
  readonly projectModels: ReadonlyArray<ProjectModel>;
  readonly projectProducts: ReadonlyArray<ProjectProduct>;
  readonly customers: ReadonlyArray<Customer>;
};

const Document: FunctionComponent<DocumentProps> = ({
  pageClassName = undefined,
  project,
  projectUnits,
  projectContract,
  projectContractChoice,
  projectModel,
  projectModels,
  projectProducts,
  customers,
}) => {
  const { withoutExtraTables, withExtraTables } = useCustomerSheetPerUnitContext(projectContract, projectContractChoice, projectModel, projectProducts);
  const style: React.CSSProperties = {};
  if (pageClassName) {
    style.position = "absolute";
    style.right = "-2000px";
    style.bottom = "-2000px";
    style.width = "1056px";
    style.padding = "50px";
  }
  return (
    <div style={style} className="customer-report-by-unit-number-document">
      {projectContract && (
        <ProjectContractInfos
          title="FICHE CLIENT PAR UNITÉ"
          className={pageClassName}
          project={project}
          projectContract={projectContract}
          projectContractChoice={projectContractChoice}
          projectModels={projectModels}
          projectUnits={projectUnits}
          customers={customers}
          withoutCustomer={pageClassName !== undefined}
        />
      )}
      <Page className={pageClassName} tables={withoutExtraTables} isExtra={false} />
      <Page className={pageClassName} tables={withExtraTables} isExtra={true} />
    </div>
  );
};

type PageProps = {
  readonly tables: CustomerSheetPerUnitTable[];
  readonly className?: string;
  readonly isExtra: boolean;
};

const Page: FunctionComponent<PageProps> = ({ className = undefined, tables, isExtra }) => {
  return (
    <section className={`customer-report-by-unit-number${className ? ` ${className}` : ""}`}>
      <div className={isExtra ? "with-extra" : "without-extra"}>
        {tables.length !== 0 && (
          <div className="title">{isExtra ? "Ajout ou modification au choix de base (EXTRA)".toLocaleUpperCase() : "Choix de base (Standard au projet)".toLocaleUpperCase()}</div>
        )}
        {tables.map((table) => (
          <div className="table" key={Math.random()}>
            <div className="table-title">{table.roomName.toUpperCase()}</div>
            <div className="table-rows">
              <div className="table-row" key={Math.random()}>
                <div className="table-column">Élément</div>
                <div className="table-column">Choix</div>
                <div className="table-column">Commentaires</div>
              </div>
              {table.rows.map((row) => (
                <div className={`table-row${row.isUpdated ? " table-row-updated" : ""}`} key={Math.random()}>
                  <div className="table-column">{row.projectModelElementName}</div>
                  <div className="table-column">{row.projectProductName}</div>
                  <div className="table-column">{row.customerRequestContent}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

type CustomerSheetPerUnitTable = {
  readonly roomName: string;
  readonly rows: {
    readonly projectModelElementName: string;
    readonly projectProductName: string;
    readonly customerRequestContent: string;
    readonly isUpdated: boolean;
  }[];
};

const useCustomerSheetPerUnitContext = (projectContract: ProjectContract, projectContractChoice: ProjectContractChoice, projectModel: ProjectModel, projectProducts: ReadonlyArray<ProjectProduct>) => {
  const previousProjectContractChoice = projectContract.choices.find((contractChoice) => contractChoice.index === projectContractChoice.index - 1);
  const withoutExtraTables: CustomerSheetPerUnitTable[] = [];
  const withExtraTables: CustomerSheetPerUnitTable[] = [];
  const projectModelRooms: { label: string; value: string | null }[] = projectModel.rooms.map((projectModelRoom) => ({ label: projectModelRoom.name, value: projectModelRoom.id }));
  projectModelRooms.push({ label: "Autres", value: null });
  for (const projectModelRoom of projectModelRooms) {
    const withoutExtraTableRows: CustomerSheetPerUnitTable["rows"] = [];
    const withExtraTableRows: CustomerSheetPerUnitTable["rows"] = [];
    const projectModelElements = projectModel.elements.filter((projectModelElement) => projectModelElement.projectModelRoomId === projectModelRoom.value);
    const projectModelElementIds = projectModelElements.map((projectModelElement) => projectModelElement.id);
    const projectContractChoiceItems = projectContractChoice.items
      .filter((projectContractChoiceItem) => projectModelElementIds.includes(projectContractChoiceItem.projectModelElementId))
      .map((projectContractChoiceItem) => {
        const projectModelElement = projectModel.elements.find((projectModelElement) => projectModelElement.id === projectContractChoiceItem.projectModelElementId)!;
        const projectModelElementOption =
          projectModelElement.options.find((projectModelElementOption) => projectModelElementOption.id === projectContractChoiceItem.projectModelElementOptionId) ?? null;
        const projectModelElementOptionProduct = projectProducts.find((projectProduct) => projectProduct.id === projectModelElementOption?.productId) ?? null;
        return {
          projectModelElement,
          projectModelElementOption,
          projectModelElementOptionProduct,
        };
      });
    for (const projectModelElement of projectModelElements.filter((projectModelElement) => !projectModelElement.options.every((projectModelElementOption) => projectModelElementOption.isExtra))) {
      const projectContractChoiceItem = projectContractChoiceItems.find((projectContractChoiceItem) => projectContractChoiceItem.projectModelElement.id === projectModelElement.id);
      if (projectContractChoiceItem) {
        const projectContractRequest = projectContract.requests.find((projectContractRequest) => projectContractRequest.projectModelElementId === projectModelElement.id);
        withoutExtraTableRows.push({
          projectModelElementName: projectContractChoiceItem.projectModelElement.name,
          projectProductName:
            projectContractChoiceItem.projectModelElementOption?.isExtra ?? false
              ? "EXTRA"
              : projectContractChoiceItem.projectModelElement.isBoolean && projectContractChoiceItem.projectModelElementOption !== null
              ? "OUI"
              : projectContractChoiceItem.projectModelElementOptionProduct?.name ?? "--",
          customerRequestContent: projectContractRequest?.content ?? "",
          isUpdated:
            !!previousProjectContractChoice &&
            previousProjectContractChoice.items.find(
              (previousProjectContractChoiceItem) => previousProjectContractChoiceItem.projectModelElementId === projectContractChoiceItem.projectModelElement.id
            )!.projectModelElementOptionId !== (projectContractChoiceItem.projectModelElementOption?.id ?? null),
        });
      }
    }
    for (const projectModelElement of projectModelElements.filter((projectModelElement) => projectModelElement.options.some((projectModelElementOption) => projectModelElementOption.isExtra))) {
      const projectContractChoiceItem = projectContractChoiceItems.find((projectContractChoiceItem) => projectContractChoiceItem.projectModelElement.id === projectModelElement.id);
      if (projectContractChoiceItem) {
        const projectModelElementOption = projectContractChoiceItem.projectModelElementOption;
        if (projectModelElementOption?.isExtra ?? true) {
          const projectContractRequest = projectContract.requests.find((projectContractRequest) => projectContractRequest.projectModelElementId === projectModelElement.id);
          withExtraTableRows.push({
            projectModelElementName: projectContractChoiceItem.projectModelElement.name,
            projectProductName:
              projectContractChoiceItem.projectModelElement.isBoolean && projectModelElementOption !== null ? "OUI" : projectContractChoiceItem.projectModelElementOptionProduct?.name ?? "--",
            customerRequestContent: projectContractRequest?.content ?? "",
            isUpdated:
              !!previousProjectContractChoice &&
              previousProjectContractChoice.items.find(
                (previousProjectContractChoiceItem) => previousProjectContractChoiceItem.projectModelElementId === projectContractChoiceItem.projectModelElement.id
              )!.projectModelElementOptionId !== (projectContractChoiceItem.projectModelElementOption?.id ?? null),
          });
        }
      }
    }
    if (withoutExtraTableRows.length !== 0) {
      withoutExtraTables.push({
        roomName: projectModelRoom.label,
        rows: withoutExtraTableRows,
      });
    }
    if (withExtraTableRows.length !== 0) {
      withExtraTables.push({
        roomName: projectModelRoom.label,
        rows: withExtraTableRows,
      });
    }
  }
  return { withoutExtraTables, withExtraTables };
};
