import "./index.less";
import { FunctionComponent, PropsWithChildren, useState } from "react";
import { Button, ButtonToolbar, Form } from "rsuite";
import { FormError } from "buildingBlocks";

type Props = PropsWithChildren & {
  readonly label: string;
  readonly value: string;
  readonly errors: string[];
  readonly loading: boolean;
  readonly confirm: string;
  readonly onSubmit: () => Promise<void>;
  readonly onCancel: () => void;
};

type DeleteFormValues = {
  readonly confirm: string;
};

export const DeleteForm: FunctionComponent<Props> = ({ children, label, value, confirm, errors, loading, onSubmit, onCancel }) => {
  const [initialValues, setInitialValues] = useState<DeleteFormValues>({ confirm: "" });
  return (
    <>
      <FormError errors={errors} />
      <Form
        id="delete-form"
        className="form"
        fluid
        formValue={initialValues}
        onChange={(values) => {
          setInitialValues(values as DeleteFormValues);
        }}
        onSubmit={() => {
          onSubmit();
        }}
      >
        <div className="rs-form-group" role="group">
          <label className="rs-form-control-label">{label}</label>
          <div className="rs-form-control rs-form-control-wrapper">
            <div className="rs-plaintext">{value}</div>
          </div>
        </div>
        <Form.Group>{children}</Form.Group>
        <Form.Group controlId="confirm">
          <Form.ControlLabel>{confirm}</Form.ControlLabel>
          <Form.Control name="confirm" size="lg" />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button appearance="primary" type="submit" loading={loading} disabled={initialValues.confirm !== value}>
              Supprimer
            </Button>
            <Button disabled={loading} appearance="default" onClick={onCancel}>
              Annuler
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};
