import "./index.less";
import { FunctionComponent } from "react";
import { Button } from "rsuite";
import { ButtonWithPopover, TagWithPopover } from "buildingBlocks";
import { ProjectContractInvoice } from "graphql/__generated__/ProjectContractInvoice";
import { ContractInvoiceStatus } from "graphql/globalTypes";
import { useRouting } from "hooks";

type Props = {
  readonly href: string;
  readonly projectContractInvoice: ProjectContractInvoice;
};

export const ProjectContractInvoicePanel: FunctionComponent<Props> = ({ href, projectContractInvoice }) => {
  const { navigate } = useRouting();
  return (
    <div className="customer-invoice-panel panel">
      <div className="panel-header">
        <section className="panel-title">
          <span>
            {new Date(projectContractInvoice.createdAt).toLocaleString("fr-CA", {
              year: "numeric",
              day: "2-digit",
              month: "long",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </span>
          <h2>{projectContractInvoice.label}</h2>
        </section>
        <section className="panel-status">
          <label>Statut</label>
          <PanelStatus status={projectContractInvoice.status} />
        </section>
        <section className="panel-balance">
          <label>Solde dû</label>
          <span>
            <strong>{Number(projectContractInvoice.balance).toCurrencyFormat("CAD")}</strong>
          </span>
        </section>
        <section className="panel-action">
          <div className="panel-buttons">
            <Button
              appearance="primary"
              size="md"
              onClick={() => {
                navigate(href);
              }}
            >
              Voir ma facture
            </Button>
            {(projectContractInvoice.status === ContractInvoiceStatus.PENDING || projectContractInvoice.status === ContractInvoiceStatus.PARTIAL) && (
              <ButtonWithPopover
                title="Effectuer un paiement"
                content="Les paiements en ligne ne sont pas disponibles. Veuillez vous rendre au bureau des ventes de Dév Méta pour acquitter votre paiement."
                appearance="primary"
                size="md"
                disabled
              />
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

// TODO:
const PanelStatus: FunctionComponent<{ status: ContractInvoiceStatus }> = ({ status }) => {
  if (status === ContractInvoiceStatus.NO_CHARGE) {
    return <TagWithPopover title="SANS FRAIS" content="Personnalisation sans frais." color="blue" />;
  } else if (status === ContractInvoiceStatus.PARTIAL) {
    return <TagWithPopover title="PARTIEL" content="La facture a été partiellement acquittée ou partiellement remboursé." color="yellow" />;
  } else if (status === ContractInvoiceStatus.PAID) {
    return <TagWithPopover title="PAYÉ" content="La facture a été acquittée en totalité." color="green" />;
  } else if (status === ContractInvoiceStatus.REFUNDED) {
    return <TagWithPopover title="REMBOURSEMENT" content="La facture a été remboursé en totalité." color="green" />;
  } else {
    return <TagWithPopover title="EN ATTENTE" content="En attente de paiement." color="yellow" />;
  }
};
