import "./index.less";
import { FunctionComponent, useState } from "react";
import { Button, ButtonToolbar, Form, Input, Message } from "rsuite";
import { ProjectModelVibe } from "graphql/__generated__/ProjectModelVibe";
import { FormError } from "buildingBlocks";

type Props = {
  readonly projectModelVibe: ProjectModelVibe;
  readonly errors: string[];
  readonly loading: boolean;
  readonly onSubmit: () => Promise<void>;
  readonly onCancel: () => void;
};

export const ProjectModelVibePublishForm: FunctionComponent<Props> = ({ projectModelVibe, errors, loading, onSubmit, onCancel }) => {
  const [projectModelVibeName, setProjectModelVibeName] = useState<string>("");
  return (
    <>
      <FormError errors={errors} />
      <Form id="project-model-vibe-publish-form" className="form" fluid>
        <Form.Group>
          <Form.ControlLabel>Nom de l'ambiance</Form.ControlLabel>
          <Input plaintext value={projectModelVibe.name} />
        </Form.Group>
        <Form.Group>
          <Message
            style={{
              textAlign: "justify",
            }}
            header={<h3>DANGER</h3>}
            type="error"
          >
            <p>Un fois publié cette ambiance sera accessible aux clients qui ont une unité assigné à un modèle qui possède cette ambiance. Il ne sera plus possibe de modifier cette ambiance.</p>
            <p>
              Cette opération est <strong>IRRÉVERSIBLE</strong>.
            </p>
          </Message>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Veuillez saisir le nom de l'ambiance pour confirmer.</Form.ControlLabel>
          <Input onChange={setProjectModelVibeName} size="lg" />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button appearance="primary" loading={loading} disabled={projectModelVibeName !== projectModelVibe.name} onClick={onSubmit}>
              Publier
            </Button>
            <Button
              disabled={loading}
              appearance="default"
              onClick={() => {
                onCancel();
              }}
            >
              Annuler
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};
