import { useAppContext, useProjectContext } from "hooks";
import { FunctionComponent } from "react";
import { Button, ButtonToolbar, CheckPicker, Form, Input, InputPicker, SelectPicker } from "rsuite";
import { useFormik } from "formik";
import produce from "immer";
import { UnitOfMeasure } from "graphql/globalTypes";
import { FormError } from "buildingBlocks";

export type ProjectProductFormValues = {
  readonly name: string;
  readonly variant: string | null;
  readonly categoryId: string | null;
  readonly description: string | null;
  readonly activityIds: string[];
  readonly provider: {
    readonly id: string | null;
    readonly productModel: string | null;
    readonly productCode: string | null;
    readonly productUrl: string | null;
  };
  readonly dimension: {
    readonly unitOfMeasure: string;
    readonly length: number;
    readonly width: number;
    readonly height: number;
  };
};

type Props = {
  readonly initialValues: ProjectProductFormValues;
  readonly errors: string[];
  readonly loading: boolean;
  readonly onSubmit: (values: ProjectProductFormValues) => Promise<void>;
  readonly onCancel: () => void;
};

export const ProjectProductForm: FunctionComponent<Props> = ({ initialValues, errors, loading, onSubmit, onCancel }) => {
  const { providers } = useAppContext();
  const { projectProductCategories, projectActivities } = useProjectContext();
  const { setValues, values } = useFormik<ProjectProductFormValues>({
    initialValues,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });
  return (
    <>
      <FormError errors={errors} />
      <Form className="form" fluid>
        <Form.Group>
          <Form.ControlLabel>Nom</Form.ControlLabel>
          <Input
            readOnly
            value={values.name}
            onChange={(value) =>
              setValues(
                produce(values, (draft) => {
                  draft.name = value;
                })
              )
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Modèle</Form.ControlLabel>
          <Input
            readOnly
            value={values.variant ?? undefined}
            onChange={(value) =>
              setValues(
                produce(values, (draft) => {
                  draft.variant = value;
                })
              )
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Catégorie</Form.ControlLabel>
          <SelectPicker
            readOnly
            value={values.categoryId ?? undefined}
            onChange={(value) =>
              setValues(
                produce(values, (draft) => {
                  draft.categoryId = value;
                })
              )
            }
            data={projectProductCategories.map((projectProductCategory) => ({
              label: projectProductCategory.name,
              value: projectProductCategory.id,
            }))}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Description</Form.ControlLabel>
          <Input
            value={values.description ?? undefined}
            onChange={(value) =>
              setValues(
                produce(values, (draft) => {
                  draft.description = value === "" ? null : value;
                })
              )
            }
            as="textarea"
            rows={3}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Activités</Form.ControlLabel>
          <CheckPicker
            name="activityIds"
            value={values.activityIds}
            onChange={(value) =>
              setValues(
                produce(values, (draft) => {
                  draft.activityIds = value;
                })
              )
            }
            data={projectActivities.map((projectActivity) => ({
              label: projectActivity.label,
              value: projectActivity.id,
            }))}
          />
        </Form.Group>
        <h2>Fournisseur</h2>
        <Form.Group>
          <Form.ControlLabel>Nom</Form.ControlLabel>
          <SelectPicker
            value={values.provider.id ?? undefined}
            onChange={(value) =>
              setValues(
                produce(values, (draft) => {
                  draft.provider.id = value;
                })
              )
            }
            data={providers.map((provider) => ({
              label: provider.name,
              value: provider.id,
            }))}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Modèle du produit</Form.ControlLabel>
          <Input
            value={values.provider.productModel ?? undefined}
            onChange={(value) =>
              setValues(
                produce(values, (draft) => {
                  draft.provider.productModel = value === "" ? null : value;
                })
              )
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Code du produit</Form.ControlLabel>
          <Input
            value={values.provider.productCode ?? undefined}
            onChange={(value) =>
              setValues(
                produce(values, (draft) => {
                  draft.provider.productCode = value === "" ? null : value;
                })
              )
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Site Web du produit</Form.ControlLabel>
          <Input
            value={values.provider.productUrl ?? undefined}
            onChange={(value) =>
              setValues(
                produce(values, (draft) => {
                  draft.provider.productUrl = value === "" ? null : value;
                })
              )
            }
          />
        </Form.Group>
        <h2>Dimension</h2>
        <Form.Group>
          <Form.ControlLabel>Unité de mesure</Form.ControlLabel>
          <InputPicker
            readOnly
            value={values.dimension.unitOfMeasure}
            onChange={(value) =>
              setValues(
                produce(values, (draft) => {
                  draft.dimension.unitOfMeasure = value;
                })
              )
            }
            data={[
              { label: "Aucune", value: UnitOfMeasure.NONE },
              { label: "Millimètre", value: UnitOfMeasure.MILLIMETER },
              { label: "Centimètre", value: UnitOfMeasure.CENTIMETER },
              { label: "Mètre", value: UnitOfMeasure.METER },
              { label: "Pouce", value: UnitOfMeasure.INCH },
              { label: "Pied", value: UnitOfMeasure.FOOT },
            ]}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Longueur</Form.ControlLabel>
          <Input
            readOnly
            value={values.dimension.length}
            onChange={(value) =>
              setValues(
                produce(values, (draft) => {
                  draft.dimension.length = Number(value);
                })
              )
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Largeur</Form.ControlLabel>
          <Input
            readOnly
            value={values.dimension.width}
            onChange={(value) =>
              setValues(
                produce(values, (draft) => {
                  draft.dimension.width = Number(value);
                })
              )
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Hauteur</Form.ControlLabel>
          <Input
            readOnly
            value={values.dimension.height}
            onChange={(value) =>
              setValues(
                produce(values, (draft) => {
                  draft.dimension.height = Number(value);
                })
              )
            }
          />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              loading={loading}
              appearance="primary"
              onClick={() => {
                onSubmit(values);
              }}
            >
              Enregistrer
            </Button>
            <Button appearance="default" onClick={onCancel}>
              Annuler
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};
