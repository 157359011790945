import { FunctionComponent } from "react";
import { useRoutes } from "react-router-dom";
import { Layout } from "views/_layout";
import { ProjectsView } from "views/projects";
import { ProjectCreateView } from "views/projects/create";
import { ProjectUpdateView } from "views/projects/projectId/update";
import { ProjectView } from "views/projects/projectId";
import { ProjectActivityCreateView } from "views/projects/projectId/activities/create";
import { ProjectActivityUpdateView } from "views/projects/projectId/activities/activityId/update";
import { ProjectActivitiesView } from "views/projects/projectId/activities";
import { ProjectModelsView } from "views/projects/projectId/models";
import { ProjectModelUploadView } from "views/projects/projectId/models/upload";
import { ProjectModelView } from "views/projects/projectId/models/modelId";
import { ProjectProductsView } from "views/projects/projectId/products";
import { ProjectProductView } from "views/projects/projectId/products/productId";
import { ProjectUnitsView } from "views/projects/projectId/units";
import { ProjectUnitUploadView } from "views/projects/projectId/units/upload";
import { CustomersView } from "views/customers";
import { CustomerView } from "views/customers/customerId";
import { CustomerCreateView } from "views/customers/create";
import { CustomerUpdateView } from "views/customers/customerId/update";
import { ProjectProductUpdateView } from "views/projects/projectId/products/productId/update";
import { ProvidersView } from "views/providers";
import { ProviderCreateView } from "views/providers/create";
import { ProviderView } from "views/providers/providerId";
import { ProviderUpdateView } from "views/providers/providerId/update";
import { ProjectModelVibeView } from "views/projects/projectId/models/modelId/vibes/vibeId";
import { ProjectModelVibeCreateView } from "views/projects/projectId/models/modelId/vibes/create";
import { ProjectModelVibeUpdateView } from "views/projects/projectId/models/modelId/vibes/vibeId/update";
import { ProjectProvider, LayoutProvider, LocaleProvider } from "providers";
import { Error } from "pages/Error";
import { NotFound } from "pages/NotFound";
import { ProjectActivityDeleteView } from "views/projects/projectId/activities/activityId/delete";
import { CustomerDeleteView } from "views/customers/customerId/delete";
import { ProviderDeleteView } from "views/providers/providerId/delete";
import { ProjectDeleteView } from "views/projects/projectId/delete";
import { ProjectModelDeleteView } from "views/projects/projectId/models/modelId/delete";
import { ProjectProductDeleteView } from "views/projects/projectId/products/productId/delete";
import { ProjectUnitDeleteView } from "views/projects/projectId/units/unitId/delete";
import { ProjectUnitCreateView } from "views/projects/projectId/units/create";
import { ProjectUnitUpdateView } from "views/projects/projectId/units/unitId/update";
import { ProjectModelUpdateView } from "views/projects/projectId/models/modelId/update";
import { ProjectModelVibeDeleteView } from "views/projects/projectId/models/modelId/vibes/vibeId/delete";
import { ProjectContractsView } from "views/projects/projectId/contracts";
import { ProjectModelPublishView } from "views/projects/projectId/models/modelId/publish";
import { ProjectModelVibesView } from "views/projects/projectId/models/modelId/vibes";
import { ProjectModelVibePublishView } from "views/projects/projectId/models/modelId/vibes/vibeId/publish";
import { ProjectReportsView } from "views/projects/projectId/reports";
import { ProjectReportsConstructionSheetPerFloorView } from "views/projects/projectId/reports/construction-sheet-per-floor";
import { ProjectReportsConstructionSheetPerUnitView } from "views/projects/projectId/reports/construction-sheet-per-unit";
import { ProjectReportSetupsView } from "views/projects/projectId/reports/setups";
import { ProjectReportSetupView } from "views/projects/projectId/reports/setups/setupId";
import { ProjectReportSetupCreateView } from "views/projects/projectId/reports/setups/create";
import { ProjectReportSetupDeleteView } from "views/projects/projectId/reports/setups/setupId/delete";
import { ProjectReportSetupUpdateView } from "views/projects/projectId/reports/setups/setupId/update";
import { ProjectModelRelationshipsManageView } from "views/projects/projectId/models/modelId/relationships/manage";
import { ProjectReportsCustomerSheetPerUnitView } from "views/projects/projectId/reports/customer-sheet-per-unit";
import { ProjectContractView } from "views/projects/projectId/contracts/contractId";
import { ProjectContractInvoicesView } from "views/projects/projectId/contracts/contractId/invoices";
import { ProjectContractInvoiceView } from "views/projects/projectId/contracts/contractId/invoices/invoiceId";
import { ProjectContractChoicesView } from "views/projects/projectId/contracts/contractId/choices";
import { ProjectContractChoiceView } from "views/projects/projectId/contracts/contractId/choices/choiceId";
import { ProjectContractChoiceCreateView } from "views/projects/projectId/contracts/contractId/choices/create";
import { ProjectContractRequestsUpdateView } from "views/projects/projectId/contracts/contractId/requests/update";
import { ProjectContractCancelView } from "views/projects/projectId/contracts/contractId/cancel";
import { ProjectContractAssociateView } from "views/projects/projectId/contracts/associate";
import { ProjectContractPaymentsView } from "views/projects/projectId/contracts/contractId/payments";
import { ProjectContractPaymentCreateView } from "views/projects/projectId/contracts/contractId/payments/create";
import { ProjectContractPaymentDeleteView } from "views/projects/projectId/contracts/contractId/payments/paymentId/delete";
import { ProjectContractPaymentUpdateView } from "views/projects/projectId/contracts/contractId/payments/paymentId/update";
import { UsersView } from "views/users";
import { Unauthorized } from "pages/Unauthorized";
import { Forbidden } from "pages/Forbidden";
import { UserAccessUpdateView } from "views/users/userId/access/update";
import { ProjectProductsUpdateView } from "views/projects/projectId/products/update";
import { ProjectUnitsUpdateView } from "views/projects/projectId/units/update";
import NotificationProvider from "providers/NotificationProvider";
import { CustomerCredentialsResendView } from "views/customers/customerId/credentials/resend";

export const App: FunctionComponent = () => {
  const element = useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { index: true, element: <ProjectsView /> },
        {
          path: "projects/:projectId/",
          element: <ProjectProvider />,
          children: [
            { path: "reports", element: <ProjectReportsView /> },
            { path: "reports/setups/:reportSetupId/delete", element: <ProjectReportSetupDeleteView /> },
            { path: "reports/setups/:reportSetupId/update", element: <ProjectReportSetupUpdateView /> },
            { path: "reports/setups/:reportSetupId", element: <ProjectReportSetupView /> },
            { path: "reports/setups/create", element: <ProjectReportSetupCreateView /> },
            { path: "reports/setups", element: <ProjectReportSetupsView /> },
            { path: "reports/construction-sheet-per-floor", element: <ProjectReportsConstructionSheetPerFloorView /> },
            { path: "reports/construction-sheet-per-unit", element: <ProjectReportsConstructionSheetPerUnitView /> },
            { path: "reports/customer-sheet-per-unit", element: <ProjectReportsCustomerSheetPerUnitView /> },
            { path: "contracts/:contractId/payments/:paymentId/delete", element: <ProjectContractPaymentDeleteView /> },
            { path: "contracts/:contractId/payments/:paymentId/update", element: <ProjectContractPaymentUpdateView /> },
            { path: "contracts/:contractId/payments/create", element: <ProjectContractPaymentCreateView /> },
            { path: "contracts/:contractId/payments", element: <ProjectContractPaymentsView /> },
            { path: "contracts/:contractId/invoices/:invoiceId", element: <ProjectContractInvoiceView /> },
            { path: "contracts/:contractId/invoices", element: <ProjectContractInvoicesView /> },
            { path: "contracts/:contractId/choices/:choiceId", element: <ProjectContractChoiceView /> },
            { path: "contracts/:contractId/choices/create", element: <ProjectContractChoiceCreateView /> },
            { path: "contracts/:contractId/choices", element: <ProjectContractChoicesView /> },
            { path: "contracts/:contractId/requests/update", element: <ProjectContractRequestsUpdateView /> },
            { path: "contracts/:contractId/cancel", element: <ProjectContractCancelView /> },
            { path: "contracts/:contractId", element: <ProjectContractView /> },
            { path: "contracts/associate", element: <ProjectContractAssociateView /> },
            { path: "contracts", element: <ProjectContractsView /> },
            { path: "activities/:activityId/delete", element: <ProjectActivityDeleteView /> },
            { path: "activities/:activityId/update", element: <ProjectActivityUpdateView /> },
            { path: "activities/create", element: <ProjectActivityCreateView /> },
            { path: "activities", element: <ProjectActivitiesView /> },
            { path: "models/:modelId/relationships/manage", element: <ProjectModelRelationshipsManageView /> },
            { path: "models/:modelId/vibes/:vibeId/publish", element: <ProjectModelVibePublishView /> },
            { path: "models/:modelId/vibes/:vibeId/delete", element: <ProjectModelVibeDeleteView /> },
            { path: "models/:modelId/vibes/:vibeId/update", element: <ProjectModelVibeUpdateView /> },
            { path: "models/:modelId/vibes/:vibeId", element: <ProjectModelVibeView /> },
            { path: "models/:modelId/vibes/create", element: <ProjectModelVibeCreateView /> },
            { path: "models/:modelId/vibes", element: <ProjectModelVibesView /> },
            { path: "models/:modelId/publish", element: <ProjectModelPublishView /> },
            { path: "models/:modelId/delete", element: <ProjectModelDeleteView /> },
            { path: "models/:modelId/update", element: <ProjectModelUpdateView /> },
            { path: "models/:modelId", element: <ProjectModelView /> },
            { path: "models/upload", element: <ProjectModelUploadView /> },
            { path: "models", element: <ProjectModelsView /> },
            { path: "products/:productId/delete", element: <ProjectProductDeleteView /> },
            { path: "products/:productId/update", element: <ProjectProductUpdateView /> },
            { path: "products/:productId", element: <ProjectProductView /> },
            { path: "products/update", element: <ProjectProductsUpdateView /> },
            { path: "products", element: <ProjectProductsView /> },
            { path: "units/:unitId/delete", element: <ProjectUnitDeleteView /> },
            { path: "units/:unitId/update", element: <ProjectUnitUpdateView /> },
            { path: "units/upload", element: <ProjectUnitUploadView /> },
            { path: "units/update", element: <ProjectUnitsUpdateView /> },
            { path: "units/create", element: <ProjectUnitCreateView /> },
            { path: "units", element: <ProjectUnitsView /> },
            { path: "delete", element: <ProjectDeleteView /> },
            { path: "update", element: <ProjectUpdateView /> },
            {
              index: true,
              element: <ProjectView />,
            },
          ],
        },
        { path: "projects/create", element: <ProjectCreateView /> },
        { path: "projects", element: <ProjectsView /> },
        { path: "providers/:providerId/delete", element: <ProviderDeleteView /> },
        { path: "providers/:providerId/update", element: <ProviderUpdateView /> },
        { path: "providers/:providerId", element: <ProviderView /> },
        { path: "providers/create", element: <ProviderCreateView /> },
        { path: "providers", element: <ProvidersView /> },
        { path: "customers/:customerId/credentials/resend", element: <CustomerCredentialsResendView /> },
        { path: "customers/:customerId/delete", element: <CustomerDeleteView /> },
        { path: "customers/:customerId/update", element: <CustomerUpdateView /> },
        { path: "customers/:customerId", element: <CustomerView /> },
        { path: "customers/create", element: <CustomerCreateView /> },
        { path: "customers", element: <CustomersView /> },
        { path: "users/:userId/access/update", element: <UserAccessUpdateView /> },
        { path: "users", element: <UsersView /> },
      ],
    },
    { path: "/unauthorized", element: <Unauthorized /> },
    { path: "/forbidden", element: <Forbidden /> },
    { path: "/error", element: <Error /> },
    { path: "*", element: <NotFound /> },
  ]);
  return (
    <LocaleProvider>
      <NotificationProvider>
        <LayoutProvider>{element}</LayoutProvider>
      </NotificationProvider>
    </LocaleProvider>
  );
};
