import "./index.less";
import { FunctionComponent, useState } from "react";
import { Button, ButtonToolbar, Form, Uploader } from "rsuite";
import { FormError } from "buildingBlocks";
import produce from "immer";

type ProjectUnitUploadFormValues = {
  readonly projectUnitsBlobFile: File | undefined;
};

type Props = {
  readonly initialValues: ProjectUnitUploadFormValues;
  readonly errors: string[];
  readonly loading: boolean;
  readonly onSubmit: (values: ProjectUnitUploadFormValues) => Promise<void>;
  readonly onCancel: () => void;
};

export const ProjectUnitUploadForm: FunctionComponent<Props> = ({ initialValues, errors, loading, onSubmit, onCancel }) => {
  const [values, setValues] = useState<ProjectUnitUploadFormValues>(initialValues);
  return (
    <>
      <FormError errors={errors} />
      <Form className="form" fluid>
        <Form.Group>
          <Form.ControlLabel>Fichier pour importer les unités</Form.ControlLabel>
          <Uploader
            action=""
            accept=".csv"
            autoUpload={false}
            disabled={values.projectUnitsBlobFile !== undefined}
            onChange={(fileList) => {
              const file = fileList[0];
              if (file) {
                setValues(
                  produce(values, (draft) => {
                    draft.projectUnitsBlobFile = file.blobFile;
                  })
                );
              }
            }}
            onRemove={() => {
              setValues(
                produce(values, (draft) => {
                  draft.projectUnitsBlobFile = undefined;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              disabled={values.projectUnitsBlobFile === undefined}
              loading={loading}
              appearance="primary"
              onClick={() => {
                onSubmit(values);
              }}
            >
              Enregistrer
            </Button>
            <Button appearance="default" onClick={onCancel}>
              Annuler
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};
