import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext, useProjectUnitContext } from "hooks";
import { useList } from "react-use";
import { useUpdateProjectUnitMutation } from "graphql/mutations";
import { ProjectUnitForm } from "forms/ProjectUnitForm";
import { PersistentStorageOperation } from "graphql/globalTypes";

export const ProjectUnitUpdateView: FunctionComponent = () => {
  const { navigate, toProjectView, toProjectsView, toProjectUnitsView } = useRouting();
  const { project } = useProjectContext();
  const { projectUnit } = useProjectUnitContext();
  const [errors, { set: setErrors }] = useList<string>();
  const { updateProjectUnit, updateProjectUnitLoading } = useUpdateProjectUnitMutation((projectId) => {
    navigate(toProjectUnitsView(projectId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Modifier l'unité"
      permissions={[[Permission.READ_PROJECT_UNITS, Permission.UPDATE_PROJECT_UNITS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Unités",
          href: toProjectUnitsView(project.id),
        },
        {
          label: projectUnit.label,
        },
      ]}
    >
      <ProjectUnitForm
        _operation={PersistentStorageOperation.UPDATE}
        initialValues={{
          number: projectUnit.number,
          floor: projectUnit.floor,
          numberOfBedrooms: projectUnit.numberOfBedrooms,
          numberOfBathrooms: projectUnit.numberOfBathrooms,
          projectModelId: projectUnit.projectModelId,
        }}
        errors={errors}
        loading={updateProjectUnitLoading}
        onSubmit={async (values) => {
          await updateProjectUnit(project.id, projectUnit.id, {
            numberOfBedrooms: values.numberOfBedrooms,
            numberOfBathrooms: values.numberOfBathrooms,
            projectModelId: values.projectModelId,
          });
        }}
        onCancel={() => {
          navigate(toProjectUnitsView(project.id));
        }}
      />
    </View>
  );
};
