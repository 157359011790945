import "./index.less";
import { FunctionComponent, useState } from "react";
import { Button, Form, Input, InputGroup, InputPicker, Message } from "rsuite";
import { FormError } from "buildingBlocks";
import produce from "immer";
import { Guid } from "utils";
import { ProjectReportSetupFormValues } from "types";
import { FaTrash, FaUndo } from "react-icons/fa";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import { PersistentStorageOperation } from "graphql/globalTypes";

type Props = {
  readonly projectModels: ReadonlyArray<ProjectModel>;
  readonly _operation: PersistentStorageOperation;
  readonly values: ProjectReportSetupFormValues;
  readonly setValues: (values: ProjectReportSetupFormValues) => void;
  readonly errors: string[];
};

export const ProjectReportSetupForm: FunctionComponent<Props> = ({ projectModels, _operation, values, setValues, errors }) => {
  const [projectModelId, setProjectModelId] = useState<string | null>(null);
  return (
    <>
      <FormError errors={errors} />
      <div id="project-report-setup-form">
        <div>
          <Form.Group>
            <Form.ControlLabel>Nom</Form.ControlLabel>
            <Input
              value={values.name}
              onChange={(value) => {
                setValues(
                  produce(values, (draft) => {
                    draft.name = value;
                  })
                );
              }}
            />
          </Form.Group>
          <Form.Group>
            <div className="project-report-setup-room-container">
              <div className="project-report-setup-room-header">
                <h2>Pièces</h2>
                <Button
                  size="sm"
                  appearance="link"
                  onClick={() => {
                    setValues(
                      produce(values, (draft) => {
                        draft.rooms.push({
                          id: Guid.newGuid(),
                          name: "",
                          elements: [
                            {
                              id: Guid.newGuid(),
                              name: "",
                              _operation: PersistentStorageOperation.CREATE,
                            },
                          ],
                          _operation: PersistentStorageOperation.CREATE,
                        });
                      })
                    );
                  }}
                >
                  Ajouter une pièce
                </Button>
              </div>
              <div className="project-report-setup-room-body">
                {values.rooms.map((projectReportSetupRoom, projectReportSetupRoomIndex) => {
                  const r = values.rooms.find((room) => room.id === projectReportSetupRoom.id)!;
                  return (
                    <div className="project-report-setup-room" key={projectReportSetupRoom.id}>
                      <div className="project-report-setup-room-input">
                        <InputGroup>
                          <InputGroup.Addon>Pièce #{projectReportSetupRoomIndex + 1}</InputGroup.Addon>
                          <Input
                            disabled={projectReportSetupRoom._operation === PersistentStorageOperation.DELETE}
                            size="sm"
                            value={r.name}
                            onChange={(value) => {
                              setValues(
                                produce(values, (draft) => {
                                  const room = draft.rooms.find((room) => room.id === projectReportSetupRoom.id);
                                  if (room) {
                                    room.name = value;
                                  }
                                })
                              );
                            }}
                          />
                          {projectReportSetupRoom._operation === PersistentStorageOperation.DELETE ? (
                            <InputGroup.Button
                              appearance="primary"
                              onClick={() => {
                                setValues(
                                  produce(values, (draft) => {
                                    const room = draft.rooms.find((t) => t.id === projectReportSetupRoom.id);
                                    if (room) {
                                      if (room._operation === PersistentStorageOperation.DELETE) {
                                        room._operation = PersistentStorageOperation.UPDATE;
                                      }
                                    }
                                  })
                                );
                              }}
                            >
                              <FaUndo />
                            </InputGroup.Button>
                          ) : (
                            <InputGroup.Button
                              appearance="primary"
                              onClick={() => {
                                setValues(
                                  produce(values, (draft) => {
                                    const room = draft.rooms.find((t) => t.id === projectReportSetupRoom.id);
                                    if (room) {
                                      if (room._operation === PersistentStorageOperation.CREATE) {
                                        draft.rooms = draft.rooms.filter((e) => e.id !== projectReportSetupRoom.id);
                                      }
                                      if (room._operation === PersistentStorageOperation.UPDATE) {
                                        room._operation = PersistentStorageOperation.DELETE;
                                      }
                                    }
                                  })
                                );
                              }}
                            >
                              <FaTrash />
                            </InputGroup.Button>
                          )}
                        </InputGroup>
                      </div>
                      <div className="project-report-setup-element-container">
                        <div className="project-report-setup-element-header">
                          <h3>Éléments</h3>
                          <Button
                            size="xs"
                            appearance="link"
                            onClick={() => {
                              setValues(
                                produce(values, (draft) => {
                                  const test = draft.rooms.find((x) => x.id === projectReportSetupRoom.id);
                                  if (test) {
                                    test.elements.push({
                                      id: Guid.newGuid(),
                                      name: "",
                                      _operation: PersistentStorageOperation.CREATE,
                                    });
                                  }
                                })
                              );
                            }}
                          >
                            Ajouter un élément
                          </Button>
                        </div>
                        <div className="project-report-setup-element-body">
                          {r.elements.map((projectReportSetupElement, projectReportSetupRoomElementIndex) => (
                            <div className="project-report-setup-element">
                              <div className="project-report-setup-element-input">
                                <InputGroup>
                                  <InputGroup.Addon>{projectReportSetupRoomElementIndex + 1}</InputGroup.Addon>
                                  <Input
                                    disabled={projectReportSetupElement._operation === PersistentStorageOperation.DELETE}
                                    size="sm"
                                    value={projectReportSetupElement.name}
                                    onChange={(value) => {
                                      setValues(
                                        produce(values, (draft) => {
                                          const room = draft.rooms.find((room) => room.id === projectReportSetupRoom.id);
                                          if (room) {
                                            const element = room.elements.find((g) => g.id === projectReportSetupElement.id);
                                            if (element) {
                                              element.name = value;
                                            }
                                          }
                                        })
                                      );
                                    }}
                                  />
                                  {projectReportSetupElement._operation === PersistentStorageOperation.DELETE ? (
                                    <InputGroup.Button
                                      appearance="primary"
                                      onClick={() => {
                                        setValues(
                                          produce(values, (draft) => {
                                            const room = draft.rooms.find((room) => room.id === projectReportSetupRoom.id);
                                            if (room) {
                                              const element = room.elements.find((element) => element.id === projectReportSetupElement.id);
                                              if (element) {
                                                if (element._operation === PersistentStorageOperation.DELETE) {
                                                  element._operation = PersistentStorageOperation.UPDATE;
                                                }
                                              }
                                            }
                                          })
                                        );
                                      }}
                                    >
                                      <FaUndo />
                                    </InputGroup.Button>
                                  ) : (
                                    <InputGroup.Button
                                      appearance="primary"
                                      onClick={() => {
                                        setValues(
                                          produce(values, (draft) => {
                                            const room = draft.rooms.find((room) => room.id === projectReportSetupRoom.id);
                                            if (room) {
                                              const element = room.elements.find((element) => element.id === projectReportSetupElement.id);
                                              if (element) {
                                                if (element._operation === PersistentStorageOperation.CREATE) {
                                                  room.elements = room.elements.filter((e) => e.id !== projectReportSetupElement.id);
                                                }
                                                if (element._operation === PersistentStorageOperation.UPDATE) {
                                                  element._operation = PersistentStorageOperation.DELETE;
                                                }
                                              }
                                            }
                                          })
                                        );
                                      }}
                                    >
                                      <FaTrash />
                                    </InputGroup.Button>
                                  )}
                                </InputGroup>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Form.Group>
        </div>
        {_operation === PersistentStorageOperation.CREATE && (
          <div className="project-models">
            <div>
              <h2>Modèles</h2>
              <div>
                <InputPicker
                  value={projectModelId}
                  onChange={setProjectModelId}
                  onClean={() => setProjectModelId(null)}
                  data={projectModels.map((projectModel) => ({
                    label: projectModel.name,
                    value: projectModel.id,
                  }))}
                />
                <Button
                  appearance="ghost"
                  onClick={() => {
                    setValues(
                      produce(values, (draft) => {
                        const projectReportSetupRooms: ProjectReportSetupFormValues["rooms"] = [];
                        const projectModel = projectModels.find((projectModel) => projectModel.id === projectModelId);
                        if (projectModel) {
                          for (const projectModelRoom of projectModel.rooms) {
                            const projectModelElements = projectModel.elements.filter((projectModelElement) => projectModelElement.projectModelRoomId === projectModelRoom.id);
                            projectReportSetupRooms.push({
                              id: Guid.newGuid(),
                              name: projectModelRoom.name,
                              elements: projectModelElements.map((projectModelElement) => ({
                                id: Guid.newGuid(),
                                name: projectModelElement.name,
                                _operation: PersistentStorageOperation.CREATE,
                              })),
                              _operation: PersistentStorageOperation.CREATE,
                            });
                          }
                          draft.rooms = projectReportSetupRooms;
                        }
                      })
                    );
                  }}
                >
                  Appliquer
                </Button>
              </div>
            </div>
            <Message>Test</Message>
          </div>
        )}
      </div>
    </>
  );
};
