import { FunctionComponent, useState } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext, useProjectReportSetupContext } from "hooks";
import { useUpdateProjectReportSetupMutation } from "graphql/mutations";
import { useList } from "react-use";
import { ProjectReportSetupForm } from "forms";
import { ProjectReportSetupFormValues } from "types";
import { PersistentStorageOperation } from "graphql/globalTypes";

export const ProjectReportSetupUpdateView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectReportsView, toProjectReportSetupsView, toProjectReportSetupView } = useRouting();
  const { project, projectModels } = useProjectContext();
  const { projectReportSetup } = useProjectReportSetupContext();
  const [values, setValues] = useState<ProjectReportSetupFormValues>({
    name: projectReportSetup.name,
    rooms: projectReportSetup.rooms.map((projectReportSetupRoom) => ({
      id: projectReportSetupRoom.id,
      name: projectReportSetupRoom.name,
      elements: projectReportSetup.elements
        .filter((projectReportSetupElement) => projectReportSetupElement.projectReportSetupRoomId === projectReportSetupRoom.id)
        .map((projectReportSetupElement) => ({
          id: projectReportSetupElement.id,
          name: projectReportSetupElement.name,
          _operation: PersistentStorageOperation.UPDATE,
        })),
      _operation: PersistentStorageOperation.UPDATE,
    })),
  });
  const [errors, { set: setErrors }] = useList<string>();
  const { updateProjectReportSetup, updateProjectReportSetupLoading } = useUpdateProjectReportSetupMutation((projectId, projectReportSetupId) => {
    navigate(toProjectReportSetupView(projectId, projectReportSetupId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Modifier le paramétrage"
      form={{
        loading: updateProjectReportSetupLoading,
        onSubmit: async () => {
          await updateProjectReportSetup(project.id, projectReportSetup.id, {
            name: values.name,
            rooms: values.rooms.map((room) => ({
              id: room.id,
              name: room.name,
              elements: room.elements.map((element) => ({
                id: element.id,
                name: element.name,
                _operation: element._operation,
              })),
              _operation: room._operation,
            })),
          });
        },
        onCancel: () => {
          navigate(toProjectReportSetupView(project.id, projectReportSetup.id));
        },
      }}
      permissions={[[Permission.READ_PROJECT_REPORT_SETUPS, Permission.UPDATE_PROJECT_REPORT_SETUPS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Rapports",
          href: toProjectReportsView(project.id),
        },
        {
          label: "Paramétrages",
          href: toProjectReportSetupsView(project.id),
        },
        {
          label: projectReportSetup.name,
          href: toProjectReportSetupView(project.id, projectReportSetup.id),
        },
      ]}
    >
      <ProjectReportSetupForm _operation={PersistentStorageOperation.UPDATE} projectModels={projectModels} values={values} setValues={setValues} errors={errors} />
    </View>
  );
};
