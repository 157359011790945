import "./index.less";
import { FunctionComponent } from "react";
import {
  Button,
  Dropdown,
  IconButton,
  Input,
  Popover,
  Table,
  Whisper,
} from "rsuite";
import { ProviderNoteAction, ProviderNoteInput } from "graphql/globalTypes";
import { ProviderFormValues } from "forms/ProviderForm";
import produce from "immer";
import MoreIcon from "@rsuite/icons/legacy/More";
import moment from "moment";
import { Guid } from "utils";

type Props = {
  readonly values: ProviderFormValues;
  readonly setValues: (values: ProviderFormValues) => void;
  readonly notes: ReadonlyArray<ProviderNoteInput>;
  readonly createdAt: string;
};

export const ProviderNotesForm: FunctionComponent<Props> = ({
  notes,
  values,
  setValues,
  createdAt,
}) => (
  <>
    <div className="form-subtitle">
      <h2>Notes</h2>
      <Button
        appearance="link"
        size="xs"
        onClick={() => {
          setValues(
            produce(values, (draft) => {
              draft.notes.push({
                id: Guid.newGuid(),
                createdAt,
                text: "",
                action: ProviderNoteAction.CREATE,
              });
            })
          );
        }}
      >
        Créer une note
      </Button>
    </div>
    <Table
      className="provider-notes-form form-table"
      autoHeight
      data={notes.filter((note) => note.action !== ProviderNoteAction.DELETE)}
      rowHeight={94}
    >
      <Table.Column width={175}>
        <Table.HeaderCell>Créé le</Table.HeaderCell>
        <Table.Cell>
          {(rowData: any) => {
            const input = rowData as ProviderNoteInput;
            return (
              <Input
                size="sm"
                plaintext
                value={moment(input.createdAt).format("yyyy-MM-DD HH:MM")}
              />
            );
          }}
        </Table.Cell>
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Contenu</Table.HeaderCell>
        <Table.Cell>
          {(rowData: any) => {
            const input = rowData as ProviderNoteInput;
            return (
              <Input
                size="sm"
                value={input.text}
                onChange={(value) =>
                  setValues(
                    produce(values, (draft) => {
                      const note = draft.notes.find(
                        (note) => note.id === input.id
                      );
                      if (note) {
                        note.text = value;
                      }
                    })
                  )
                }
                as="textarea"
                rows={3}
              />
            );
          }}
        </Table.Cell>
      </Table.Column>
      <Table.Column width={50}>
        <Table.HeaderCell>{""}</Table.HeaderCell>
        <Table.Cell>
          {(rowData: any) => {
            const input = rowData as ProviderNoteInput;
            return (
              <Whisper
                placement="autoVerticalStart"
                trigger="click"
                speaker={({ onClose, left, top, className }, ref) => (
                  <Popover
                    ref={ref}
                    className={className}
                    style={{ left, top }}
                    full
                  >
                    <Dropdown.Menu
                      onSelect={() => {
                        onClose();
                      }}
                    >
                      <Dropdown.Item
                        eventKey="1"
                        onClick={() => {
                          setValues(
                            produce(values, (draft) => {
                              const note = draft.notes.find(
                                (note) => note.id === input.id
                              );
                              if (note) {
                                if (note.action === ProviderNoteAction.UPDATE) {
                                  note.action = ProviderNoteAction.DELETE;
                                } else {
                                  draft.notes = draft.notes.filter(
                                    (note) => note.id !== input.id
                                  );
                                }
                              }
                            })
                          );
                        }}
                      >
                        Supprimer
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Popover>
                )}
              >
                <IconButton
                  size="sm"
                  appearance="primary"
                  icon={<MoreIcon />}
                />
              </Whisper>
            );
          }}
        </Table.Cell>
      </Table.Column>
    </Table>
  </>
);
