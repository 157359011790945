import "./index.less";
import { FunctionComponent, useState } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext, useProjectContractContext } from "hooks";
import { useCreateProjectContractPaymentMutation } from "graphql/mutations";
import { useList } from "react-use";
import { ProjectContractPaymentForm } from "forms";
import { PersistentStorageOperation } from "graphql/globalTypes";

export const ProjectContractPaymentCreateView: FunctionComponent = () => {
  const { navigate, searchParams, toProjectsView, toProjectView, toProjectContractsView, toProjectContractView, toProjectContractPaymentsView } = useRouting();
  const [customerInvoiceId] = useState<string>(searchParams.get("customerInvoiceId") ?? "");
  const { project } = useProjectContext();
  const { projectContract } = useProjectContractContext();
  const [errors, { set: setErrors }] = useList<string>();
  const { createProjectContractPayment, createProjectContractPaymentLoading } = useCreateProjectContractPaymentMutation((projectId, customerContractId) => {
    navigate(toProjectContractPaymentsView(projectId, customerContractId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      id="project-contract-payment-create-view"
      title="Créer un paiement"
      permissions={[[Permission.READ_PROJECT_CONTRACTS, Permission.READ_PROJECT_CONTRACT_PAYMENTS, Permission.CREATE_PROJECT_CONTRACT_PAYMENTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Contrats",
          href: toProjectContractsView(project.id),
        },
        {
          label: `Unité #${projectContract.projectUnit.number} (${projectContract.customer.firstName} ${projectContract.customer.lastName})`,
          href: toProjectContractView(project.id, projectContract.id),
        },
        {
          label: "Paiements",
          href: toProjectContractPaymentsView(project.id, projectContract.id),
        },
      ]}
    >
      <ProjectContractPaymentForm
        _operation={PersistentStorageOperation.CREATE}
        projectContractInvoices={projectContract.invoices}
        readonly={customerInvoiceId !== ""}
        initialValues={{
          customerInvoiceId: customerInvoiceId,
          amount: 0,
          referenceNumber: "",
        }}
        errors={errors}
        loading={createProjectContractPaymentLoading}
        onSubmit={async (values) => {
          await createProjectContractPayment(project.id, projectContract.id, values.customerInvoiceId, {
            amount: values.amount,
            referenceNumber: values.referenceNumber === "" ? null : values.referenceNumber,
          });
        }}
        onCancel={() => {
          navigate(toProjectContractPaymentsView(project.id, projectContract.id));
        }}
      />
    </View>
  );
};
