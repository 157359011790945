import "./index.less";
import { FunctionComponent, useMemo } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext, useProjectContractContext, useProjectContractPaymentContext } from "hooks";
import { useDeleteProjectContractPaymentMutation } from "graphql/mutations";
import { useList } from "react-use";
import { DeleteForm } from "forms";

export const ProjectContractPaymentDeleteView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectContractsView, toProjectContractView, toProjectContractPaymentsView } = useRouting();
  const { project } = useProjectContext();
  const { projectContract } = useProjectContractContext();
  const { projectContractPayment } = useProjectContractPaymentContext();
  // TODO:
  const payment = useMemo(() => {
    return projectContractPayment;
    // eslint-disable-next-line
  }, []);
  const [errors, { set: setErrors }] = useList<string>();
  const { deleteProjectContractPayment, deleteProjectContractPaymentLoading } = useDeleteProjectContractPaymentMutation((projectId, customerContractId) => {
    navigate(toProjectContractPaymentsView(projectId, customerContractId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      id="project-contract-payment-delete-view"
      permissions={[[Permission.READ_PROJECT_CONTRACTS, Permission.READ_PROJECT_CONTRACT_PAYMENTS, Permission.DELETE_PROJECT_CONTRACT_PAYMENTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Contrats",
          href: toProjectContractsView(project.id),
        },
        {
          label: `Unité #${projectContract.projectUnit.number} (${projectContract.customer.firstName} ${projectContract.customer.lastName})`,
          href: toProjectContractView(project.id, projectContract.id),
        },
        {
          label: "Paiements",
          href: toProjectContractPaymentsView(project.id, projectContract.id),
        },
      ]}
    >
      <DeleteForm
        label="Numéro du paiement"
        value={payment.number.toString()}
        confirm="Veuillez saisir le numéro du paiement pour confirmer."
        errors={errors}
        loading={deleteProjectContractPaymentLoading}
        onSubmit={async () => {
          await deleteProjectContractPayment(project.id, projectContract.id, projectContractPayment.projectContractInvoiceId, payment.id);
        }}
        onCancel={() => {
          navigate(toProjectContractPaymentsView(project.id, projectContract.id));
        }}
      >
        Ce paiement sera définitivement supprimé. <strong>Cette opération est IRRÉVERSIBLE.</strong>
      </DeleteForm>
    </View>
  );
};
