import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useAppContext, useLayout, useRouting, useProjectContext } from "hooks";
import { useList } from "react-use";
import { ProjectContractCreateForm } from "forms";
import { useCreateProjectContractMutation } from "graphql/mutations";
import { ContractChoiceMode } from "graphql/globalTypes";

export const ProjectContractAssociateView: FunctionComponent = () => {
  const { searchParams, navigate, toProjectsView, toProjectView, toProjectContractsView, toProjectContractView } = useRouting();
  const { customers } = useAppContext();
  const { project, projectUnits, projectModels } = useProjectContext();
  const projectUnitId = searchParams.get("projectUnitId") ?? "";
  const [errors, { set: setErrors }] = useList<string>();
  const { createProjectContract, createProjectContractLoading } = useCreateProjectContractMutation((projectId, customerContractId) => {
    navigate(toProjectContractView(projectId, customerContractId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Associer un contrat"
      permissions={[[Permission.READ_PROJECT_CONTRACTS, Permission.CREATE_PROJECT_CONTRACTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Contrats",
          href: toProjectContractsView(project.id),
        },
      ]}
    >
      <ProjectContractCreateForm
        customers={customers}
        project={project}
        projectModels={projectModels}
        projectUnits={projectUnits}
        readonly={projectUnitId !== ""}
        initialValues={{
          projectUnitId: projectUnitId,
          isCustomized: false,
          customerId: null,
          projectModelId: null,
          projectContractBlobFile: undefined,
          projectContractChoiceMode: ContractChoiceMode.PROJECT_MODEL_VIBE_ITEMS,
          projectContractChoiceBillingSettings: {
            customOptionUnitPrice: project.customOptionUnitPrice,
            additionalCosts: 0,
          },
        }}
        errors={errors}
        loading={createProjectContractLoading}
        onSubmit={async (values) => {
          await createProjectContract(project.id, {
            projectUnitId: values.projectUnitId!,
            projectModelId: values.projectModelId,
            projectContractBlobFile: values.projectContractBlobFile,
            customerId: values.customerId!,
            isCustomized: values.isCustomized,
            projectContractChoiceMode: values.projectContractChoiceMode,
            projectContractChoiceBillingSettings: values.projectContractChoiceBillingSettings,
          });
        }}
        onCancel={() => {
          navigate(toProjectContractsView(project.id));
        }}
      />
    </View>
  );
};
