import "./index.less";
import { FunctionComponent, useState } from "react";
import { Button, ButtonToolbar, Form, Input, SelectPicker } from "rsuite";
import { FormError } from "buildingBlocks";
import produce from "immer";
import { ProjectModelElement } from "graphql/__generated__/ProjectModelElement";
import { ProjectModelRoom } from "graphql/__generated__/ProjectModelRoom";
import { ProjectContractRequest } from "graphql/__generated__/ProjectContractRequest";

type ProjectContractRequestFormValues = {
  readonly projectModelElementId: string | null;
  readonly content: string | null;
};

type Props = {
  readonly projectModelElementId: string | null;
  readonly projectModelRooms: ReadonlyArray<ProjectModelRoom>;
  readonly projectModelElements: ReadonlyArray<ProjectModelElement>;
  readonly projectContractRequests: ReadonlyArray<ProjectContractRequest>;
  readonly initialValues: ProjectContractRequestFormValues;
  readonly errors: string[];
  readonly loading: boolean;
  readonly onSubmit: (values: ProjectContractRequestFormValues) => Promise<void>;
  readonly onCancel: () => void;
};

export const ProjectContractRequestForm: FunctionComponent<Props> = ({
  projectModelElementId,
  projectModelElements,
  projectModelRooms,
  projectContractRequests,
  initialValues,
  errors,
  loading,
  onSubmit,
  onCancel,
}) => {
  const [values, setValues] = useState<ProjectContractRequestFormValues>(initialValues);
  return (
    <>
      <FormError errors={errors} />
      <Form className="form" fluid>
        <Form.Group>
          <Form.ControlLabel>Élément</Form.ControlLabel>
          <SelectPicker
            cleanable={false}
            readOnly={projectModelElementId !== null}
            value={projectModelElementId ?? values.projectModelElementId}
            groupBy="role"
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.projectModelElementId = value!;
                  const customerRequest = projectContractRequests.find((projectContractRequest) => projectContractRequest.projectModelElementId === value!);
                  if (customerRequest) {
                    draft.content = customerRequest.content;
                  }
                })
              );
            }}
            data={projectModelElements.map((projectModelElement) => {
              const role = projectModelRooms.find((projectModelRoom) => projectModelRoom.id === projectModelElement.projectModelRoomId)?.name ?? "Autres";
              return {
                label: `${role} - ${projectModelElement.name}`,
                value: projectModelElement.id,
                role: role,
              };
            })}
            renderMenuItem={(label) => {
              return (
                <div>
                  <i className="rs-icon rs-icon-user" /> {label}
                </div>
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Contenu</Form.ControlLabel>
          <Input
            value={values.content ?? undefined}
            onChange={(value) =>
              setValues(
                produce(values, (draft) => {
                  draft.content = value;
                })
              )
            }
            as="textarea"
            rows={3}
          />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              loading={loading}
              appearance="primary"
              onClick={() => {
                onSubmit(values);
              }}
            >
              Enregistrer
            </Button>
            <Button appearance="default" onClick={onCancel}>
              Annuler
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};
