import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext } from "hooks";
import { useDeleteProjectMutation } from "graphql/mutations";
import { useList } from "react-use";
import { DeleteForm } from "forms";

export const ProjectDeleteView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView } = useRouting();
  const { project } = useProjectContext();
  const [errors, { set: setErrors }] = useList<string>([]);
  const { deleteProject, deleteProjectLoading } = useDeleteProjectMutation(() => {
    navigate(toProjectsView());
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Supprimer le projet"
      permissions={[[Permission.READ_PROJECTS, Permission.DELETE_PROJECTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
      ]}
    >
      <DeleteForm
        label="Nom du projet"
        value={project.label}
        confirm="Veuillez saisir le nom du projet pour confirmer."
        errors={errors}
        loading={deleteProjectLoading}
        onSubmit={async () => {
          await deleteProject(project.id);
        }}
        onCancel={() => {
          navigate(toProjectView(project.id));
        }}
      >
        Ce projet sera définitivement supprimé. <strong>Cette opération est IRRÉVERSIBLE.</strong>
      </DeleteForm>
    </View>
  );
};
