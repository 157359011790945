import "./index.less";
import { FunctionComponent } from "react";
import { ProjectActivity } from "graphql/__generated__/ProjectActivity";
import { ProjectUnit } from "graphql/__generated__/ProjectUnit";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import { ProjectProduct } from "graphql/__generated__/ProjectProduct";
import { ProjectContract } from "graphql/__generated__/ProjectContract";
import { ProjectContractInfos } from "components";
import { Customer } from "graphql/__generated__/Customer";
import { Provider } from "graphql/__generated__/Provider";
import { Project } from "graphql/__generated__/Project";
import { Message } from "rsuite";

type Props = {
  readonly pageClassName?: string;
  readonly project: Project;
  readonly projectUnit: ProjectUnit | null | undefined;
  readonly projectUnits: ReadonlyArray<ProjectUnit>;
  readonly projectProducts: ReadonlyArray<ProjectProduct>;
  readonly projectModel: ProjectModel | null | undefined;
  readonly projectModels: ReadonlyArray<ProjectModel>;
  readonly projectActivities: ReadonlyArray<ProjectActivity>;
  readonly projectContract: ProjectContract | null | undefined;
  readonly providers: ReadonlyArray<Provider>;
  readonly customers: ReadonlyArray<Customer>;
};

export const ConstructionSheetPerUnitDocument: FunctionComponent<Props> = ({
  pageClassName = undefined,
  project,
  projectUnit,
  projectUnits,
  projectProducts,
  projectModel,
  projectModels,
  projectActivities,
  projectContract,
  providers,
  customers,
}) => {
  const style: React.CSSProperties = {};
  if (pageClassName) {
    style.display = "none";
  }
  if (!projectUnit) {
    return (
      <Message type="warning" style={style} header={<h2>Projet sans unité</h2>}>
        Ajouter une unité au projet pour avoir accès au rapport par unité.
      </Message>
    );
  }
  if (!projectContract) {
    return (
      <Message type="warning" style={style} header={<h2>Unité sans contrat</h2>}>
        Un contrat client doit être associé à cette unité pour qu'un rapport soit généré.
      </Message>
    );
  }
  if (!projectModel) {
    return (
      <Message type="warning" style={style} header={<h2>Unité sans modèle</h2>}>
        Assigner un modèle à l'unité pour que le client puisse faire son choix.
      </Message>
    );
  }
  if (projectContract.isCustomized) {
    return (
      <Message style={style} header={<h2>Unité en personnalisation</h2>}>
        Le rapport n'est pas disponible. Cette unité n'est pas gérée par l'application.
      </Message>
    );
  }
  const projectContractChoice = projectContract.choices.find((customerChoice) => customerChoice.id === projectContract.currentProjectContractChoiceId);
  if (projectContract.choices.length > 1 && (!projectContractChoice || projectContractChoice.items.length === 0)) {
    return (
      <Message type="warning" style={style} header={<h2>Unité en révision</h2>}>
        Le client doit refaire son choix d'options pour que sa fiche client soit générée.
      </Message>
    );
  }
  if (!projectContractChoice || projectContractChoice.items.length === 0) {
    return (
      <Message type="warning" style={style} header={<h2>Unité sans choix du client</h2>}>
        Le client doit faire son choix d'options pour qu'un rapport soit généré.
      </Message>
    );
  }
  return (
    <Document
      pageClassName={pageClassName}
      project={project}
      projectUnits={projectUnits}
      projectProducts={projectProducts}
      projectModel={projectModel}
      projectModels={projectModels}
      projectActivities={projectActivities}
      projectContract={projectContract}
      providers={providers}
      customers={customers}
    />
  );
};

type DocumentProps = {
  readonly pageClassName?: string;
  readonly project: Project;
  readonly projectUnits: ReadonlyArray<ProjectUnit>;
  readonly projectProducts: ReadonlyArray<ProjectProduct>;
  readonly projectModel: ProjectModel;
  readonly projectModels: ReadonlyArray<ProjectModel>;
  readonly projectActivities: ReadonlyArray<ProjectActivity>;
  readonly projectContract: ProjectContract;
  readonly providers: ReadonlyArray<Provider>;
  readonly customers: ReadonlyArray<Customer>;
};

export const Document: FunctionComponent<DocumentProps> = ({
  pageClassName = undefined,
  project,
  projectContract,
  projectUnits,
  projectProducts,
  projectModel,
  projectModels,
  projectActivities,
  providers,
  customers,
}) => {
  const { withoutExtraTables, withExtraTables } = useConstructionSheetPerUnitContext(projectProducts, projectModel, projectActivities, projectContract, providers);
  const style: React.CSSProperties = {};
  if (pageClassName) {
    style.position = "absolute";
    style.right = "-2000px";
    style.bottom = "-2000px";
    style.width = "1056px";
    style.padding = "50px";
  }
  return (
    <div style={style} className="construction-report-by-unit-number-document">
      {projectContract && (
        <ProjectContractInfos
          title="FICHE DE CONSTRUCTION PAR UNITÉ"
          className={pageClassName}
          project={project}
          projectContract={projectContract}
          projectContractChoice={projectContract.choices.find((projectContractChoice) => projectContractChoice.id === projectContract.currentProjectContractChoiceId)}
          projectModels={projectModels}
          projectUnits={projectUnits}
          customers={customers}
          withoutCustomer={pageClassName !== undefined}
          withProjectCode
        />
      )}
      <Page className={pageClassName} tables={withoutExtraTables} isExtra={false} usedByDocument={pageClassName !== undefined} />
      <Page className={pageClassName} tables={withExtraTables} isExtra={true} usedByDocument={pageClassName !== undefined} />
    </div>
  );
};

type PageProps = {
  readonly tables: ConstructionSheetPerUnitTable[];
  readonly className?: string;
  readonly isExtra: boolean;
  readonly usedByDocument?: boolean;
};

const Page: FunctionComponent<PageProps> = ({ className = undefined, tables, isExtra, usedByDocument }) => {
  return (
    <section className={`construction-report-by-unit-number${className ? ` ${className}` : ""}`}>
      <div className={isExtra ? "with-extra" : "without-extra"}>
        {tables.length !== 0 && (
          <div className="title">{isExtra ? "Ajout ou modification au choix de base (EXTRA)".toLocaleUpperCase() : "Choix de base (Standard au projet)".toLocaleUpperCase()}</div>
        )}
        {tables.map((table) => (
          <div className="table" key={Math.random()}>
            <div className="table-title">{table.activityTitle.toUpperCase()}</div>
            <div className="table-rows">
              <div className="table-row">
                <div className="table-column">Élément</div>
                <div className="table-column">Pièce</div>
                <div className="table-column">Choix</div>
                {!usedByDocument && <div className="table-column">Fournisseur</div>}
                {!usedByDocument && <div className="table-column">Nom du modèle</div>}
                {!usedByDocument && <div className="table-column">Code du modèle</div>}
                {!usedByDocument && <div className="table-column">Format</div>}
                <div className="table-column">Commentaires</div>
              </div>
              {table.rows.map((tableRow) => (
                <div className="table-row" key={Math.random()}>
                  <div className="table-column">{tableRow.projectModelElementName}</div>
                  <div className="table-column">{tableRow.projectModelRoomName}</div>
                  <div className="table-column">{tableRow.projectProductName}</div>
                  {!usedByDocument && <div className="table-column">{tableRow.projectProductProviderName}</div>}
                  {!usedByDocument && <div className="table-column">{tableRow.projectProductProviderProductModel}</div>}
                  {!usedByDocument && <div className="table-column">{tableRow.projectProductProviderProductCode}</div>}
                  {!usedByDocument && <div className="table-column">{tableRow.projectProductFormat}</div>}
                  <div className="table-column">--</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

const getProjectContractChoiceItems = (projectModel: ProjectModel, projectContract: ProjectContract, projectProducts: ReadonlyArray<ProjectProduct>) => {
  const projectContractChoice = projectContract.choices.find((customerChoice) => customerChoice.id === projectContract?.currentProjectContractChoiceId);
  if (projectContractChoice) {
    return projectContractChoice.items.map((projectContractChoiceItem) => {
      const projectModelElement = projectModel.elements.find((projectModelElement) => projectModelElement.id === projectContractChoiceItem.projectModelElementId)!;
      const projectModelElementOption = projectModelElement.options.find((projectModelElementOption) => projectModelElementOption.id === projectContractChoiceItem.projectModelElementOptionId) ?? null;
      const projectModelElementOptionProduct = projectProducts.find((projectProduct) => projectProduct.id === projectModelElementOption?.productId) ?? null;
      return {
        projectModelElement,
        projectModelElementOption,
        projectModelElementOptionProduct,
      };
    });
  } else {
    return [];
  }
};

export type ConstructionSheetPerUnitTable = {
  readonly activityTitle: string;
  readonly rows: {
    readonly projectModelElementName: string;
    readonly projectModelRoomName: string;
    readonly projectProductName: string;
    readonly projectProductProviderName: string;
    readonly projectProductProviderProductModel: string;
    readonly projectProductProviderProductCode: string;
    readonly projectProductFormat: string;
  }[];
};

const getProjectModelElements = (projectModel: ProjectModel, projectProducts: ReadonlyArray<ProjectProduct>, projectActivityId: string) => {
  const projectModelElements = projectModel.elements.map((projectModelElement) => {
    const projectModelRoom = projectModel.rooms.find((projectModelRoom) => projectModelRoom.id === projectModelElement.projectModelRoomId) ?? null;
    const parts: string[] = [];
    if (projectModelRoom) {
      parts.push(projectModelRoom.name);
    }
    parts.push(projectModelElement.name);
    const projectModelElementOptionProducts = projectModelElement.options.map(
      (projectModelElementOption) => projectProducts.find((projectProduct) => projectProduct.id === projectModelElementOption.productId)!
    );
    const activityIds = projectModelElementOptionProducts
      .map((projectProduct) => projectProduct.activityIds)
      .reduce((previousValue, currentValue) => previousValue.concat(currentValue), [])
      .filter((value, index, array) => array.indexOf(value) === index);
    return {
      id: projectModelElement.id,
      name: projectModelElement.name,
      label: parts.join(" | ").toLocaleUpperCase(),
      isBoolean: projectModelElement.isBoolean,
      activityIds,
      hasExtra: projectModelElement.options.some((projectModelElementOption) => projectModelElementOption.isExtra),
      extraOnly: projectModelElement.options.every((projectModelElementOption) => projectModelElementOption.isExtra),
      projectModelRoom,
    };
  });
  return projectModelElements
    .filter((projectModelElement) => projectModelElement.activityIds.includes(projectActivityId))
    .sort((left, right) => {
      if (left.label < right.label) {
        return -1;
      }
      if (left.label > right.label) {
        return 1;
      }
      return 0;
    });
};

export const useConstructionSheetPerUnitContext = (
  projectProducts: ReadonlyArray<ProjectProduct>,
  projectModel: ProjectModel,
  projectActivities: ReadonlyArray<ProjectActivity>,
  projectContract: ProjectContract,
  providers: ReadonlyArray<Provider>
) => {
  const withoutExtraTables: ConstructionSheetPerUnitTable[] = [];
  const withExtraTables: ConstructionSheetPerUnitTable[] = [];
  for (const projectActivity of projectActivities) {
    const elements = getProjectModelElements(projectModel, projectProducts, projectActivity.id).sort((left, right) => {
      if (left.name < right.name) {
        return -1;
      }
      if (left.name > right.name) {
        return 1;
      }
      return 0;
    });
    const withoutExtraTableRows: ConstructionSheetPerUnitTable["rows"] = [];
    for (const element of elements.filter((projectModelElement) => !projectModelElement.extraOnly)) {
      const projectContractChoiceItems = getProjectContractChoiceItems(projectModel, projectContract, projectProducts);
      const projectContractChoiceItem = projectContractChoiceItems.find((projectContractChoiceItem) => projectContractChoiceItem.projectModelElement.id === element.id);
      if (projectContractChoiceItem && projectContractChoiceItem.projectModelElementOptionProduct?.activityIds.includes(projectActivity.id)) {
        const provider = providers.find((provider) => provider.id === projectContractChoiceItem.projectModelElementOptionProduct?.provider.id);
        withoutExtraTableRows.push({
          projectModelElementName: projectContractChoiceItem.projectModelElement.name,
          projectModelRoomName: element.projectModelRoom?.name ?? "Autres",
          projectProductName:
            projectContractChoiceItem.projectModelElementOption?.isExtra ?? false
              ? "EXTRA"
              : projectContractChoiceItem.projectModelElement.isBoolean && projectContractChoiceItem.projectModelElementOption !== null
              ? "OUI"
              : projectContractChoiceItem.projectModelElementOptionProduct?.name ?? "--",
          projectProductProviderName: provider?.name ?? "--",
          projectProductProviderProductModel: projectContractChoiceItem.projectModelElementOptionProduct?.provider.productModel ?? "--",
          projectProductProviderProductCode: projectContractChoiceItem.projectModelElementOptionProduct?.provider.productCode ?? "--",
          projectProductFormat: "--",
        });
      }
    }
    const withExtraTableRows: ConstructionSheetPerUnitTable["rows"] = [];
    for (const element of elements.filter((projectModelElement) => projectModelElement.hasExtra)) {
      const projectContractChoiceItems = getProjectContractChoiceItems(projectModel, projectContract, projectProducts);
      const projectContractChoiceItem = projectContractChoiceItems.find((projectContractChoiceItem) => projectContractChoiceItem.projectModelElement.id === element.id);
      if (projectContractChoiceItem && projectContractChoiceItem.projectModelElementOptionProduct?.activityIds.includes(projectActivity.id)) {
        const provider = providers.find((provider) => provider.id === projectContractChoiceItem.projectModelElementOptionProduct?.provider.id);
        if (projectContractChoiceItem.projectModelElementOption?.isExtra ?? false) {
          withExtraTableRows.push({
            projectModelElementName: projectContractChoiceItem.projectModelElement.name,
            projectModelRoomName: element.projectModelRoom?.name ?? "Autres",
            projectProductName:
              projectContractChoiceItem.projectModelElement.isBoolean && projectContractChoiceItem.projectModelElementOption !== null
                ? "OUI"
                : projectContractChoiceItem.projectModelElementOptionProduct?.name ?? "--",
            projectProductProviderName: provider?.name ?? "--",
            projectProductProviderProductModel: projectContractChoiceItem.projectModelElementOptionProduct?.provider.productModel ?? "--",
            projectProductProviderProductCode: projectContractChoiceItem.projectModelElementOptionProduct?.provider.productCode ?? "--",
            projectProductFormat: "--",
          });
        }
      }
    }
    if (withoutExtraTableRows.length !== 0) {
      withoutExtraTables.push({
        activityTitle: projectActivity.title,
        rows: withoutExtraTableRows,
      });
    }
    if (withExtraTableRows.length !== 0) {
      withExtraTables.push({
        activityTitle: projectActivity.title,
        rows: withExtraTableRows,
      });
    }
  }
  return { withoutExtraTables, withExtraTables };
};
