import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext, useProjectModelContext, useProjectModelVibeContext } from "hooks";
import { usePublishProjectModelVibeMutation } from "graphql/mutations";
import { useList } from "react-use";
import { ProjectModelVibePublishForm } from "forms";

export const ProjectModelVibePublishView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectModelsView, toProjectModelView, toProjectModelVibesView, toProjectModelVibeView } = useRouting();
  const { project } = useProjectContext();
  const { projectModel } = useProjectModelContext();
  const { projectModelVibe } = useProjectModelVibeContext();
  const [errors, { set: setErrors }] = useList<string>([]);
  const { publishProjectModelVibe, publishProjectModelVibeLoading } = usePublishProjectModelVibeMutation((projectId, projectModelId, projectModelVibeId) => {
    navigate(toProjectModelVibeView(projectId, projectModelId, projectModelVibeId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Publier l'ambiance"
      permissions={[[Permission.READ_PROJECT_MODEL_VIBES, Permission.PUBLISH_PROJECT_MODEL_VIBES]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Modèles",
          href: toProjectModelsView(project.id),
        },
        {
          label: projectModel.name,
          href: toProjectModelView(project.id, projectModel.id),
        },
        {
          label: "Ambiances",
          href: toProjectModelVibesView(project.id, projectModel.id),
        },
        {
          label: projectModelVibe.name,
          href: toProjectModelVibeView(project.id, projectModel.id, projectModelVibe.id),
        },
      ]}
    >
      <ProjectModelVibePublishForm
        projectModelVibe={projectModelVibe}
        errors={errors}
        loading={publishProjectModelVibeLoading}
        onSubmit={async () => {
          await publishProjectModelVibe(project.id, projectModel.id, projectModelVibe.id);
        }}
        onCancel={() => {
          navigate(toProjectModelVibeView(project.id, projectModel.id, projectModelVibe.id));
        }}
      />
    </View>
  );
};
