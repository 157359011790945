import "./index.less";
import { FunctionComponent, useState } from "react";
import { Button, ButtonToolbar, Form, Input, MaskedInput } from "rsuite";
import { FormError } from "buildingBlocks";
import produce from "immer";

type ProjectActivityFormValues = {
  readonly number: string;
  readonly title: string;
  readonly description: string;
};

type Props = {
  readonly initialValues: ProjectActivityFormValues;
  readonly errors: string[];
  readonly loading: boolean;
  readonly onSubmit: (values: ProjectActivityFormValues) => Promise<void>;
  readonly onCancel: () => void;
};

export const ProjectActivityForm: FunctionComponent<Props> = ({ initialValues, errors, loading, onSubmit, onCancel }) => {
  const [values, setValues] = useState<ProjectActivityFormValues>(initialValues);
  return (
    <>
      <FormError errors={errors} />
      <Form className="form" fluid>
        <Form.Group>
          <Form.ControlLabel>Numéro</Form.ControlLabel>
          <MaskedInput
            value={values.number}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.number = value;
                })
              );
            }}
            mask={[/\d/, /\d/, " ", /\d/, /\d/, " ", /\d/, /\d/]}
            placeholder="00 00 00"
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Titre</Form.ControlLabel>
          <Input
            value={values.title}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.title = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Description</Form.ControlLabel>
          <Input
            value={values.description}
            onChange={(value) =>
              setValues(
                produce(values, (draft) => {
                  draft.description = value;
                })
              )
            }
            as="textarea"
            rows={3}
          />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              loading={loading}
              appearance="primary"
              onClick={() => {
                onSubmit(values);
              }}
            >
              Enregistrer
            </Button>
            <Button appearance="default" onClick={onCancel}>
              Annuler
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};
