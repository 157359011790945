import { useContext, useEffect } from "react";
import { LayoutContext } from "providers/LayoutProvider";
import { SidebarAction, SidebarButton } from "types";
import { useParams } from "react-router-dom";
import { AppContext } from "providers/AppProvider";
import { ProjectContext } from "providers/ProjectProvider";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Project } from "graphql/__generated__/Project";
import { NotificationContext } from "providers/NotificationProvider";

export * from "./useAuth";
export * from "./useRouting";

export const useLayoutContext = () => useContext(LayoutContext);

type LayoutProps = {
  readonly sidebarActions: ReadonlyArray<SidebarAction>;
  readonly sidebarButtons: ReadonlyArray<SidebarButton>;
};

type UseLayout = (props: LayoutProps) => void;

export const useLayout: UseLayout = ({ sidebarActions, sidebarButtons }) => {
  const { setSidebarButtons, setSidebarActions } = useLayoutContext();
  useEffect(() => {
    setSidebarActions([...sidebarActions]);
    setSidebarButtons([...sidebarButtons]);
    // eslint-disable-next-line
  }, [setSidebarActions, setSidebarButtons]);
};

export const useNotificationContext = () => useContext(NotificationContext);

export const useAppContext = () => useContext(AppContext);

export const useCustomerContext = () => {
  const { customerId } = useParams<{ readonly customerId: string }>();
  const { customers } = useAppContext();
  return {
    customer: customers.find((customer) => customer.id === customerId)!,
  };
};

export const useProviderContext = () => {
  const { providerId } = useParams<{ readonly providerId: string }>();
  const { providers } = useAppContext();
  return {
    provider: providers.find((provider) => provider.id === providerId)!,
  };
};

export const useUserContext = () => {
  const { userId } = useParams<{ readonly userId: string }>();
  const { users } = useAppContext();
  return {
    user: users.find((user) => user.id === userId)!,
  };
};

export const useProjectContext = () => useContext(ProjectContext);

export const useProjectContractContext = () => {
  const { contractId: projectContractId } = useParams<{ readonly contractId: string }>();
  const { projectContracts } = useProjectContext();
  return {
    projectContract: projectContracts.find((projectContract) => projectContract.id === projectContractId)!,
  };
};

export const useProjectContractInvoiceContext = () => {
  const { invoiceId: projectContractInvoiceId } = useParams<{ readonly invoiceId: string }>();
  const { projectContract } = useProjectContractContext();
  return {
    projectContractInvoice: projectContract.invoices.find((projectContractInvoice) => projectContractInvoice.id === projectContractInvoiceId)!,
  };
};

export const useProjectContractChoiceContext = () => {
  const { choiceId: projectContractChoiceId } = useParams<{ readonly choiceId: string }>();
  const { projectContract } = useProjectContractContext();
  return {
    projectContractChoice: projectContract.choices.find((projectContractChoice) => projectContractChoice.id === projectContractChoiceId)!,
  };
};

export const useProjectContractPaymentContext = () => {
  const { paymentId: projectContractPaymentId } = useParams<{ readonly paymentId: string }>();
  const { projectContract } = useProjectContractContext();
  return {
    projectContractPayment: projectContract.payments.find((projectContractPayment) => projectContractPayment.id === projectContractPaymentId)!,
  };
};

export const useProjectActivityContext = () => {
  const { activityId: projectActivityId } = useParams<{ readonly activityId: string }>();
  const { projectActivities } = useProjectContext();
  return {
    projectActivity: projectActivities.find((projectActivity) => projectActivity.id === projectActivityId)!,
  };
};

export const useProjectModelContext = () => {
  const { modelId: projectModelId } = useParams<{ readonly modelId: string }>();
  const { projectModels } = useProjectContext();
  return {
    projectModel: projectModels.find((projectModel) => projectModel.id === projectModelId)!,
  };
};

export const useProjectModelElementContext = () => {
  const { modelElementId: projectModelElementId } = useParams<{ readonly modelElementId: string }>();
  const { projectModel } = useProjectModelContext();
  return {
    projectModelElement: projectModel.elements.find((projectModelElement) => projectModelElement.id === projectModelElementId)!,
  };
};

export const useProjectModelElementOptionContext = () => {
  const { modelElementOptionId: projectModelElementOptionId } = useParams<{ readonly modelElementOptionId: string }>();
  const { projectModelElement } = useProjectModelElementContext();
  return {
    projectModelElementOption: projectModelElement.options.find((projectModelElementOption) => projectModelElementOption.id === projectModelElementOptionId)!,
  };
};

export const useProjectModelVibeContext = () => {
  const { vibeId: projectModelVibeId } = useParams<{ readonly vibeId: string }>();
  const { projectModel } = useProjectModelContext();
  return {
    projectModelVibe: projectModel.vibes.find((projectModelVibe) => projectModelVibe.id === projectModelVibeId)!,
  };
};

export const useProjectProductContext = () => {
  const { productId: projectProductId } = useParams<{ readonly productId: string }>();
  const { projectProducts } = useProjectContext();
  return {
    projectProduct: projectProducts.find((projectProduct) => projectProduct.id === projectProductId)!,
  };
};

// TODO:
export const useProjectUnitContext = () => {
  const { unitId: projectUnitId } = useParams<{ readonly unitId: string }>();
  const { projectUnits, projectContracts } = useProjectContext();
  const projectUnit = projectUnits.find((projectUnit) => projectUnit.id === projectUnitId);
  return {
    projectUnit: projectUnit!, // TODO:
    contracts: projectContracts.filter((projectContract) => projectUnit?.projectContractIds.includes(projectContract.id) ?? false),
  };
};

export const useProjectUnitContractContext = () => {
  const { contractId: projectContractId } = useParams<{ readonly contractId: string }>();
  const { projectContracts } = useProjectContext();
  return {
    contract: projectContracts.find((projectContract) => projectContract.id === projectContractId)!,
  };
};

// TODO:
export const useProjectUnitCurrentContractContext = () => {
  const { projectUnit } = useProjectUnitContext();
  const { projectContracts } = useProjectContext();
  return {
    currentContract: projectContracts.find((projectContract) => projectContract.id === projectUnit.currentProjectContractId)!,
  };
};

// TODO:
export const useProjectUnitCustomerChoiceContext = () => {
  const { customerChoiceId } = useParams<{ readonly customerChoiceId: string }>();
  const { currentContract } = useProjectUnitCurrentContractContext();
  return {
    customerChoice: currentContract.choices.find((projectContractChoice) => projectContractChoice.id === customerChoiceId)!,
  };
};

export const useProjectReportSetupContext = () => {
  const { reportSetupId: projectReportSetupId } = useParams<{ readonly reportSetupId: string }>();
  const { projectReportSetups } = useProjectContext();
  return {
    projectReportSetup: projectReportSetups.find((projectReportSetup) => projectReportSetup.id === projectReportSetupId)!,
  };
};

export const useProjectReportSetupElementContext = () => {
  const { reportSetupElementId: projectReportSetupElementId } = useParams<{ readonly reportSetupElementId: string }>();
  const { projectReportSetup } = useProjectReportSetupContext();
  return {
    projectReportSetupElement: projectReportSetup.elements.find((projectReportSetupElement) => projectReportSetupElement.id === projectReportSetupElementId)!,
  };
};

export const useProjectReportSetupRoomContext = () => {
  const { reportSetupRoomId: projectReportSetupRoomId } = useParams<{ readonly reportSetupRoomId: string }>();
  const { projectReportSetup } = useProjectReportSetupContext();
  return {
    projectReportSetupRoom: projectReportSetup.rooms.find((projectReportSetupRoom) => projectReportSetupRoom.id === projectReportSetupRoomId)!,
  };
};

export const useCountryContext = (countryCode: string) => {
  const { countries } = useAppContext();
  return {
    country: countries.find((country) => country.code === countryCode)!,
  };
};

export const useCountrySubdivisionContext = (countryCode: string, subdivisionCode: string) => {
  const { country } = useCountryContext(countryCode);
  return {
    countrySubdivision: country.subdivisions.find((subdivision) => subdivision.code === subdivisionCode)!,
  };
};

export const useProjectContractInvoiceDocumentContext = (elementId: string, projectContractInvoiceLabel: string) => {
  return {
    download: async () => {
      const element = document.getElementById(elementId);
      if (element) {
        const height = element.offsetHeight;
        const width = element.offsetWidth;
        const ratio = height / width;
        const canvas = await html2canvas(element, {
          height,
          width,
        });
        const image = canvas.toDataURL("image/png");
        const pdf = new jsPDF("portrait", "px", "letter");
        var pageWidth = pdf.internal.pageSize.getWidth();
        let pageHeight = pdf.internal.pageSize.getHeight();
        pageHeight = ratio * pageWidth;
        pdf.addImage(image, "PNG", 0, 0, pageWidth, pageHeight, undefined, "NONE");
        pdf.autoPrint();
        pdf.save(projectContractInvoiceLabel);
      }
    },
  };
};

export const useConstuctionReportByUnitFloorDocumentContext = (elementClassNames: string, project: Project, projectUnitFloor: number) => {
  return {
    download: async () => {
      const pdf = new jsPDF("landscape", "px", "ledger");
      const elements = document.getElementsByClassName(elementClassNames);
      for (let index = 0; index < elements.length; index++) {
        if (index !== 0) {
          pdf.addPage("ledger", "landscape");
        }
        const element = elements[index] as HTMLElement;
        const height = element.offsetHeight;
        const width = element.offsetWidth;
        const ratio = height / width;
        const canvas = await html2canvas(element, {
          height,
          width,
        });
        const image = canvas.toDataURL("image/png");
        var pageWidth = pdf.internal.pageSize.getWidth();
        let pageHeight = pdf.internal.pageSize.getHeight();
        pageHeight = ratio * pageWidth;
        pdf.addImage(image, "PNG", 0, 0, pageWidth, pageHeight, undefined, "NONE");
      }
      pdf.autoPrint();
      pdf.save(`${project.label} [${projectUnitFloor} FLOOR]`);
    },
  };
};

export const useConstuctionReportByUnitNumberDocumentContext = (elementClassNames: string, filename: string) => {
  return {
    download: async () => {
      const pdf = new jsPDF("portrait", "px", "ledger");
      const elements = document.getElementsByClassName(elementClassNames);
      for (let index = 0; index < elements.length; index++) {
        if (index !== 0) {
          pdf.addPage("ledger", "portrait");
        }
        const element = elements[index] as HTMLElement;
        const height = element.offsetHeight;
        const width = element.offsetWidth;
        const ratio = height / width;
        const canvas = await html2canvas(element, {
          height,
          width,
        });
        const image = canvas.toDataURL("image/png");
        var pageWidth = pdf.internal.pageSize.getWidth();
        let pageHeight = pdf.internal.pageSize.getHeight();
        pageHeight = ratio * pageWidth;
        pdf.addImage(image, "PNG", 0, 0, pageWidth, pageHeight, undefined, "NONE");
      }
      pdf.autoPrint();
      pdf.save(filename);
    },
  };
};

export const useCustomerReportByUnitNumberDocumentContext = (elementClassNames: string, filename: string) => {
  return {
    download: async () => {
      const pdf = new jsPDF("portrait", "px", "ledger");
      const elements = document.getElementsByClassName(elementClassNames);
      for (let index = 0; index < elements.length; index++) {
        if (index !== 0) {
          pdf.addPage("ledger", "portrait");
        }
        const element = elements[index] as HTMLElement;
        const height = element.offsetHeight;
        const width = element.offsetWidth;
        const ratio = height / width;
        const canvas = await html2canvas(element, {
          height,
          width,
        });
        const image = canvas.toDataURL("image/png");
        var pageWidth = pdf.internal.pageSize.getWidth();
        let pageHeight = pdf.internal.pageSize.getHeight();
        pageHeight = ratio * pageWidth;
        pdf.addImage(image, "PNG", 0, 0, pageWidth, pageHeight, undefined, "NONE");
      }
      pdf.autoPrint();
      pdf.save(filename);
    },
  };
};

// export const useCustomerReportByUnitNumberDocumentContextTest = (filename: string) => {
//   const ref = useRef<HTMLDivElement>(null);
//   return {
//     ref,
//     download: useCallback(async () => {
//       const element = ref.current;
//       if (element) {
//         const pdf = new jsPDF({
//           orientation: "portrait",
//           format: "ledger",
//         });
//         pdf.html(element, {
//           callback: (doc) => {
//             doc.save(filename);
//           },
//           x: 0,
//           y: 0,
//           width: 1056,
//         });
//       }
//     }, [ref]),
//   };
// };
