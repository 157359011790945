import "./index.less";
import { FunctionComponent } from "react";
import { CheckPicker, Form, Input, List, SelectPicker } from "rsuite";
import produce from "immer";
import { FormError } from "buildingBlocks";
import { ProjectActivity } from "graphql/__generated__/ProjectActivity";
import { ProjectProductCategory } from "graphql/__generated__/ProjectProductCategory";
import { Provider } from "graphql/__generated__/Provider";
import { ProjectProductsUpdateFormValues } from "types";
import { ProjectProduct } from "graphql/__generated__/ProjectProduct";

type Props = {
  readonly providers: ReadonlyArray<Provider>;
  readonly projectProducts: ReadonlyArray<ProjectProduct>;
  readonly projectProductCategories: ReadonlyArray<ProjectProductCategory>;
  readonly projectActivities: ReadonlyArray<ProjectActivity>;
  readonly errors: string[];
  readonly loading: boolean;
  readonly values: ProjectProductsUpdateFormValues;
  readonly setValues: (values: ProjectProductsUpdateFormValues) => void;
};

export const ProjectProductsUpdateForm: FunctionComponent<Props> = ({ projectProducts, projectProductCategories, providers, projectActivities, errors, loading, values, setValues }) => {
  return (
    <>
      <FormError errors={errors} />
      <Form id="project-products-update-form" fluid>
        {projectProductCategories.map((projectProductCategory) => {
          const products = projectProducts.filter((projectProduct) => projectProductCategory.productIds.includes(projectProduct.id));
          return (
            <div className="project-product-category-group" key={projectProductCategory.id}>
              <h2>{projectProductCategory.name}</h2>
              <List className="project-product-list">
                {products.map((product, index) => {
                  const projectProduct = values.projectProducts.find((x) => x.id === product.id)!;
                  return (
                    <List.Item className="project-product-list-item" key={product.id} index={index + 1}>
                      <div className="project-product">
                        {product.hasThumbnail ? (
                          <img
                            className="project-product-image"
                            alt="Vignette du produit"
                            src={`${process.env.REACT_APP_AZURE_STORAGE_BLOB_SERVICE_URL}/project-products/${product.id}/thumbnail.png`}
                          />
                        ) : (
                          <div className="project-product-image" />
                        )}
                        <div>
                          <h3>{product.variant ?? product.name}</h3>
                          <span className="project-product-description">{product.description ?? "Pas de description."}</span>
                        </div>
                      </div>
                      <div className="project-product-inputs">
                        <div className="activities-input">
                          <Form.Group>
                            <Form.ControlLabel>Activités</Form.ControlLabel>
                            <CheckPicker
                              readOnly={loading}
                              value={projectProduct.activityIds}
                              onChange={(value) =>
                                setValues(
                                  produce(values, (draft) => {
                                    const draftProjectProduct = draft.projectProducts.find((draftProjectProduct) => draftProjectProduct.id === projectProduct.id);
                                    if (draftProjectProduct) {
                                      draftProjectProduct.activityIds = value;
                                    }
                                  })
                                )
                              }
                              data={projectActivities.map((projectActivity) => ({
                                label: projectActivity.label,
                                value: projectActivity.id,
                              }))}
                            />
                          </Form.Group>
                        </div>
                        <div className="divider" />
                        <div className="provider-input">
                          <Form.Group>
                            <Form.ControlLabel>Fournisseur</Form.ControlLabel>
                            <SelectPicker
                              readOnly={loading}
                              value={projectProduct.provider.id}
                              onChange={(value) =>
                                setValues(
                                  produce(values, (draft) => {
                                    const draftProjectProduct = draft.projectProducts.find((draftProjectProduct) => draftProjectProduct.id === projectProduct.id);
                                    if (draftProjectProduct) {
                                      draftProjectProduct.provider.id = value;
                                    }
                                  })
                                )
                              }
                              data={providers.map((provider) => ({
                                label: provider.name,
                                value: provider.id,
                              }))}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.ControlLabel>Modèle du produit</Form.ControlLabel>
                            <Input
                              readOnly={loading}
                              value={projectProduct.provider.productModel ?? undefined}
                              onChange={(value) =>
                                setValues(
                                  produce(values, (draft) => {
                                    const draftProjectProduct = draft.projectProducts.find((draftProjectProduct) => draftProjectProduct.id === projectProduct.id);
                                    if (draftProjectProduct) {
                                      draftProjectProduct.provider.productModel = value === "" ? null : value;
                                    }
                                  })
                                )
                              }
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.ControlLabel>Code du produit</Form.ControlLabel>
                            <Input
                              readOnly={loading}
                              value={projectProduct.provider.productCode ?? undefined}
                              onChange={(value) =>
                                setValues(
                                  produce(values, (draft) => {
                                    const draftProjectProduct = draft.projectProducts.find((draftProjectProduct) => draftProjectProduct.id === projectProduct.id);
                                    if (draftProjectProduct) {
                                      draftProjectProduct.provider.productCode = value === "" ? null : value;
                                    }
                                  })
                                )
                              }
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.ControlLabel>Site Web du produit</Form.ControlLabel>
                            <Input
                              readOnly={loading}
                              value={projectProduct.provider.productUrl ?? undefined}
                              onChange={(value) =>
                                setValues(
                                  produce(values, (draft) => {
                                    const draftProjectProduct = draft.projectProducts.find((draftProjectProduct) => draftProjectProduct.id === projectProduct.id);
                                    if (draftProjectProduct) {
                                      draftProjectProduct.provider.productUrl = value === "" ? null : value;
                                    }
                                  })
                                )
                              }
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </List.Item>
                  );
                })}
              </List>
            </div>
          );
        })}
      </Form>
    </>
  );
};
