import "./index.less";
import { FunctionComponent } from "react";
import { Button, ButtonToolbar, Form, Input } from "rsuite";
import { useFormik } from "formik";
import produce from "immer";
import { ProviderContactInput, ProviderNoteInput } from "graphql/globalTypes";
import { ProviderContactsForm, ProviderNotesForm } from "forms";
import { FormError } from "buildingBlocks";

export type ProviderFormValues = {
  readonly name: string;
  readonly website?: string | null;
  readonly description?: string | null;
  readonly contacts: ReadonlyArray<ProviderContactInput>;
  readonly notes: ReadonlyArray<ProviderNoteInput>;
};

type Props = {
  readonly initialValues: ProviderFormValues;
  readonly errors: string[];
  readonly loading: boolean;
  readonly onSubmit: (values: ProviderFormValues) => Promise<void>;
  readonly onCancel: () => void;
};

export const ProviderForm: FunctionComponent<Props> = ({ initialValues, errors, loading, onSubmit, onCancel }) => {
  const createdAt = new Date().toISOString();
  const { setValues, values } = useFormik<ProviderFormValues>({
    initialValues,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });
  return (
    <>
      <FormError errors={errors} />
      <Form className="form" fluid>
        <Form.Group>
          <Form.ControlLabel>Nom</Form.ControlLabel>
          <Input
            value={values.name}
            placeholder="Contoso"
            onChange={(value) =>
              setValues(
                produce(values, (draft) => {
                  draft.name = value;
                })
              )
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            Site Web <small>(Facultatif)</small>
          </Form.ControlLabel>
          <Input
            value={values.website ?? undefined}
            placeholder="https://contoso.com"
            onChange={(value) =>
              setValues(
                produce(values, (draft) => {
                  draft.website = value === "" ? null : value;
                })
              )
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            Description <small>(Facultative)</small>
          </Form.ControlLabel>
          <Input
            value={values.description ?? undefined}
            onChange={(value) =>
              setValues(
                produce(values, (draft) => {
                  draft.description = value === "" ? null : value;
                })
              )
            }
            as="textarea"
            rows={3}
          />
        </Form.Group>
        <ProviderContactsForm contacts={values.contacts} values={values} setValues={setValues} createdAt={createdAt} />
        <ProviderNotesForm notes={values.notes} values={values} setValues={setValues} createdAt={createdAt} />
        <Form.Group>
          <ButtonToolbar>
            <Button
              loading={loading}
              appearance="primary"
              onClick={() => {
                onSubmit(values);
              }}
            >
              Enregistrer
            </Button>
            <Button appearance="default" onClick={onCancel}>
              Annuler
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};
