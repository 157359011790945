import { FunctionComponent } from "react";
import { ProjectReportSetupElementPanelGroup } from "components";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useProjectContext, useProjectReportSetupContext, useRouting } from "hooks";

export const ProjectReportSetupView: FunctionComponent = () => {
  const { toProjectsView, toProjectView, toProjectReportsView, toProjectReportSetupsView, toProjectReportSetupUpdateView, toProjectReportSetupDeleteView } = useRouting();
  const { project } = useProjectContext();
  const { projectReportSetup } = useProjectReportSetupContext();
  useLayout({
    sidebarActions: [],
    sidebarButtons: [
      {
        label: "Modifier le paramètrage",
        href: toProjectReportSetupUpdateView(project.id, projectReportSetup.id),
        permissions: [[Permission.UPDATE_PROJECT_REPORT_SETUPS]],
      },
      {
        label: "Supprimer le paramètrage",
        href: toProjectReportSetupDeleteView(project.id, projectReportSetup.id),
        permissions: [[Permission.DELETE_PROJECT_REPORT_SETUPS]],
      },
    ],
  });
  return (
    <View
      title={projectReportSetup.name}
      permissions={[[Permission.READ_PROJECT_REPORT_SETUPS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Rapports",
          href: toProjectReportsView(project.id),
        },
        {
          label: "Paramètrages",
          href: toProjectReportSetupsView(project.id),
        },
        {
          label: projectReportSetup.name,
        },
      ]}
    >
      {projectReportSetup.rooms
        .filter(
          (projectReportSetupRoom) => projectReportSetup.elements.filter((projectReportSetupElement) => projectReportSetupElement.projectReportSetupRoomId === projectReportSetupRoom.id).length !== 0
        )
        .map((projectReportSetupRoom) => (
          <ProjectReportSetupElementPanelGroup
            key={projectReportSetupRoom.id}
            projectReportSetupRoomName={projectReportSetupRoom.name}
            projectReportSetupElements={projectReportSetup.elements.filter((projectReportSetupElement) => projectReportSetupElement.projectReportSetupRoomId === projectReportSetupRoom.id)}
          />
        ))}
    </View>
  );
};
