import "./index.less";
import { FunctionComponent } from "react";
import { Input, InputGroup } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";

type Props = {
  readonly placeholder?: string | undefined;
  readonly onChange?: (value: string) => void | undefined;
};

export const SearchInputGroup: FunctionComponent<Props> = ({ placeholder = undefined, onChange = undefined }) => (
  <InputGroup
    className="search-input-group"
    style={{
      width: "250px",
    }}
  >
    <Input size="md" placeholder={placeholder} onChange={onChange} />
    <InputGroup.Addon>
      <SearchIcon />
    </InputGroup.Addon>
  </InputGroup>
);
