import "./index.less";
import { FunctionComponent, useState } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext, useAppContext } from "hooks";
import { ProjectProductsUpdateForm } from "forms";
import { useUpdateProjectProductsMutation } from "graphql/mutations";
import { useList } from "react-use";
import { ProjectProductsUpdateFormValues } from "types";

export const ProjectProductsUpdateView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectProductsView } = useRouting();
  const { providers } = useAppContext();
  const { project, projectProducts, projectActivities, projectProductCategories } = useProjectContext();
  const [errors, { set: setErrors }] = useList<string>();
  const { updateProjectProducts, updateProjectProductsLoading } = useUpdateProjectProductsMutation((projectId) => {
    navigate(toProjectProductsView(projectId));
  }, setErrors);
  const [values, setValues] = useState<ProjectProductsUpdateFormValues>({
    projectProducts: projectProducts.map((projectProduct) => ({
      id: projectProduct.id,
      name: projectProduct.name,
      variant: projectProduct.variant,
      description: projectProduct.description,
      hasThumbnail: projectProduct.hasThumbnail,
      activityIds: [...projectProduct.activityIds],
      provider: {
        id: projectProduct.provider.id,
        productModel: projectProduct.provider.productModel,
        productCode: projectProduct.provider.productCode,
        productUrl: projectProduct.provider.productUrl,
      },
    })),
  });
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      form={{
        loading: updateProjectProductsLoading,
        onSubmit: async () => {
          await updateProjectProducts(project.id, {
            projectProducts: values.projectProducts.map((projectProduct) => ({
              id: projectProduct.id,
              activityIds: projectProduct.activityIds,
              provider: {
                id: projectProduct.provider.id,
                productModel: projectProduct.provider.productModel,
                productCode: projectProduct.provider.productCode,
                productUrl: projectProduct.provider.productUrl,
              },
            })),
          });
        },
        onCancel: () => {
          navigate(toProjectProductsView(project.id));
        },
      }}
      title="Modifier les produits"
      permissions={[[Permission.READ_PROJECT_PRODUCTS, Permission.UPDATE_PROJECT_PRODUCTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Produits",
          href: toProjectProductsView(project.id),
        },
      ]}
    >
      <ProjectProductsUpdateForm
        providers={providers}
        projectProducts={projectProducts}
        projectActivities={projectActivities}
        projectProductCategories={projectProductCategories}
        values={values}
        setValues={setValues}
        errors={errors}
        loading={updateProjectProductsLoading}
      />
    </View>
  );
};
