import "./index.less";
import { FunctionComponent } from "react";
import { ProjectModelElement } from "graphql/__generated__/ProjectModelElement";
import { Input, InputGroup, InputNumber } from "rsuite";
import { ProjectModelElementOption } from "graphql/__generated__/ProjectModelElementOption";
import { useProjectContext } from "hooks";
import produce from "immer";

type Props = {
  readonly projectModelElement: ProjectModelElement;
  readonly projectModelElementOption: ProjectModelElementOption;
  readonly setValues:
    | ((values: {
        readonly name: string;
        readonly projectModelElementOptionPrices: {
          readonly projectModelElementOptionId: string;
          readonly amount: number;
        }[];
      }) => void)
    | undefined;
  readonly values: {
    readonly name: string;
    readonly projectModelElementOptionPrices: {
      readonly projectModelElementOptionId: string;
      readonly amount: number;
    }[];
  };
};

export const ProjectModelElementOptionPriceListItem: FunctionComponent<Props> = ({ values, setValues, projectModelElement, projectModelElementOption }) => {
  const { projectProducts } = useProjectContext();
  const projectProduct = projectProducts.find((projectProduct) => projectProduct.id === projectModelElementOption.productId)!;
  const amount =
    values.projectModelElementOptionPrices.find((projectModelElementOptionPrice) => projectModelElementOptionPrice.projectModelElementOptionId === projectModelElementOption.id)?.amount ?? 0;
  return (
    <div className="project-model-element-option-price-list-item">
      <label>{projectModelElement.isBoolean ? projectModelElement.name : projectProduct.name}</label>
      {setValues === undefined ? (
        <Input plaintext size="xs" value={String(amount) + "$"} />
      ) : (
        <InputGroup size="xs">
          <InputNumber
            min={0}
            size="xs"
            value={amount}
            onChange={(value) => {
              if (setValues) {
                setValues(
                  produce(values, (draft) => {
                    const projectModelElementOptionPrice = draft.projectModelElementOptionPrices.find(
                      (projectModelElementOptionPrice) => projectModelElementOptionPrice.projectModelElementOptionId === projectModelElementOption.id
                    );
                    if (projectModelElementOptionPrice) {
                      projectModelElementOptionPrice.amount = Number(value);
                    }
                  })
                );
              }
            }}
          />
          <InputGroup.Addon>$</InputGroup.Addon>
        </InputGroup>
      )}
    </div>
  );
};
