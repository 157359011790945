import { FunctionComponent, PropsWithChildren } from "react";
import { Auth0Provider as Provider } from "@auth0/auth0-react";
import { useRouting } from "hooks";

type Props = PropsWithChildren;

export const Auth0Provider: FunctionComponent<Props> = ({ children }) => {
  const { navigate } = useRouting();
  return (
    <Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
      onRedirectCallback={(appState) => {
        navigate(appState?.returnTo || window.location.pathname);
      }}
    >
      {children}
    </Provider>
  );
};
