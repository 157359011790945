import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  PROJECT_ACTIVITY_FRAGMENT,
  PROJECT_MODEL_FRAGMENT,
  PROJECT_PRODUCT_CATEGORY_FRAGMENT,
  PROJECT_PRODUCT_FRAGMENT,
  PROJECT_UNIT_FRAGMENT,
  CUSTOMER_FRAGMENT,
  PROVIDER_FRAGMENT,
  PROJECT_FRAGMENT,
  COUNTRY_FRAGMENT,
  PROJECT_CONTRACT_FRAGMENT,
  PROJECT_REPORT_SETUP_FRAGMENT,
  USER_FRAGMENT,
  ROLE_FRAGMENT,
} from "./fragments";
import { Customer } from "./__generated__/Customer";
import { FetchProviders, FetchProvidersVariables } from "./__generated__/FetchProviders";
import { FetchCustomers } from "./__generated__/FetchCustomers";
import { Provider } from "./__generated__/Provider";
import { FetchProjects, FetchProjects_projects } from "./__generated__/FetchProjects";
import { Project } from "./__generated__/Project";
import { FindProject, FindProjectVariables } from "./__generated__/FindProject";
import { ProjectActivity } from "./__generated__/ProjectActivity";
import { ProjectModel } from "./__generated__/ProjectModel";
import { ProjectProductCategory } from "./__generated__/ProjectProductCategory";
import { ProjectProduct } from "./__generated__/ProjectProduct";
import { ProjectUnit } from "./__generated__/ProjectUnit";
import { FetchCountries } from "./__generated__/FetchCountries";
import { FetchUsers } from "./__generated__/FetchUsers";
import { FetchRoles } from "./__generated__/FetchRoles";
import { Country } from "./__generated__/Country";
import { ProjectContract } from "./__generated__/ProjectContract";
import { ProjectReportSetup } from "./__generated__/ProjectReportSetup";
import { User } from "./__generated__/User";
import { Role } from "./__generated__/Role";

const FETCH_USERS_QUERY = gql`
  ${USER_FRAGMENT}
  query FetchUsers {
    users: users {
      ...User
    }
  }
`;

export const useFetchUsersQuery = (onCompleted: (users: User[]) => void) => {
  return useQuery<FetchUsers>(FETCH_USERS_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      onCompleted([...data.users]);
    },
  });
};

const FETCH_ROLES_QUERY = gql`
  ${ROLE_FRAGMENT}
  query FetchRoles {
    roles: roles {
      ...Role
    }
  }
`;

export const useFetchRolesQuery = (onCompleted: (roles: Role[]) => void) => {
  return useQuery<FetchRoles>(FETCH_ROLES_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      onCompleted([...data.roles]);
    },
  });
};

const FETCH_PROVIDERS_QUERY = gql`
  ${PROVIDER_FRAGMENT}
  query FetchProviders($take: Int, $skip: Int) {
    providers: providers(take: $take, skip: $skip) {
      ...Provider
    }
  }
`;

export const useFetchProvidersQuery = (onCompleted: (providers: Provider[]) => void) => {
  return useQuery<FetchProviders, FetchProvidersVariables>(FETCH_PROVIDERS_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      onCompleted([...data.providers]);
    },
  });
};

const FETCH_CUSTOMERS_QUERY = gql`
  ${CUSTOMER_FRAGMENT}
  query FetchCustomers {
    customers: customers {
      ...Customer
    }
  }
`;

export const useFetchCustomersQuery = (onCompleted: (customers: Customer[]) => void) => {
  return useQuery<FetchCustomers>(FETCH_CUSTOMERS_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      onCompleted([...data.customers]);
    },
  });
};

const FECTH_COUNTRIES_QUERY = gql`
  ${COUNTRY_FRAGMENT}
  query FetchCountries {
    countries: countries {
      ...Country
    }
  }
`;

export const useFetchCountriesQuery = (onCompleted: (countries: Country[]) => void) => {
  return useQuery<FetchCountries>(FECTH_COUNTRIES_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      onCompleted([...data.countries]);
    },
  });
};

const FECTH_PROJECTS_QUERY = gql`
  ${PROJECT_FRAGMENT}
  query FetchProjects($take: Int, $skip: Int) {
    projects: projects(take: $take, skip: $skip) {
      ...Project
    }
  }
`;

export const useFetchProjectsQuery = (onCompleted: (projects: FetchProjects_projects[]) => void) => {
  return useQuery<FetchProjects>(FECTH_PROJECTS_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      onCompleted([...data.projects]);
    },
  });
};

const FIND_PROJECT_QUERY = gql`
  ${PROJECT_FRAGMENT}
  ${PROJECT_ACTIVITY_FRAGMENT}
  ${PROJECT_MODEL_FRAGMENT}
  ${PROJECT_PRODUCT_CATEGORY_FRAGMENT}
  ${PROJECT_PRODUCT_FRAGMENT}
  ${PROJECT_UNIT_FRAGMENT}
  ${PROJECT_REPORT_SETUP_FRAGMENT}
  ${PROJECT_CONTRACT_FRAGMENT}
  query FindProject($projectId: ID!) {
    project: project(projectId: $projectId) {
      ...Project
      activities {
        ...ProjectActivity
      }
      models {
        ...ProjectModel
      }
      productCategories {
        ...ProjectProductCategory
      }
      products {
        ...ProjectProduct
      }
      units {
        ...ProjectUnit
      }
      contracts {
        ...ProjectContract
      }
      reportSetups {
        ...ProjectReportSetup
      }
    }
  }
`;

export const useFindProjectLazyQuery = (
  onCompleted: (
    project: Project,
    activities: ProjectActivity[],
    models: ProjectModel[],
    productCategories: ProjectProductCategory[],
    products: ProjectProduct[],
    units: ProjectUnit[],
    contracts: ProjectContract[],
    reportSetups: ProjectReportSetup[]
  ) => void
) => {
  return useLazyQuery<FindProject, FindProjectVariables>(FIND_PROJECT_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data.project) {
        onCompleted(
          {
            __typename: "Project",
            id: data.project.id,
            createdAt: data.project.createdAt,
            updatedAt: data.project.updatedAt,
            code: data.project.code,
            name: data.project.name,
            label: data.project.label,
            description: data.project.description,
            customOptionUnitPrice: data.project.customOptionUnitPrice,
          },
          [...data.project.activities],
          [...data.project.models],
          [...data.project.productCategories],
          [...data.project.products],
          [...data.project.units],
          [...data.project.contracts],
          [...data.project.reportSetups]
        );
      }
    },
  });
};
