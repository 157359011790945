import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useCustomerContext, useRouting } from "hooks";
import { useList } from "react-use";
import { useDeleteCustomerMutation } from "graphql/mutations";
import { DeleteForm } from "forms";

export const CustomerDeleteView: FunctionComponent = () => {
  const { navigate, toCustomersView, toCustomerView } = useRouting();
  const { customer } = useCustomerContext();
  const [errors, { set: setErrors }] = useList<string>([]);
  const { deleteCustomer, deleteCustomerLoading } = useDeleteCustomerMutation(() => {
    navigate(toCustomersView());
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Supprimer le client"
      permissions={[[Permission.DELETE_CUSTOMERS]]}
      breadcrumbItems={[
        {
          label: "Clients",
          href: toCustomersView(),
        },
        {
          label: customer.fullName,
          href: toCustomerView(customer.id),
        },
      ]}
    >
      <DeleteForm
        label="Nom du client"
        value={customer.fullName}
        confirm="Veuillez saisir le nom du client pour confirmer."
        errors={errors}
        loading={deleteCustomerLoading}
        onSubmit={async () => {
          await deleteCustomer(customer.id);
        }}
        onCancel={() => {
          navigate(toCustomerView(customer.id));
        }}
      >
        Ce client sera définitivement supprimé. <strong>Cette opération est IRRÉVERSIBLE.</strong>
      </DeleteForm>
    </View>
  );
};
