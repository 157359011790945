import "./index.less";
import { FunctionComponent, useState } from "react";
import { ListItemActions, SearchInputGroup, View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useAppContext, useRouting } from "hooks";
import { List, FlexboxGrid } from "rsuite";

export const CustomersView: FunctionComponent = () => {
  const { searchParams, toCustomerView, toCustomerCreateView, toCustomerUpdateView, toCustomerDeleteView } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  const { customers } = useAppContext();
  useLayout({
    sidebarActions: [],
    sidebarButtons: [
      {
        label: "Créer un client",
        href: toCustomerCreateView(),
        permissions: [[Permission.CREATE_CUSTOMERS]],
      },
    ],
  });
  return (
    <View
      id="customers-view"
      permissions={[[Permission.READ_CUSTOMERS]]}
      breadcrumbItems={[
        {
          label: "Clients",
        },
      ]}
    >
      <SearchInputGroup placeholder="Recherche par nom complet, courriel et téléphone" onChange={setSearch} />
      <List className="customer-list">
        {customers
          .filter(
            (customer) =>
              customer.fullName.toSearchFormat().includes(search.toSearchFormat()) ||
              customer.email.toSearchFormat().includes(search.toSearchFormat()) ||
              customer.phone.toSearchFormat().includes(search.toSearchFormat())
          )
          .map((customer, index) => {
            return (
              <List.Item key={customer.id} index={index + 1}>
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={7}>
                    <h3>{customer.fullName}</h3>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={7}>
                    <span className="subtile">Courriel</span>
                    <div className="customer-email">
                      <a href={`mailto:${customer.email}`}>{customer.email}</a>
                    </div>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={8}>
                    <span className="subtile">Téléphone</span>
                    <div className="customer-phone">
                      <a href={`tel:${customer.phone}`}>{customer.phone.toPhoneNumberFormat()}</a>
                    </div>
                  </FlexboxGrid.Item>
                  <ListItemActions
                    colspan={2}
                    actions={[
                      {
                        href: toCustomerView(customer.id),
                        content: "Voir le client",
                        permissions: [[Permission.READ_CUSTOMERS]],
                        show: true,
                      },
                      {
                        href: toCustomerUpdateView(customer.id),
                        content: "Modifier le client",
                        permissions: [[Permission.UPDATE_CUSTOMERS]],
                        show: true,
                      },
                      {
                        href: toCustomerDeleteView(customer.id),
                        content: "Supprimer le client",
                        permissions: [[Permission.DELETE_CUSTOMERS]],
                        show: customer.contractIds.length === 0,
                      },
                    ]}
                  />
                </FlexboxGrid>
              </List.Item>
            );
          })}
      </List>
    </View>
  );
};
