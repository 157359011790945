import "./index.less";
import { FunctionComponent, useState } from "react";
import { Link } from "react-router-dom";
import { SearchInputGroup, View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useProjectContext, useAppContext, useAuth, useRouting } from "hooks";
import { Col, Tag, Panel, Row, Whisper, Dropdown, IconButton, Popover } from "rsuite";
import MoreIcon from "@rsuite/icons/legacy/More";
import { ProjectModelTags } from "components/ProjectModelTags";

export const ProjectUnitsView: FunctionComponent = () => {
  const {
    navigate,
    searchParams,
    toCustomerView,
    toProjectModelView,
    toProjectView,
    toProjectsView,
    toProjectContractView,
    toProjectContractCancelView,
    toProjectContractAssociateView,
    toProjectUnitsUploadView,
    toProjectUnitsUpdateView,
    toProjectUnitCreateView,
    toProjectUnitUpdateView,
    toProjectUnitDeleteView,
  } = useRouting();
  const { hasPermissions } = useAuth();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  const { customers } = useAppContext();
  const { project, projectUnits, projectModels, projectContracts } = useProjectContext();
  useLayout({
    sidebarActions: [],
    sidebarButtons: [
      {
        label: "Créer une unité",
        href: toProjectUnitCreateView(project.id),
        permissions: [[Permission.CREATE_PROJECT_UNITS]],
        enabled: projectUnits.length !== 0,
      },
      {
        label: "Modifier les unités",
        href: toProjectUnitsUpdateView(project.id),
        permissions: [[Permission.UPDATE_PROJECT_UNITS]],
        enabled: projectUnits.length !== 0,
      },
      {
        label: "Téléverser des unités",
        href: toProjectUnitsUploadView(project.id),
        permissions: [[Permission.UPLOAD_PROJECT_UNITS]],
        enabled: projectUnits.length === 0,
      },
    ],
  });
  return (
    <View
      id="project-units-view"
      permissions={[[Permission.READ_PROJECT_UNITS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Unités",
        },
      ]}
    >
      <SearchInputGroup placeholder="Recherche par étiquette" onChange={setSearch} />
      <Row className="project-unit-row">
        {projectUnits
          .filter((projectUnit) => projectUnit.label.toSearchFormat().includes(search.toSearchFormat()))
          .map((projectUnit) => {
            const customer = customers.find((customer) => customer.id === projectUnit.customerId);
            const projectContract = projectContracts.find((projectContract) => projectContract.id === projectUnit.currentProjectContractId);
            const projectModel = projectModels.find((projectModel) => projectModel.id === (projectContract !== undefined ? projectContract.projectModelId : projectUnit.projectModelId));
            return (
              <Col key={projectUnit.id} lg={8} md={12} sm={24}>
                <Panel
                  className="panel-project-unit"
                  shaded
                  header={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {projectUnit.customerId ? (
                            <Tag size="sm" color="red">
                              Indisponible
                            </Tag>
                          ) : (
                            <Tag size="sm" color="green">
                              Disponible
                            </Tag>
                          )}
                        </div>
                        <h3>{projectUnit.label}</h3>
                      </div>
                      <div>
                        <Whisper
                          placement="autoVerticalStart"
                          trigger="click"
                          speaker={({ onClose, left, top, className }, ref) => {
                            return (
                              <Popover ref={ref} className={className} style={{ left, top }} full>
                                <Dropdown.Menu
                                  onSelect={() => {
                                    onClose();
                                  }}
                                >
                                  {hasPermissions([Permission.READ_PROJECT_CONTRACTS]) && projectUnit.currentProjectContractId && (
                                    <Dropdown.Item
                                      eventKey={1}
                                      onClick={() => {
                                        navigate(toProjectContractView(project.id, projectUnit.currentProjectContractId ?? ""));
                                      }}
                                    >
                                      Voir le contrat
                                    </Dropdown.Item>
                                  )}
                                  {hasPermissions([Permission.UPDATE_PROJECT_UNITS]) && (
                                    <Dropdown.Item
                                      eventKey={2}
                                      onClick={() => {
                                        navigate(toProjectUnitUpdateView(project.id, projectUnit.id));
                                      }}
                                    >
                                      Modifier l'unité
                                    </Dropdown.Item>
                                  )}
                                  {hasPermissions([Permission.DELETE_PROJECT_UNITS]) && projectUnit.projectContractIds.length === 0 && (
                                    <Dropdown.Item
                                      eventKey={3}
                                      onClick={() => {
                                        navigate(toProjectUnitDeleteView(project.id, projectUnit.id));
                                      }}
                                    >
                                      Supprimer l'unité
                                    </Dropdown.Item>
                                  )}
                                  {(hasPermissions([Permission.CANCEL_PROJECT_CONTRACTS]) || hasPermissions([Permission.CREATE_PROJECT_CONTRACTS])) && <Dropdown.Item divider />}
                                  {hasPermissions([Permission.CANCEL_PROJECT_CONTRACTS]) && projectUnit.currentProjectContractId && (
                                    <Dropdown.Item
                                      eventKey={4}
                                      onClick={() => {
                                        navigate(toProjectContractCancelView(project.id, projectUnit.currentProjectContractId ?? ""));
                                      }}
                                    >
                                      Annuler le contrat
                                    </Dropdown.Item>
                                  )}
                                  {hasPermissions([Permission.CREATE_PROJECT_CONTRACTS]) && !projectUnit.currentProjectContractId && (
                                    <Dropdown.Item
                                      eventKey={4}
                                      onClick={() => {
                                        navigate(
                                          toProjectContractAssociateView(project.id, {
                                            projectUnitId: projectUnit.id,
                                          })
                                        );
                                      }}
                                    >
                                      Associer un contrat
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Popover>
                            );
                          }}
                        >
                          <IconButton size="sm" appearance="primary" icon={<MoreIcon />} />
                        </Whisper>
                      </div>
                    </div>
                  }
                  style={{
                    background: "#f7f7fa",
                    marginBottom: "15px",
                  }}
                >
                  <dl>
                    <dt>En personnalisation</dt>
                    <dd>{projectContract ? (projectContract.isCustomized ? "Oui" : "Non") : "N/A"}</dd>
                  </dl>
                  <dl>
                    <dt>Client</dt>
                    <dd>{customer === undefined ? "N/A" : <Link to={toCustomerView(customer.id)}>{customer.fullName}</Link>}</dd>
                  </dl>
                  <dl>
                    <dt>Modèle</dt>
                    <dd>{projectModel === undefined ? "N/A" : <Link to={toProjectModelView(project.id, projectModel.id)}>{projectModel.name}</Link>}</dd>
                  </dl>
                  <dl>
                    <dt>Tags</dt>
                    <dd>{projectModel === undefined ? "N/A" : <ProjectModelTags projectModelTags={projectModel?.tags ?? []} />}</dd>
                  </dl>
                </Panel>
              </Col>
            );
          })}
      </Row>
    </View>
  );
};
