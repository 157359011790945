import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useAppContext, useLayout, useRouting } from "hooks";
import { CustomerForm } from "forms";
import { useCreateCustomerMutation } from "graphql/mutations";
import { useList } from "react-use";
import { PersistentStorageOperation } from "graphql/globalTypes";

export const CustomerCreateView: FunctionComponent = () => {
  const { navigate, toCustomerView, toCustomersView } = useRouting();
  const { countries } = useAppContext();
  const [errors, { set: setErrors }] = useList<string>();
  const { createCustomer, createCustomerLoading } = useCreateCustomerMutation((customerId) => {
    navigate(toCustomerView(customerId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Créer un client"
      permissions={[[Permission.CREATE_CUSTOMERS]]}
      breadcrumbItems={[
        {
          label: "Clients",
          href: toCustomersView(),
        },
      ]}
    >
      <CustomerForm
        _operation={PersistentStorageOperation.CREATE}
        countries={countries}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          language: "",
          gender: "",
          address: {
            line: "",
            city: "",
            state: "",
            country: "",
            postalCode: "",
          },
        }}
        errors={errors}
        loading={createCustomerLoading}
        onSubmit={async (values) => {
          await createCustomer({
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            language: values.language,
            gender: values.gender,
            address: {
              line: values.address.line,
              city: values.address.city,
              state: values.address.state,
              country: values.address.country,
              postalCode: values.address.postalCode,
            },
          });
        }}
        onCancel={() => {
          navigate(toCustomersView());
        }}
      />
    </View>
  );
};
