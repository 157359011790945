import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext, useProjectProductContext } from "hooks";
import { useList } from "react-use";
import { useDeleteProjectProductMutation } from "graphql/mutations";
import { DeleteForm } from "forms";

export const ProjectProductDeleteView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectProductsView, toProjectProductView } = useRouting();
  const { project } = useProjectContext();
  const { projectProduct } = useProjectProductContext();
  const [errors, { set: setErrors }] = useList<string>([]);
  const { deleteProjectProduct, deleteProjectProductLoading } = useDeleteProjectProductMutation((projectId) => {
    navigate(toProjectProductsView(projectId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Supprimer le produit"
      permissions={[[Permission.READ_PROJECT_PRODUCTS, Permission.DELETE_PROJECT_PRODUCTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Produits",
          href: toProjectProductsView(project.id),
        },
        {
          label: projectProduct.name,
          href: toProjectProductView(project.id, projectProduct.id),
        },
      ]}
    >
      <DeleteForm
        label="Nom du produit"
        value={projectProduct.name}
        confirm="Veuillez saisir le nom du produit pour confirmer."
        errors={errors}
        loading={deleteProjectProductLoading}
        onSubmit={async () => {
          await deleteProjectProduct(project.id, projectProduct.id);
        }}
        onCancel={() => {
          navigate(toProjectProductView(project.id, projectProduct.id));
        }}
      >
        Le produit sera définitivement supprimé. <strong>Cette opération est IRRÉVERSIBLE.</strong>
      </DeleteForm>
    </View>
  );
};
