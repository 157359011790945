import { gql, useMutation } from "@apollo/client";
import { useAppContext, useNotificationContext, useProjectContext } from "hooks";
import { useCallback } from "react";
import {
  PROVIDER_FRAGMENT,
  ERROR_FRAGMENT,
  CUSTOMER_FRAGMENT,
  PROJECT_FRAGMENT,
  PROJECT_ACTIVITY_FRAGMENT,
  PROJECT_UNIT_FRAGMENT,
  PROJECT_MODEL_FRAGMENT,
  PROJECT_PRODUCT_CATEGORY_FRAGMENT,
  PROJECT_PRODUCT_FRAGMENT,
  PROJECT_CONTRACT_FRAGMENT,
  PROJECT_MODEL_VIBE_FRAGMENT,
  PROJECT_REPORT_SETUP_FRAGMENT,
  USER_FRAGMENT,
} from "./fragments";
import {
  CreateCustomerInput,
  CreateProjectActivityInput,
  CreateProjectInput,
  UpdateCustomerInput,
  UpdateProjectActivityInput,
  UpdateProjectInput,
  UploadProjectModelInput,
  UploadProjectUnitsInput,
  UpdateProjectUnitInput,
  CreateProjectUnitInput,
  UpdateProjectProductInput,
  UpdateProjectProductsInput,
  UpdateProjectModelInput,
  CreateProviderInput,
  UpdateProviderInput,
  CreateProjectModelVibeInput,
  UpdateProjectModelVibeInput,
  CreateProjectReportSetupInput,
  UpdateProjectReportSetupInput,
  CreateProjectContractChoiceInput,
  CreateProjectContractPaymentInput,
  UpdateProjectContractPaymentInput,
  UpdateProjectContractRequestsInput,
  UpdateProjectModelReportSetupRelationshipsInput,
  CreateProjectContractInput,
  UpdateUserAccessInput,
  TemplateName,
  UpdateProjectUnitsInput,
  ResendCustomerCredentialsInput,
} from "./globalTypes";
import { CreateCustomer, CreateCustomerVariables } from "./__generated__/CreateCustomer";
import { CreateProject, CreateProjectVariables } from "./__generated__/CreateProject";
import { CreateProjectActivity, CreateProjectActivityVariables } from "./__generated__/CreateProjectActivity";
import { CreateProjectUnit, CreateProjectUnitVariables } from "./__generated__/CreateProjectUnit";
import { DeleteCustomer, DeleteCustomerVariables } from "./__generated__/DeleteCustomer";
import { DeleteProject, DeleteProjectVariables } from "./__generated__/DeleteProject";
import { DeleteProjectActivity, DeleteProjectActivityVariables } from "./__generated__/DeleteProjectActivity";
import { DeleteProjectModel, DeleteProjectModelVariables } from "./__generated__/DeleteProjectModel";
import { DeleteProjectModelVibe, DeleteProjectModelVibeVariables } from "./__generated__/DeleteProjectModelVibe";
import { DeleteProjectProduct, DeleteProjectProductVariables } from "./__generated__/DeleteProjectProduct";
import { DeleteProjectUnit, DeleteProjectUnitVariables } from "./__generated__/DeleteProjectUnit";
import { DeleteProvider, DeleteProviderVariables } from "./__generated__/DeleteProvider";
import { UpdateCustomer, UpdateCustomerVariables } from "./__generated__/UpdateCustomer";
import { UpdateProject, UpdateProjectVariables } from "./__generated__/UpdateProject";
import { UpdateProjectActivity, UpdateProjectActivityVariables } from "./__generated__/UpdateProjectActivity";
import { UpdateProjectModel, UpdateProjectModelVariables } from "./__generated__/UpdateProjectModel";
import { UpdateProjectProduct, UpdateProjectProductVariables } from "./__generated__/UpdateProjectProduct";
import { UpdateProjectUnit, UpdateProjectUnitVariables } from "./__generated__/UpdateProjectUnit";
import { UploadProjectModel, UploadProjectModelVariables } from "./__generated__/UploadProjectModel";
import { UploadProjectUnits, UploadProjectUnitsVariables } from "./__generated__/UploadProjectUnits";
import { CreateProvider, CreateProviderVariables } from "./__generated__/CreateProvider";
import { UpdateProvider, UpdateProviderVariables } from "./__generated__/UpdateProvider";
import { CreateProjectModelVibe, CreateProjectModelVibeVariables } from "./__generated__/CreateProjectModelVibe";
import { UpdateProjectModelVibe, UpdateProjectModelVibeVariables } from "./__generated__/UpdateProjectModelVibe";
import { PublishProjectModelVibe, PublishProjectModelVibeVariables } from "./__generated__/PublishProjectModelVibe";
import { PublishProjectModel, PublishProjectModelVariables } from "./__generated__/PublishProjectModel";
import { CreateProjectReportSetup, CreateProjectReportSetupVariables } from "./__generated__/CreateProjectReportSetup";
import { UpdateProjectReportSetup, UpdateProjectReportSetupVariables } from "./__generated__/UpdateProjectReportSetup";
import { DeleteProjectReportSetup, DeleteProjectReportSetupVariables } from "./__generated__/DeleteProjectReportSetup";
import { CancelProjectContract, CancelProjectContractVariables } from "./__generated__/CancelProjectContract";
import { CreateProjectContract, CreateProjectContractVariables } from "./__generated__/CreateProjectContract";
import { CreateProjectContractChoice, CreateProjectContractChoiceVariables } from "./__generated__/CreateProjectContractChoice";
import { CreateProjectContractPayment, CreateProjectContractPaymentVariables } from "./__generated__/CreateProjectContractPayment";
import { DeleteProjectContractPayment, DeleteProjectContractPaymentVariables } from "./__generated__/DeleteProjectContractPayment";
import { UpdateProjectContractPayment, UpdateProjectContractPaymentVariables } from "./__generated__/UpdateProjectContractPayment";
import { UpdateProjectContractRequests, UpdateProjectContractRequestsVariables } from "./__generated__/UpdateProjectContractRequests";
import { UpdateProjectModelReportSetupRelationships, UpdateProjectModelReportSetupRelationshipsVariables } from "./__generated__/UpdateProjectModelReportSetupRelationships";
import { UpdateUserAccess, UpdateUserAccessVariables } from "./__generated__/UpdateUserAccess";
import { DownloadProjectContract, DownloadProjectContractVariables } from "./__generated__/DownloadProjectContract";
import { DownloadProjectModelUnityWebGL, DownloadProjectModelUnityWebGLVariables } from "./__generated__/DownloadProjectModelUnityWebGL";
import { DownloadTemplate, DownloadTemplateVariables } from "./__generated__/DownloadTemplate";
import { UpdateProjectProducts, UpdateProjectProductsVariables } from "./__generated__/UpdateProjectProducts";
import { UpdateProjectUnits, UpdateProjectUnitsVariables } from "./__generated__/UpdateProjectUnits";
import { ResendCustomerCredentials, ResendCustomerCredentialsVariables } from "./__generated__/ResendCustomerCredentials";

// Project
const CREATE_PROJECT_MUTATION = gql`
  ${PROJECT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateProject($input: CreateProjectInput!) {
    payload: createProject(input: $input) {
      project {
        ...Project
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateProjectMutation = (onCompleted: (projectId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProject } = useAppContext();
  const [createProjectMutation, { loading: createProjectLoading }] = useMutation<CreateProject, CreateProjectVariables>(CREATE_PROJECT_MUTATION);
  const createProject = useCallback(
    async (input: CreateProjectInput) => {
      await createProjectMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { project, errors } = payload;
          if (project) {
            upsertProject(project);
            onCompleted(project.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [createProjectMutation, onCompleted, onError, upsertProject, pushErrors]
  );
  return { createProject, createProjectLoading };
};

const UPDATE_PROJECT_MUTATION = gql`
  ${PROJECT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateProject($projectId: ID!, $input: UpdateProjectInput!) {
    payload: updateProject(projectId: $projectId, input: $input) {
      project {
        ...Project
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateProjectMutation = (onCompleted: (projectId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProject } = useAppContext();
  const [updateProjectMutation, { loading: updateProjectLoading }] = useMutation<UpdateProject, UpdateProjectVariables>(UPDATE_PROJECT_MUTATION);
  const updateProject = useCallback(
    async (projectId: string, input: UpdateProjectInput) => {
      await updateProjectMutation({
        variables: {
          projectId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { project, errors } = payload;
          if (project) {
            upsertProject(project);
            onCompleted(project.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [updateProjectMutation, onCompleted, onError, upsertProject, pushErrors]
  );
  return { updateProject, updateProjectLoading };
};

const DELETE_PROJECT_MUTATION = gql`
  ${PROVIDER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation DeleteProject($projectId: ID!) {
    payload: deleteProject(projectId: $projectId) {
      projectId
      providers {
        ...Provider
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteProjectMutation = (onCompleted: (projectId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { removeProject, upsertProvider } = useAppContext();
  const [deleteProjectMutation, { loading: deleteProjectLoading }] = useMutation<DeleteProject, DeleteProjectVariables>(DELETE_PROJECT_MUTATION);
  const deleteProject = useCallback(
    async (projectId: string) => {
      await deleteProjectMutation({
        variables: {
          projectId,
        },
        onCompleted: ({ payload }) => {
          const { projectId, providers, errors } = payload;
          if (projectId && providers) {
            removeProject(projectId);
            providers.forEach((provider) => {
              upsertProvider(provider);
            });
            onCompleted(projectId);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [deleteProjectMutation, onCompleted, onError, removeProject, upsertProvider, pushErrors]
  );
  return { deleteProject, deleteProjectLoading };
};

// ProjectActivity
const CREATE_PROJECT_ACTIVITY_MUTATION = gql`
  ${PROJECT_ACTIVITY_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateProjectActivity($projectId: ID!, $input: CreateProjectActivityInput!) {
    payload: createProjectActivity(projectId: $projectId, input: $input) {
      projectId
      projectActivity {
        ...ProjectActivity
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateProjectActivityMutation = (onCompleted: (projectId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProjectActivity } = useProjectContext();
  const [createProjectActivityMutation, { loading: createProjectActivityLoading }] = useMutation<CreateProjectActivity, CreateProjectActivityVariables>(CREATE_PROJECT_ACTIVITY_MUTATION);
  const createProjectActivity = useCallback(
    async (projectId: string, input: CreateProjectActivityInput) => {
      await createProjectActivityMutation({
        variables: {
          projectId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectActivity, errors } = payload;
          if (projectId && projectActivity) {
            upsertProjectActivity(projectId, projectActivity);
            onCompleted(projectId);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [createProjectActivityMutation, onCompleted, onError, upsertProjectActivity, pushErrors]
  );
  return { createProjectActivity, createProjectActivityLoading };
};

const UPDATE_PROJECT_ACTIVITY_MUTATION = gql`
  ${PROJECT_ACTIVITY_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateProjectActivity($projectId: ID!, $projectActivityId: ID!, $input: UpdateProjectActivityInput!) {
    payload: updateProjectActivity(projectId: $projectId, projectActivityId: $projectActivityId, input: $input) {
      projectId
      projectActivity {
        ...ProjectActivity
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateProjectActivityMutation = (onCompleted: (projectId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProjectActivity } = useProjectContext();
  const [updateProjectActivityMutation, { loading: updateProjectActivityLoading }] = useMutation<UpdateProjectActivity, UpdateProjectActivityVariables>(UPDATE_PROJECT_ACTIVITY_MUTATION);
  const updateProjectActivity = useCallback(
    async (projectId: string, projectActivityId: string, input: UpdateProjectActivityInput) => {
      await updateProjectActivityMutation({
        variables: {
          projectId,
          projectActivityId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectActivity, errors } = payload;
          if (projectId && projectActivity) {
            upsertProjectActivity(projectId, projectActivity);
            onCompleted(projectId);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [updateProjectActivityMutation, onCompleted, onError, upsertProjectActivity, pushErrors]
  );
  return { updateProjectActivity, updateProjectActivityLoading };
};

const DELETE_PROJECT_ACTIVITY_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DeleteProjectActivity($projectId: ID!, $projectActivityId: ID!) {
    payload: deleteProjectActivity(projectId: $projectId, projectActivityId: $projectActivityId) {
      projectId
      projectActivityId
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteProjectActivityMutation = (onCompleted: (projectId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { removeProjectActivity } = useProjectContext();
  const [deleteProjectActivityMutation, { loading: deleteProjectActivityLoading }] = useMutation<DeleteProjectActivity, DeleteProjectActivityVariables>(DELETE_PROJECT_ACTIVITY_MUTATION);
  const deleteProjectActivity = useCallback(
    async (projectId: string, projectActivityId: string) => {
      await deleteProjectActivityMutation({
        variables: {
          projectId,
          projectActivityId,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectActivityId, errors } = payload;
          if (projectId && projectActivityId) {
            removeProjectActivity(projectId, projectActivityId);
            onCompleted(projectId);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [deleteProjectActivityMutation, onCompleted, onError, removeProjectActivity, pushErrors]
  );
  return { deleteProjectActivity, deleteProjectActivityLoading };
};

// ProjectModel
export const UPDATE_PROJECT_MODEL_MUTATION = gql`
  ${PROJECT_MODEL_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateProjectModel($projectId: ID!, $projectModelId: ID!, $input: UpdateProjectModelInput!) {
    payload: updateProjectModel(projectId: $projectId, projectModelId: $projectModelId, input: $input) {
      projectId
      projectModel {
        ...ProjectModel
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateProjectModelMutation = (onCompleted: (projectId: string, projectModelId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProjectModel } = useProjectContext();
  const [updateProjectModelMutation, { loading: updateProjectModelLoading }] = useMutation<UpdateProjectModel, UpdateProjectModelVariables>(UPDATE_PROJECT_MODEL_MUTATION);
  const updateProjectModel = useCallback(
    async (projectId: string, projectModelId: string, input: UpdateProjectModelInput) => {
      await updateProjectModelMutation({
        variables: {
          projectId,
          projectModelId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectModel, errors } = payload;
          if (projectId && projectModel) {
            upsertProjectModel(projectId, projectModel);
            onCompleted(projectId, projectModel.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [updateProjectModelMutation, onCompleted, onError, upsertProjectModel, pushErrors]
  );
  return { updateProjectModel, updateProjectModelLoading };
};

export const UPLOAD_PROJECT_MODEL_MUTATION = gql`
  ${PROJECT_MODEL_FRAGMENT}
  ${PROJECT_PRODUCT_CATEGORY_FRAGMENT}
  ${PROJECT_PRODUCT_FRAGMENT}
  ${PROJECT_UNIT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UploadProjectModel($projectId: ID!, $input: UploadProjectModelInput!) {
    payload: uploadProjectModel(projectId: $projectId, input: $input) {
      projectId
      projectModel {
        ...ProjectModel
      }
      projectProductCategories {
        ...ProjectProductCategory
      }
      projectProducts {
        ...ProjectProduct
      }
      projectUnits {
        ...ProjectUnit
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUploadProjectModelMutation = (onCompleted: (projectId: string, projectModelId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProjectModel, upsertProjectUnit, upsertProjectProduct, upsertProjectProductCategory } = useProjectContext();
  const [uploadProjectModelMutation, { loading: uploadProjectModelLoading }] = useMutation<UploadProjectModel, UploadProjectModelVariables>(UPLOAD_PROJECT_MODEL_MUTATION);
  const uploadProjectModel = useCallback(
    async (projectId: string, input: UploadProjectModelInput) => {
      await uploadProjectModelMutation({
        variables: {
          projectId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectModel, projectProductCategories, projectProducts, projectUnits, errors } = payload;
          if (projectId && projectModel && projectProductCategories && projectProducts && projectUnits) {
            upsertProjectModel(projectId, projectModel);
            projectProductCategories.forEach((projectProductCategory) => {
              upsertProjectProductCategory(projectId, projectProductCategory);
            });
            projectProducts.forEach((projectProduct) => {
              upsertProjectProduct(projectId, projectProduct);
            });
            projectUnits.forEach((projectUnit) => {
              upsertProjectUnit(projectId, projectUnit);
            });
            onCompleted(projectId, projectModel.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [uploadProjectModelMutation, onCompleted, onError, upsertProjectModel, upsertProjectUnit, upsertProjectProduct, upsertProjectProductCategory, pushErrors]
  );
  return { uploadProjectModel, uploadProjectModelLoading };
};

const PUBLISH_PROJECT_MODEL_MUTATION = gql`
  ${PROJECT_UNIT_FRAGMENT}
  ${PROJECT_MODEL_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation PublishProjectModel($projectId: ID!, $projectModelId: ID!) {
    payload: publishProjectModel(projectId: $projectId, projectModelId: $projectModelId) {
      projectId
      projectModel {
        ...ProjectModel
      }
      projectUnits {
        ...ProjectUnit
      }
      errors {
        ...Error
      }
    }
  }
`;

export const usePublishProjectModelMutation = (onCompleted: (projectId: string, projectModelId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProjectModel, upsertProjectUnit } = useProjectContext();
  const [publishProjectModelMutation, { loading: publishProjectModelLoading }] = useMutation<PublishProjectModel, PublishProjectModelVariables>(PUBLISH_PROJECT_MODEL_MUTATION);
  const publishProjectModel = useCallback(
    async (projectId: string, projectModelId: string) => {
      await publishProjectModelMutation({
        variables: {
          projectId,
          projectModelId,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectModel, projectUnits, errors } = payload;
          if (projectId && projectModel && projectUnits) {
            upsertProjectModel(projectId, projectModel);
            projectUnits.forEach((projectUnit) => {
              upsertProjectUnit(projectId, projectUnit);
            });
            onCompleted(projectId, projectModel.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [publishProjectModelMutation, onCompleted, onError, upsertProjectModel, upsertProjectUnit, pushErrors]
  );
  return { publishProjectModel, publishProjectModelLoading };
};

const DELETE_PROJECT_MODEL_MUTATION = gql`
  ${PROJECT_PRODUCT_FRAGMENT}
  ${PROJECT_UNIT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation DeleteProjectModel($projectId: ID!, $projectModelId: ID!) {
    payload: deleteProjectModel(projectId: $projectId, projectModelId: $projectModelId) {
      projectId
      projectModelId
      projectProducts {
        ...ProjectProduct
      }
      projectUnits {
        ...ProjectUnit
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteProjectModelMutation = (onCompleted: (projectId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { removeProjectModel, upsertProjectProduct, upsertProjectUnit } = useProjectContext();
  const [deleteProjectModelMutation, { loading: deleteProjectModelLoading }] = useMutation<DeleteProjectModel, DeleteProjectModelVariables>(DELETE_PROJECT_MODEL_MUTATION);
  const deleteProjectModel = useCallback(
    async (projectId: string, projectModelId: string) => {
      await deleteProjectModelMutation({
        variables: {
          projectId,
          projectModelId,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectModelId, projectProducts, projectUnits, errors } = payload;
          if (projectId && projectModelId && projectProducts && projectUnits) {
            removeProjectModel(projectId, projectModelId);
            projectProducts.forEach((projectProduct) => {
              upsertProjectProduct(projectId, projectProduct);
            });
            projectUnits.forEach((projectUnit) => {
              upsertProjectUnit(projectId, projectUnit);
            });
            onCompleted(projectId);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [deleteProjectModelMutation, onCompleted, onError, removeProjectModel, upsertProjectProduct, upsertProjectUnit, pushErrors]
  );
  return { deleteProjectModel, deleteProjectModelLoading };
};

const DOWNLOAD_PROJECT_MODEL_UNITY_WEB_GL_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DownloadProjectModelUnityWebGL($projectId: ID!, $projectModelId: ID!) {
    payload: downloadProjectModelUnityWebGL(projectId: $projectId, projectModelId: $projectModelId) {
      loaderUrl
      dataUrl
      frameworkUrl
      codeUrl
      errors {
        ...Error
      }
    }
  }
`;

export const useDownloadProjectModelUnityWebGLMutation = (onCompleted: (loaderUrl: string, dataUrl: string, frameworkUrl: string, codeUrl: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const [downloadProjectModelUnityWebGLMutation, { loading: downloadProjectModelUnityWebGLLoading }] = useMutation<DownloadProjectModelUnityWebGL, DownloadProjectModelUnityWebGLVariables>(
    DOWNLOAD_PROJECT_MODEL_UNITY_WEB_GL_MUTATION
  );
  const downloadProjectModelUnityWebGL = useCallback(
    async (projectId: string, projectModelId: string) => {
      await downloadProjectModelUnityWebGLMutation({
        variables: {
          projectId,
          projectModelId,
        },
        onCompleted: ({ payload }) => {
          const { loaderUrl, dataUrl, frameworkUrl, codeUrl, errors } = payload;
          if (loaderUrl && dataUrl && frameworkUrl && codeUrl) {
            onCompleted(loaderUrl, dataUrl, frameworkUrl, codeUrl);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [downloadProjectModelUnityWebGLMutation, onCompleted, onError, pushErrors]
  );
  return { downloadProjectModelUnityWebGL, downloadProjectModelUnityWebGLLoading };
};

// ProjectModelVibe
const CREATE_PROJECT_MODEL_VIBE_MUTATION = gql`
  ${PROJECT_MODEL_VIBE_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateProjectModelVibe($projectId: ID!, $projectModelId: ID!, $input: CreateProjectModelVibeInput!) {
    payload: createProjectModelVibe(projectId: $projectId, projectModelId: $projectModelId, input: $input) {
      projectId
      projectModelId
      projectModelVibe {
        ...ProjectModelVibe
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateProjectModelVibeMutation = (onCompleted: (projectId: string, projectModelId: string, projectModelVibeId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProjectModelVibe } = useProjectContext();
  const [createProjectModelVibeMutation, { loading: createProjectModelVibeLoading }] = useMutation<CreateProjectModelVibe, CreateProjectModelVibeVariables>(CREATE_PROJECT_MODEL_VIBE_MUTATION);
  const createProjectModelVibe = useCallback(
    async (projectId: string, projectModelId: string, input: CreateProjectModelVibeInput) => {
      await createProjectModelVibeMutation({
        variables: {
          projectId,
          projectModelId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectModelId, projectModelVibe, errors } = payload;
          if (projectId && projectModelId && projectModelVibe) {
            upsertProjectModelVibe(projectId, projectModelId, projectModelVibe);
            onCompleted(projectId, projectModelId, projectModelVibe.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [createProjectModelVibeMutation, onCompleted, onError, upsertProjectModelVibe, pushErrors]
  );
  return { createProjectModelVibe, createProjectModelVibeLoading };
};

const UPDATE_PROJECT_MODEL_VIBE_MUTATION = gql`
  ${PROJECT_MODEL_VIBE_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateProjectModelVibe($projectId: ID!, $projectModelId: ID!, $projectModelVibeId: ID!, $input: UpdateProjectModelVibeInput!) {
    payload: updateProjectModelVibe(projectId: $projectId, projectModelId: $projectModelId, projectModelVibeId: $projectModelVibeId, input: $input) {
      projectId
      projectModelId
      projectModelVibe {
        ...ProjectModelVibe
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateProjectModelVibeMutation = (onCompleted: (projectId: string, projectModelId: string, projectModelVibeId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProjectModelVibe } = useProjectContext();
  const [updateProjectModelVibeMutation, { loading: updateProjectModelVibeLoading }] = useMutation<UpdateProjectModelVibe, UpdateProjectModelVibeVariables>(UPDATE_PROJECT_MODEL_VIBE_MUTATION);
  const updateProjectModelVibe = useCallback(
    async (projectId: string, projectModelId: string, projectModelVibeId: string, input: UpdateProjectModelVibeInput) => {
      await updateProjectModelVibeMutation({
        variables: {
          projectId,
          projectModelId,
          projectModelVibeId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectModelId, projectModelVibe, errors } = payload;
          if (projectId && projectModelId && projectModelVibe) {
            upsertProjectModelVibe(projectId, projectModelId, projectModelVibe);
            onCompleted(projectId, projectModelId, projectModelVibe.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [updateProjectModelVibeMutation, onCompleted, onError, upsertProjectModelVibe, pushErrors]
  );
  return { updateProjectModelVibe, updateProjectModelVibeLoading };
};

const PUBLISH_PROJECT_MODEL_VIBE_MUTATION = gql`
  ${PROJECT_MODEL_VIBE_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation PublishProjectModelVibe($projectId: ID!, $projectModelId: ID!, $projectModelVibeId: ID!) {
    payload: publishProjectModelVibe(projectId: $projectId, projectModelId: $projectModelId, projectModelVibeId: $projectModelVibeId) {
      projectId
      projectModelId
      projectModelVibe {
        ...ProjectModelVibe
      }
      errors {
        ...Error
      }
    }
  }
`;

export const usePublishProjectModelVibeMutation = (onCompleted: (projectId: string, projectModelId: string, projectModelVibeId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProjectModelVibe } = useProjectContext();
  const [publishProjectModelVibeMutation, { loading: publishProjectModelVibeLoading }] = useMutation<PublishProjectModelVibe, PublishProjectModelVibeVariables>(PUBLISH_PROJECT_MODEL_VIBE_MUTATION);
  const publishProjectModelVibe = useCallback(
    async (projectId: string, projectModelId: string, projectModelVibeId: string) => {
      await publishProjectModelVibeMutation({
        variables: {
          projectId,
          projectModelId,
          projectModelVibeId,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectModelId, projectModelVibe, errors } = payload;
          if (projectId && projectModelId && projectModelVibe) {
            upsertProjectModelVibe(projectId, projectModelId, projectModelVibe);
            onCompleted(projectId, projectModelId, projectModelVibe.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [publishProjectModelVibeMutation, onCompleted, onError, upsertProjectModelVibe, pushErrors]
  );
  return { publishProjectModelVibe, publishProjectModelVibeLoading };
};

const DELETE_PROJECT_MODEL_VIBE_MUTATION = gql`
  ${PROJECT_MODEL_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation DeleteProjectModelVibe($projectId: ID!, $projectModelId: ID!, $projectModelVibeId: ID!) {
    payload: deleteProjectModelVibe(projectId: $projectId, projectModelId: $projectModelId, projectModelVibeId: $projectModelVibeId) {
      projectId
      projectModel {
        ...ProjectModel
      }
      projectModelVibeId
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteProjectModelVibeMutation = (onCompleted: (projectId: string, projectModelId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProjectModel } = useProjectContext();
  const [deleteProjectModelVibeMutation, { loading: deleteProjectModelVibeLoading }] = useMutation<DeleteProjectModelVibe, DeleteProjectModelVibeVariables>(DELETE_PROJECT_MODEL_VIBE_MUTATION);
  const deleteProjectModelVibe = useCallback(
    async (projectId: string, projectModelId: string, projectModelVibeId: string) => {
      await deleteProjectModelVibeMutation({
        variables: {
          projectId,
          projectModelId,
          projectModelVibeId,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectModel, errors } = payload;
          if (projectId && projectModel && projectModelVibeId) {
            upsertProjectModel(projectId, projectModel);
            onCompleted(projectId, projectModel.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [deleteProjectModelVibeMutation, onCompleted, onError, upsertProjectModel, pushErrors]
  );
  return { deleteProjectModelVibe, deleteProjectModelVibeLoading };
};

// ProjectReportSetup
const CREATE_PROJECT_REPORT_SETUP_MUTATION = gql`
  ${PROJECT_REPORT_SETUP_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateProjectReportSetup($projectId: ID!, $input: CreateProjectReportSetupInput!) {
    payload: createProjectReportSetup(projectId: $projectId, input: $input) {
      projectId
      projectReportSetup {
        ...ProjectReportSetup
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateProjectReportSetupMutation = (onCompleted: (projectId: string, projectReportSetupId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProjectReportSetup } = useProjectContext();
  const [createProjectReportSetupMutation, { loading: createProjectReportSetupLoading }] = useMutation<CreateProjectReportSetup, CreateProjectReportSetupVariables>(
    CREATE_PROJECT_REPORT_SETUP_MUTATION
  );
  const createProjectReportSetup = useCallback(
    async (projectId: string, input: CreateProjectReportSetupInput) => {
      await createProjectReportSetupMutation({
        variables: {
          projectId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectReportSetup, errors } = payload;
          if (projectId && projectReportSetup) {
            upsertProjectReportSetup(projectId, projectReportSetup);
            onCompleted(projectId, projectReportSetup.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [createProjectReportSetupMutation, onCompleted, onError, upsertProjectReportSetup, pushErrors]
  );
  return { createProjectReportSetup, createProjectReportSetupLoading };
};

const UPDATE_PROJECT_REPORT_SETUP_MUTATION = gql`
  ${PROJECT_REPORT_SETUP_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateProjectReportSetup($projectId: ID!, $projectReportSetupId: ID!, $input: UpdateProjectReportSetupInput!) {
    payload: updateProjectReportSetup(projectId: $projectId, projectReportSetupId: $projectReportSetupId, input: $input) {
      projectId
      projectReportSetup {
        ...ProjectReportSetup
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateProjectReportSetupMutation = (onCompleted: (projectId: string, projectReportSetupId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProjectReportSetup } = useProjectContext();
  const [updateProjectReportSetupMutation, { loading: updateProjectReportSetupLoading }] = useMutation<UpdateProjectReportSetup, UpdateProjectReportSetupVariables>(
    UPDATE_PROJECT_REPORT_SETUP_MUTATION
  );
  const updateProjectReportSetup = useCallback(
    async (projectId: string, projectReportSetupId: string, input: UpdateProjectReportSetupInput) => {
      await updateProjectReportSetupMutation({
        variables: {
          projectId,
          projectReportSetupId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectReportSetup, errors } = payload;
          if (projectId && projectReportSetup) {
            upsertProjectReportSetup(projectId, projectReportSetup);
            onCompleted(projectId, projectReportSetup.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [updateProjectReportSetupMutation, onCompleted, onError, upsertProjectReportSetup, pushErrors]
  );
  return { updateProjectReportSetup, updateProjectReportSetupLoading };
};

const DELETE_PROJECT_REPORT_SETUP_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DeleteProjectReportSetup($projectId: ID!, $projectReportSetupId: ID!) {
    payload: deleteProjectReportSetup(projectId: $projectId, projectReportSetupId: $projectReportSetupId) {
      projectId
      projectReportSetupId
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteProjectReportSetupMutation = (onCompleted: (projectId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { removeProjectReportSetup } = useProjectContext();
  const [deleteProjectReportSetupMutation, { loading: deleteProjectReportSetupLoading }] = useMutation<DeleteProjectReportSetup, DeleteProjectReportSetupVariables>(
    DELETE_PROJECT_REPORT_SETUP_MUTATION
  );
  const deleteProjectReportSetup = useCallback(
    async (projectId: string, projectReportSetupId: string) => {
      await deleteProjectReportSetupMutation({
        variables: {
          projectId,
          projectReportSetupId,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectReportSetupId, errors } = payload;
          if (projectId && projectReportSetupId) {
            removeProjectReportSetup(projectId, projectReportSetupId);
            onCompleted(projectId);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [deleteProjectReportSetupMutation, onCompleted, onError, removeProjectReportSetup, pushErrors]
  );
  return { deleteProjectReportSetup, deleteProjectReportSetupLoading };
};

const UPDATE_PROJECT_MODEL_REPORT_SETUP_RELATIONSHIPS_MUTATION = gql`
  ${PROJECT_REPORT_SETUP_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateProjectModelReportSetupRelationships($projectId: ID!, $projectModelId: ID!, $input: UpdateProjectModelReportSetupRelationshipsInput!) {
    payload: updateProjectModelReportSetupRelationships(projectId: $projectId, projectModelId: $projectModelId, input: $input) {
      projectId
      projectReportSetup {
        ...ProjectReportSetup
      }
      errors {
        ...Error
      }
    }
  }
`;

// TODO: Verify redirection.
export const useUpdateProjectModelReportSetupRelationshipsMutation = (onCompleted: (projectId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProjectReportSetup } = useProjectContext();
  const [updateProjectModelReportSetupRelationshipsMutation, { loading: updateProjectModelReportSetupRelationshipsLoading }] = useMutation<
    UpdateProjectModelReportSetupRelationships,
    UpdateProjectModelReportSetupRelationshipsVariables
  >(UPDATE_PROJECT_MODEL_REPORT_SETUP_RELATIONSHIPS_MUTATION);
  const updateProjectModelReportSetupRelationships = useCallback(
    async (projectId: string, projectModelId: string, input: UpdateProjectModelReportSetupRelationshipsInput) => {
      await updateProjectModelReportSetupRelationshipsMutation({
        variables: {
          projectId,
          projectModelId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectReportSetup, errors } = payload;
          if (projectId && projectReportSetup) {
            upsertProjectReportSetup(projectId, projectReportSetup);
            onCompleted(projectId);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [updateProjectModelReportSetupRelationshipsMutation, onCompleted, onError, upsertProjectReportSetup, pushErrors]
  );
  return { updateProjectModelReportSetupRelationships, updateProjectModelReportSetupRelationshipsLoading };
};

// ProjectProduct
const UPDATE_PROJECT_PRODUCT_MUTATION = gql`
  ${PROJECT_PRODUCT_FRAGMENT}
  ${PROJECT_ACTIVITY_FRAGMENT}
  ${PROVIDER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateProjectProduct($projectId: ID!, $projectProductId: ID!, $input: UpdateProjectProductInput!) {
    payload: updateProjectProduct(projectId: $projectId, projectProductId: $projectProductId, input: $input) {
      projectId
      projectProduct {
        ...ProjectProduct
      }
      projectActivities {
        ...ProjectActivity
      }
      provider {
        ...Provider
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateProjectProductMutation = (onCompleted: (projectId: string, projectProductId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProvider } = useAppContext();
  const { upsertProjectProduct, upsertProjectActivity } = useProjectContext();
  const [updateProjectProductMutation, { loading: updateProjectProductLoading }] = useMutation<UpdateProjectProduct, UpdateProjectProductVariables>(UPDATE_PROJECT_PRODUCT_MUTATION);
  const updateProjectProduct = useCallback(
    async (projectId: string, projectProductId: string, input: UpdateProjectProductInput) => {
      await updateProjectProductMutation({
        variables: {
          projectId,
          projectProductId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectProduct, projectActivities, provider, errors } = payload;
          if (projectId && projectProduct && projectActivities) {
            upsertProjectProduct(projectId, projectProduct);
            projectActivities.forEach((projectActivity) => {
              upsertProjectActivity(projectId, projectActivity);
            });
            if (provider) {
              upsertProvider(provider);
            }
            onCompleted(projectId, projectProduct.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [updateProjectProductMutation, onCompleted, onError, upsertProvider, upsertProjectProduct, upsertProjectActivity, pushErrors]
  );
  return { updateProjectProduct, updateProjectProductLoading };
};

const UPDATE_PROJECT_PRODUCTS_MUTATION = gql`
  ${PROJECT_PRODUCT_FRAGMENT}
  ${PROJECT_ACTIVITY_FRAGMENT}
  ${PROVIDER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateProjectProducts($projectId: ID!, $input: UpdateProjectProductsInput!) {
    payload: updateProjectProducts(projectId: $projectId, input: $input) {
      projectId
      projectProducts {
        ...ProjectProduct
      }
      projectActivities {
        ...ProjectActivity
      }
      providers {
        ...Provider
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateProjectProductsMutation = (onCompleted: (projectId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProvider } = useAppContext();
  const { upsertProjectProduct, upsertProjectActivity } = useProjectContext();
  const [updateProjectProductsMutation, { loading: updateProjectProductsLoading }] = useMutation<UpdateProjectProducts, UpdateProjectProductsVariables>(UPDATE_PROJECT_PRODUCTS_MUTATION);
  const updateProjectProducts = useCallback(
    async (projectId: string, input: UpdateProjectProductsInput) => {
      await updateProjectProductsMutation({
        variables: {
          projectId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectProducts, projectActivities, providers, errors } = payload;
          if (projectId && projectProducts && projectActivities && providers) {
            projectProducts.forEach((projectProduct) => {
              upsertProjectProduct(projectId, projectProduct);
            });
            projectActivities.forEach((projectActivity) => {
              upsertProjectActivity(projectId, projectActivity);
            });
            providers.forEach((provider) => {
              upsertProvider(provider);
            });
            onCompleted(projectId);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [updateProjectProductsMutation, onCompleted, onError, upsertProvider, upsertProjectProduct, upsertProjectActivity, pushErrors]
  );
  return { updateProjectProducts, updateProjectProductsLoading };
};

const DELETE_PROJECT_PRODUCT_MUTATION = gql`
  ${PROJECT_ACTIVITY_FRAGMENT}
  ${PROVIDER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation DeleteProjectProduct($projectId: ID!, $projectProductId: ID!) {
    payload: deleteProjectProduct(projectId: $projectId, projectProductId: $projectProductId) {
      projectId
      projectProductId
      projectActivities {
        ...ProjectActivity
      }
      provider {
        ...Provider
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteProjectProductMutation = (onCompleted: (projectId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProvider } = useAppContext();
  const { removeProjectProduct, upsertProjectActivity } = useProjectContext();
  const [deleteProjectProductMutation, { loading: deleteProjectProductLoading }] = useMutation<DeleteProjectProduct, DeleteProjectProductVariables>(DELETE_PROJECT_PRODUCT_MUTATION);
  const deleteProjectProduct = useCallback(
    async (projectId: string, projectProductId: string) => {
      await deleteProjectProductMutation({
        variables: {
          projectId,
          projectProductId,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectProductId, projectActivities, provider, errors } = payload;
          if (projectId && projectProductId && projectActivities) {
            removeProjectProduct(projectId, projectProductId);
            projectActivities.forEach((projectActivity) => {
              upsertProjectActivity(projectId, projectActivity);
            });
            if (provider) {
              upsertProvider(provider);
            }
            onCompleted(projectId);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [deleteProjectProductMutation, onCompleted, onError, upsertProjectActivity, upsertProvider, removeProjectProduct, pushErrors]
  );
  return { deleteProjectProduct, deleteProjectProductLoading };
};

// ProjectUnit
export const CREATE_PROJECT_UNIT_MUTATION = gql`
  ${PROJECT_UNIT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateProjectUnit($projectId: ID!, $input: CreateProjectUnitInput!) {
    payload: createProjectUnit(projectId: $projectId, input: $input) {
      projectId
      projectUnit {
        ...ProjectUnit
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateProjectUnitMutation = (onCompleted: (projectId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProjectUnit } = useProjectContext();
  const [createProjectUnitMutation, { loading: createProjectUnitLoading }] = useMutation<CreateProjectUnit, CreateProjectUnitVariables>(CREATE_PROJECT_UNIT_MUTATION);
  const createProjectUnit = useCallback(
    async (projectId: string, input: CreateProjectUnitInput) => {
      await createProjectUnitMutation({
        variables: {
          projectId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectUnit, errors } = payload;
          if (projectId && projectUnit) {
            upsertProjectUnit(projectId, projectUnit);
            onCompleted(projectId);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [createProjectUnitMutation, onCompleted, onError, upsertProjectUnit, pushErrors]
  );
  return { createProjectUnit, createProjectUnitLoading };
};

export const UPDATE_PROJECT_UNIT_MUTATION = gql`
  ${PROJECT_UNIT_FRAGMENT}
  ${PROJECT_CONTRACT_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateProjectUnit($projectId: ID!, $projectUnitId: ID!, $input: UpdateProjectUnitInput!) {
    payload: updateProjectUnit(projectId: $projectId, projectUnitId: $projectUnitId, input: $input) {
      projectId
      projectUnit {
        ...ProjectUnit
      }
      projectContract {
        ...ProjectContract
      }
      customer {
        ...Customer
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateProjectUnitMutation = (onCompleted: (projectId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertCustomer } = useAppContext();
  const { upsertProjectUnit, upsertProjectContract } = useProjectContext();
  const [updateProjectUnitMutation, { loading: updateProjectUnitLoading }] = useMutation<UpdateProjectUnit, UpdateProjectUnitVariables>(UPDATE_PROJECT_UNIT_MUTATION);
  const updateProjectUnit = useCallback(
    async (projectId: string, projectUnitId: string, input: UpdateProjectUnitInput) => {
      await updateProjectUnitMutation({
        variables: {
          projectId,
          projectUnitId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectUnit, projectContract, customer, errors } = payload;
          if (projectId && projectUnit) {
            upsertProjectUnit(projectId, projectUnit);
            if (projectContract) {
              upsertProjectContract(projectId, projectContract);
            }
            if (customer) {
              upsertCustomer(customer);
            }
            onCompleted(projectId);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [updateProjectUnitMutation, onCompleted, onError, upsertCustomer, upsertProjectUnit, upsertProjectContract, pushErrors]
  );
  return { updateProjectUnit, updateProjectUnitLoading };
};

const DELETE_PROJECT_UNIT_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DeleteProjectUnit($projectId: ID!, $projectUnitId: ID!) {
    payload: deleteProjectUnit(projectId: $projectId, projectUnitId: $projectUnitId) {
      projectId
      projectUnitId
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteProjectUnitMutation = (onCompleted: (projectId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { removeProjectUnit } = useProjectContext();
  const [deleteProjectUnitMutation, { loading: deleteProjectUnitLoading }] = useMutation<DeleteProjectUnit, DeleteProjectUnitVariables>(DELETE_PROJECT_UNIT_MUTATION);
  const deleteProjectUnit = useCallback(
    async (projectId: string, projectUnitId: string) => {
      await deleteProjectUnitMutation({
        variables: {
          projectId,
          projectUnitId,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectUnitId, errors } = payload;
          if (projectId && projectUnitId) {
            removeProjectUnit(projectId, projectUnitId);
            onCompleted(projectId);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [deleteProjectUnitMutation, onCompleted, onError, removeProjectUnit, pushErrors]
  );
  return { deleteProjectUnit, deleteProjectUnitLoading };
};

const UPDATE_PROJECT_UNITS_MUTATION = gql`
  ${PROJECT_UNIT_FRAGMENT}
  ${PROJECT_CONTRACT_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateProjectUnits($projectId: ID!, $input: UpdateProjectUnitsInput!) {
    payload: updateProjectUnits(projectId: $projectId, input: $input) {
      projectId
      projectUnits {
        ...ProjectUnit
      }
      projectContracts {
        ...ProjectContract
      }
      customers {
        ...Customer
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateProjectUnitsMutation = (onCompleted: (projectId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertCustomer } = useAppContext();
  const { upsertProjectUnit, upsertProjectContract } = useProjectContext();
  const [updateProjectUnitsMutation, { loading: updateProjectUnitsLoading }] = useMutation<UpdateProjectUnits, UpdateProjectUnitsVariables>(UPDATE_PROJECT_UNITS_MUTATION);
  const updateProjectUnits = useCallback(
    async (projectId: string, input: UpdateProjectUnitsInput) => {
      await updateProjectUnitsMutation({
        variables: {
          projectId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectUnits, projectContracts, customers, errors } = payload;
          if (projectId && projectUnits && projectContracts && customers) {
            projectUnits.forEach((projectUnit) => {
              upsertProjectUnit(projectId, projectUnit);
            });
            projectContracts.forEach((projectContract) => {
              upsertProjectContract(projectId, projectContract);
            });
            customers.forEach((customer) => {
              upsertCustomer(customer);
            });
            onCompleted(projectId);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [updateProjectUnitsMutation, onCompleted, onError, upsertCustomer, upsertProjectUnit, upsertProjectContract, pushErrors]
  );
  return { updateProjectUnits, updateProjectUnitsLoading };
};

export const UPLOAD_PROJECT_UNITS_MUTATION = gql`
  ${PROJECT_UNIT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UploadProjectUnits($projectId: ID!, $input: UploadProjectUnitsInput!) {
    payload: uploadProjectUnits(projectId: $projectId, input: $input) {
      projectId
      projectUnits {
        ...ProjectUnit
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUploadProjectUnitsMutation = (onCompleted: (projectId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProjectUnit } = useProjectContext();
  const [uploadProjectUnitsMutation, { loading: uploadProjectUnitsLoading }] = useMutation<UploadProjectUnits, UploadProjectUnitsVariables>(UPLOAD_PROJECT_UNITS_MUTATION);
  const uploadProjectUnits = useCallback(
    async (projectId: string, input: UploadProjectUnitsInput) => {
      await uploadProjectUnitsMutation({
        variables: {
          projectId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectUnits, errors } = payload;
          if (projectId && projectUnits) {
            projectUnits.forEach((projectUnit) => {
              upsertProjectUnit(projectId, projectUnit);
            });
            onCompleted(projectId);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [uploadProjectUnitsMutation, onCompleted, onError, upsertProjectUnit, pushErrors]
  );
  return { uploadProjectUnits, uploadProjectUnitsLoading };
};

// Provider
export const CREATE_PROVIDER_MUTATION = gql`
  ${PROVIDER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateProvider($input: CreateProviderInput!) {
    payload: createProvider(input: $input) {
      provider {
        ...Provider
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateProviderMutation = (onCompleted: (providerId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProvider } = useAppContext();
  const [createProviderMutation, { loading: createProviderLoading }] = useMutation<CreateProvider, CreateProviderVariables>(CREATE_PROVIDER_MUTATION);
  const createProvider = useCallback(
    async (input: CreateProviderInput) => {
      await createProviderMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { provider, errors } = payload;
          if (provider) {
            upsertProvider(provider);
            onCompleted(provider.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [createProviderMutation, onCompleted, onError, upsertProvider, pushErrors]
  );
  return { createProvider, createProviderLoading };
};

export const UPDATE_PROVIDER_MUTATION = gql`
  ${PROVIDER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateProvider($providerId: ID!, $input: UpdateProviderInput!) {
    payload: updateProvider(providerId: $providerId, input: $input) {
      provider {
        ...Provider
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateProviderMutation = (onCompleted: (providerId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProvider } = useAppContext();
  const [updateProviderMutation, { loading: updateProviderLoading }] = useMutation<UpdateProvider, UpdateProviderVariables>(UPDATE_PROVIDER_MUTATION);
  const updateProvider = useCallback(
    async (providerId: string, input: UpdateProviderInput) => {
      await updateProviderMutation({
        variables: {
          providerId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { provider, errors } = payload;
          if (provider) {
            upsertProvider(provider);
            onCompleted(provider.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [updateProviderMutation, onCompleted, onError, upsertProvider, pushErrors]
  );
  return { updateProvider, updateProviderLoading };
};

const DELETE_PROVIDER_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DeleteProvider($providerId: ID!) {
    payload: deleteProvider(providerId: $providerId) {
      providerId
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteProviderMutation = (onCompleted: (providerId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { removeProvider } = useAppContext();
  const [deleteProviderMutation, { loading: deleteProviderLoading }] = useMutation<DeleteProvider, DeleteProviderVariables>(DELETE_PROVIDER_MUTATION);
  const deleteProvider = useCallback(
    async (providerId: string) => {
      await deleteProviderMutation({
        variables: {
          providerId,
        },
        onCompleted: ({ payload }) => {
          const { providerId, errors } = payload;
          if (providerId) {
            removeProvider(providerId);
            onCompleted(providerId);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [deleteProviderMutation, onCompleted, onError, removeProvider, pushErrors]
  );
  return { deleteProvider, deleteProviderLoading };
};

// Customer
const CREATE_CUSTOMER_MUTATION = gql`
  ${CUSTOMER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateCustomer($input: CreateCustomerInput!) {
    payload: createCustomer(input: $input) {
      customer {
        ...Customer
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateCustomerMutation = (onCompleted: (customerId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertCustomer } = useAppContext();
  const [createCustomerMutation, { loading: createCustomerLoading }] = useMutation<CreateCustomer, CreateCustomerVariables>(CREATE_CUSTOMER_MUTATION);
  const createCustomer = useCallback(
    async (input: CreateCustomerInput) => {
      await createCustomerMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { customer, errors } = payload;
          if (customer) {
            upsertCustomer(customer);
            onCompleted(customer.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [createCustomerMutation, onCompleted, onError, upsertCustomer, pushErrors]
  );
  return { createCustomer, createCustomerLoading };
};

const UPDATE_CUSTOMER_MUTATION = gql`
  ${CUSTOMER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateCustomer($customerId: ID!, $input: UpdateCustomerInput!) {
    payload: updateCustomer(customerId: $customerId, input: $input) {
      customer {
        ...Customer
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateCustomerMutation = (onCompleted: (customerId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertCustomer } = useAppContext();
  const [updateCustomerMutation, { loading: updateCustomerLoading }] = useMutation<UpdateCustomer, UpdateCustomerVariables>(UPDATE_CUSTOMER_MUTATION);
  const updateCustomer = useCallback(
    async (customerId: string, input: UpdateCustomerInput) => {
      await updateCustomerMutation({
        variables: {
          customerId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { customer, errors } = payload;
          if (customer) {
            upsertCustomer(customer);
            onCompleted(customer.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [updateCustomerMutation, onCompleted, onError, upsertCustomer, pushErrors]
  );
  return { updateCustomer, updateCustomerLoading };
};

const DELETE_CUSTOMER_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DeleteCustomer($customerId: ID!) {
    payload: deleteCustomer(customerId: $customerId) {
      customerId
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteCustomerMutation = (onCompleted: () => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { removeCustomer } = useAppContext();
  const [deleteCustomerMutation, { loading: deleteCustomerLoading }] = useMutation<DeleteCustomer, DeleteCustomerVariables>(DELETE_CUSTOMER_MUTATION);
  const deleteCustomer = useCallback(
    async (customerId: string) => {
      await deleteCustomerMutation({
        variables: {
          customerId,
        },
        onCompleted: ({ payload }) => {
          const { customerId, errors } = payload;
          if (customerId) {
            removeCustomer(customerId);
            onCompleted();
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [deleteCustomerMutation, onCompleted, onError, removeCustomer, pushErrors]
  );
  return { deleteCustomer, deleteCustomerLoading };
};

const RESEND_CUSTOMER_CREDENTIALS_MUTATION = gql`
  ${CUSTOMER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation ResendCustomerCredentials($customerId: ID!, $input: ResendCustomerCredentialsInput!) {
    payload: resendCustomerCredentials(customerId: $customerId, input: $input) {
      customer {
        ...Customer
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useResendCustomerCredentialsMutation = (onCompleted: () => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertCustomer } = useAppContext();
  const [resendCustomerCredentialsMutation, { loading: resendCustomerCredentialsLoading }] = useMutation<ResendCustomerCredentials, ResendCustomerCredentialsVariables>(
    RESEND_CUSTOMER_CREDENTIALS_MUTATION
  );
  const resendCustomerCredentials = useCallback(
    async (customerId: string, input: ResendCustomerCredentialsInput) => {
      await resendCustomerCredentialsMutation({
        variables: {
          customerId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { customer, errors } = payload;
          if (customer) {
            upsertCustomer(customer);
            onCompleted();
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [resendCustomerCredentialsMutation, onCompleted, onError, upsertCustomer, pushErrors]
  );
  return { resendCustomerCredentials, resendCustomerCredentialsLoading };
};

// ProjectContract
export const CREATE_PROJECT_CONTRACT_MUTATION = gql`
  ${PROJECT_UNIT_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${PROJECT_CONTRACT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateProjectContract($projectId: ID!, $input: CreateProjectContractInput!) {
    payload: createProjectContract(projectId: $projectId, input: $input) {
      projectId
      projectUnit {
        ...ProjectUnit
      }
      projectContract {
        ...ProjectContract
      }
      customer {
        ...Customer
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateProjectContractMutation = (onCompleted: (projectId: string, projectContractId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertCustomer } = useAppContext();
  const { upsertProjectUnit, upsertProjectContract } = useProjectContext();
  const [createProjectContractMutation, { loading: createProjectContractLoading }] = useMutation<CreateProjectContract, CreateProjectContractVariables>(CREATE_PROJECT_CONTRACT_MUTATION);
  const createProjectContract = useCallback(
    async (projectId: string, input: CreateProjectContractInput) => {
      await createProjectContractMutation({
        variables: {
          projectId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { projectUnit, customer, projectContract, errors } = payload;
          if (projectUnit && customer && projectContract) {
            upsertCustomer(customer);
            upsertProjectUnit(projectId, projectUnit);
            upsertProjectContract(projectId, projectContract);
            onCompleted(projectId, projectContract.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [createProjectContractMutation, onCompleted, onError, upsertCustomer, upsertProjectUnit, upsertProjectContract, pushErrors]
  );
  return { createProjectContract, createProjectContractLoading };
};

export const CANCEL_PROJECT_CONTRACT_MUTATION = gql`
  ${PROJECT_UNIT_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${PROJECT_CONTRACT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CancelProjectContract($projectId: ID!, $projectContractId: ID!) {
    payload: cancelProjectContract(projectId: $projectId, projectContractId: $projectContractId) {
      projectId
      projectUnit {
        ...ProjectUnit
      }
      projectContract {
        ...ProjectContract
      }
      customer {
        ...Customer
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCancelProjectContractMutation = (onCompleted: (projectId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertCustomer } = useAppContext();
  const { upsertProjectUnit, upsertProjectContract } = useProjectContext();
  const [cancelProjectContractMutation, { loading: cancelProjectContractLoading }] = useMutation<CancelProjectContract, CancelProjectContractVariables>(CANCEL_PROJECT_CONTRACT_MUTATION);
  const cancelProjectContract = useCallback(
    async (projectId: string, projectContractId: string) => {
      await cancelProjectContractMutation({
        variables: {
          projectId,
          projectContractId,
        },
        onCompleted: ({ payload }) => {
          const { projectUnit, customer, projectContract, errors } = payload;
          if (projectUnit && customer && projectContract) {
            upsertCustomer(customer);
            upsertProjectUnit(projectId, projectUnit);
            upsertProjectContract(projectId, projectContract);
            onCompleted(projectId);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [cancelProjectContractMutation, onCompleted, onError, upsertCustomer, upsertProjectUnit, upsertProjectContract, pushErrors]
  );
  return { cancelProjectContract, cancelProjectContractLoading };
};

const DOWNLOAD_PROJECT_CONTRACT_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DownloadProjectContract($projectId: ID!, $projectContractId: ID!) {
    payload: downloadProjectContract(projectId: $projectId, projectContractId: $projectContractId) {
      projectContractUrl
      errors {
        ...Error
      }
    }
  }
`;

export const useDownloadProjectContractMutation = (onCompleted: (projectContractUrl: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const [downloadProjectContractMutation, { loading: downloadProjectContractLoading }] = useMutation<DownloadProjectContract, DownloadProjectContractVariables>(DOWNLOAD_PROJECT_CONTRACT_MUTATION);
  const downloadProjectContract = useCallback(
    async (projectId: string, projectContractId: string) => {
      await downloadProjectContractMutation({
        variables: {
          projectId,
          projectContractId,
        },
        onCompleted: ({ payload }) => {
          const { projectContractUrl, errors } = payload;
          if (projectContractUrl) {
            onCompleted(projectContractUrl);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [downloadProjectContractMutation, onCompleted, onError, pushErrors]
  );
  return { downloadProjectContract, downloadProjectContractLoading };
};

// ProjectContractChoice
const CREATE_PROJECT_CONTRACT_CHOICE_MUTATION = gql`
  ${PROJECT_CONTRACT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateProjectContractChoice($projectId: ID!, $projectContractId: ID!, $input: CreateProjectContractChoiceInput!) {
    payload: createProjectContractChoice(projectId: $projectId, projectContractId: $projectContractId, input: $input) {
      projectId
      projectContract {
        ...ProjectContract
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateProjectContractChoiceMutation = (onCompleted: (projectId: string, projectContractId: string, projectContractChoiceId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProjectContract } = useProjectContext();
  const [createProjectContractChoiceMutation, { loading: createProjectContractChoiceLoading }] = useMutation<CreateProjectContractChoice, CreateProjectContractChoiceVariables>(
    CREATE_PROJECT_CONTRACT_CHOICE_MUTATION
  );
  const createProjectContractChoice = useCallback(
    async (projectId: string, projectContractId: string, input: CreateProjectContractChoiceInput) => {
      await createProjectContractChoiceMutation({
        variables: {
          projectId,
          projectContractId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectContract, errors } = payload;
          if (projectId && projectContract) {
            upsertProjectContract(projectId, projectContract);
            onCompleted(projectId, projectContract.id, projectContract.currentProjectContractChoiceId);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [createProjectContractChoiceMutation, onCompleted, onError, upsertProjectContract, pushErrors]
  );
  return { createProjectContractChoice, createProjectContractChoiceLoading };
};

// ProjectContractRequest
const UPDATE_PROJECT_CONTRACT_REQUESTS_MUTATION = gql`
  ${PROJECT_CONTRACT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateProjectContractRequests($projectId: ID!, $projectContractId: ID!, $input: UpdateProjectContractRequestsInput!) {
    payload: updateProjectContractRequests(projectId: $projectId, projectContractId: $projectContractId, input: $input) {
      projectId
      projectContract {
        ...ProjectContract
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateProjectContractRequestsMutation = (onCompleted: (projectId: string, projectContractId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProjectContract } = useProjectContext();
  const [updateProjectContractRequestsMutation, { loading: updateProjectContractRequestsLoading }] = useMutation<UpdateProjectContractRequests, UpdateProjectContractRequestsVariables>(
    UPDATE_PROJECT_CONTRACT_REQUESTS_MUTATION
  );
  const updateProjectContractRequests = useCallback(
    async (projectId: string, projectContractId: string, input: UpdateProjectContractRequestsInput) => {
      await updateProjectContractRequestsMutation({
        variables: {
          projectId,
          projectContractId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectContract, errors } = payload;
          if (projectId && projectContract) {
            upsertProjectContract(projectId, projectContract);
            onCompleted(projectId, projectContract.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [updateProjectContractRequestsMutation, onCompleted, onError, upsertProjectContract, pushErrors]
  );
  return { updateProjectContractRequests, updateProjectContractRequestsLoading };
};

// ProjectContractPayment
const CREATE_PROJECT_CONTRACT_PAYMENT_MUTATION = gql`
  ${PROJECT_CONTRACT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateProjectContractPayment($projectId: ID!, $projectContractId: ID!, $projectContractInvoiceId: ID!, $input: CreateProjectContractPaymentInput!) {
    payload: createProjectContractPayment(projectId: $projectId, projectContractId: $projectContractId, projectContractInvoiceId: $projectContractInvoiceId, input: $input) {
      projectId
      projectContract {
        ...ProjectContract
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateProjectContractPaymentMutation = (onCompleted: (projectId: string, projectContractId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProjectContract } = useProjectContext();
  const [createProjectContractPaymentMutation, { loading: createProjectContractPaymentLoading }] = useMutation<CreateProjectContractPayment, CreateProjectContractPaymentVariables>(
    CREATE_PROJECT_CONTRACT_PAYMENT_MUTATION
  );
  const createProjectContractPayment = useCallback(
    async (projectId: string, projectContractId: string, projectContractInvoiceId: string, input: CreateProjectContractPaymentInput) => {
      await createProjectContractPaymentMutation({
        variables: {
          projectId,
          projectContractId,
          projectContractInvoiceId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectContract, errors } = payload;
          if (projectId && projectContract) {
            upsertProjectContract(projectId, projectContract);
            onCompleted(projectId, projectContract.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [createProjectContractPaymentMutation, onCompleted, onError, upsertProjectContract, pushErrors]
  );
  return { createProjectContractPayment, createProjectContractPaymentLoading };
};

const UPDATE_PROJECT_CONTRACT_PAYMENT_MUTATION = gql`
  ${PROJECT_CONTRACT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateProjectContractPayment($projectId: ID!, $projectContractId: ID!, $projectContractInvoiceId: ID!, $projectContractPaymentId: ID!, $input: UpdateProjectContractPaymentInput!) {
    payload: updateProjectContractPayment(
      projectId: $projectId
      projectContractId: $projectContractId
      projectContractInvoiceId: $projectContractInvoiceId
      projectContractPaymentId: $projectContractPaymentId
      input: $input
    ) {
      projectId
      projectContract {
        ...ProjectContract
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateProjectContractPaymentMutation = (onCompleted: (projectId: string, projectContractId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProjectContract } = useProjectContext();
  const [updateProjectContractPaymentMutation, { loading: updateProjectContractPaymentLoading }] = useMutation<UpdateProjectContractPayment, UpdateProjectContractPaymentVariables>(
    UPDATE_PROJECT_CONTRACT_PAYMENT_MUTATION
  );
  const updateProjectContractPayment = useCallback(
    async (projectId: string, projectContractId: string, projectContractInvoiceId: string, projectContractPaymentId: string, input: UpdateProjectContractPaymentInput) => {
      await updateProjectContractPaymentMutation({
        variables: {
          projectId,
          projectContractId,
          projectContractInvoiceId,
          projectContractPaymentId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectContract, errors } = payload;
          if (projectId && projectContract) {
            upsertProjectContract(projectId, projectContract);
            onCompleted(projectId, projectContract.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [updateProjectContractPaymentMutation, onCompleted, onError, upsertProjectContract, pushErrors]
  );
  return { updateProjectContractPayment, updateProjectContractPaymentLoading };
};

const DELETE_PROJECT_CONTRACT_PAYMENT_MUTATION = gql`
  ${PROJECT_CONTRACT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation DeleteProjectContractPayment($projectId: ID!, $projectContractId: ID!, $projectContractInvoiceId: ID!, $projectContractPaymentId: ID!) {
    payload: deleteProjectContractPayment(
      projectId: $projectId
      projectContractId: $projectContractId
      projectContractInvoiceId: $projectContractInvoiceId
      projectContractPaymentId: $projectContractPaymentId
    ) {
      projectId
      projectContract {
        ...ProjectContract
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteProjectContractPaymentMutation = (onCompleted: (projectId: string, projectContractId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertProjectContract } = useProjectContext();
  const [deleteProjectContractPaymentMutation, { loading: deleteProjectContractPaymentLoading }] = useMutation<DeleteProjectContractPayment, DeleteProjectContractPaymentVariables>(
    DELETE_PROJECT_CONTRACT_PAYMENT_MUTATION
  );
  const deleteProjectContractPayment = useCallback(
    async (projectId: string, projectContractId: string, projectContractInvoiceId: string, projectContractPaymentId: string) => {
      await deleteProjectContractPaymentMutation({
        variables: {
          projectId,
          projectContractId,
          projectContractInvoiceId,
          projectContractPaymentId,
        },
        onCompleted: ({ payload }) => {
          const { projectId, projectContract, errors } = payload;
          if (projectId && projectContract) {
            upsertProjectContract(projectId, projectContract);
            onCompleted(projectId, projectContract.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [deleteProjectContractPaymentMutation, onCompleted, onError, upsertProjectContract, pushErrors]
  );
  return { deleteProjectContractPayment, deleteProjectContractPaymentLoading };
};

const UPDATE_USER_ACCESS_MUTATION = gql`
  ${USER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateUserAccess($userId: ID!, $input: UpdateUserAccessInput!) {
    payload: updateUserAccess(userId: $userId, input: $input) {
      user {
        ...User
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateUserAccessMutation = (onCompleted: (userId: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const { upsertUser } = useAppContext();
  const [updateUserAccessMutation, { loading: updateUserAccessLoading }] = useMutation<UpdateUserAccess, UpdateUserAccessVariables>(UPDATE_USER_ACCESS_MUTATION);
  const updateUserAccess = useCallback(
    async (userId: string, input: UpdateUserAccessInput) => {
      await updateUserAccessMutation({
        variables: {
          userId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { user, errors } = payload;
          if (user) {
            upsertUser(user);
            onCompleted(user.id);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [updateUserAccessMutation, onCompleted, onError, upsertUser, pushErrors]
  );
  return { updateUserAccess, updateUserAccessLoading };
};

// Template
const DOWNLOAD_TEMPLATE_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DownloadTemplate($name: TemplateName!) {
    payload: downloadTemplate(name: $name) {
      templateUrl
      errors {
        ...Error
      }
    }
  }
`;

export const useDownloadTemplateMutation = (onCompleted: (templateUrl: string) => void, onError: (errors: string[]) => void) => {
  const { pushErrors } = useNotificationContext();
  const [downloadTemplateMutation, { loading: downloadTemplateLoading }] = useMutation<DownloadTemplate, DownloadTemplateVariables>(DOWNLOAD_TEMPLATE_MUTATION);
  const downloadTemplate = useCallback(
    async (name: TemplateName) => {
      await downloadTemplateMutation({
        variables: {
          name,
        },
        onCompleted: ({ payload }) => {
          const { templateUrl, errors } = payload;
          if (templateUrl) {
            onCompleted(templateUrl);
          }
          if (errors) {
            if (Boolean(process.env.REACT_APP_USE_TOASTR)) {
              pushErrors(errors.map((error) => error.message));
            } else {
              onError(errors.map((error) => error.message));
            }
          }
        },
      });
    },
    [downloadTemplateMutation, onCompleted, onError, pushErrors]
  );
  return { downloadTemplate, downloadTemplateLoading };
};
