import "./index.less";
import { FunctionComponent, useEffect, useState } from "react";
import { Button, ButtonToolbar, Form, Input, InputGroup, InputNumber, SelectPicker } from "rsuite";
import { FormError } from "buildingBlocks";
import produce from "immer";
import { ProjectContractInvoice } from "graphql/__generated__/ProjectContractInvoice";
import { PersistentStorageOperation } from "graphql/globalTypes";

type ProjectContractPaymentFormValues = {
  readonly customerInvoiceId: string;
  readonly amount: number;
  readonly referenceNumber: string;
};

type Props = {
  readonly projectContractInvoices: ReadonlyArray<ProjectContractInvoice>;
  readonly readonly: boolean;
  readonly _operation: PersistentStorageOperation;
  readonly initialValues: ProjectContractPaymentFormValues;
  readonly errors: string[];
  readonly loading: boolean;
  readonly onSubmit: (values: ProjectContractPaymentFormValues) => Promise<void>;
  readonly onCancel: () => void;
};

export const ProjectContractPaymentForm: FunctionComponent<Props> = ({ projectContractInvoices, _operation, readonly, initialValues, errors, loading, onSubmit, onCancel }) => {
  const [values, setValues] = useState<ProjectContractPaymentFormValues>(initialValues);
  const projectContractInvoice = projectContractInvoices.find((projectContractInvoice) => projectContractInvoice.id === values.customerInvoiceId);
  useEffect(() => {
    if (projectContractInvoice) {
      setValues(
        produce(values, (draft) => {
          draft.amount = projectContractInvoice.balance;
        })
      );
    }
    // eslint-disable-next-line
  }, [projectContractInvoice]);
  return (
    <>
      <FormError errors={errors} />
      <Form className="form" fluid>
        <Form.Group>
          <Form.ControlLabel>Facture</Form.ControlLabel>
          <SelectPicker
            readOnly={readonly || _operation === PersistentStorageOperation.UPDATE}
            cleanable={_operation !== PersistentStorageOperation.UPDATE}
            value={projectContractInvoice?.id ?? undefined}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  console.log(value);
                  draft.customerInvoiceId = value ?? "";
                })
              );
            }}
            data={[...projectContractInvoices]
              .sort((left, right) => (left.number < right.number ? -1 : left.number > right.number ? 1 : 1))
              .map((projectContractInvoice) => ({
                label: `${projectContractInvoice.label} (${Number(projectContractInvoice.balance).toCurrencyFormat("CAD")})`,
                value: projectContractInvoice.id,
              }))}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Montant</Form.ControlLabel>
          <InputGroup size="md">
            <InputNumber
              size="md"
              value={values.amount}
              onChange={(value) => {
                setValues(
                  produce(values, (draft) => {
                    draft.amount = Number(value);
                  })
                );
              }}
              min={projectContractInvoice && projectContractInvoice.balance < 0 ? projectContractInvoice.balance : 0}
              max={projectContractInvoice && projectContractInvoice.balance > 0 ? projectContractInvoice.balance : 0}
            />
            <InputGroup.Addon>$</InputGroup.Addon>
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Numéro de référence</Form.ControlLabel>
          <Input
            value={values.referenceNumber}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.referenceNumber = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              loading={loading}
              appearance="primary"
              onClick={() => {
                onSubmit(values);
              }}
            >
              Enregistrer
            </Button>
            <Button appearance="default" onClick={onCancel}>
              Annuler
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};
