import "./index.less";
import { FunctionComponent } from "react";
import { Form, List, SelectPicker } from "rsuite";
import produce from "immer";
import { FormError } from "buildingBlocks";
import { ProjectUnit } from "graphql/__generated__/ProjectUnit";
import { ProjectModel } from "graphql/__generated__/ProjectModel";

export type ProjectUnitsUpdateFormValues = {
  readonly projectUnits: {
    readonly id: string;
    readonly projectModelId?: string | null;
    readonly hasProjectModel: boolean;
  }[];
};

type Props = {
  readonly projectUnits: ReadonlyArray<ProjectUnit>;
  readonly projectModels: ReadonlyArray<ProjectModel>;
  readonly errors: string[];
  readonly loading: boolean;
  readonly values: ProjectUnitsUpdateFormValues;
  readonly setValues: (values: ProjectUnitsUpdateFormValues) => void;
};

export const ProjectUnitsUpdateForm: FunctionComponent<Props> = ({ projectUnits, projectModels, errors, loading, values, setValues }) => {
  const floors = projectUnits
    .map((projectUnit) => projectUnit.floor)
    .filter((value, index, array) => array.indexOf(value) === index)
    .sort((left, right) => left - right);
  return (
    <>
      <FormError errors={errors} />
      <Form id="project-units-update-form" fluid>
        {floors.map((floor) => {
          const units = projectUnits.filter((projectUnit) => projectUnit.floor === floor);
          return (
            <div className="project-unit-group" key={floor}>
              <h2>{`${floor}${floor === 1 ? "er" : "e"} étage`}</h2>
              <List className="project-unit-list">
                {units.map((unit, index) => {
                  const projectUnit = values.projectUnits.find((projectUnit) => projectUnit.id === unit.id)!;
                  return (
                    <List.Item className="project-unit-list-item" key={unit.id} index={index + 1}>
                      <div className="project-unit">
                        <h3>Unité {unit.number}</h3>
                      </div>
                      <div className="project-unit-inputs">
                        <Form.Group>
                          <Form.ControlLabel>Modèle</Form.ControlLabel>
                          <SelectPicker
                            cleanable={projectUnit.hasProjectModel}
                            readOnly={loading}
                            value={projectUnit.projectModelId}
                            onChange={(value) =>
                              setValues(
                                produce(values, (draft) => {
                                  const draftProjectUnit = draft.projectUnits.find((draftProjectUnit) => draftProjectUnit.id === projectUnit.id);
                                  if (draftProjectUnit) {
                                    draftProjectUnit.projectModelId = value;
                                  }
                                })
                              )
                            }
                            data={projectModels
                              .filter((projectModel) => !projectModel.isDraft)
                              .map((projectModel) => ({
                                label: projectModel.name,
                                value: projectModel.id,
                              }))}
                          />
                        </Form.Group>
                      </div>
                    </List.Item>
                  );
                })}
              </List>
            </div>
          );
        })}
      </Form>
    </>
  );
};
