import "./index.less";
import { FunctionComponent } from "react";
import { Dropdown, FlexboxGrid, IconButton, Popover, Whisper } from "rsuite";
import MoreIcon from "@rsuite/icons/legacy/More";
import { useAuth, useRouting } from "hooks";
import { Permission } from "enums";

type Props = {
  readonly colspan: number;
  readonly actions: {
    readonly content: React.ReactNode;
    readonly href: string;
    readonly permissions: Permission[][];
    readonly show: boolean;
  }[];
};

export const ListItemActions: FunctionComponent<Props> = ({ colspan, actions }) => {
  const { hasPermissions } = useAuth();
  const { navigate } = useRouting();
  return (
    <FlexboxGrid.Item className="list-item-actions" colspan={colspan}>
      <Whisper
        placement="autoVerticalEnd"
        trigger="click"
        speaker={({ onClose, left, top, className }, ref) => {
          actions = actions.filter((action) => action.permissions.some((permissions) => hasPermissions(permissions))).filter((action) => action.show);
          return (
            <Popover ref={ref} className={className} style={{ left, top }} full>
              <Dropdown.Menu
                disabled={actions.length === 0}
                onSelect={() => {
                  onClose();
                }}
              >
                {actions
                  .filter((action) => action.permissions.some((permissions) => hasPermissions(permissions)))
                  .filter((action) => action.show)
                  .map((action, index) => (
                    <Dropdown.Item
                      key={action.href}
                      eventKey={index}
                      onClick={() => {
                        navigate(action.href);
                      }}
                    >
                      {action.content}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Popover>
          );
        }}
      >
        <IconButton size="sm" appearance="primary" icon={<MoreIcon />} />
      </Whisper>
    </FlexboxGrid.Item>
  );
};
