import "./index.less";
import { FunctionComponent, useState } from "react";
import { Link } from "react-router-dom";
import { ListItemActions, SearchInputGroup, View, ViewFilters } from "buildingBlocks";
import { Permission } from "enums";
import { useAppContext, useLayout, useProjectContext, useRouting } from "hooks";
import { FlexboxGrid, List, CheckPicker } from "rsuite";
import { useList } from "react-use";

export const ProjectProductsView: FunctionComponent = () => {
  const { searchParams, toProjectsView, toProjectView, toProviderView, toProjectProductView, toProjectProductUpdateView, toProjectProductDeleteView, toProjectModelView, toProjectProductsUpdateView } =
    useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  const [activityIds, { set: setActivityIds }] = useList<string>(searchParams.get("activityIds")?.split(",") ?? []);
  const [providerIds, { set: setProviderIds }] = useList<string>(searchParams.get("providerIds")?.split(",") ?? []);
  const [modelIds, { set: setModelIds }] = useList<string>(searchParams.get("modelIds")?.split(",") ?? []);
  const { providers } = useAppContext();
  const { project, projectProducts, projectModels, projectActivities, projectProductCategories } = useProjectContext();
  useLayout({
    sidebarActions: [],
    sidebarButtons: [
      {
        label: "Modifier les produits",
        href: toProjectProductsUpdateView(project.id),
        permissions: [[Permission.UPDATE_PROJECT_PRODUCTS]],
        enabled: projectProducts.length !== 0,
      },
    ],
  });
  return (
    <View
      id="project-products-view"
      permissions={[[Permission.READ_PROJECT_PRODUCTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Produits",
        },
      ]}
    >
      <ViewFilters>
        <SearchInputGroup placeholder="Recherche par nom" onChange={setSearch} />
        <CheckPicker
          size="md"
          label="Fournisseurs"
          defaultValue={providerIds}
          onChange={setProviderIds}
          data={projectProducts
            .filter((projectProduct) => (projectProduct.provider.id === null ? false : providers.find((provider) => provider.id === projectProduct.provider.id)))
            .map((provider) => ({
              label: provider.name,
              value: provider.id,
            }))}
          style={{ width: 250 }}
        />
        <CheckPicker
          size="md"
          label="Activités"
          defaultValue={activityIds}
          onChange={setActivityIds}
          data={projectActivities
            .filter((projectActivity) => projectActivity.productIds.length !== 0)
            .map((projectActivity) => ({
              label: projectActivity.label,
              value: projectActivity.id,
            }))}
          style={{ width: 250 }}
        />
        <CheckPicker
          size="md"
          label="Modèles"
          defaultValue={modelIds}
          onChange={setModelIds}
          data={projectModels
            .filter((projectModel) => projectModel.productIds.length !== 0)
            .map((projectModel) => ({
              label: projectModel.name,
              value: projectModel.id,
            }))}
          style={{ width: 250 }}
        />
      </ViewFilters>
      {projectProductCategories
        .filter((projectProductCategory) =>
          projectProducts
            .filter((projectProduct) => projectProductCategory.productIds.includes(projectProduct.id))
            .some((projectProduct) => (activityIds.length !== 0 ? projectProduct.activityIds.some((activityId) => activityIds.includes(activityId)) : true))
        )
        .filter((projectProductCategory) =>
          projectProducts
            .filter((projectProduct) => projectProductCategory.productIds.includes(projectProduct.id))
            .some((projectProduct) => (modelIds.length !== 0 ? projectProduct.modelIds.some((modelId) => modelIds.includes(modelId)) : true))
        )
        .filter((projectProductCategory) =>
          projectProducts
            .filter((projectProduct) => projectProductCategory.productIds.includes(projectProduct.id))
            .some((projectProduct) => (providerIds.length !== 0 ? (projectProduct.provider && projectProduct.provider.id ? providerIds.includes(projectProduct.provider.id) : false) : true))
        )
        .filter((projectProductCategory) =>
          projectProducts.filter((projectProduct) => projectProductCategory.productIds.includes(projectProduct.id)).some((product) => product.name.toSearchFormat().includes(search.toSearchFormat()))
        )
        .map((productCategory) => {
          const data = projectProducts
            .filter((projectProduct) => productCategory.productIds.includes(projectProduct.id))
            .filter((projectProduct) => (activityIds.length !== 0 ? projectProduct.activityIds.some((activityId) => activityIds.includes(activityId)) : true))
            .filter((projectProduct) => (modelIds.length !== 0 ? projectProduct.modelIds.some((modelId) => modelIds.includes(modelId)) : true))
            .filter((projectProduct) => (providerIds.length !== 0 ? (projectProduct.provider && projectProduct.provider.id ? providerIds.includes(projectProduct.provider.id) : false) : true))
            .filter((projectProduct) => projectProduct.name.toSearchFormat().includes(search.toSearchFormat()));
          return (
            <div key={productCategory.id}>
              <h2>{productCategory.name}</h2>
              <List className="project-product-list">
                {data.map((product, index) => {
                  const provider = providers.find((provider) => provider.id === product.provider.id);
                  const productModels = projectModels.filter((projectModel) => product.modelIds.includes(projectModel.id));
                  const productActivities = projectActivities.filter((projectActivity) => product.activityIds.includes(projectActivity.id));
                  return (
                    <List.Item key={product.id} index={index + 1}>
                      <FlexboxGrid>
                        <FlexboxGrid.Item colspan={7}>
                          {/** TODO: */}
                          <div className="project-product">
                            {product.hasThumbnail ? (
                              <img
                                className="project-product-image"
                                alt="Vignette du produit"
                                src={`${process.env.REACT_APP_AZURE_STORAGE_BLOB_SERVICE_URL}/project-products/${product.id}/thumbnail.png`}
                              />
                            ) : (
                              <div className="project-product-image" />
                            )}
                            <div>
                              <h3>{product.variant ?? product.name}</h3>
                              <span className="project-product-description">{product.description ?? "Pas de description."}</span>
                            </div>
                          </div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={5}>
                          <span className="subtile">Fourni par</span>
                          <div className="project-product-provider-name">{provider ? <Link to={toProviderView(provider.id)}>{provider.name}</Link> : "N/A"}</div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={5}>
                          <div>
                            <span className="subtile">Inclut(s) dans</span>
                            <ul>{productActivities.length !== 0 ? productActivities.map((activity) => <li key={activity.id}>{activity.label}</li>) : <li>N/A</li>}</ul>
                          </div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={5}>
                          <div>
                            <span className="subtile">Utilisé(s) par</span>
                            <ul>
                              {productModels.length !== 0 ? (
                                productModels.map((model) => (
                                  <li key={model.id}>
                                    <Link to={toProjectModelView(project.id, model.id)}>{model.name}</Link>
                                  </li>
                                ))
                              ) : (
                                <li>N/A</li>
                              )}
                            </ul>
                          </div>
                        </FlexboxGrid.Item>
                        <ListItemActions
                          colspan={2}
                          actions={[
                            {
                              href: toProjectProductView(project.id, product.id),
                              content: "Voir le produit",
                              permissions: [[Permission.READ_PROJECT_PRODUCTS]],
                              show: true,
                            },
                            {
                              href: toProjectProductUpdateView(project.id, product.id),
                              content: "Modifier le produit",
                              permissions: [[Permission.UPDATE_PROJECT_PRODUCTS]],
                              show: true,
                            },
                            {
                              href: toProjectProductDeleteView(project.id, product.id),
                              content: "Supprimer le produit",
                              permissions: [[Permission.DELETE_PROJECT_PRODUCTS]],
                              show: product.modelIds.length === 0,
                            },
                          ]}
                        />
                      </FlexboxGrid>
                    </List.Item>
                  );
                })}
              </List>
            </div>
          );
        })}
    </View>
  );
};
