import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext, useProjectModelContext } from "hooks";
import { useDeleteProjectModelMutation } from "graphql/mutations";
import { useList } from "react-use";
import { DeleteForm } from "forms";

export const ProjectModelDeleteView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectModelsView, toProjectModelView } = useRouting();
  const { project } = useProjectContext();
  const { projectModel } = useProjectModelContext();
  const [errors, { set: setErrors }] = useList<string>([]);
  const { deleteProjectModel, deleteProjectModelLoading } = useDeleteProjectModelMutation((projectId) => {
    navigate(toProjectModelsView(projectId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Supprimer le modèle"
      permissions={[[Permission.READ_PROJECT_MODELS, Permission.DELETE_PROJECT_MODELS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Modèles",
          href: toProjectModelsView(project.id),
        },
        {
          label: projectModel.name,
          href: toProjectModelView(project.id, projectModel.id),
        },
      ]}
    >
      <DeleteForm
        label="Nom du modèle"
        value={projectModel.name}
        confirm="Veuillez saisir le nom du modèle pour confirmer."
        errors={errors}
        loading={deleteProjectModelLoading}
        onSubmit={async () => {
          await deleteProjectModel(project.id, projectModel.id);
        }}
        onCancel={() => {
          navigate(toProjectModelView(project.id, projectModel.id));
        }}
      >
        Le modèle sera définitivement supprimé. <strong>Cette opération est IRRÉVERSIBLE.</strong>
      </DeleteForm>
    </View>
  );
};
