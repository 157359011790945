export enum Role {
  SA = "SA",
  ADMINISTRATOR = "ADMINISTRATOR",
  CUSTOMER = "CUSTOMER",
  MANAGER = "MANAGER",
  READER = "READER",
  SELLER = "SELLER",
  ALL_PROJECTS = "ALL_PROJECTS",
}

export enum Permission {
  READ_CUSTOMERS = "read:customers",
  CREATE_CUSTOMERS = "create:customers",
  UPDATE_CUSTOMERS = "update:customers",
  DELETE_CUSTOMERS = "delete:customers",
  RESEND_CUSTOMERS_CREDENTIALS = "resend:customers:credentials",
  READ_PROJECTS = "read:projects",
  CREATE_PROJECTS = "create:projects",
  UPDATE_PROJECTS = "update:projects",
  DELETE_PROJECTS = "delete:projects",
  READ_PROJECT_ACTIVITIES = "read:project_activities",
  CREATE_PROJECT_ACTIVITIES = "create:project_activities",
  UPDATE_PROJECT_ACTIVITIES = "update:project_activities",
  DELETE_PROJECT_ACTIVITIES = "delete:project_activities",
  READ_PROJECT_CONTRACTS = "read:project_contracts",
  CREATE_PROJECT_CONTRACTS = "create:project_contracts",
  CANCEL_PROJECT_CONTRACTS = "cancel:project_contracts",
  CREATE_PROJECT_CONTRACT_CHOICES = "create:project_contract_choices",
  UPDATE_PROJECT_CONTRACT_REQUESTS = "update:project_contract_requests",
  READ_PROJECT_CONTRACT_INVOICES = "read:project_contract_invoices",
  READ_PROJECT_CONTRACT_PAYMENTS = "read:project_contract_payments",
  CREATE_PROJECT_CONTRACT_PAYMENTS = "create:project_contract_payments",
  UPDATE_PROJECT_CONTRACT_PAYMENTS = "update:project_contract_payments",
  DELETE_PROJECT_CONTRACT_PAYMENTS = "delete:project_contract_payments",
  READ_PROJECT_MODELS = "read:project_models",
  PUBLISH_PROJECT_MODELS = "publish:project_models",
  UPLOAD_PROJECT_MODELS = "upload:project_models",
  UPDATE_PROJECT_MODELS = "update:project_models",
  DELETE_PROJECT_MODELS = "delete:project_models",
  READ_PROJECT_MODEL_VIBES = "read:project_model_vibes",
  CREATE_PROJECT_MODEL_VIBES = "create:project_model_vibes",
  UPDATE_PROJECT_MODEL_VIBES = "update:project_model_vibes",
  DELETE_PROJECT_MODEL_VIBES = "delete:project_model_vibes",
  PUBLISH_PROJECT_MODEL_VIBES = "publish:project_model_vibes",
  UPDATE_PROJECT_REPORT_RELATIONSHIPS = "update:project_report_relationships",
  READ_PROJECT_PRODUCTS = "read:project_products",
  UPDATE_PROJECT_PRODUCTS = "update:project_products",
  DELETE_PROJECT_PRODUCTS = "delete:project_products",
  DISABLE_PROJECT_PRODUCTS = "disable:project_products",
  READ_PROJECT_REPORTS = "read:project_reports",
  READ_PROJECT_REPORTS_CONSTRUCTION_SHEET_PER_FLOOR = "read:project_reports:construction_sheet_per_floor",
  READ_PROJECT_REPORTS_CONSTRUCTION_SHEET_PER_UNIT = "read:project_reports:construction_sheet_per_unit",
  READ_PROJECT_REPORTS_CUSTOMER_SHEET_PER_UNIT = "read:project_reports:customer_sheet_per_unit",
  READ_PROJECT_REPORT_SETUPS = "read:project_report_setups",
  UPDATE_PROJECT_REPORT_SETUPS = "update:project_report_setups",
  DELETE_PROJECT_REPORT_SETUPS = "delete:project_report_setups",
  READ_PROJECT_UNITS = "read:project_units",
  CREATE_PROJECT_UNITS = "create:project_units",
  UPDATE_PROJECT_UNITS = "update:project_units",
  DELETE_PROJECT_UNITS = "delete:project_units",
  UPLOAD_PROJECT_UNITS = "upload:project_units",
  READ_PROVIDERS = "read:providers",
  CREATE_PROVIDERS = "create:providers",
  UPDATE_PROVIDERS = "update:providers",
  DELETE_PROVIDERS = "delete:providers",
  READ_USERS = "read:users",
  UPDATE_USER_ACCESS = "update:user_access",
}
