import "./index.less";
import { FunctionComponent, useState } from "react";
import { Link } from "react-router-dom";
import { ListItemActions, SearchInputGroup, View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useProjectContext, useRouting } from "hooks";
import { List, FlexboxGrid, Tag } from "rsuite";

export const ProjectModelsView: FunctionComponent = () => {
  const {
    searchParams,
    toProjectsView,
    toProjectView,
    toProjectProductsView,
    toProjectModelView,
    toProjectModelPublishView,
    toProjectModelUploadView,
    toProjectModelDeleteView,
    toProjectModelUpdateView,
  } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  const { project, projectModels } = useProjectContext();
  useLayout({
    sidebarActions: [],
    sidebarButtons: [
      {
        label: "Téléverser un modèle",
        href: toProjectModelUploadView(project.id),
        permissions: [[Permission.UPLOAD_PROJECT_MODELS]],
      },
    ],
  });
  return (
    <View
      id="project-models-view"
      permissions={[[Permission.READ_PROJECT_MODELS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Modèles",
        },
      ]}
    >
      <SearchInputGroup placeholder="Recherche par étiquette" onChange={setSearch} />
      <List className="project-model-list">
        {projectModels
          .filter((projectModel) => projectModel.name.toSearchFormat().includes(search.toSearchFormat()))
          .map((projectModel, index) => {
            return (
              <List.Item key={projectModel.id} index={index + 1}>
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={6}>
                    <h3>{projectModel.name}</h3>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={8}>
                    {projectModel.isDraft ? (
                      <Tag size="lg">BROUILLON</Tag>
                    ) : (
                      <Tag size="lg" color="green">
                        PUBLIÉ
                      </Tag>
                    )}
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={8}>
                    <span className="subtile">Lié à</span>
                    <div>
                      <Link
                        to={toProjectProductsView(project.id, {
                          projectModelIds: [projectModel.id],
                        })}
                      >{`${projectModel.productIds.length} produit(s)`}</Link>
                    </div>
                  </FlexboxGrid.Item>
                  <ListItemActions
                    colspan={2}
                    actions={[
                      {
                        content: "Voir le modèle",
                        href: toProjectModelView(project.id, projectModel.id),
                        permissions: [[Permission.READ_PROJECT_MODELS]],
                        show: true,
                      },
                      {
                        content: "Modifier le modèle",
                        href: toProjectModelUpdateView(project.id, projectModel.id),
                        permissions: [[Permission.UPDATE_PROJECT_MODELS]],
                        show: true,
                      },
                      {
                        content: "Publier le modèle",
                        href: toProjectModelPublishView(project.id, projectModel.id),
                        permissions: [[Permission.PUBLISH_PROJECT_MODELS]],
                        show: projectModel.isDraft,
                      },
                      {
                        content: "Supprimer le modèle",
                        href: toProjectModelDeleteView(project.id, projectModel.id),
                        permissions: [[Permission.DELETE_PROJECT_MODELS]],
                        show: projectModel.vibes.every((projectModelVibe) => projectModelVibe.customerIds.length === 0) && projectModel.isDraft,
                      },
                    ]}
                  />
                </FlexboxGrid>
              </List.Item>
            );
          })}
      </List>
    </View>
  );
};
