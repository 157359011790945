import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext, useProjectModelContext } from "hooks";
import { useUpdateProjectModelMutation } from "graphql/mutations";
import { useList } from "react-use";
import { ProjectModelForm } from "forms/ProjectModelForm";

export const ProjectModelUpdateView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectModelsView, toProjectModelView } = useRouting();
  const { project } = useProjectContext();
  const { projectModel } = useProjectModelContext();
  const [errors, { set: setErrors }] = useList<string>();
  const { updateProjectModel, updateProjectModelLoading } = useUpdateProjectModelMutation((projectId, projectModelId) => {
    navigate(toProjectModelView(projectId, projectModelId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Modifier le projet"
      permissions={[[Permission.READ_PROJECT_MODELS, Permission.UPDATE_PROJECT_MODELS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Modèles",
          href: toProjectModelsView(project.id),
        },
        {
          label: projectModel.name,
          href: toProjectModelView(project.id, projectModel.id),
        },
      ]}
    >
      <ProjectModelForm
        projectModel={projectModel}
        initialValues={{
          name: projectModel.name,
          projectModelElementOptionPrices: projectModel.elements
            .map((projectModelElement) => projectModelElement.options)
            .reduce((left, right) => left.concat(right), [])
            .filter((projectModelElementOption) => projectModelElementOption.isExtra)
            .map((projectModelElementOption) => ({
              projectModelElementOptionId: projectModelElementOption.id,
              amount: projectModelElementOption.price ?? 0,
            })),
        }}
        errors={errors}
        loading={updateProjectModelLoading}
        onSubmit={async (values) => {
          await updateProjectModel(project.id, projectModel.id, {
            name: values.name,
            projectModelElementOptionPrices: values.projectModelElementOptionPrices.map((projectModelElementOptionPrice) => ({
              projectModelElementOptionId: projectModelElementOptionPrice.projectModelElementOptionId,
              amount: projectModelElementOptionPrice.amount,
            })),
          });
        }}
        onCancel={() => {
          navigate(toProjectModelView(project.id, projectModel.id));
        }}
      />
    </View>
  );
};
