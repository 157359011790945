import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useProjectContext, useRouting } from "hooks";
import { Row, Col } from "rsuite";
import { ChartPanel, StatsPanel } from "components";
import { PieChart } from "@rsuite/charts";

export const ProjectView: FunctionComponent = () => {
  const { toProjectsView, toProjectDeleteView, toProjectUpdateView } = useRouting();
  const { project, projectContracts, projectUnits } = useProjectContext();
  const data: any = [
    [
      "En cours",
      projectContracts.filter(
        (projectContract) =>
          !projectContract.isCanceled && !projectContract.isCustomized && (projectContract.choices.some((projectContractChoice) => projectContractChoice.isDraft) || projectContract.balance !== 0)
      ).length,
    ],
    ["En personnalisation", projectContracts.filter((projectContract) => !projectContract.isCanceled && projectContract.isCustomized).length],
    [
      "Terminé",
      projectContracts.filter(
        (projectContract) =>
          !projectContract.isCanceled && !projectContract.isCustomized && projectContract.choices.every((projectContractChoice) => !projectContractChoice.isDraft) && projectContract.balance === 0
      ).length,
    ],
  ];
  useLayout({
    sidebarActions: [],
    sidebarButtons: [
      {
        label: "Modifier le projet",
        href: toProjectUpdateView(project.id),
        permissions: [[Permission.UPDATE_PROJECTS]],
      },
      {
        label: "Supprimer le projet",
        href: toProjectDeleteView(project.id),
        permissions: [[Permission.DELETE_PROJECTS]],
        enabled: projectContracts.length === 0,
      },
    ],
  });
  return (
    <View
      title={project.label}
      permissions={[[Permission.READ_PROJECTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
        },
      ]}
    >
      <Row>
        <Col xl={6} lg={12} md={24} sm={24}>
          <StatsPanel label="Unités disponibles">
            {projectUnits.filter((projectUnit) => projectUnit.currentProjectContractId == null).length}/{projectUnits.length}
          </StatsPanel>
        </Col>
        <Col xl={6} lg={12} md={24} sm={24}>
          <StatsPanel label="Nombre de client">
            {
              projectContracts
                .filter((projectContract) => !projectContract.isCanceled)
                .map((projectContract) => projectContract.customerId)
                .filter((value, index, array) => array.indexOf(value) === index).length
            }
          </StatsPanel>
        </Col>
        <Col xl={6} lg={12} md={24} sm={24}>
          <StatsPanel label="Total facturé">
            {projectContracts
              .map((projectContract) =>
                projectContract.invoices.map((projectContractInvoice) => Number(projectContractInvoice.total)).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
              )
              .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
              .toCurrencyFormat("CAD")}
          </StatsPanel>
        </Col>
        <Col xl={6} lg={12} md={24} sm={24}>
          <StatsPanel label="Total payé">
            {projectContracts
              .map((projectContract) =>
                projectContract.payments.map((projectContractPayment) => Number(projectContractPayment.amount)).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
              )
              .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
              .toCurrencyFormat("CAD")}
          </StatsPanel>
        </Col>
        {/* <Col xl={16} lg={24} md={24} sm={24}>
          <ChartPanel label="Statut des contrats">
            <BarChart data={data} legend={false}>
              <YAxis axisLabel={(value) => String(value)} splitLine={false} />
              <Bars name="休眠客户" />
              <Bars name="新客" />
              <Bars name="非品牌用户" />
              <Bars name="老客" />
              <Bars name="流失至竞品" />
              <Bars name="来自竞品" />
            </BarChart>
          </ChartPanel>
        </Col> */}
        <Col xl={8} lg={24} md={24} sm={24}>
          <ChartPanel label="Statut des contrats">
            <PieChart
              name="Statut"
              data={data}
              legend={false}
              label={{
                verticalAlign: "bottom",
              }}
              percentPrecision={3}
            />
          </ChartPanel>
        </Col>
      </Row>
    </View>
  );
};
