import "./index.less";
import { FunctionComponent, useState } from "react";
import { Button, ButtonToolbar, Form, Input } from "rsuite";
import { ProjectUnit } from "graphql/__generated__/ProjectUnit";
import { FormError } from "buildingBlocks";
import { ProjectContract } from "graphql/__generated__/ProjectContract";
import { Customer } from "graphql/__generated__/Customer";

type Props = {
  readonly customers: ReadonlyArray<Customer>;
  readonly projectContract: ProjectContract & {
    readonly projectUnit: ProjectUnit;
  };
  readonly errors: string[];
  readonly loading: boolean;
  readonly onSubmit: () => Promise<void>;
  readonly onCancel: () => void;
};

export const ProjectContractCancelForm: FunctionComponent<Props> = ({ customers, projectContract, errors, loading, onSubmit, onCancel }) => {
  const [customerFullNameInput, setCustomerFullNameInput] = useState<string>("");
  const customer = customers.find((customer) => customer.id === projectContract.customerId);
  const customerFullName = customer?.fullName;
  return (
    <>
      <FormError errors={errors} />
      <Form id="customer-contract-cancel-form" className="form" fluid>
        <div className="rs-form-group" role="group">
          <label className="rs-form-control-label">Unité #</label>
          <div className="rs-form-control rs-form-control-wrapper">
            <div className="rs-plaintext">{projectContract.projectUnit.number}</div>
          </div>
        </div>
        <div className="rs-form-group" role="group">
          <label className="rs-form-control-label">Nom du client</label>
          <div className="rs-form-control rs-form-control-wrapper">
            <div className="rs-plaintext">{customerFullName}</div>
          </div>
        </div>
        <Form.Group>
          <p>Si le client a déjà été facturer et que cette facture a été payer, il vous sera possible d'effectuer un rembourssement.</p>
          <p>
            Cette opération est <strong>IRRÉVERSIBLE</strong>.
          </p>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Veuillez saisir le nom du client pour confirmer.</Form.ControlLabel>
          <Input onChange={setCustomerFullNameInput} size="lg" />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button appearance="primary" loading={loading} disabled={customerFullNameInput !== customerFullName} onClick={onSubmit}>
              Confimer
            </Button>
            <Button
              disabled={loading}
              appearance="default"
              onClick={() => {
                onCancel();
              }}
            >
              Annuler
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};
