import { useNavigate, useSearchParams } from "react-router-dom";

export const useRouting = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const toCustomersView = () => {
    return `/customers`;
  };
  const toCustomerView = (customerId: string) => {
    return `/customers/${customerId}`;
  };
  const toCustomerCreateView = () => {
    return `/customers/create`;
  };
  const toCustomerDeleteView = (customerId: string) => {
    return `/customers/${customerId}/delete`;
  };
  const toCustomerUpdateView = (customerId: string) => {
    return `/customers/${customerId}/update`;
  };
  const toCustomerCredentialsResendView = (customerId: string) => {
    return `/customers/${customerId}/credentials/resend`;
  };
  const toProvidersView = () => {
    return `/providers`;
  };
  const toProviderView = (providerId: string) => {
    return `/providers/${providerId}`;
  };
  const toProviderCreateView = () => {
    return `/providers/create`;
  };
  const toProviderDeleteView = (providerId: string) => {
    return `/providers/${providerId}/delete`;
  };
  const toProviderUpdateView = (providerId: string) => {
    return `/providers/${providerId}/update`;
  };
  const toProjectsView = () => {
    return `/projects`;
  };
  const toProjectCreateView = () => {
    return `/projects/create`;
  };
  const toProjectView = (projectId: string) => {
    return `/projects/${projectId}`;
  };
  const toProjectUpdateView = (projectId: string) => {
    return `/projects/${projectId}/update`;
  };
  const toProjectDeleteView = (projectId: string) => {
    return `/projects/${projectId}/delete`;
  };
  const toProjectUnitsView = (projectId: string) => {
    return `/projects/${projectId}/units`;
  };
  const toProjectUnitCreateView = (projectId: string) => {
    return `/projects/${projectId}/units/create`;
  };
  const toProjectUnitsUploadView = (projectId: string) => {
    return `/projects/${projectId}/units/upload`;
  };
  const toProjectUnitsUpdateView = (projectId: string) => {
    return `/projects/${projectId}/units/update`;
  };
  const toProjectUnitUpdateView = (projectId: string, projectUnitId: string) => {
    return `/projects/${projectId}/units/${projectUnitId}/update`;
  };
  const toProjectUnitDeleteView = (projectId: string, projectUnitId: string) => {
    return `/projects/${projectId}/units/${projectUnitId}/delete`;
  };
  const toProjectProductsView = (
    projectId: string,
    searchParams?: {
      activityIds?: string[];
      projectModelIds?: string[];
    }
  ) => {
    let url = `/projects/${projectId}/products`;
    if (searchParams) {
      const init: string[][] = [];
      if (searchParams.activityIds && searchParams.activityIds.length !== 0) {
        init.push(["activityIds", searchParams.activityIds.join(",")]);
      }
      if (searchParams.projectModelIds && searchParams.projectModelIds.length !== 0) {
        init.push(["projectModelIds", searchParams.projectModelIds.join(",")]);
      }
      url += `?${new URLSearchParams(init).toString()}`;
    }
    return url;
  };
  const toProjectProductView = (projectId: string, projectProductId: string) => {
    return `/projects/${projectId}/products/${projectProductId}`;
  };
  const toProjectProductsUpdateView = (projectId: string) => {
    return `/projects/${projectId}/products/update`;
  };
  const toProjectProductUpdateView = (projectId: string, projectProductId: string) => {
    return `/projects/${projectId}/products/${projectProductId}/update`;
  };
  const toProjectProductDeleteView = (projectId: string, projectProductId: string) => {
    return `/projects/${projectId}/products/${projectProductId}/delete`;
  };
  const toProjectModelsView = (projectId: string) => {
    return `/projects/${projectId}/models`;
  };
  const toProjectModelUploadView = (projectId: string) => {
    return `/projects/${projectId}/models/upload`;
  };
  const toProjectModelView = (projectId: string, projectModelId: string) => {
    return `/projects/${projectId}/models/${projectModelId}`;
  };
  const toProjectModelUpdateView = (projectId: string, projectModelId: string) => {
    return `/projects/${projectId}/models/${projectModelId}/update`;
  };
  const toProjectModelDeleteView = (projectId: string, projectModelId: string) => {
    return `/projects/${projectId}/models/${projectModelId}/delete`;
  };
  const toProjectModelPublishView = (projectId: string, projectModelId: string) => {
    return `/projects/${projectId}/models/${projectModelId}/publish`;
  };
  const toProjectModelVibesView = (projectId: string, projectModelId: string) => {
    return `/projects/${projectId}/models/${projectModelId}/vibes`;
  };
  const toProjectModelVibeCreateView = (projectId: string, projectModelId: string) => {
    return `/projects/${projectId}/models/${projectModelId}/vibes/create`;
  };
  const toProjectModelVibeView = (projectId: string, projectModelId: string, projectModelVibeId: string) => {
    return `/projects/${projectId}/models/${projectModelId}/vibes/${projectModelVibeId}`;
  };
  const toProjectModelVibeUpdateView = (projectId: string, projectModelId: string, projectModelVibeId: string) => {
    return `/projects/${projectId}/models/${projectModelId}/vibes/${projectModelVibeId}/update`;
  };
  const toProjectModelVibeDeleteView = (projectId: string, projectModelId: string, projectModelVibeId: string) => {
    return `/projects/${projectId}/models/${projectModelId}/vibes/${projectModelVibeId}/delete`;
  };
  const toProjectModelVibePublishView = (projectId: string, projectModelId: string, projectModelVibeId: string) => {
    return `/projects/${projectId}/models/${projectModelId}/vibes/${projectModelVibeId}/publish`;
  };
  const toProjectReportRelationshipsUpdateView = (projectId: string, projectModelId: string) => {
    return `/projects/${projectId}/models/${projectModelId}/relationships/manage`;
  };
  const toProjectActivitiesView = (projectId: string) => {
    return `/projects/${projectId}/activities`;
  };
  const toProjectActivityCreateView = (projectId: string) => {
    return `/projects/${projectId}/activities/create`;
  };
  const toProjectActivityUpdateView = (projectId: string, projectActivityId: string) => {
    return `/projects/${projectId}/activities/${projectActivityId}/update`;
  };
  const toProjectActivityDeleteView = (projectId: string, projectActivityId: string) => {
    return `/projects/${projectId}/activities/${projectActivityId}/delete`;
  };
  const toProjectContractsView = (projectId: string) => {
    return `/projects/${projectId}/contracts`;
  };
  const toProjectContractAssociateView = (
    projectId: string,
    searchParams?: {
      projectUnitId: string | undefined;
    }
  ) => {
    let url = `/projects/${projectId}/contracts/associate`;
    if (searchParams) {
      const init: string[][] = [];
      if (searchParams.projectUnitId) {
        init.push(["projectUnitId", searchParams.projectUnitId]);
      }
      url += `?${new URLSearchParams(init).toString()}`;
    }
    return url;
  };
  const toProjectContractView = (projectId: string, projectContractId: string) => {
    return `/projects/${projectId}/contracts/${projectContractId}`;
  };
  const toProjectContractCancelView = (projectId: string, projectContractId: string) => {
    return `/projects/${projectId}/contracts/${projectContractId}/cancel`;
  };
  const toProjectContractRequestsUpsertView = (projectId: string, projectContractId: string) => {
    return `/projects/${projectId}/contracts/${projectContractId}/requests/update`;
  };
  const toProjectContractChoicesView = (projectId: string, projectContractId: string) => {
    return `/projects/${projectId}/contracts/${projectContractId}/choices`;
  };
  const toProjectContractChoiceCreateView = (projectId: string, projectContractId: string) => {
    return `/projects/${projectId}/contracts/${projectContractId}/choices/create`;
  };
  const toProjectContractChoiceView = (projectId: string, projectContractId: string, customerChoiceId: string) => {
    return `/projects/${projectId}/contracts/${projectContractId}/choices/${customerChoiceId}`;
  };
  const toProjectContractInvoicesView = (
    projectId: string,
    projectContractId: string,
    searchParams?: {
      readonly customerChoiceIds?: string[];
    }
  ) => {
    let url = `/projects/${projectId}/contracts/${projectContractId}/invoices`;
    if (searchParams) {
      const init: string[][] = [];
      if (searchParams.customerChoiceIds && searchParams.customerChoiceIds.length !== 0) {
        init.push(["customerChoiceIds", searchParams.customerChoiceIds.join(",")]);
      }
      url += `?${new URLSearchParams(init).toString()}`;
    }
    return url;
  };
  const toProjectContractInvoiceView = (projectId: string, projectContractId: string, customerInvoiceId: string) => {
    return `/projects/${projectId}/contracts/${projectContractId}/invoices/${customerInvoiceId}`;
  };
  const toProjectContractPaymentsView = (
    projectId: string,
    projectContractId: string,
    searchParams?: {
      readonly customerInvoiceIds?: string[];
    }
  ) => {
    let url = `/projects/${projectId}/contracts/${projectContractId}/payments`;
    if (searchParams) {
      const init: string[][] = [];
      if (searchParams.customerInvoiceIds && searchParams.customerInvoiceIds.length !== 0) {
        init.push(["customerInvoiceIds", searchParams.customerInvoiceIds.join(",")]);
      }
      url += `?${new URLSearchParams(init).toString()}`;
    }
    return url;
  };
  const toProjectContractPaymentCreateView = (
    projectId: string,
    projectContractId: string,
    searchParams?: {
      readonly customerInvoiceId?: string;
    }
  ) => {
    let url = `/projects/${projectId}/contracts/${projectContractId}/payments/create`;
    if (searchParams) {
      const init: string[][] = [];
      if (searchParams.customerInvoiceId) {
        init.push(["customerInvoiceId", searchParams.customerInvoiceId]);
      }
      url += `?${new URLSearchParams(init).toString()}`;
    }
    return url;
  };
  const toProjectContractPaymentUpdateView = (projectId: string, projectContractId: string, customerPaymentId: string) => {
    return `/projects/${projectId}/contracts/${projectContractId}/payments/${customerPaymentId}/update`;
  };
  const toProjectContractPaymentDeleteView = (projectId: string, projectContractId: string, customerPaymentId: string) => {
    return `/projects/${projectId}/contracts/${projectContractId}/payments/${customerPaymentId}/delete`;
  };
  const toProjectReportsView = (projectId: string) => {
    return `/projects/${projectId}/reports`;
  };
  const toProjectReportsConstructionSheetPerFloorView = (projectId: string) => {
    return `/projects/${projectId}/reports/construction-sheet-per-floor`;
  };
  const toProjectReportsConstructionSheetPerUnitView = (projectId: string) => {
    return `/projects/${projectId}/reports/construction-sheet-per-unit`;
  };
  const toProjectReportsCustomerSheetPerUnitView = (projectId: string) => {
    return `/projects/${projectId}/reports/customer-sheet-per-unit`;
  };
  const toProjectReportSetupsView = (projectId: string) => {
    return `/projects/${projectId}/reports/setups`;
  };
  const toProjectReportSetupView = (projectId: string, projectReportSetupId: string) => {
    return `/projects/${projectId}/reports/setups/${projectReportSetupId}`;
  };
  const toProjectReportSetupCreateView = (projectId: string) => {
    return `/projects/${projectId}/reports/setups/create`;
  };
  const toProjectReportSetupUpdateView = (projectId: string, projectReportSetupId: string) => {
    return `/projects/${projectId}/reports/setups/${projectReportSetupId}/update`;
  };
  const toProjectReportSetupDeleteView = (projectId: string, projectReportSetupId: string) => {
    return `/projects/${projectId}/reports/setups/${projectReportSetupId}/delete`;
  };
  const toUsersView = () => {
    return `/users`;
  };
  const toUserAccessUpdateView = (userId: string) => {
    return `/users/${userId}/access/update`;
  };
  return {
    searchParams,
    navigate,
    toCustomersView,
    toCustomerCreateView,
    toCustomerView,
    toCustomerUpdateView,
    toCustomerDeleteView,
    toCustomerCredentialsResendView,
    toProvidersView,
    toProviderCreateView,
    toProviderView,
    toProviderUpdateView,
    toProviderDeleteView,
    toProjectsView,
    toProjectCreateView,
    toProjectView,
    toProjectUpdateView,
    toProjectDeleteView,
    toProjectUnitsView,
    toProjectUnitCreateView,
    toProjectUnitsUploadView,
    toProjectUnitsUpdateView,
    toProjectUnitUpdateView,
    toProjectUnitDeleteView,
    toProjectProductsView,
    toProjectProductsUpdateView,
    toProjectProductView,
    toProjectProductUpdateView,
    toProjectProductDeleteView,
    toProjectModelsView,
    toProjectModelUploadView,
    toProjectModelView,
    toProjectModelUpdateView,
    toProjectModelDeleteView,
    toProjectModelPublishView,
    toProjectModelVibesView,
    toProjectModelVibeCreateView,
    toProjectModelVibeView,
    toProjectModelVibeUpdateView,
    toProjectModelVibeDeleteView,
    toProjectModelVibePublishView,
    toProjectReportRelationshipsUpdateView,
    toProjectActivitiesView,
    toProjectActivityCreateView,
    toProjectActivityUpdateView,
    toProjectActivityDeleteView,
    toProjectContractsView,
    toProjectContractAssociateView,
    toProjectContractView,
    toProjectContractCancelView,
    toProjectContractRequestsUpsertView,
    toProjectContractChoicesView,
    toProjectContractChoiceCreateView,
    toProjectContractChoiceView,
    toProjectContractInvoicesView,
    toProjectContractInvoiceView,
    toProjectContractPaymentsView,
    toProjectContractPaymentCreateView,
    toProjectContractPaymentUpdateView,
    toProjectContractPaymentDeleteView,
    toProjectReportsView,
    toProjectReportsConstructionSheetPerFloorView,
    toProjectReportsConstructionSheetPerUnitView,
    toProjectReportsCustomerSheetPerUnitView,
    toProjectReportSetupsView,
    toProjectReportSetupView,
    toProjectReportSetupCreateView,
    toProjectReportSetupUpdateView,
    toProjectReportSetupDeleteView,
    toUsersView,
    toUserAccessUpdateView,
  };
};
