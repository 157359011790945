import "./index.less";
import { FunctionComponent } from "react";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import { ProjectUnit } from "graphql/__generated__/ProjectUnit";
import { Customer } from "graphql/__generated__/Customer";
import { Project } from "graphql/__generated__/Project";
import { ProjectContract } from "graphql/__generated__/ProjectContract";
import { ProjectContractChoice } from "graphql/__generated__/ProjectContractChoice";

type Props = {
  readonly title?: string;
  readonly className?: string;
  readonly project: Project;
  readonly projectContract: ProjectContract;
  readonly projectContractChoice?: ProjectContractChoice;
  readonly projectModels: ReadonlyArray<ProjectModel>;
  readonly projectUnits: ReadonlyArray<ProjectUnit>;
  readonly customers: ReadonlyArray<Customer>;
  readonly withoutCustomer?: boolean;
  readonly withProjectCode?: boolean;
};

export const ProjectContractInfos: FunctionComponent<Props> = ({
  title = undefined,
  className = undefined,
  project,
  projectContract,
  projectContractChoice,
  projectModels,
  projectUnits,
  customers,
  withoutCustomer,
  withProjectCode = false,
}) => {
  const projectModel = projectModels.find((projectModel) => projectModel.id === projectContract.projectModelId);
  const projectModelVibe = projectModel?.vibes.find((projectModelVibe) => projectModelVibe.id === projectContractChoice?.projectModelVibeId);
  const projectUnit = projectUnits.find((projectUnit) => projectUnit.id === projectContract.projectUnitId);
  const customer = customers.find((customer) => customer.id === projectContract.customerId);
  return (
    <div className={`project-contract-infos${className ? ` ${className}` : ""}`}>
      {title && <div className="title">{title}</div>}
      <div className="panel">
        <div className="title">Informations</div>
        <div className="row">
          <div className="column">Projet</div>
          <div className="column">{withProjectCode ? project.label : project.name}</div>
        </div>
        <div className="row">
          <div className="column">Unité #</div>
          <div className="column">{projectUnit ? projectUnit.number : "--"}</div>
        </div>
        <div className="row">
          <div className="column">Étage</div>
          <div className="column">{projectUnit ? projectUnit.floor : "--"}</div>
        </div>
        <div className="row">
          <div className="column">Modèle</div>
          <div className="column">{projectModel ? projectModel.name : "--"}</div>
        </div>
        <div className="row">
          <div className="column">Ambiance</div>
          <div className="column">{projectModelVibe ? projectModelVibe.name : "--"}</div>
        </div>
        {projectContractChoice && (
          <>
            <div className="row">
              <div className="column">Révision</div>
              <div className="column">{projectContractChoice.label}</div>
            </div>
            <div className="row">
              <div className="column">Créer le</div>
              <div className="column">
                {new Date(projectContractChoice.createdAt).toLocaleString("fr-CA", {
                  year: "numeric",
                  day: "2-digit",
                  month: "long",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </div>
            </div>
            <div className="row">
              <div className="column">Modifié le</div>
              <div className="column">
                {new Date(projectContractChoice.updatedAt).toLocaleString("fr-CA", {
                  year: "numeric",
                  day: "2-digit",
                  month: "long",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </div>
            </div>
          </>
        )}
        {!withoutCustomer && (
          <>
            <div className="row">
              <div className="column">Nom du client</div>
              <div className="column">{customer ? `${customer.firstName} ${customer.lastName}` : "--"}</div>
            </div>
            <div className="row">
              <div className="column">Courriel du client</div>
              <div className="column">{customer ? customer.email : "--"}</div>
            </div>
            <div className="row">
              <div className="column">Téléphone du client</div>
              <div className="column">{customer ? customer.phone : "--"}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
