import "./index.less";
import { FunctionComponent, useState } from "react";
import { ListItemActions, SearchInputGroup, View } from "buildingBlocks";
import { Permission } from "enums";
import { useAppContext, useAuth, useLayout, useRouting } from "hooks";
import { List, FlexboxGrid } from "rsuite";

export const ProjectsView: FunctionComponent = () => {
  const { searchParams, toProjectView, toProjectCreateView, toProjectUpdateView } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  const { projects } = useAppContext();
  const { hasProjects } = useAuth();
  useLayout({
    sidebarActions: [],
    sidebarButtons: [
      {
        label: "Créer un projet",
        href: toProjectCreateView(),
        permissions: [[Permission.CREATE_PROJECTS]],
      },
    ],
  });
  return (
    <View
      id="projects-view"
      permissions={[[Permission.READ_PROJECTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
        },
      ]}
    >
      <SearchInputGroup placeholder="Recherche par étiquette" onChange={setSearch} />
      <List className="project-list">
        {projects
          .filter((project) => hasProjects([project.id]))
          .filter((project) => project.label.toSearchFormat().includes(search.toSearchFormat()))
          .map((project, index) => {
            return (
              <List.Item key={project.id} index={index + 1}>
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={22}>
                    <h3>{project.label}</h3>
                    <span className="project-description">{project.description ?? "Pas de description."}</span>
                  </FlexboxGrid.Item>
                  <ListItemActions
                    colspan={2}
                    actions={[
                      {
                        content: "Voir le projet",
                        href: toProjectView(project.id),
                        permissions: [[Permission.READ_PROJECTS]],
                        show: true,
                      },
                      {
                        content: "Modifier le projet",
                        href: toProjectUpdateView(project.id),
                        permissions: [[Permission.UPDATE_PROJECTS]],
                        show: true,
                      },
                    ]}
                  />
                </FlexboxGrid>
              </List.Item>
            );
          })}
      </List>
    </View>
  );
};
