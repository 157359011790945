import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useProjectContext, useProjectProductContext, useRouting } from "hooks";

export const ProjectProductView: FunctionComponent = () => {
  const { toProjectsView, toProjectView, toProjectProductsView, toProjectProductUpdateView, toProjectProductDeleteView } = useRouting();
  const { project } = useProjectContext();
  const { projectProduct } = useProjectProductContext();
  useLayout({
    sidebarActions: [],
    sidebarButtons: [
      {
        label: "Modifier le produit",
        href: toProjectProductUpdateView(project.id, projectProduct.id),
        permissions: [[Permission.UPDATE_PROJECT_PRODUCTS]],
      },
      {
        label: "Supprimer le produit",
        href: toProjectProductDeleteView(project.id, projectProduct.id),
        permissions: [[Permission.DELETE_PROJECT_PRODUCTS]],
        enabled: projectProduct.modelIds.length === 0,
      },
    ],
  });
  return (
    <View
      title={projectProduct.name}
      permissions={[[Permission.READ_PROJECT_PRODUCTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Produits",
          href: toProjectProductsView(project.id),
        },
        {
          label: projectProduct.name,
        },
      ]}
    ></View>
  );
};
