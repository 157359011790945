import "./index.less";
import { FunctionComponent } from "react";
import { ProjectReportSetupElement } from "graphql/__generated__/ProjectReportSetupElement";
import { ProjectReportSetupElementPanel } from "components";

type Props = {
  readonly projectReportSetupRoomName: string;
  readonly projectReportSetupElements: ReadonlyArray<ProjectReportSetupElement>;
};

export const ProjectReportSetupElementPanelGroup: FunctionComponent<Props> = ({ projectReportSetupRoomName, projectReportSetupElements }) => (
  <div className="project-report-setup-element-panel-group">
    <span className="project-report-setup-element-panel-group-title">{projectReportSetupRoomName}</span>
    {projectReportSetupElements.map((projectReportSetupElement) => (
      <ProjectReportSetupElementPanel key={projectReportSetupElement.id} projectReportSetupElement={projectReportSetupElement} />
    ))}
  </div>
);
