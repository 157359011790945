import { FunctionComponent } from "react";
import { ProjectModelVibeItemList } from "components";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useProjectContext, useProjectModelContext, useProjectModelVibeContext, useRouting } from "hooks";
import { Tag } from "rsuite";

export const ProjectModelVibeView: FunctionComponent = () => {
  const { toProjectsView, toProjectView, toProjectModelsView, toProjectModelView, toProjectModelVibesView, toProjectModelVibePublishView, toProjectModelVibeUpdateView, toProjectModelVibeDeleteView } =
    useRouting();
  const { project, projectProducts } = useProjectContext();
  const { projectModel } = useProjectModelContext();
  const { projectModelVibe } = useProjectModelVibeContext();
  useLayout({
    sidebarActions: [],
    sidebarButtons: [
      {
        label: "Publier l'ambiance",
        href: toProjectModelVibePublishView(project.id, projectModel.id, projectModelVibe.id),
        permissions: [[Permission.PUBLISH_PROJECT_MODEL_VIBES]],
        enabled: projectModelVibe.isDraft,
      },
      {
        label: "Modifier l'ambiance",
        href: toProjectModelVibeUpdateView(project.id, projectModel.id, projectModelVibe.id),
        permissions: [[Permission.UPDATE_PROJECT_MODEL_VIBES]],
        enabled: projectModelVibe.isDraft,
      },
      {
        label: "Supprimer l'ambiance",
        href: toProjectModelVibeDeleteView(project.id, projectModel.id, projectModelVibe.id),
        permissions: [[Permission.DELETE_PROJECT_MODEL_VIBES]],
        enabled: projectModelVibe.isDraft,
      },
    ],
  });
  return (
    <View
      header={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <h1>{projectModelVibe.name.toUpperCase()}</h1>
          {projectModelVibe.isDraft ? (
            <Tag size="lg">BROUILLON</Tag>
          ) : (
            <Tag size="lg" color="green">
              PUBLIÉ
            </Tag>
          )}
        </div>
      }
      permissions={[[Permission.READ_PROJECT_MODEL_VIBES]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Modèles",
          href: toProjectModelsView(project.id),
        },
        {
          label: projectModel.name,
          href: toProjectModelView(project.id, projectModel.id),
        },
        {
          label: "Ambiances",
          href: toProjectModelVibesView(project.id, projectModel.id),
        },
        {
          label: projectModelVibe.name,
        },
      ]}
    >
      {projectModel.rooms.map((projectModelRoom) => (
        <section key={projectModelRoom.id}>
          <h2>{projectModelRoom.name}</h2>
          <ProjectModelVibeItemList
            projectModelElements={projectModel.elements.filter((projectModelElement) => projectModelElement.projectModelRoomId === projectModelRoom.id)}
            projectProducts={projectProducts}
            projectModelVibeItems={[...projectModelVibe.items]}
          />
        </section>
      ))}
    </View>
  );
};
