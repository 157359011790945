import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectActivityContext, useProjectContext } from "hooks";
import { ProjectActivityForm } from "forms";
import { useUpdateProjectActivityMutation } from "graphql/mutations";
import { useList } from "react-use";

export const ProjectActivityUpdateView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectActivitiesView } = useRouting();
  const { project } = useProjectContext();
  const { projectActivity } = useProjectActivityContext();
  const [errors, { set: setErrors }] = useList<string>();
  const { updateProjectActivity, updateProjectActivityLoading } = useUpdateProjectActivityMutation((projectId) => {
    navigate(toProjectActivitiesView(projectId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Modifier l'activité"
      permissions={[[Permission.READ_PROJECT_ACTIVITIES, Permission.UPDATE_PROJECT_ACTIVITIES]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Activités",
          href: toProjectActivitiesView(project.id),
        },
        {
          label: projectActivity.label,
        },
      ]}
    >
      <ProjectActivityForm
        initialValues={{
          number: projectActivity.number,
          title: projectActivity.title,
          description: projectActivity.description ?? "",
        }}
        errors={errors}
        loading={updateProjectActivityLoading}
        onSubmit={async (values) => {
          await updateProjectActivity(project.id, projectActivity.id, {
            number: values.number,
            title: values.title,
            description: values.description === "" ? null : values.description,
          });
        }}
        onCancel={() => {
          navigate(toProjectActivitiesView(project.id));
        }}
      />
    </View>
  );
};
