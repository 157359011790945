import { FunctionComponent, useState } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext, useProjectModelContext } from "hooks";
import { useUpdateProjectModelReportSetupRelationshipsMutation } from "graphql/mutations";
import { useList } from "react-use";
import { ProjectReportSetupRelationshipsForm } from "forms";

// TODO: Ajuster l'emplacement de ce fichier avec le nom de la permission.
export const ProjectModelRelationshipsManageView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectModelsView, toProjectModelView } = useRouting();
  const { project, projectReportSetups } = useProjectContext();
  const { projectModel } = useProjectModelContext();
  const [projectReportSetupId, setProjectReportSetupId] = useState<string | null>(null);
  const projectReportSetup = projectReportSetups.find((projectReportSetup) => projectReportSetup.id === projectReportSetupId) ?? null;
  const [errors, { set: setErrors }] = useList<string>();
  const { updateProjectModelReportSetupRelationships, updateProjectModelReportSetupRelationshipsLoading } = useUpdateProjectModelReportSetupRelationshipsMutation((projectId) => {
    navigate(toProjectModelsView(projectId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Modifier les relations"
      permissions={[[Permission.READ_PROJECT_MODELS, Permission.UPDATE_PROJECT_REPORT_RELATIONSHIPS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Modèles",
          href: toProjectModelsView(project.id),
        },
        {
          label: projectModel.name,
          href: toProjectModelView(project.id, projectModel.id),
        },
      ]}
    >
      <ProjectReportSetupRelationshipsForm
        projectModel={projectModel}
        projectModelElements={projectModel.elements}
        projectReportSetups={projectReportSetups}
        projectReportSetup={projectReportSetup}
        projectReportSetupId={projectReportSetupId}
        setProjectReportSetupId={setProjectReportSetupId}
        initialValues={{
          projectReportSetupRelationships:
            projectReportSetup?.relationships.map((projectReportSetupRelationship) => ({
              projectModelElementId: projectReportSetupRelationship.projectModelElementId,
              projectReportSetupElementId: projectReportSetupRelationship.projectReportSetupElementId,
            })) ?? [],
        }}
        errors={errors}
        loading={updateProjectModelReportSetupRelationshipsLoading}
        onSubmit={async (values) => {
          if (projectReportSetup) {
            await updateProjectModelReportSetupRelationships(project.id, projectModel.id, {
              projectReportSetupId: projectReportSetup.id,
              projectReportSetupRelationships: values.projectReportSetupRelationships,
            });
          }
        }}
        onCancel={() => {
          navigate(toProjectModelView(project.id, projectModel.id));
        }}
      />
    </View>
  );
};
