import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useCustomerContext, useRouting } from "hooks";
import { useList } from "react-use";
import { useResendCustomerCredentialsMutation } from "graphql/mutations";
import { CustomerCredentialsResendForm } from "forms";

export const CustomerCredentialsResendView: FunctionComponent = () => {
  const { navigate, toCustomersView, toCustomerView } = useRouting();
  const { customer } = useCustomerContext();
  const [errors, { set: setErrors }] = useList<string>([]);
  const { resendCustomerCredentials, resendCustomerCredentialsLoading } = useResendCustomerCredentialsMutation(() => {
    navigate(toCustomersView());
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Renvoyer les identifiants"
      permissions={[[Permission.RESEND_CUSTOMERS_CREDENTIALS]]}
      breadcrumbItems={[
        {
          label: "Clients",
          href: toCustomersView(),
        },
        {
          label: customer.fullName,
          href: toCustomerView(customer.id),
        },
        {
          label: "Identifiants",
        },
      ]}
    >
      <CustomerCredentialsResendForm
        initialValues={{
          fullName: customer.fullName,
          email: customer.email,
          credentialsCanBeResentAt: customer.credentialsCanBeResentAt,
          password: null,
        }}
        errors={errors}
        loading={resendCustomerCredentialsLoading}
        onSubmit={async (values) => {
          await resendCustomerCredentials(customer.id, {
            password: values.password,
          });
        }}
        onCancel={() => {
          navigate(toCustomerView(customer.id));
        }}
      />
    </View>
  );
};
