import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext, useProjectModelContext, useProjectModelVibeContext } from "hooks";
import { ProjectModelVibeForm } from "forms";
import { useUpdateProjectModelVibeMutation } from "graphql/mutations";
import { useList } from "react-use";

export const ProjectModelVibeUpdateView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectModelsView, toProjectModelView, toProjectModelVibeView } = useRouting();
  const { project, projectProducts, projectProductCategories } = useProjectContext();
  const { projectModel } = useProjectModelContext();
  const { projectModelVibe } = useProjectModelVibeContext();
  const [errors, { set: setErrors }] = useList<string>();
  const { updateProjectModelVibe, updateProjectModelVibeLoading } = useUpdateProjectModelVibeMutation((projectId, projectModelId, projectModelVibeId) => {
    navigate(toProjectModelVibeView(projectId, projectModelId, projectModelVibeId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      fluid
      title="Modifier l'ambiance"
      permissions={[[Permission.READ_PROJECT_MODEL_VIBES, Permission.UPDATE_PROJECT_MODEL_VIBES]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Modèles",
          href: toProjectModelsView(project.id),
        },
        {
          label: projectModel.name,
          href: toProjectModelView(project.id, projectModel.id),
        },
        {
          label: "Ambiances",
        },
        {
          label: projectModelVibe.name,
          href: toProjectModelVibeView(project.id, projectModel.id, projectModelVibe.id),
        },
      ]}
    >
      <ProjectModelVibeForm
        project={project}
        projectModel={projectModel}
        projectProducts={projectProducts}
        projectProductCategories={projectProductCategories}
        initialValues={{
          projectModelVibeName: projectModelVibe.name,
          projectModelVibeItems: projectModelVibe.items.map((projectModelVibeItem) => ({
            projectModelElementId: projectModelVibeItem.projectModelElementId,
            projectModelElementOptionId: projectModelVibeItem.projectModelElementOptionId,
          })),
        }}
        errors={errors}
        loading={updateProjectModelVibeLoading}
        onSubmit={async (values) => {
          await updateProjectModelVibe(project.id, projectModel.id, projectModelVibe.id, {
            name: values.projectModelVibeName,
            items: values.projectModelVibeItems.map((item) => ({
              projectModelElementId: item.projectModelElementId,
              projectModelElementOptionId: item.projectModelElementOptionId,
            })),
          });
        }}
        onCancel={() => {
          navigate(toProjectModelVibeView(project.id, projectModel.id, projectModelVibe.id));
        }}
      />
    </View>
  );
};
