import "./index.less";
import { FunctionComponent } from "react";
import { Button } from "rsuite";
import { useRouting } from "hooks";

export const NotFound: FunctionComponent = () => {
  const { navigate, toProjectsView } = useRouting();
  return (
    <div className="not-found">
      <h1>LA PAGE N'EXISTE PAS</h1>
      <p>L'URL de la page web est invalide.</p>
      <Button
        onClick={() => {
          navigate(toProjectsView());
        }}
      >
        Accueil
      </Button>
      <small>
        <pre>Error Code: 404 Not Found</pre>
      </small>
    </div>
  );
};
