import "./index.less";
import { FunctionComponent } from "react";
import { ListItemActions, View, ViewFilters } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useProjectContext, useProjectContractContext } from "hooks";
import { CheckPicker, FlexboxGrid, List } from "rsuite";
import { useList } from "react-use";
import { useRouting } from "hooks";

export const ProjectContractPaymentsView: FunctionComponent = () => {
  const {
    searchParams,
    toProjectsView,
    toProjectView,
    toProjectContractsView,
    toProjectContractView,
    toProjectContractPaymentCreateView,
    toProjectContractPaymentUpdateView,
    toProjectContractPaymentDeleteView,
  } = useRouting();
  const [customerInvoiceIds, { set: setCustomerInvoiceIds }] = useList<string>(searchParams.get("customerInvoiceIds")?.split(",") ?? []);
  const { project } = useProjectContext();
  const { projectContract } = useProjectContractContext();
  useLayout({
    sidebarActions: [],
    sidebarButtons: [
      {
        label: "Créer un paiement",
        href: toProjectContractPaymentCreateView(project.id, projectContract.id),
        permissions: [[Permission.CREATE_PROJECT_CONTRACT_PAYMENTS]],
      },
    ],
  });
  return (
    <View
      id="project-contract-payments-view"
      permissions={[[Permission.READ_PROJECT_CONTRACTS, Permission.READ_PROJECT_CONTRACT_PAYMENTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Contrats",
          href: toProjectContractsView(project.id),
        },
        {
          label: `Unité #${projectContract.projectUnit.number} (${projectContract.customer.firstName} ${projectContract.customer.lastName})`,
          href: toProjectContractView(project.id, projectContract.id),
        },
        {
          label: "Paiements",
        },
      ]}
    >
      <ViewFilters>
        <CheckPicker
          style={{
            width: "250px",
          }}
          value={customerInvoiceIds}
          data={projectContract.invoices.map((projectContractInvoice) => ({
            label: projectContractInvoice.label,
            value: projectContractInvoice.id,
          }))}
          onChange={setCustomerInvoiceIds}
          label="Factures"
        />
      </ViewFilters>
      <List className="project-list">
        {projectContract.payments
          .filter((projectContractPayment) => (customerInvoiceIds.length !== 0 ? customerInvoiceIds.includes(projectContractPayment.projectContractInvoiceId) : true))
          .map((projectContractPayment, index) => (
            <List.Item key={projectContractPayment.id} index={index + 1}>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={11}>
                  <span>
                    {new Date(projectContractPayment.createdAt).toLocaleString("fr-CA", {
                      year: "numeric",
                      day: "2-digit",
                      month: "long",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                  <h2>{projectContractPayment.label}</h2>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={11}>
                  <span>Montant</span>
                  <h2>{projectContractPayment.amount.toCurrencyFormat("CAD")}</h2>
                </FlexboxGrid.Item>
                <ListItemActions
                  colspan={2}
                  actions={[
                    {
                      href: toProjectContractPaymentUpdateView(project.id, projectContract.id, projectContractPayment.id),
                      content: "Modifier le paiement",
                      permissions: [[Permission.UPDATE_PROJECT_CONTRACT_PAYMENTS]],
                      show: true,
                    },
                    {
                      href: toProjectContractPaymentDeleteView(project.id, projectContract.id, projectContractPayment.id),
                      content: "Supprimer le paiement",
                      permissions: [[Permission.DELETE_PROJECT_CONTRACT_PAYMENTS]],
                      show: true,
                    },
                  ]}
                />
              </FlexboxGrid>
            </List.Item>
          ))}
      </List>
    </View>
  );
};
