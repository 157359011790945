import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext, useProjectUnitContext } from "hooks";
import { useDeleteProjectUnitMutation } from "graphql/mutations";
import { useList } from "react-use";
import { DeleteForm } from "forms";

export const ProjectUnitDeleteView: FunctionComponent = () => {
  const { navigate, toProjectView, toProjectsView, toProjectUnitsView } = useRouting();
  const { project } = useProjectContext();
  const { projectUnit } = useProjectUnitContext();
  const [errors, { set: setErrors }] = useList<string>([]);
  const { deleteProjectUnit, deleteProjectUnitLoading } = useDeleteProjectUnitMutation((projectId) => {
    navigate(toProjectUnitsView(projectId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Supprimer l'unité"
      permissions={[[Permission.READ_PROJECT_UNITS, Permission.DELETE_PROJECT_UNITS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Unités",
          href: toProjectUnitsView(project.id),
        },
        {
          label: projectUnit.label,
        },
      ]}
    >
      <DeleteForm
        label="Nom de l'unité"
        value={projectUnit.label}
        confirm="Veuillez saisir le nom de l'unité pour confirmer."
        errors={errors}
        loading={deleteProjectUnitLoading}
        onSubmit={async () => {
          await deleteProjectUnit(project.id, projectUnit.id);
        }}
        onCancel={() => {
          navigate(toProjectUnitsView(project.id));
        }}
      >
        L'unité sera définitivement supprimé. <strong>Cette opération est IRRÉVERSIBLE.</strong>
      </DeleteForm>
    </View>
  );
};
