import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext } from "hooks";
import { ProjectUnitUploadForm } from "forms";
import { useDownloadTemplateMutation, useUploadProjectUnitsMutation } from "graphql/mutations";
import { useList } from "react-use";
import { TemplateName } from "graphql/globalTypes";

export const ProjectUnitUploadView: FunctionComponent = () => {
  const { navigate, toProjectView, toProjectsView, toProjectModelsView, toProjectUnitsView } = useRouting();
  const { project } = useProjectContext();
  const [errors, { set: setErrors }] = useList<string>();
  const { uploadProjectUnits, uploadProjectUnitsLoading } = useUploadProjectUnitsMutation((projectId) => {
    navigate(toProjectUnitsView(projectId));
  }, setErrors);
  const { downloadTemplate } = useDownloadTemplateMutation(
    (templateUrl) => {
      const link = document.createElement("a");
      link.href = templateUrl;
      link.click();
    },
    (errors) => {
      console.error(errors);
    }
  );
  useLayout({
    sidebarActions: [],
    sidebarButtons: [
      {
        label: "Télécharger le gabarit",
        onClick: () => {
          downloadTemplate(TemplateName.BULK_UNITS);
        },
        permissions: [[Permission.UPLOAD_PROJECT_UNITS]],
      },
    ],
  });
  return (
    <View
      title="Téléverser des unités"
      permissions={[[Permission.READ_PROJECT_UNITS, Permission.UPLOAD_PROJECT_UNITS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Modèles",
          href: toProjectModelsView(project.id),
        },
      ]}
    >
      <ProjectUnitUploadForm
        initialValues={{
          projectUnitsBlobFile: undefined,
        }}
        errors={errors}
        loading={uploadProjectUnitsLoading}
        onSubmit={async (values) => {
          await uploadProjectUnits(project.id, {
            projectUnitsBlobFile: values.projectUnitsBlobFile,
          });
        }}
        onCancel={() => {
          navigate(toProjectUnitsView(project.id));
        }}
      />
    </View>
  );
};
