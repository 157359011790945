import "./index.less";
import { FunctionComponent, useState } from "react";
import { ListItemActions, SearchInputGroup, View } from "buildingBlocks";
import { useLayout, useAppContext, useRouting } from "hooks";
import { List, FlexboxGrid, Tag } from "rsuite";
import { Permission, Role } from "enums";

export const UsersView: FunctionComponent = () => {
  const { searchParams, toUserAccessUpdateView } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  const { users, projects, roles } = useAppContext();
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      id="users-view"
      permissions={[[Permission.READ_USERS]]}
      breadcrumbItems={[
        {
          label: "Utilisateurs",
        },
      ]}
    >
      <SearchInputGroup placeholder="Recherche par nom, courriel" onChange={setSearch} />
      <List className="user-list">
        {users
          .filter((user) => user.name.toSearchFormat().includes(search.toSearchFormat()) || user.email.toSearchFormat().includes(search.toSearchFormat()))
          .map((user, index) => {
            return (
              <List.Item key={user.id} index={index + 1}>
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={8}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "7.5px",
                        }}
                      >
                        <h3
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          {user.name ?? user.email}
                        </h3>
                      </div>
                      {user.name && (
                        <small>
                          <a href={`mailto:${user.email}`}>{user.email}</a>
                        </small>
                      )}
                    </div>
                  </FlexboxGrid.Item>
                  {user.isSuperAdministrator ? (
                    <FlexboxGrid.Item
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                      colspan={16}
                    >
                      <Tag
                        style={{
                          fontWeight: "bold",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "175px",
                          height: "30px",
                          color: "#FFF",
                          backgroundColor: "#152e41",
                        }}
                      >
                        SUPER ADMINISTRATEUR
                      </Tag>
                    </FlexboxGrid.Item>
                  ) : (
                    <>
                      <FlexboxGrid.Item colspan={7}>
                        <span className="subtile">Rôles</span>
                        <div>
                          {user.roleIds.length !== 0 ? (
                            <ul>
                              {roles
                                .filter((role) => user.roleIds.includes(role.id))
                                .filter((role) => role.name !== Role.SA)
                                .filter((role) => role.name !== Role.ALL_PROJECTS)
                                .filter((role) => role.name !== Role.CUSTOMER)
                                .map((role) => (
                                  <li key={role.id}>{role.description}</li>
                                ))}
                            </ul>
                          ) : (
                            <span>Aucun</span>
                          )}
                        </div>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item colspan={7}>
                        <span className="subtile">Projets</span>
                        <div>
                          {user.projectIds.length !== 0 && !user.hasAccessToAllProjects && (
                            <ul>
                              {projects
                                .filter((project) => user.projectIds.includes(project.id))
                                .map((project) => (
                                  <li key={project.id}>{project.label}</li>
                                ))}
                            </ul>
                          )}
                          {user.hasAccessToAllProjects && (
                            <ul>
                              <li>Tous les projets</li>
                            </ul>
                          )}
                          {user.projectIds.length === 0 && !user.hasAccessToAllProjects && (
                            <ul>
                              <li>Aucun</li>
                            </ul>
                          )}
                        </div>
                      </FlexboxGrid.Item>
                      <ListItemActions
                        colspan={2}
                        actions={[
                          {
                            content: "Modifier l'accès de l'utilisateur",
                            href: toUserAccessUpdateView(user.id),
                            permissions: [[Permission.UPDATE_USER_ACCESS]],
                            show: !user.isSuperAdministrator,
                          },
                        ]}
                      />
                    </>
                  )}
                </FlexboxGrid>
              </List.Item>
            );
          })}
      </List>
    </View>
  );
};
