import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext } from "hooks";
import { ProjectForm } from "forms";
import { useUpdateProjectMutation } from "graphql/mutations";
import { useList } from "react-use";

export const ProjectUpdateView: FunctionComponent = () => {
  const { navigate, toProjectView, toProjectsView } = useRouting();
  const { project } = useProjectContext();
  const [errors, { set: setErrors }] = useList<string>();
  const { updateProject, updateProjectLoading } = useUpdateProjectMutation((projectId) => {
    navigate(toProjectView(projectId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Modifier le projet"
      permissions={[[Permission.READ_PROJECTS, Permission.UPDATE_PROJECTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
      ]}
    >
      <ProjectForm
        initialValues={{
          code: project.code,
          name: project.name,
          description: project.description ?? "",
          customOptionUnitPrice: project.customOptionUnitPrice,
        }}
        errors={errors}
        loading={updateProjectLoading}
        onSubmit={async (values) => {
          await updateProject(project.id, {
            code: values.code,
            name: values.name,
            description: values.description === "" ? null : values.description,
            customOptionUnitPrice: values.customOptionUnitPrice,
          });
        }}
        onCancel={() => {
          navigate(toProjectView(project.id));
        }}
      />
    </View>
  );
};
