import "./index.less";
import { ProjectModelElementOptionPriceList } from "components";
import { FormError } from "buildingBlocks";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import produce from "immer";
import { FunctionComponent, useState } from "react";
import { Button, ButtonToolbar, Form, Input } from "rsuite";

export type ProjectModelFormValues = {
  readonly name: string;
  readonly projectModelElementOptionPrices: {
    readonly projectModelElementOptionId: string;
    readonly amount: number;
  }[];
};

type Props = {
  readonly initialValues: ProjectModelFormValues;
  readonly projectModel: ProjectModel;
  readonly errors: string[];
  readonly loading: boolean;
  readonly onSubmit: (values: ProjectModelFormValues) => Promise<void>;
  readonly onCancel: () => void;
};

export const ProjectModelForm: FunctionComponent<Props> = ({ initialValues, projectModel, errors, loading, onSubmit, onCancel }) => {
  const [values, setValues] = useState<ProjectModelFormValues>(initialValues);
  return (
    <>
      <FormError errors={errors} />
      <Form id="project-model-form" className="form" fluid>
        <Form.Group controlId="name">
          <Form.ControlLabel>Nom</Form.ControlLabel>
          <Input
            value={values.name}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.name = value;
                })
              );
            }}
          />
        </Form.Group>
        <ProjectModelElementOptionPriceList values={values} setValues={setValues} projectModel={projectModel} />
        <Form.Group>
          <ButtonToolbar>
            <Button appearance="primary" loading={loading} onClick={() => onSubmit(values)}>
              Enregister
            </Button>
            <Button
              disabled={loading}
              appearance="default"
              onClick={() => {
                onCancel();
              }}
            >
              Annuler
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};
