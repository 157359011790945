import "./index.less";
import { FunctionComponent, useState } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext } from "hooks";
import { useUpdateProjectUnitsMutation } from "graphql/mutations";
import { useList } from "react-use";
import { ProjectUnitsUpdateFormValues, ProjectUnitsUpdateForm } from "forms/ProjectUnitsUpdateForm";

export const ProjectUnitsUpdateView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectUnitsView } = useRouting();
  const { project, projectUnits, projectModels } = useProjectContext();
  const [errors, { set: setErrors }] = useList<string>();
  const { updateProjectUnits, updateProjectUnitsLoading } = useUpdateProjectUnitsMutation((projectId) => {
    navigate(toProjectUnitsView(projectId));
  }, setErrors);
  const [values, setValues] = useState<ProjectUnitsUpdateFormValues>({
    projectUnits: projectUnits.map((projectUnit) => ({
      id: projectUnit.id,
      projectModelId: projectUnit.projectModelId,
      hasProjectModel: projectUnit.projectModelId === null,
    })),
  });
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      form={{
        loading: updateProjectUnitsLoading,
        onSubmit: async () => {
          await updateProjectUnits(project.id, {
            projectUnits: values.projectUnits.map((projectUnit) => ({
              id: projectUnit.id,
              projectModelId: projectUnit.projectModelId,
            })),
          });
        },
        onCancel: () => {
          navigate(toProjectUnitsView(project.id));
        },
      }}
      title="Modifier les produits"
      permissions={[[Permission.READ_PROJECT_PRODUCTS, Permission.UPDATE_PROJECT_PRODUCTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Units",
          href: toProjectUnitsView(project.id),
        },
      ]}
    >
      <ProjectUnitsUpdateForm projectUnits={projectUnits} projectModels={projectModels} values={values} setValues={setValues} errors={errors} loading={updateProjectUnitsLoading} />
    </View>
  );
};
