import "./index.less";
import { FunctionComponent, useEffect, useState } from "react";
import { Button, ButtonToolbar, Form, SelectPicker } from "rsuite";
import { FormError } from "buildingBlocks";
import { ProjectReportSetupRelationshipPanelGroup } from "components";
import { ProjectReportSetupRelationshipsFormValues } from "types";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import { ProjectReportSetup } from "graphql/__generated__/ProjectReportSetup";
import { ProjectModelElement } from "graphql/__generated__/ProjectModelElement";
import produce from "immer";

type Props = {
  readonly projectModel: ProjectModel;
  readonly projectModelElements: ReadonlyArray<ProjectModelElement>;
  readonly projectReportSetups: ReadonlyArray<ProjectReportSetup>;
  readonly projectReportSetup: ProjectReportSetup | null;
  readonly projectReportSetupId: string | null;
  readonly setProjectReportSetupId: (projectReportSetupId: string | null) => void;
  readonly initialValues: ProjectReportSetupRelationshipsFormValues;
  readonly errors: string[];
  readonly loading: boolean;
  readonly onSubmit: (values: ProjectReportSetupRelationshipsFormValues) => Promise<void>;
  readonly onCancel: () => void;
};

export const ProjectReportSetupRelationshipsForm: FunctionComponent<Props> = ({
  projectModel,
  projectModelElements,
  projectReportSetups,
  projectReportSetup,
  projectReportSetupId,
  setProjectReportSetupId,
  initialValues,
  errors,
  loading,
  onSubmit,
  onCancel,
}) => {
  const [values, setValues] = useState<ProjectReportSetupRelationshipsFormValues>(initialValues);
  useEffect(() => {
    if (projectReportSetupId) {
      setValues(
        produce(values, (draft) => {
          draft.projectReportSetupRelationships =
            projectModelElements.map((projectModelElement) => ({
              projectModelElementId: projectModelElement.id,
              projectReportSetupElementId:
                projectReportSetup?.relationships.find((projectReportSetupRelationship) => projectReportSetupRelationship.projectModelElementId === projectModelElement.id)
                  ?.projectReportSetupElementId ?? null,
            })) ?? [];
        })
      );
    } else {
      setValues(
        produce(values, (draft) => {
          draft.projectReportSetupRelationships = [];
        })
      );
    }
    // eslint-disable-next-line
  }, [projectReportSetupId]);
  return (
    <>
      <FormError errors={errors} />
      <Form className="form" fluid>
        <hr />
        <Form.Group className="project-report-setup-selector" style={{ maxWidth: "100%" }}>
          <Form.ControlLabel>Paramétrage du rapport</Form.ControlLabel>
          <SelectPicker
            style={{ width: "250px" }}
            cleanable
            value={projectReportSetupId}
            onChange={(value) => {
              setProjectReportSetupId(value);
            }}
            data={projectReportSetups.map((projectReportSetup) => {
              return {
                label: projectReportSetup.name,
                value: projectReportSetup.id,
              };
            })}
          />
        </Form.Group>
        <hr />
        <Form.Group style={{ maxWidth: "100%" }}>
          {projectModel.rooms
            .filter((projectModelRoom) => projectModelElements.filter((projectModelElement) => projectModelElement.projectModelRoomId === projectModelRoom.id).length !== 0)
            .map((projectModelRoom) => (
              <ProjectReportSetupRelationshipPanelGroup
                key={projectModelRoom.id}
                projectModelRoomName={projectModelRoom.name}
                projectReportSetup={projectReportSetup}
                projectModelElements={projectModelElements.filter((projectModelElement) => projectModelElement.projectModelRoomId === projectModelRoom.id)}
                values={values}
                setValues={setValues}
              />
            ))}
        </Form.Group>
        <Form.Group style={{ maxWidth: "100%" }}>
          <ButtonToolbar>
            <Button
              loading={loading}
              appearance="primary"
              onClick={() => {
                onSubmit(values);
              }}
            >
              Enregistrer
            </Button>
            <Button appearance="default" onClick={onCancel}>
              Annuler
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};
