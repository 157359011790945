import "./index.less";
import { FunctionComponent } from "react";
import { ListItemActions, View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useProjectContext, useProjectContractContext, useRouting } from "hooks";
import { List, FlexboxGrid } from "rsuite";

export const ProjectContractChoicesView: FunctionComponent = () => {
  const { toProjectsView, toProjectView, toProjectContractsView, toProjectContractView, toProjectContractChoiceView, toProjectContractChoiceCreateView } = useRouting();
  const { project } = useProjectContext();
  const { projectContract } = useProjectContractContext();
  const projectContractChoice = projectContract.choices.find((projectContractChoice) => projectContractChoice.id === projectContract.currentProjectContractChoiceId)!;
  useLayout({
    sidebarActions: [],
    sidebarButtons: [
      {
        label: "Créer une révision",
        href: toProjectContractChoiceCreateView(project.id, projectContract.id),
        permissions: [[Permission.CREATE_PROJECT_CONTRACT_CHOICES]],
        enabled: !projectContract.isCanceled && !projectContractChoice.isDraft,
      },
    ],
  });
  return (
    <View
      id="project-contract-choices-view"
      permissions={[[Permission.READ_PROJECT_CONTRACTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Contrats",
          href: toProjectContractsView(project.id),
        },
        {
          label: `Unité #${projectContract.projectUnit.number} (${projectContract.customer.firstName} ${projectContract.customer.lastName})`,
          href: toProjectContractView(project.id, projectContract.id),
        },
        {
          label: "Révisions",
        },
      ]}
    >
      <List className="customer-choice-list">
        {projectContract.choices.map((customerChoice, index) => {
          return (
            <List.Item key={customerChoice.id} index={index + 1}>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={22}>
                  <h2>{customerChoice.label}</h2>
                  <span>
                    {new Date(customerChoice.createdAt).toLocaleString("fr-CA", {
                      year: "numeric",
                      day: "2-digit",
                      month: "long",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </FlexboxGrid.Item>
                <ListItemActions
                  colspan={2}
                  actions={[
                    {
                      href: toProjectContractChoiceView(project.id, projectContract.id, customerChoice.id),
                      content: "Voir la révision",
                      permissions: [[Permission.READ_PROJECT_CONTRACTS]],
                      show: true,
                    },
                  ]}
                />
              </FlexboxGrid>
            </List.Item>
          );
        })}
      </List>
    </View>
  );
};
