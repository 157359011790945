import { FunctionComponent, PropsWithChildren } from "react";
import { CustomProvider } from "rsuite";
import enUS from "rsuite/locales/en_US";

type Props = PropsWithChildren;

const Calendar = {
  sunday: "Su",
  monday: "Mo",
  tuesday: "Tu",
  wednesday: "We",
  thursday: "Th",
  friday: "Fr",
  saturday: "Sa",
  ok: "OK",
  today: "Today",
  yesterday: "Yesterday",
  hours: "Hours",
  minutes: "Minutes",
  seconds: "Seconds",
  /**
   * Format of the string is based on Unicode Technical Standard #35:
   * https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
   **/
  formattedMonthPattern: "MMM yyyy",
  formattedDayPattern: "dd MMM yyyy",
  dateLocale: enUS,
};

export const LocaleProvider: FunctionComponent<Props> = ({ children }) => (
  <CustomProvider
    locale={{
      common: {
        loading: "Chargement en cours...",
        emptyMessage: "Aucune donnée disponible",
      },
      Plaintext: {
        unfilled: "Unfilled",
        notSelected: "Not selected",
        notUploaded: "Not uploaded",
      },
      Pagination: {
        more: "More",
        prev: "Previous",
        next: "Next",
        first: "First",
        last: "Last",
        limit: "{0} par page",
        total: "Nombre total de lignes: {0}",
        skip: "Go to{0}",
      },
      Calendar,
      DatePicker: {
        ...Calendar,
      },
      DateRangePicker: {
        ...Calendar,
        last7Days: "Last 7 Days",
      },
      Picker: {
        noResultsText: "Aucun résultat trouvé",
        placeholder: "Sélectionner",
        searchPlaceholder: "Recherche",
        checkAll: "Tout",
      },
      InputPicker: {
        newItem: "New item",
        createOption: 'Create option "{0}"',
      },
      Uploader: {
        inited: "Initial",
        progress: "Uploading",
        error: "Error",
        complete: "Finished",
        emptyFile: "Empty",
        upload: "Téléverser",
      },
      CloseButton: {
        closeLabel: "Close",
      },
      Breadcrumb: {
        expandText: "Show path",
      },
      Toggle: {
        on: "Open",
        off: "Close",
      },
    }}
  >
    {children}
  </CustomProvider>
);
