import { v4 as uuid } from "uuid";

declare global {
  interface String {
    toSearchFormat(): string;
    toPhoneNumberFormat(): string;
  }
  interface Number {
    toCurrencyFormat(currencyIsoCode: "CAD"): string;
  }
}

// eslint-disable-next-line
Number.prototype.toCurrencyFormat = function (currencyIsoCode) {
  return this.toLocaleString("fr-CA", {
    style: "currency",
    currency: currencyIsoCode,
  });
};

// eslint-disable-next-line
String.prototype.toSearchFormat = function (): string {
  return this.toLowerCase()
    .replace("é", "e")
    .replace("è", "e")
    .replace("à", "a")
    .replace(/[^a-z0-9]/g, "");
};

// eslint-disable-next-line
String.prototype.toPhoneNumberFormat = function (): string {
  //Filter only numbers from the input
  const cleaned = ("" + this).replace(/\D/g, "");
  //Check if the input is of correct
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return "";
};

export class Guid {
  public static newGuid = () => uuid();
}

export {};
