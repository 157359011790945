import "./index.less";
import { FunctionComponent, useState } from "react";
import { Button, Form, Input, InputPicker, Message, Tag } from "rsuite";
import { ProjectModelElementPanelGroup, ProjectModelUnityWebGL } from "components";
import produce from "immer";
import { FormError } from "buildingBlocks";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import { Project } from "graphql/__generated__/Project";
import { ProjectProduct } from "graphql/__generated__/ProjectProduct";
import { ProjectProductCategory } from "graphql/__generated__/ProjectProductCategory";

export type ProjectModelVibeFormValues = {
  readonly projectModelVibeName: string;
  readonly projectModelVibeItems: {
    readonly projectModelElementId: string;
    readonly projectModelElementOptionId: string | null;
  }[];
};

type Props = {
  readonly project: Project;
  readonly projectModel: ProjectModel;
  readonly projectProducts: ReadonlyArray<ProjectProduct>;
  readonly projectProductCategories: ReadonlyArray<ProjectProductCategory>;
  readonly errors: string[];
  readonly loading: boolean;
  readonly initialValues: ProjectModelVibeFormValues;
  readonly onSubmit: (values: ProjectModelVibeFormValues) => Promise<void>;
  readonly onCancel: () => void;
};

export const ProjectModelVibeForm: FunctionComponent<Props> = ({ project, projectModel, projectProducts, projectProductCategories, initialValues, errors, loading, onSubmit, onCancel }) => {
  const [projectModelRoomId, setProjectModelRoomId] = useState<string>("");
  const [values, setValues] = useState<ProjectModelVibeFormValues>(initialValues);
  return (
    <Form id="customer-choice-update-form" className="form" fluid>
      <div className="customer-choice-update-body">
        <ProjectModelUnityWebGL
          projectId={project.id}
          projectModelId={projectModel.id}
          projectModelRoomId={projectModelRoomId}
          projectContractChoiceItems={values.projectModelVibeItems.map((item) => ({
            projectModelElementId: item.projectModelElementId,
            projectModelElementOptionId: item.projectModelElementOptionId,
          }))}
        />
        <aside className="project-model-unity-webgl-menu">
          <div className="project-model-unity-webgl-menu-header">
            <div className="title">
              <span className="project-name">{project.name}</span>
              <span className="project-unit-label">{projectModel.name}</span>
            </div>
            <Tag size="lg" className="customer-contract-choice-label">
              {"Ambiance".toUpperCase()}
            </Tag>
          </div>
          <div className="project-model-unity-webgl-menu-body">
            <FormError errors={errors} />
            <div className="vibe-selector">
              <label className="vibe-selector-label">Nom</label>
              <Input
                className="vibe-selector-input-picker"
                size="sm"
                value={values.projectModelVibeName}
                onChange={(value) => {
                  console.log(value);
                  setValues(
                    produce(values, (draft) => {
                      draft.projectModelVibeName = value;
                    })
                  );
                }}
              />
            </div>
            <hr />
            <div className="room-selector">
              <label className="room-selector-label">Pièce</label>
              <InputPicker
                className="room-selector-input-picker"
                size="sm"
                data={projectModel.rooms.map((projectModelRoom) => ({
                  label: projectModelRoom.name,
                  value: projectModelRoom.id,
                }))}
                value={projectModelRoomId}
                onChange={setProjectModelRoomId}
                cleanable={false}
              />
            </div>
            <hr />
            {projectModelRoomId ? (
              <ProjectModelElementPanelGroup
                projectProductPanelSize="sm"
                projectModelElements={projectModel.elements.filter((projectModelElement) =>
                  projectModel.elements.every((x) => x.projectModelRoomId !== projectModelRoomId)
                    ? projectModelElement.projectModelRoomId === null
                    : projectModelElement.projectModelRoomId === projectModelRoomId
                )}
                projectProducts={projectProducts}
                projectProductCategories={projectProductCategories}
                projectModelVibeItems={values.projectModelVibeItems}
                setProjectModelVibeItems={(projectModelVibeItems) => {
                  setValues(
                    produce(values, (draft) => {
                      for (const projectModelVibeItem of projectModelVibeItems) {
                        const index = draft.projectModelVibeItems.findIndex((item) => item.projectModelElementId === projectModelVibeItem.projectModelElementId);
                        if (index !== -1) {
                          draft.projectModelVibeItems[index].projectModelElementOptionId = projectModelVibeItem.projectModelElementOptionId;
                        }
                      }
                    })
                  );
                }}
              />
            ) : (
              <Message showIcon type="info">
                Veuillez sélectionner une pièce pour personnaliser cette ambiance.
              </Message>
            )}
          </div>
          <div className="project-model-unity-webgl-menu-footer">
            <Button
              disabled={
                !projectModel.elements
                  .filter((projectModelElement) => !projectModelElement.isOptional)
                  .every((projectModelElement) => values.projectModelVibeItems.find((item) => item.projectModelElementId === projectModelElement.id)?.projectModelElementOptionId !== null)
              }
              loading={loading}
              size="lg"
              appearance="primary"
              onClick={() => {
                onSubmit(values);
              }}
            >
              Enregistrer
            </Button>
            <Button size="lg" appearance="ghost" onClick={onCancel}>
              Annuler
            </Button>
          </div>
        </aside>
      </div>
    </Form>
  );
};
