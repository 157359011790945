import "./index.less";
import { FunctionComponent } from "react";
import { ProjectReportSetup } from "graphql/__generated__/ProjectReportSetup";
import { ProjectModelElement } from "graphql/__generated__/ProjectModelElement";
import { ProjectReportSetupRelationshipPanel } from "components/ProjectReportSetupRelationshipPanel";
import { ProjectReportSetupRelationshipsFormValues } from "types";

type Props = {
  readonly projectReportSetup: ProjectReportSetup | null;
  readonly projectModelRoomName: string;
  readonly projectModelElements: ReadonlyArray<ProjectModelElement>;
  readonly values: ProjectReportSetupRelationshipsFormValues;
  readonly setValues: (values: ProjectReportSetupRelationshipsFormValues) => void;
};

export const ProjectReportSetupRelationshipPanelGroup: FunctionComponent<Props> = ({ projectReportSetup, projectModelRoomName, projectModelElements, values, setValues }) => (
  <div className="project-report-setup-relationship-panel-group">
    <span className="project-report-setup-relationship-panel-group-title">{projectModelRoomName}</span>
    {projectModelElements.map((projectModelElement) => (
      <ProjectReportSetupRelationshipPanel key={projectModelElement.id} projectReportSetup={projectReportSetup} projectModelElement={projectModelElement} values={values} setValues={setValues} />
    ))}
  </div>
);
