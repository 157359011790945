import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useCustomerContext, useRouting } from "hooks";

export const CustomerView: FunctionComponent = () => {
  const { toCustomersView, toCustomerUpdateView, toCustomerDeleteView, toCustomerCredentialsResendView } = useRouting();
  const { customer } = useCustomerContext();
  useLayout({
    sidebarActions: [],
    sidebarButtons: [
      {
        label: "Renvoyer les identifiants",
        href: toCustomerCredentialsResendView(customer.id),
        permissions: [[Permission.RESEND_CUSTOMERS_CREDENTIALS]],
        enabled: customer.hasAccount,
      },
      {
        label: "Modifier le client",
        href: toCustomerUpdateView(customer.id),
        permissions: [[Permission.UPDATE_CUSTOMERS]],
      },
      {
        label: "Supprimer le client",
        href: toCustomerDeleteView(customer.id),
        permissions: [[Permission.DELETE_CUSTOMERS]],
        enabled: customer.contractIds.length === 0,
      },
    ],
  });
  return (
    <View
      title={customer.fullName}
      permissions={[[Permission.READ_CUSTOMERS]]}
      breadcrumbItems={[
        {
          label: "Clients",
          href: toCustomersView(),
        },
        {
          label: customer.fullName,
        },
      ]}
    ></View>
  );
};
