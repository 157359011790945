import "./index.less";
import { FunctionComponent, useState } from "react";
import { View, ViewFilters } from "buildingBlocks";
import { Permission } from "enums";
import { useAppContext, useConstuctionReportByUnitNumberDocumentContext, useLayout, useProjectContext, useRouting } from "hooks";
import { Button, ButtonToolbar, SelectPicker } from "rsuite";
import { ProjectUnit } from "graphql/__generated__/ProjectUnit";
import { ConstructionSheetPerUnitDocument } from "documents/ConstructionSheetPerUnitDocument";

export const ProjectReportsConstructionSheetPerUnitView: FunctionComponent = () => {
  const { toProjectsView, toProjectView, toProjectReportsView } = useRouting();
  const { providers, customers } = useAppContext();
  const { project, projectUnits, projectActivities, projectProducts, projectModels, projectContracts } = useProjectContext();
  const [projectUnit, setProjectUnit] = useState<ProjectUnit | null>(projectUnits.find((_) => true) ?? null);
  const projectModel = projectModels.find((projectModel) => projectModel.id === projectUnit?.projectModelId);
  const projectContract = projectContracts.find((projectContract) => projectContract.id === projectUnit?.currentProjectContractId);
  const { download } = useConstuctionReportByUnitNumberDocumentContext("construction-report-by-unit-number-document-page", `FICHE DE CONSTRUCTION PAR UNITÉ (#${projectUnit?.number ?? "??"})`);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      id="construction-report-by-unit-number-view"
      permissions={[[Permission.READ_PROJECT_REPORTS], [Permission.READ_PROJECT_REPORTS_CONSTRUCTION_SHEET_PER_UNIT]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Rapports",
          href: toProjectReportsView(project.id),
        },
        {
          label: "Fiche de construction par numéro d'unité",
        },
      ]}
    >
      <ViewFilters>
        <SelectPicker
          size="md"
          label="Unité #"
          defaultValue={projectUnit?.id ?? undefined}
          onChange={(value) => {
            setProjectUnit(projectUnits.find((projectUnit) => projectUnit.id === value) ?? null);
          }}
          data={projectUnits.map((projectUnit) => ({
            label: projectUnit.number,
            value: projectUnit.id,
          }))}
          cleanable={false}
          style={{ width: 250 }}
        />
      </ViewFilters>
      <hr />
      <ButtonToolbar>
        <Button
          appearance="primary"
          size="lg"
          onClick={download}
          disabled={
            !(
              projectContract &&
              !projectContract.isCanceled &&
              !projectContract.isCustomized &&
              !projectContract.choices.find((projectContractChoice) => projectContractChoice.id === projectContract.currentProjectContractChoiceId)!.isDraft
            )
          }
        >
          Télécharger
        </Button>
      </ButtonToolbar>
      <hr />
      <ConstructionSheetPerUnitDocument
        project={project}
        projectUnit={projectUnit}
        projectProducts={projectProducts}
        projectModel={projectModel}
        projectActivities={projectActivities}
        projectContract={projectContract}
        projectModels={projectModels}
        projectUnits={projectUnits}
        providers={providers}
        customers={customers}
      />
      <ConstructionSheetPerUnitDocument
        pageClassName="construction-report-by-unit-number-document-page"
        project={project}
        projectUnit={projectUnit}
        projectProducts={projectProducts}
        projectModel={projectModel}
        projectActivities={projectActivities}
        projectContract={projectContract}
        projectModels={projectModels}
        projectUnits={projectUnits}
        providers={providers}
        customers={customers}
      />
    </View>
  );
};
