import "./index.less";
import { FunctionComponent, useState } from "react";
import { Button, ButtonToolbar, Form, InputNumber, SelectPicker } from "rsuite";
import { useProjectContext } from "hooks";
import { FormError } from "buildingBlocks";
import produce from "immer";
import { PersistentStorageOperation } from "graphql/globalTypes";
import { ProjectModelTags } from "components/ProjectModelTags";

type ProjectUnitFormValues = {
  readonly number: number;
  readonly floor: number;
  readonly numberOfBedrooms: number;
  readonly numberOfBathrooms: number;
  readonly projectModelId: string | null;
};

type Props = {
  readonly _operation: PersistentStorageOperation;
  readonly initialValues: ProjectUnitFormValues;
  readonly errors: string[];
  readonly loading: boolean;
  readonly onSubmit: (values: ProjectUnitFormValues) => Promise<void>;
  readonly onCancel: () => void;
};

export const ProjectUnitForm: FunctionComponent<Props> = ({ _operation, initialValues, errors, loading, onSubmit, onCancel }) => {
  const [values, setValues] = useState<ProjectUnitFormValues>(initialValues);
  const { projectModels } = useProjectContext();
  return (
    <>
      <FormError errors={errors} />
      <Form className="form" fluid>
        <Form.Group>
          <Form.ControlLabel>Numéro</Form.ControlLabel>
          <InputNumber
            readOnly={_operation === PersistentStorageOperation.UPDATE}
            min={1}
            max={75000}
            value={values.number}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.number = Number(value);
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Étage</Form.ControlLabel>
          <InputNumber
            readOnly={_operation === PersistentStorageOperation.UPDATE}
            min={1}
            max={75000}
            value={values.floor}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.floor = Number(value);
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Nombre de chambres à coucher</Form.ControlLabel>
          <InputNumber
            min={1}
            max={25}
            value={values.numberOfBedrooms}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.numberOfBedrooms = Number(value);
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Nombre de salles de bain</Form.ControlLabel>
          <InputNumber
            min={1}
            max={25}
            value={values.numberOfBathrooms}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.numberOfBathrooms = Number(value);
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Modèle</Form.ControlLabel>
          <SelectPicker
            cleanable={_operation === PersistentStorageOperation.CREATE}
            value={values.projectModelId}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.projectModelId = value;
                })
              );
            }}
            data={projectModels
              .filter((projectModel) => !projectModel.isDraft)
              .map((projectModel) => ({
                label: projectModel.name,
                value: projectModel.id,
              }))}
          />
        </Form.Group>
        <Form.Group>
          <ProjectModelTags projectModelTags={projectModels.find((projectModel) => projectModel.id === values.projectModelId)?.tags ?? []} />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              loading={loading}
              appearance="primary"
              onClick={() => {
                onSubmit(values);
              }}
            >
              Enregistrer
            </Button>
            <Button appearance="default" onClick={onCancel}>
              Annuler
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};
