import "./index.less";
import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext, useProjectModelContext } from "hooks";
import { ProjectModelVibeForm } from "forms";
import { useList } from "react-use";
import { useCreateProjectModelVibeMutation } from "graphql/mutations";

export const ProjectModelVibeCreateView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView, toProjectModelsView, toProjectModelView, toProjectModelVibesView, toProjectModelVibeView } = useRouting();
  const { project, projectProducts, projectProductCategories } = useProjectContext();
  const { projectModel } = useProjectModelContext();
  const [errors, { set: setErrors }] = useList<string>();
  const { createProjectModelVibe, createProjectModelVibeLoading } = useCreateProjectModelVibeMutation(
    (projectId, projectModelId, projectModelVibeId) => {
      navigate(toProjectModelVibeView(projectId, projectModelId, projectModelVibeId));
    },
    (errors) => {
      setErrors(errors);
    }
  );
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      fluid
      title="Créer une ambiance"
      permissions={[[Permission.READ_PROJECT_MODEL_VIBES, Permission.CREATE_PROJECT_MODEL_VIBES]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Modèles",
          href: toProjectModelsView(project.id),
        },
        {
          label: projectModel.name,
          href: toProjectModelView(project.id, projectModel.id),
        },
        {
          label: "Ambiances",
        },
      ]}
    >
      <ProjectModelVibeForm
        project={project}
        projectModel={projectModel}
        projectProducts={projectProducts}
        projectProductCategories={projectProductCategories}
        initialValues={{
          projectModelVibeName: "",
          projectModelVibeItems: projectModel.elements.map((projectModelElement) => ({
            projectModelElementId: projectModelElement.id,
            projectModelElementOptionId: projectModelElement.defaultOptionId,
          })),
        }}
        errors={errors}
        loading={createProjectModelVibeLoading}
        onCancel={() => {
          navigate(toProjectModelVibesView(project.id, projectModel.id));
        }}
        onSubmit={async (values) => {
          await createProjectModelVibe(project.id, projectModel.id, {
            name: values.projectModelVibeName,
            items: values.projectModelVibeItems.map((item) => ({
              projectModelElementId: item.projectModelElementId,
              projectModelElementOptionId: item.projectModelElementOptionId,
            })),
          });
        }}
      />
    </View>
  );
};
