import { createContext, FunctionComponent, PropsWithChildren, useCallback } from "react";
import { useList } from "react-use";
import { ContainerLoader } from "buildingBlocks";
import { useFetchCountriesQuery, useFetchCustomersQuery, useFetchProjectsQuery, useFetchProvidersQuery, useFetchRolesQuery, useFetchUsersQuery } from "graphql/queries";
import { Customer } from "graphql/__generated__/Customer";
import { Project } from "graphql/__generated__/Project";
import { Provider } from "graphql/__generated__/Provider";
import { FetchProjects_projects } from "graphql/__generated__/FetchProjects";
import { Country } from "graphql/__generated__/Country";
import { CreateProject_payload_project } from "graphql/__generated__/CreateProject";
import { UpdateProject_payload_project } from "graphql/__generated__/UpdateProject";
import { User } from "graphql/__generated__/User";
import { Role } from "graphql/__generated__/Role";

type AppContextValue = {
  readonly countries: Country[];
  readonly customers: Customer[];
  readonly upsertCustomer: (customer: Customer) => void;
  readonly removeCustomer: (customerId: string) => void;
  readonly projects: Project[];
  readonly upsertProject: (project: Project) => void;
  readonly removeProject: (projectId: string) => void;
  readonly providers: Provider[];
  readonly upsertProvider: (provider: Provider) => void;
  readonly removeProvider: (providerId: string) => void;
  readonly users: User[];
  readonly upsertUser: (user: User) => void;
  readonly roles: Role[];
};

const initialValue: AppContextValue = {
  countries: [],
  customers: [],
  upsertCustomer: () => {},
  removeCustomer: () => {},
  projects: [],
  upsertProject: () => {},
  removeProject: () => {},
  providers: [],
  upsertProvider: () => {},
  removeProvider: () => {},
  users: [],
  upsertUser: () => {},
  roles: [],
};

export const AppContext = createContext<AppContextValue>(initialValue);

export const AppProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [countries, { set: setCountries }] = useList<Country>(initialValue.countries);
  const { loading: loadingCountries } = useFetchCountriesQuery((countries) => {
    setCountries([...countries]);
  });
  const [customers, { set: setCustomers, upsert: upsertCustomer, removeAt: removeCustomerAt }] = useList<Customer>(initialValue.customers);
  const { loading: loadingCustomers } = useFetchCustomersQuery((customers) => {
    setCustomers([...customers]);
  });
  const upsertCustomerInternal = useCallback(
    (customer: Customer) => {
      upsertCustomer((left, right) => left.id === right.id, customer);
    },
    [upsertCustomer]
  );
  const removeCustomerInternal = useCallback(
    (customerId: string) => {
      const index = customers.findIndex((customer) => customer.id === customerId);
      if (index !== -1) {
        removeCustomerAt(index);
      }
    },
    [customers, removeCustomerAt]
  );
  const [providers, { set: setProviders, upsert: upsertProvider, removeAt: removeProviderAt }] = useList<Provider>(initialValue.providers);
  const { loading: loadingProviders } = useFetchProvidersQuery((providers) => {
    setProviders([...providers]);
  });
  const upsertProviderInternal = useCallback(
    (provider: Provider) => {
      upsertProvider((left, right) => left.id === right.id, provider);
    },
    [upsertProvider]
  );
  const removeProviderInternal = useCallback(
    (providerId: string) => {
      const index = providers.findIndex((provider) => provider.id === providerId);
      if (index !== -1) {
        removeProviderAt(index);
      }
    },
    [providers, removeProviderAt]
  );
  const [projects, { set: setProjects, upsert: upsertProject, removeAt: removeProjectAt }] = useList<Project & FetchProjects_projects>(initialValue.projects);
  const { loading: loadingProjects } = useFetchProjectsQuery((projects) => {
    setProjects([...projects]);
  });
  const upsertProjectInternal = useCallback(
    (project: FetchProjects_projects | CreateProject_payload_project | UpdateProject_payload_project) => {
      upsertProject((left, right) => left.id === right.id, project);
    },
    [upsertProject]
  );
  const removeProjectInternal = useCallback(
    (projectId: string) => {
      const index = projects.findIndex((project) => project.id === projectId);
      if (index !== -1) {
        removeProjectAt(index);
      }
    },
    [projects, removeProjectAt]
  );
  const [users, { set: setUsers, upsert: upsertUser }] = useList<User>(initialValue.users);
  const { loading: loadingUsers } = useFetchUsersQuery((users) => {
    setUsers([...users]);
  });
  const upsertUserInternal = useCallback(
    (user: User) => {
      upsertUser((left, right) => left.id === right.id, user);
    },
    [upsertUser]
  );
  const [roles, { set: setRoles }] = useList<Role>(initialValue.roles);
  const { loading: loadingRoles } = useFetchRolesQuery((roles) => {
    setRoles([...roles]);
  });
  if (loadingCountries || loadingCustomers || loadingProviders || loadingProjects || loadingUsers || loadingRoles) {
    return <ContainerLoader />;
  }
  return (
    <AppContext.Provider
      value={{
        countries,
        customers,
        upsertCustomer: upsertCustomerInternal,
        removeCustomer: removeCustomerInternal,
        providers,
        upsertProvider: upsertProviderInternal,
        removeProvider: removeProviderInternal,
        projects: projects.map((project) => ({
          ...project,
          label: `[${project.code}] ${project.name}`,
        })),
        upsertProject: upsertProjectInternal,
        removeProject: removeProjectInternal,
        users,
        roles,
        upsertUser: upsertUserInternal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
