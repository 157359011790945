import { FunctionComponent } from "react";
import { View } from "buildingBlocks";
import { Permission } from "enums";
import { useLayout, useRouting, useProjectContext, useProjectContractContext } from "hooks";
import { useUpdateProjectContractRequestsMutation } from "graphql/mutations";
import { useList } from "react-use";
import { ProjectContractRequestForm } from "forms/ProjectContractRequestForm";

export const ProjectContractRequestsUpdateView: FunctionComponent = () => {
  const { searchParams, navigate, toProjectsView, toProjectView, toProjectContractsView, toProjectContractView } = useRouting();
  const projectModelElementId = searchParams.get("projectModelElementId") ?? null;
  const { project, projectModels } = useProjectContext();
  const { projectContract } = useProjectContractContext();
  const projectModel = projectModels.find((projectModel) => projectModel.id === projectContract.projectModelId);
  const [errors, { set: setErrors }] = useList<string>([]);
  const { updateProjectContractRequests, updateProjectContractRequestsLoading } = useUpdateProjectContractRequestsMutation((projectId, customerContractId) => {
    navigate(toProjectContractView(projectId, customerContractId));
  }, setErrors);
  useLayout({
    sidebarActions: [],
    sidebarButtons: [],
  });
  return (
    <View
      title="Modifier une demande client"
      permissions={[[Permission.READ_PROJECT_CONTRACTS, Permission.UPDATE_PROJECT_CONTRACT_REQUESTS]]}
      breadcrumbItems={[
        {
          label: "Projets",
          href: toProjectsView(),
        },
        {
          label: project.label,
          href: toProjectView(project.id),
        },
        {
          label: "Contrats",
          href: toProjectContractsView(project.id),
        },
        {
          label: `Unité #${projectContract.projectUnit.number} (${projectContract.customer.firstName} ${projectContract.customer.lastName})`,
          href: toProjectContractView(project.id, projectContract.id),
        },
      ]}
    >
      <ProjectContractRequestForm
        initialValues={{
          projectModelElementId: projectModelElementId,
          content: null,
        }}
        projectModelElementId={projectModelElementId}
        projectModelElements={projectModel?.elements ?? []}
        projectModelRooms={projectModel?.rooms ?? []}
        projectContractRequests={projectContract.requests}
        loading={updateProjectContractRequestsLoading}
        errors={errors}
        onSubmit={async (values) => {
          if (projectModel) {
            await updateProjectContractRequests(project.id, projectContract.id, {
              projectModelId: projectModel.id,
              projectContractRequests:
                values.projectModelElementId !== null
                  ? [
                      {
                        projectModelElementId: values.projectModelElementId,
                        content: values.content === "" ? null : values.content,
                      },
                    ]
                  : [],
            });
          }
        }}
        onCancel={() => {
          navigate(toProjectContractView(project.id, projectContract.id));
        }}
      />
    </View>
  );
};
