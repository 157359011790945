import { createContext, FunctionComponent, useState, useEffect, useCallback } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useList } from "react-use";
import { ContainerLoader } from "buildingBlocks";
import { useFindProjectLazyQuery } from "graphql/queries";
import { Project } from "graphql/__generated__/Project";
import { ProjectActivity } from "graphql/__generated__/ProjectActivity";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import { ProjectProduct } from "graphql/__generated__/ProjectProduct";
import { ProjectProductCategory } from "graphql/__generated__/ProjectProductCategory";
import { ProjectUnit } from "graphql/__generated__/ProjectUnit";
import { ProjectModelVibe } from "graphql/__generated__/ProjectModelVibe";
import { ProjectReportSetup } from "graphql/__generated__/ProjectReportSetup";
import produce from "immer";
import { ProjectContract } from "graphql/__generated__/ProjectContract";
import { Customer } from "graphql/__generated__/Customer";
import { useAppContext, useAuth, useRouting } from "hooks";

type Params = {
  readonly projectId: string;
};

type ProjectContextValue = {
  readonly project: Project;
  readonly upsertProject: (project: Project) => void;
  readonly projectActivities: ProjectActivity[];
  readonly upsertProjectActivity: (projectId: string, projectActivity: ProjectActivity) => void;
  readonly removeProjectActivity: (projectId: string, projectActivityId: string) => void;
  readonly projectModels: ProjectModel[];
  readonly upsertProjectModel: (projectId: string, projectModel: ProjectModel) => void;
  readonly removeProjectModel: (projectId: string, projectModelId: string) => void;
  readonly upsertProjectModelVibe: (projectId: string, projectModelId: string, projectModelVibe: ProjectModelVibe) => void;
  readonly projectProductCategories: ProjectProductCategory[];
  readonly upsertProjectProductCategory: (projectId: string, projectProductCategory: ProjectProductCategory) => void;
  readonly removeProjectProductCategory: (projectId: string, projectActivityCategoryId: string) => void;
  readonly projectProducts: ProjectProduct[];
  readonly upsertProjectProduct: (projectId: string, projectProduct: ProjectProduct) => void;
  readonly removeProjectProduct: (projectId: string, projectProductId: string) => void;
  readonly projectUnits: ProjectUnit[];
  readonly upsertProjectUnit: (projectId: string, projectUnit: ProjectUnit) => void;
  readonly removeProjectUnit: (projectId: string, projectUnitId: string) => void;
  readonly projectContracts: (ProjectContract & {
    readonly customer: Customer;
    readonly projectUnit: ProjectUnit;
    readonly projectModel: ProjectModel | null;
  })[];
  readonly upsertProjectContract: (projectId: string, projectContract: ProjectContract) => void;
  readonly projectReportSetups: ProjectReportSetup[];
  readonly upsertProjectReportSetup: (projectId: string, projectReportSetup: ProjectReportSetup) => void;
  readonly removeProjectReportSetup: (projecId: string, projectReportSetupId: string) => void;
};

const initialValue: ProjectContextValue = {
  project: {
    __typename: "Project",
    id: "",
    createdAt: "",
    updatedAt: "",
    code: "",
    name: "",
    description: null,
    customOptionUnitPrice: 0,
    label: "",
  },
  upsertProject: () => {},
  projectActivities: [],
  upsertProjectActivity: () => {},
  removeProjectActivity: () => {},
  projectModels: [],
  upsertProjectModel: () => {},
  removeProjectModel: () => {},
  upsertProjectModelVibe: () => {},
  projectProductCategories: [],
  upsertProjectProductCategory: () => {},
  removeProjectProductCategory: () => {},
  projectProducts: [],
  upsertProjectProduct: () => {},
  removeProjectProduct: () => {},
  projectUnits: [],
  upsertProjectUnit: () => {},
  removeProjectUnit: () => {},
  projectContracts: [],
  upsertProjectContract: () => {},
  projectReportSetups: [],
  upsertProjectReportSetup: () => {},
  removeProjectReportSetup: () => {},
};

export const ProjectContext = createContext<ProjectContextValue>(initialValue);

export const ProjectProvider: FunctionComponent = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const { customers } = useAppContext();
  const { projectId } = useParams<Params>();
  const [project, setProject] = useState<Project>(initialValue.project);
  const upsertProject = useCallback(
    (project: Project) => {
      setProject(project);
    },
    [setProject]
  );
  const [projectActivities, { set: setProjectActivities, upsert: upsertProjectActivity, removeAt: removeProjectActivityAt }] = useList<ProjectActivity>(initialValue.projectActivities);
  const upsertProjectActivityInternal = useCallback(
    (projectId: string, projectActivity: ProjectActivity) => {
      if (projectId === project.id) {
        upsertProjectActivity((left, right) => left.id === right.id, projectActivity);
      }
    },
    [project, upsertProjectActivity]
  );
  const removeProjectActivityInternal = useCallback(
    (projectId: string, projectActivityId: string) => {
      if (projectId === project.id) {
        const index = projectActivities.findIndex((activity) => activity.id === projectActivityId);
        if (index !== -1) {
          removeProjectActivityAt(index);
        }
      }
    },
    [project, projectActivities, removeProjectActivityAt]
  );
  const [projectModels, { set: setProjectModels, upsert: upsertProjectModel, removeAt: removeProjectModelAt }] = useList<ProjectModel>(initialValue.projectModels);
  const upsertProjectModelInternal = useCallback(
    (projectId: string, projectModel: ProjectModel) => {
      if (projectId === project.id) {
        upsertProjectModel((left, right) => left.id === right.id, projectModel);
      }
    },
    [project, upsertProjectModel]
  );
  const removeProjectModelInternal = useCallback(
    (projectId: string, projectModelId: string) => {
      if (projectId === project.id) {
        const index = projectModels.findIndex((model) => model.id === projectModelId);
        if (index !== -1) {
          removeProjectModelAt(index);
        }
      }
    },
    [project, projectModels, removeProjectModelAt]
  );
  const upsertProjectModelVibeInternal = useCallback(
    (projectId: string, projectModelId: string, projectModelVibe: ProjectModelVibe) => {
      if (projectId === project.id) {
        setProjectModels(
          produce(projectModels, (draft) => {
            const projectModel = draft.find((projectModel) => projectModel.id === projectModelId);
            if (projectModel) {
              const index = projectModel.vibes.findIndex((vibe) => vibe.id === projectModelVibe.id);
              if (index !== -1) {
                projectModel.vibes[index] = projectModelVibe as any;
              } else {
                projectModel.vibes.push(projectModelVibe as any);
              }
            }
          })
        );
      }
    },
    [project, projectModels, setProjectModels]
  );
  const [projectProductCategories, { set: setProjectProductCategories, upsert: upsertProjectProductCategory, removeAt: removeProjectProductCategoryAt }] = useList<ProjectProductCategory>(
    initialValue.projectProductCategories
  );
  const upsertProjectProductCategoryInternal = useCallback(
    (projectId: string, projectProductCategory: ProjectProductCategory) => {
      if (projectId === project.id) {
        upsertProjectProductCategory((left, right) => left.id === right.id, projectProductCategory);
      }
    },
    [project, upsertProjectProductCategory]
  );
  const removeProjectProductCategoryInternal = useCallback(
    (projectId: string, projectProductCategoryId: string) => {
      if (projectId === project.id) {
        const index = projectProductCategories.findIndex((productCategory) => productCategory.id === projectProductCategoryId);
        if (index !== -1) {
          removeProjectProductCategoryAt(index);
        }
      }
    },
    [project, projectProductCategories, removeProjectProductCategoryAt]
  );
  const [projectProducts, { set: setProjectProducts, upsert: upsertProjectProduct, removeAt: removeProjectProductAt }] = useList<ProjectProduct>(initialValue.projectProducts);
  const upsertProjectProductInternal = useCallback(
    (projectId: string, projectProduct: ProjectProduct) => {
      if (projectId === project.id) {
        upsertProjectProduct((left, right) => left.id === right.id, projectProduct);
      }
    },
    [project, upsertProjectProduct]
  );
  const removeProjectProductInternal = useCallback(
    (projectId: string, projectProductId: string) => {
      if (projectId === project.id) {
        const index = projectProducts.findIndex((product) => product.id === projectProductId);
        if (index !== -1) {
          removeProjectProductAt(index);
        }
      }
    },
    [project, projectProducts, removeProjectProductAt]
  );
  const [projectUnits, { set: setProjectUnits, upsert: upsertProjectUnit, removeAt: removeProjectUnitAt }] = useList<ProjectUnit>(initialValue.projectUnits);
  const upsertProjectUnitInternal = useCallback(
    (projectId: string, projectUnit: ProjectUnit) => {
      if (projectId === project.id) {
        upsertProjectUnit((left, right) => left.id === right.id, projectUnit);
      }
    },
    [project, upsertProjectUnit]
  );
  const removeProjectUnitInternal = useCallback(
    (projectId: string, projectUnitId: string) => {
      if (projectId === project.id) {
        const index = projectUnits.findIndex((unit) => unit.id === projectUnitId);
        if (index !== -1) {
          removeProjectUnitAt(index);
        }
      }
    },
    [project, projectUnits, removeProjectUnitAt]
  );
  const [projectContracts, { set: setProjectContracts, upsert: upsertProjectContract }] = useList<ProjectContract>(initialValue.projectContracts);
  const upsertProjectContractInternal = useCallback(
    (projectId: string, projectContract: ProjectContract) => {
      if (projectId === project.id) {
        upsertProjectContract((left, right) => left.id === right.id, projectContract);
      }
    },
    [project, upsertProjectContract]
  );
  const [projectReportSetups, { set: setProjectReportSetups, upsert: upsertProjectReportSetup, removeAt: removeProjectReportSetupAt }] = useList<ProjectReportSetup>(initialValue.projectReportSetups);
  const upsertProjectReportSetupInternal = useCallback(
    (projectId: string, projectReportSetup: ProjectReportSetup) => {
      if (projectId === project.id) {
        upsertProjectReportSetup((left, right) => left.id === right.id, projectReportSetup);
      }
    },
    [project, upsertProjectReportSetup]
  );
  const removeProjectReportSetupInternal = useCallback(
    (projectId: string, projectReportSetupId: string) => {
      if (projectId === project.id) {
        const index = projectReportSetups.findIndex((reportSetup) => reportSetup.id === projectReportSetupId);
        if (index !== -1) {
          removeProjectReportSetupAt(index);
        }
      }
    },
    [project, projectReportSetups, removeProjectReportSetupAt]
  );
  const [findProject, { loading }] = useFindProjectLazyQuery((project, activities, models, productCategories, products, units, contracts, reportSetups) => {
    setProject(project);
    setProjectActivities(activities);
    setProjectModels(models);
    setProjectProductCategories(productCategories);
    setProjectProducts(products);
    setProjectUnits(units);
    setProjectContracts(contracts);
    setProjectReportSetups(reportSetups);
    setLoaded(true);
  });
  const { hasProjects } = useAuth();
  const { navigate, toProjectsView } = useRouting();
  useEffect(() => {
    if (projectId && !hasProjects([projectId])) {
      navigate(toProjectsView());
    }
  }, [projectId, hasProjects, navigate, toProjectsView]);
  useEffect(() => {
    if (projectId) {
      findProject({
        variables: {
          projectId,
        },
      });
    }
  }, [projectId, findProject]);
  if (!loaded || loading) {
    return <ContainerLoader />;
  }
  return (
    <ProjectContext.Provider
      value={{
        project,
        upsertProject,
        projectActivities,
        upsertProjectActivity: upsertProjectActivityInternal,
        removeProjectActivity: removeProjectActivityInternal,
        projectModels,
        upsertProjectModel: upsertProjectModelInternal,
        removeProjectModel: removeProjectModelInternal,
        upsertProjectModelVibe: upsertProjectModelVibeInternal,
        projectProductCategories,
        upsertProjectProductCategory: upsertProjectProductCategoryInternal,
        removeProjectProductCategory: removeProjectProductCategoryInternal,
        projectProducts,
        upsertProjectProduct: upsertProjectProductInternal,
        removeProjectProduct: removeProjectProductInternal,
        projectUnits,
        upsertProjectUnit: upsertProjectUnitInternal,
        removeProjectUnit: removeProjectUnitInternal,
        projectContracts: projectContracts.map((projectContract) => ({
          ...projectContract,
          customer: customers.find((customer) => customer.id === projectContract.customerId)!,
          projectUnit: projectUnits.find((projectUnit) => projectUnit.id === projectContract.projectUnitId)!,
          projectModel: projectModels.find((projectModel) => projectModel.id === projectContract.projectModelId) ?? null,
        })),
        upsertProjectContract: upsertProjectContractInternal,
        projectReportSetups,
        upsertProjectReportSetup: upsertProjectReportSetupInternal,
        removeProjectReportSetup: removeProjectReportSetupInternal,
      }}
    >
      <Outlet />
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
