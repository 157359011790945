import { FunctionComponent, useEffect, useState } from "react";
import { Button, ButtonToolbar, Form, SelectPicker, Uploader, Toggle, InputNumber } from "rsuite";
import { FormError } from "buildingBlocks";
import { Customer } from "graphql/__generated__/Customer";
import { ProjectUnit } from "graphql/__generated__/ProjectUnit";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import produce from "immer";
import { ContractChoiceMode } from "graphql/globalTypes";
import { Project } from "graphql/__generated__/Project";

type ProjectContractCreateFormValues = {
  readonly projectUnitId: string | null;
  readonly isCustomized: boolean;
  readonly customerId: string | null;
  readonly projectModelId: string | null;
  readonly projectContractBlobFile: File | undefined;
  readonly projectContractChoiceMode: ContractChoiceMode;
  readonly projectContractChoiceBillingSettings: {
    readonly customOptionUnitPrice: number;
    readonly additionalCosts: number;
  };
};

type Props = {
  readonly readonly: boolean;
  readonly customers: Customer[];
  readonly project: Project;
  readonly projectUnits: ProjectUnit[];
  readonly projectModels: ProjectModel[];
  readonly initialValues: ProjectContractCreateFormValues;
  readonly errors: string[];
  readonly loading: boolean;
  readonly onSubmit: (values: ProjectContractCreateFormValues) => Promise<void>;
  readonly onCancel: () => void;
};

export const ProjectContractCreateForm: FunctionComponent<Props> = ({ readonly, customers, project, projectUnits, projectModels, initialValues, errors, loading, onSubmit, onCancel }) => {
  const [values, setValues] = useState<ProjectContractCreateFormValues>(initialValues);
  const projectUnit = projectUnits.find((projectUnit) => projectUnit.id === values.projectUnitId);
  useEffect(() => {
    if (projectUnit) {
      if (projectUnit.projectModelId) {
        setValues(
          produce(values, (draft) => {
            draft.projectModelId = projectUnit.projectModelId;
          })
        );
      }
    } else {
      setValues(
        produce(values, (draft) => {
          draft.projectModelId = null;
        })
      );
    }
  }, [values, setValues, projectUnit]);
  return (
    <>
      <FormError errors={errors} />
      <Form className="form" fluid>
        <Form.Group>
          <Form.ControlLabel>Unité</Form.ControlLabel>
          <SelectPicker
            readOnly={readonly}
            value={values.projectUnitId}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.projectUnitId = value!;
                })
              );
            }}
            cleanable={false}
            data={projectUnits
              .filter((projectUnit) => !projectUnit.customerId)
              .map((projectUnit) => ({
                label: projectUnit.label,
                value: projectUnit.id,
              }))}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Client</Form.ControlLabel>
          <SelectPicker
            value={values.customerId}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.customerId = value;
                })
              );
            }}
            cleanable={false}
            data={customers.map((customer) => ({
              label: customer.label,
              value: customer.id,
            }))}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Contrat (signé)</Form.ControlLabel>
          <Uploader
            action=""
            autoUpload={false}
            disabled={values.projectContractBlobFile !== undefined}
            onChange={(fileList) => {
              const file = fileList[0];
              if (file) {
                setValues(
                  produce(values, (draft) => {
                    draft.projectContractBlobFile = file.blobFile;
                  })
                );
              }
            }}
            onRemove={() => {
              setValues(
                produce(values, (draft) => {
                  draft.projectContractBlobFile = undefined;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>En personnalisation</Form.ControlLabel>
          <Toggle
            checked={values.isCustomized}
            onChange={(checked) => {
              setValues(
                produce(values, (draft) => {
                  draft.isCustomized = checked;
                })
              );
            }}
          />
        </Form.Group>
        {!values.isCustomized && (
          <Form.Group>
            <Form.ControlLabel>Modèle</Form.ControlLabel>
            <SelectPicker
              disabled={!projectUnit}
              readOnly={!!projectUnit && !!projectUnit.projectModelId}
              value={values.projectModelId}
              onChange={(value) => {
                setValues(
                  produce(values, (draft) => {
                    draft.projectModelId = value;
                    console.log(projectModels);
                    console.log(value);
                  })
                );
                console.log(values);
              }}
              data={projectModels
                .filter((projectModel) => !projectModel.isDraft)
                .map((projectModel) => ({
                  label: projectModel.name,
                  value: projectModel.id,
                }))}
            />
          </Form.Group>
        )}
        {!values.isCustomized && (
          <Form.Group>
            <Form.ControlLabel>Mode pour le choix</Form.ControlLabel>
            <SelectPicker
              cleanable={false}
              value={values.projectContractChoiceMode}
              onChange={(value) => {
                setValues(
                  produce(values, (draft) => {
                    const projectContractChoiceMode = value!;
                    draft.projectContractChoiceMode = projectContractChoiceMode;
                    if (projectContractChoiceMode === ContractChoiceMode.PROJECT_MODEL_VIBE) {
                      draft.projectContractChoiceBillingSettings.customOptionUnitPrice = 0;
                    } else {
                      if (values.projectContractChoiceBillingSettings.customOptionUnitPrice === 0) {
                        draft.projectContractChoiceBillingSettings.customOptionUnitPrice = project.customOptionUnitPrice;
                      }
                    }
                  })
                );
              }}
              data={[
                {
                  label: "L'ambiance",
                  value: ContractChoiceMode.PROJECT_MODEL_VIBE,
                },
                {
                  label: "Éléments de l'ambiance",
                  value: ContractChoiceMode.PROJECT_MODEL_VIBE_ITEMS,
                },
              ]}
            />
          </Form.Group>
        )}
        {!values.isCustomized && (
          <Form.Group>
            <Form.ControlLabel>Coût unitaire par option personnalisée</Form.ControlLabel>
            <InputNumber
              disabled={values.projectContractChoiceMode === ContractChoiceMode.PROJECT_MODEL_VIBE}
              value={values.projectContractChoiceBillingSettings.customOptionUnitPrice}
              onChange={(value) => {
                setValues(
                  produce(values, (draft) => {
                    draft.projectContractChoiceBillingSettings.customOptionUnitPrice = Number(value);
                  })
                );
              }}
              min={0}
              max={10000}
            />
          </Form.Group>
        )}
        {!values.isCustomized && (
          <Form.Group>
            <Form.ControlLabel>Coût additionnel</Form.ControlLabel>
            <InputNumber
              value={values.projectContractChoiceBillingSettings.additionalCosts}
              onChange={(value) => {
                setValues(
                  produce(values, (draft) => {
                    draft.projectContractChoiceBillingSettings.additionalCosts = Number(value);
                  })
                );
              }}
              min={0}
              max={100000}
            />
          </Form.Group>
        )}
        <Form.Group>
          <ButtonToolbar>
            <Button
              loading={loading}
              disabled={values.projectContractBlobFile === undefined || values.customerId === null}
              appearance="primary"
              onClick={() => {
                onSubmit(values);
              }}
            >
              Enregistrer
            </Button>
            <Button appearance="default" onClick={onCancel}>
              Annuler
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};
